import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'filter-checkbox',
  templateUrl: './checkbox.component.html',
})
export class FilterCheckboxComponent implements OnInit {

    @Input()
    data: any

  //  @Output()
    
    constructor() { }

    ngOnInit(): void {
    }

    onChange(event: any): void{
        console.log('event',event)
    }

}