import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Category } from '../../../libs/common/schools/category'
import { SchoolService } from 'src/app/services/school/school.service'
import { LinkDataService } from 'src/app/libs/services/link-data.service'
import { SearchSchoolsResponse } from 'src/app/models/school'
import { PageEvent } from '@angular/material/paginator';
import { Meta, Title } from '@angular/platform-browser'
declare let $: any;

@Component({
  selector: 'schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.scss']
})
export class SchoolsComponent implements OnInit, OnDestroy {
  title: string
  board: string
  schools: any
  totalPages = 0
  totalRecords = 0
  currentPage = 0
  state = ''
  city = ''
  referrer = ''
  searchMode: SearchMode
  //length = 50;
  pageSize = 10;
  pageSizeOptions = [5, 10, 25];
  pageEvent1: PageEvent;

  filterCriteria: FilterCriteria = {
    boards: [],
    grades: [],
    facilities: [],
    specialNeeds: null,
    pageNo: 0,
    pageSize: this.pageSize,
    location : {
      state: undefined,
      city: undefined
    }
  }

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private schoolService: SchoolService, private linkDataService: LinkDataService, private titleService: Title, private metaService: Meta) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.initializeTexts()
    this.searchMode = this.linkDataService.getLinkData().state ? SearchMode.ByRegion : SearchMode.ByBoard
    if(this.searchMode === SearchMode.ByRegion){
      const {state, city, board} = this.linkDataService.getLinkData()
      this.state = state
      this.city = city
      this.board = board
      this.referrer = 'home'
      this.findSchoolsByGeography()
    }else{
      this.referrer = 'menu'
      this.findSchoolsByBoard()
    }
  }

  updatePageDetails() {
    this.metaService.updateTag({ name: 'description', content: 'Eduspeed helps you find top CBSE, ICSE, IGCSE, and IB schools near you, from Nursery to International levels. Get details on admissions, fees, docs required, teaching methodology, extracurriculars and more. Discover IIT, NEET, and JEE coaching institutes nearby. Benefit from our online guided learning for Grades 6-9, focusing on Mathematics and Science for competitive exams and Olympiads.' });
    if(this.board.toLowerCase() === 'cbse'){
        this.titleService.setTitle('Best CBSE Schools in TamilNadu 2024-2025');
        this.metaService.updateTag({ name: 'keywords', content: 'CBSE schools,ICSE schools,IGcSE schools,IB schools, Special Need Schools, International schools, Nursery schools, Primary schools, Middle schools, High schools, Day care, Preschools, Play schools, Boarding schools, Day cum boarding schools, Day schools, IIT coaching, NEET coaching, JEE coaching, Online school search, Competitive exams preparation, Educational resources, K12 education, School search, School admissions, School admission info, Admissions forms, School fees, Fee details, Teaching methodology, extracurricular, co-curricular activities, infrastructure details, Find schools nearby, locality filter, Online Guided Learning Platform, Guided Learning Coaching, Grade 6-9, Quick Search by Board, Location, State, City, Grades, Facilities, Contact details, CBSE Schools'})
    }
    if(this.board.toLowerCase() === 'igcse'){
        this.titleService.setTitle('Best IGCSE Schools in TamilNadu 2024-2025');
        this.metaService.updateTag({ name: 'keywords', content: 'CBSE schools,ICSE schools,IGcSE schools,IB schools, Special Need Schools, International schools, Nursery schools, Primary schools, Middle schools, High schools, Day care, Preschools, Play schools, Boarding schools, Day cum boarding schools, Day schools, IIT coaching, NEET coaching, JEE coaching, Online school search, Competitive exams preparation, Educational resources, K12 education, School search, School admissions, School admission info, Admissions forms, School fees, Fee details,Teaching methodology, extracurricular, co-curricular activities, infrastructure details, Find schools nearby, locality filter, Online Guided Learning Platform, Guided Learning Coaching, Grade 6-9, Quick Search by Board, Location, State, City, Grades, Facilities, Contact details, IGCSE Schools'})
    }
  }

  findSchoolsByGeography(){
    this.start()
    this.filterCriteria.location.state = this.state
    this.filterCriteria.location.city = this.city
    this.filterCriteria.boards = this.board !== Category.ALL ? [this.board] : []
    this.findSchoolsByFilter()
  }

  findSchoolsByBoard(){
    switch(this.board){
      case Category.PLAY:
        this.filterCriteria.grades = ['kindergarten']
        this.findSchoolsByFilter()
        break;
      case Category.SPECIAL:
        this.filterCriteria.specialNeeds = true
        this.findSchoolsByFilter()
        break;
      case Category.BOARDING:
        this.filterCriteria.facilities = ['hostel']
        this.findSchoolsByFilter()
        break;
      default:
        this.start()
        this.filterCriteria.boards = [this.board]
        this.schoolService
        .getSchoolsByBoard(this.board, this.currentPage, this.pageSize)
        .subscribe({
          next: (response) =>  this.handleSuccessResponse(response),
          error: (error) => this.handleErrorResponse(error)
        })
    }

  }

  findSchoolsByFilter(){
    this.start()
    this.filterCriteria.pageNo = this.currentPage
    this.filterCriteria.pageSize = this.pageSize
    this.schoolService
    .getSchoolByFilter(this.filterCriteria)
    .subscribe({
      next: (response) =>  this.handleSuccessResponse(response),
      error: (error) => this.handleErrorResponse(error)
    })
  }

  initializeTexts(): void{
    this.board = this.activatedRoute.snapshot.queryParamMap.get('category')?.toUpperCase() || this.linkDataService.getLinkData().board?.toUpperCase();
    this.title = this.board === Category.ALL ? 'Search Results' : `${this.getCategory(this.board)} Schools`
  }

  private getCategory(category: string): string{
    if(!Object.keys(Category).includes(category)){
      this.router.navigateByUrl('/page-not-found')
    }
    return Category[category]
  }

  getFilterConditions(): object{
    return {
      category: this.board,
      referrer: this.referrer
    }
  }

  ngOnDestroy(): void {
   // this.schoolSubscription?.unsubscribe()
  }

  toTitleCase(str: string): string {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  handleSuccessResponse(response: SearchSchoolsResponse): void{
    this.schools = response.basicSchoolDetails
    this.totalPages = response.totalPages
    this.totalRecords = response.totalCount
    this.end()
  }

  handleErrorResponse(error: any): void{
    this.end()
    console.error('Error processing the request!', error)
  }

  pageEvent(pageNumber: number): void {
    this.currentPage = pageNumber;
    if(this.searchMode === SearchMode.ByRegion){
      this.findSchoolsByGeography()
    }else if(this.searchMode === SearchMode.ByBoard){
      this.findSchoolsByBoard()
    }else if(this.searchMode === SearchMode.ByFilter){
      this.findSchoolsByFilter()
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent1 = e;
    this.totalRecords = e.length;
    this.pageSize = e.pageSize;
    this.currentPage = e.pageIndex;
    if(this.searchMode === SearchMode.ByRegion){
      this.findSchoolsByGeography()
    }else if(this.searchMode === SearchMode.ByBoard){
      this.findSchoolsByBoard()
    }else if(this.searchMode === SearchMode.ByFilter){
      this.findSchoolsByFilter()
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  applyFilter(updatedFilterCriteria: FilterCriteria){
    this.currentPage = 0
    this.filterCriteria = updatedFilterCriteria
    this.searchMode = SearchMode.ByFilter
    this.findSchoolsByFilter()
  }

  resetFilter(){
    this.currentPage = 0
    this.filterCriteria = {
      boards: [this.board],
      specialNeeds: null,
      grades: [],
      facilities: [],
      pageNo: this.currentPage,
      pageSize: 10,
      location : {
        state: this.state,
        city: this.city
      }
    }
    this.applyFilter(this.filterCriteria)
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }

}

enum SearchMode{
    ByRegion = 'ByRegion',
    ByBoard = 'ByBoard',
    ByFilter = 'ByFilter'
}

export interface FilterCriteria {
  grades: string[];
  boards: string[];
  location: {
    state: string;
    city: string;
  },
  facilities: string[];
  pageSize: number;
  pageNo: number;
  specialNeeds: boolean | null;
}


