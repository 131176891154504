<mat-card class="container first-typography background" style="padding-top: 30px;padding-bottom: 50px;padding-left: 50px;">
    <h2 class="title">Search Coaching center</h2>
    <form (action)="search()">
      <div class="row">
        <div class="row"><h3>Search coaching center</h3></div>
        <div class="col-4">
          <mat-form-field>
            <mat-label>Coaching Center ID</mat-label>
            <input matInput [(ngModel)]="id" name="id">
          </mat-form-field>
        </div>
      </div>
    </form>

    <div class="action">
      <button mat-raised-button color="primary" (click)="search()" >Search</button>
    </div>
  </mat-card>