<app-navbar></app-navbar>

<div class="inner-banner" style="background-image: url(images/{{event?.id}}.jpg);" style="background-position: center top;">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Events</h3>
        </div>
    </div>
</div>

<div class="event">
    <div class="event-container">
        <div class="contact-form details">
            <h2>{{event.name}}</h2>
            <div [innerHtml]="contentHtml"></div>
        </div>
        <div class="register">
            <div class="contact-form registration-form">
                <h2>Register Now</h2>
                <div [innerHtml]="iFrameLink"></div>
                <div>
                    <span>Do note, this form is only a request submission, final confirmation on seat 
                        will be based on seat availability on first come first basis.</span>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>