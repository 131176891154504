<app-navbar></app-navbar>
<div class="inner-banner inner-admin">
  <div class="container">
    <div class="inner-title text-center">
      <h3>Admin</h3>
    </div>
  </div>
</div>


<mat-card class="container first-typography background"
  style="padding-top: 50px;padding-bottom: 50px;padding-left: 50px;">
  <h2 class="title">Create Announcement</h2>
  <form>
    <div class="row input">
        <mat-form-field>
          <mat-label>Announcement ID</mat-label>
          <input matInput required [(ngModel)]="announcement.id" name="id">
        </mat-form-field>
    </div>
    <div class="row input">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput required [(ngModel)]="announcement.title" name="title">
      </mat-form-field>
    </div>
    <div class="row input">
      <mat-form-field>
        <mat-label>Category</mat-label>
        <mat-select required [(ngModel)]="announcementCategory" name="category"
          (ngModelChange)="announcement.category = announcementCategory">
          <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row input">
      <mat-form-field>
        <mat-label>Add tags for this announcement</mat-label>
        <mat-chip-grid #chipGrid aria-label="Tag selection" [(ngModel)]="announcement.tags" name="tags">
          <div *ngFor="let tag of announcement.tags; track tag">
            <mat-chip-row (removed)="remove(tag)" color="accent">
              {{tag}}
              <button matChipRemove [attr.aria-label]="'remove ' + tag">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </div>
        </mat-chip-grid>
        <input placeholder="Type a tag name and enter..." #tagInput [formControl]="tagCtrl"
          [matChipInputFor]="chipGrid"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"/>
      </mat-form-field>
    </div>
  </form>

  <text-editor (content)="updateContent($event)" [buttonAction]="buttonAction"></text-editor>

  <div class="action">
    <button mat-raised-button class="submit" (click)="reset()">Cancel</button>
    <button mat-raised-button color="primary" (click)="saveContent()">Submit</button>
  </div>
</mat-card>