import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { BasicCoachingCenterDetails, CoachingCenterDetails } from 'src/app/models/coaching-center';
import { UserService } from 'src/app/services/user/user.service';
import { ModalComponent } from '../../common/modal/modal/modal.component';

@Component({
  selector: 'coaching-center-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrl: './basic-details.component.scss'
})
export class CoachingCenterBasicDetailsComponent implements OnInit, OnChanges{
  isUserLoggedIn = false;
  @Input() coachingCenter: CoachingCenterDetails;

  coachingCenterAddress: string;

  constructor(private userService: UserService, private router: Router, private dialog: MatDialog) {}

  ngOnInit() {
    this.isUserLoggedIn = this.userService.isUserLoggedIn();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.generateAddress();
  }

  generateAddress() {
    const address = this.coachingCenter.address
    if(address.branchOfficeAddressLine1 !== "" && address.branchOfficeAddressLine1 !== undefined) {
      this.coachingCenterAddress = `${address.branchOfficeAddressLine1},${address.branchOfficeAddressLine2}, ${address.branchOfficeCity}, ${address.branchOfficeState}, ${address.branchOfficePincode}`
    } else {
      this.coachingCenterAddress = `${address.headOfficeAddressLine1},${address.headOfficeAddressLine2}, ${address.headOfficeCity}, ${address.headOfficeState}, ${address.headOfficePincode}`
    }
  }
  openCoachingCenterDetails() {
    this.isUserLoggedIn ? this.router.navigateByUrl(`/coaching-center-details/${this.coachingCenter.id}`) : this.enforceUserToLoginOrRegister()
  }

  enforceUserToLoginOrRegister(): void{
    const dialogRef = this.dialog.open(ModalComponent, {
      width: 'content',
      height: 'content',
      data: {
        displayActionButtons: true,
        alignActionButtons: 'end',
        content: `You need to be a registered user to view full details of this coaching center. Kindly login or register to continue.`,
        primaryButton: ActionButton.LOGIN,
        secondaryButton: ActionButton.REGISTER
      }
    }
    )

    dialogRef.afterClosed().subscribe(result => {
      if(result === ActionButton.LOGIN || result === ActionButton.REGISTER){
        this.router.navigate([`/coaching-center-details/${this.coachingCenter.id}`], this.getExtras(result))
      }
    })
  }

  getExtras(goToTab: ActionButton): NavigationExtras {
    return {
      state:{
          redirectOptions: {
            url: `/coaching-center-details/${this.coachingCenter.id}`,
            goToTab
          }
      }
    }
  }

  addAsFavorite(){
    this.userService.addFavorite('karthik.itc@gmail.com', this.coachingCenter.id).subscribe({
      next: (res) => {},
      error: (err) => console.error('Error while adding school as favorite', err)
    })
  }

  removeFromFavorite(){
    this.userService.removeFavorite('karthik.itc@gmail.com', this.coachingCenter.id).subscribe({
      next: (res) => {},
      error: (err) => console.error('Error while removing school as favorite', err)
    })
  }

  // updateFavorite(){
  //   this.coachingCenter.favorite ? this.removeFromFavorite(): this.addAsFavorite()
  //   this.coachingCenter.favorite = !this.coachingCenter.favorite
  // }
}

enum ActionButton {
  LOGIN = 'Login',
  REGISTER = 'Register'
}