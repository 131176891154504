import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare let $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  enquiryForm: FormGroup;

  constructor(private userService: UserService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.enquiryForm = this.formBuilder.group({
      name: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
     }
     );
  }

  get f() { 
    return this.enquiryForm.controls
  }
  
  enquiry(){

    if (this.enquiryForm.invalid) {
      console.log('Registration form is invalid')
      return;
    }
    this.start()
    const request = {
      name: this.f.name.value,
      email: this.f.email.value,
      phone: this.f.mobile.value,
      subject: `General Contact Enquiry | Subject: ${this.f.subject.value}`,
      message: this.f.message.value,
      registrationCode: ''
    }
    this.userService.enquireSchool(request).subscribe({
      next: (res) => {
        this.end()
        alert(`Thank you for your message. You will receive a response from us shortly`)
      },
      error: (err) => {
        this.end()
        alert('Please try again later')
      }
    })
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }

}
