export enum Option {
    RADIO_BUTTON = 'RADIO_BUTTON',
    CHECKBOX = 'CHECKBOX',
    DROPDOWN = 'DROPDOWN'
}

export const config = {
    location: {
        legend: 'Location',
        type: Option.DROPDOWN,
        isClosed: false,
        options: []
    },
    centerType: {
        legend: 'Coaching Center',
        type: Option.DROPDOWN,
        isClosed: false,
        options: [
            {
                label: 'Entrance Exams',
                value: 'Entrance'
            },
            {
                label: 'Accounting courses/Board exams',
                value: 'Academic'
            },
            {
                label: 'Language Studies',
                value: 'Language'
            },
            {
                label: 'Recruitment',
                value: 'Recruitment'
            }
        ]
    },
    entranceExams: {
        legend: 'Entrance Exams',
        type: Option.DROPDOWN,
        isClosed: false,
        isDisplayed: false,
        options: [
            {
                label: 'NEET',
                value: 'neet'
            },
            {
                label: 'JEE',
                value: 'jee'
            },
            {
                label: 'CLAT',
                value: 'clat'
            },
            {
                label: 'IPMAT',
                value: 'ipmat'
            },
            {
                label: 'LSAT',
                value: 'lsat'
            },
            {
                label: 'SAT',
                value: 'sat'
            },
            {
                label: 'GATE',
                value: 'gate'
            },
            {
                label: 'CAT',
                value: 'cat'
            },
            {
                label: 'NATA',
                value: 'nata'
            },
            {
                label: 'CUET',
                value: 'cuet'
            },
            {
                label: 'GMAT',
                value: 'gmat'
            },
            {
                label: 'GRE',
                value: 'gre'
            },
            {
                label: 'MCAT',
                value: 'mcat'
            },
            {
                label: 'Others',
                value: 'others'
            }
        ]
    }
    
}