<app-navbar></app-navbar>

<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-title text-center">
            <h1>{{title}}</h1>
            <h2>Find best {{board}} schools in your locality</h2>
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="index.html">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">Schools</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-5" *ngIf="board !== 'ALL'">
                    <p>Results for: <span style="color: var(--mainColor)">{{board}}</span></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div>
        <div style="text-align: center;padding: 50px;" *ngIf="!schools?.length">No results found. Please try again with
            a new query!
        </div>

        <div class="row" style="margin-top: 10%;margin-bottom:10%" *ngIf="schools?.length">
            <div class="col-lg-4 col-md-6">
                <school-filter class="filter" [conditions]="getFilterConditions()" [filterCriteria]="filterCriteria"
                    (filters)="applyFilter($event)" (reset)="resetFilter()">
                </school-filter>
            </div>
            <div class="col-lg-8 col-md-6" style="padding-left: 3%;">

                <div class="col-lg-12 col-md-12">
                        <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)"
                            [length]="totalRecords" [pageSize]="pageSize" [pageIndex]="currentPage"
                            [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
                        </mat-paginator>
                </div>

                <div class="cards" *ngFor="let school of schools">
                    <school-basic-details [school]="school"></school-basic-details>
                </div>

                <div class="col-lg-12 col-md-12">
                    <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)"
                        [length]="totalRecords" [pageSize]="pageSize" [pageIndex]="currentPage"
                        [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
                    </mat-paginator>
                </div>

            </div>
        </div>
    </div>
</div>


<app-footer></app-footer>
