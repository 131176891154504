import {Component, ElementRef, ViewChild, OnInit, TemplateRef} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { encode } from 'js-base64';
import { ButtonAction } from 'src/app/models/general';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { Campaign } from 'src/app/models/event';
import { EventService } from 'src/app/services/events/event-service.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
declare let $: any

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrl: './edit-event.component.scss'
})
export class EditEventComponent {
  eventCategory: string = '';
  eventContent: string = '';
  buttonAction: ButtonAction;
  eventStatus: string = '';
  event: Campaign = {
    id: '',
    status: '',
    name: '',
    category: this.eventCategory,
    content: '',
    startDate: null,
    endDate: null,
    formLink: ''
  }
  statuses = [
    'Active',
    'Inactive'
  ]
  categories = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  events: MatTableDataSource<any>
  displayedColumns: string[] = ['id', 'name', 'category', 'action'];
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('eventForm', { static: true }) eventForm: TemplateRef<any>;  
  eventId: string | null
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  constructor(private eventService: EventService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.categories = this.eventService.getEventCategories();
  }

  search() {
    this.start()
    const {id, name, category, status} = this.event
    if( !id && !name && !category && !status ){
      this.end()
      alert('Enter atleast one search criteria')
      return
    }
    this.eventService.searchEvents(this.event).subscribe({
      next: (res:any) => {
        this.end()
        this.events = new MatTableDataSource(res.payload)
        setTimeout(() => {
          this.events.paginator = this.paginator
          this.events.sort = this.sort; 
        });
      },
      error: () => {
        this.end()
        alert('Error retrieving events')
      }
    })
  }

  openUpdateEvent(element){
    this.eventService.getEvent(element.id).subscribe({
      next: (res) => {
        this.event = res.payload.campaign
        this.eventCategory = this.event.category
        this.eventContent = this.event.content
        this.eventStatus = this.event.status
        const dialogRef = this.dialog.open(this.eventForm);
        dialogRef.afterClosed().subscribe(() => {
          this.buttonAction = ButtonAction.SAVE
        });
        this.buttonAction = undefined;
      }
    }) 
  }

  updateEvent(){
    this.start()
    const { id, name, category, content, status, formLink, startDate, endDate} = this.event
    if( !id || !name || !category || !content || !status || !formLink || !startDate || !endDate){
      this.end()
      alert('Please fill in all the mandatory fields')
      return
    }
    this.event.formLink = encode(this.event.formLink);
    this.eventService.updateEvent(this.event).subscribe({
      next: () => {
        this.dialog.closeAll()
        this.end()
        alert('Event Updated Successfully!')
      },
      error: () => {
        this.end()
        alert('Error creating event. Try again later.')
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.events.filter = filterValue.trim().toLowerCase();

    if (this.events.paginator) {
      this.events.paginator.firstPage();
    }
  }

  updateContent(event): void{
    this.event.content = encode(event)
    this.updateEvent()
  }

  saveContent(): void{
    this.buttonAction = ButtonAction.SAVE    
  }

  startDateSelected(event: MatDatepickerInputEvent<Date>) {
    this.event.startDate = event.value;
  }

  endDateSelected(event: MatDatepickerInputEvent<Date>) {
    this.event.endDate = event.value;
  }
  
  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }

}
