import {Component, OnInit} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { InsightService } from 'src/app/services/insight/insight.service';
declare let $: any
@Component({
  selector: 'create-vlog',
  templateUrl: './create-vlog.component.html',
  styleUrls: ['./create-vlog.component.css']
})
export class CreateVlogComponent implements OnInit {
  vlogCategory: string = '';
  vlog = {
    vlogId: '',
    title: '',
    youtubeLink: '',
    category: this.vlogCategory,
  }
  categories = [
    'Education Practices And Early Education', 
    'Sports', 
    'Child And Family Wellness', 
    'Higher Education',
    'Competitive Exams', 
    'Tech Corner', 
    'Daily Digest-Current Trends', 
    'GK Corner', 
    'Parenting', 
    'General', 
    'CBSE Notifications', 
    'UGC Circulars', 
    'Important Days', 
    'Announcements'
  ].sort()
  separatorKeysCodes: number[] = [ENTER, COMMA];


  constructor(private insightService: InsightService) {
  }

  ngOnInit(): void {}

  createVlog(){
    this.start()
    console.log('this.vlog',this.vlog)
    const { vlogId, title, category, youtubeLink} = this.vlog
    if( !vlogId || !title || !youtubeLink || !category){
      this.end()
      alert('Please fill in all the mandatory fields')
      return
    }
    this.insightService.createVlog(this.vlog).subscribe({
      next: () => {
        this.end()
        alert('Vlog Created Successfully!')
      },
      error: () => {
        this.end()
        alert('Error creating Vlog. Try again later.')
      }
    })
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }

}
