import {Component, ElementRef, ViewChild, OnInit, TemplateRef} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { InsightService } from 'src/app/services/insight/insight.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
declare let $: any
@Component({
  selector: 'edit-vlog',
  templateUrl: './edit-vlog.component.html',
  styleUrls: ['./edit-vlog.component.css']
})
export class EditVlogComponent implements OnInit {
  searchVlogCategory: string = null
  youtubeLink: string = null;
  searchVlog = {
    id: null,
    title: null,
    category: this.searchVlogCategory
  }
  vlogCategory: string = '';
  vlogStatus = ''
  vlog = {
    id: '',
    status: '',
    title: '',
    youtubeLink: '',
    category: this.vlogCategory,
  }
  statuses = [
    'Active',
    'Inactive'
  ]
  categories = [
    'Education Practices And Early Education', 
    'Sports', 
    'Child And Family Wellness', 
    'Higher Education',
    'Competitive Exams', 
    'Tech Corner', 
    'Daily Digest-Current Trends', 
    'GK Corner', 
    'Parenting', 
    'General', 
    'CBSE Notifications', 
    'UGC Circulars', 
    'Important Days', 
    'Announcements'
  ].sort()
  separatorKeysCodes: number[] = [ENTER, COMMA];
  vlogs: MatTableDataSource<any>;
  displayedColumns: string[] = ['id', 'title', 'category', 'action'];
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('vlogForm', { static: true }) vlogForm: TemplateRef<any>;  
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;


  constructor(private insightService: InsightService, public dialog: MatDialog) {}

  ngOnInit(): void {}

  search() {
    this.start()
    const {id, title, category} = this.searchVlog
    if( !id && !title && !category ){
      this.end()
      alert('Enter atleast one search criteria')
      return
    }
    this.insightService.searchVlogs(this.searchVlog).subscribe({
      next: (res:any) => {
        this.end()
        this.vlogs = new MatTableDataSource(res.payload)
        setTimeout(() => {
          this.vlogs.paginator = this.paginator;
          this.vlogs.sort = this.sort;
        })
      },
      error: () => {
        this.end()
        alert('Error retrieving vlogs')
      }
    })
  }

  openUpdateVlog(element){
    this.vlog = this.vlogs.data.find(vlog => vlog.id === element.id)
    this.vlogCategory = this.vlog.category
    this.vlogStatus = this.vlog.status
    this.dialog.open(this.vlogForm);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.vlogs.filter = filterValue.trim().toLowerCase();

    if (this.vlogs.paginator) {
      this.vlogs.paginator.firstPage();
    }
  }

  saveContent(): void{
    this.updateVlog();
  }

  updateVlog(){
    this.start()
    console.log('this.vlog',this.vlog)
    const { title, category, youtubeLink, status} = this.vlog
    if( !title || !youtubeLink || !category || !status){
      this.end()
      alert('Please fill in all the mandatory fields')
      return
    }
    this.insightService.updateVlog({
      vlogId: this.vlog.id,
      title: this.vlog.title,
      category: this.vlog.category,
      youtubeLink: this.vlog.youtubeLink,
      status: this.vlog.status
    }).subscribe({
      next: () => {
        this.dialog.closeAll()
        this.end()
        alert('Vlog Updated Successfully!')
      },
      error: () => {
        this.end()
        alert('Error creating Vlog. Try again later.')
      }
    })
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }

}
