import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { BasicCoachingCenterDetails, CoachingCenterDetails, CoachingCenterFilterCriteria, CoachingCenterResponse, SearchCoachingCentersResponse } from 'src/app/models/coaching-center';
import { MapsInstitutesDataModel } from 'src/app/components/google-maps/google-maps.component';

const defaultLocation = {
  state: 'Tamilnadu',
  city: 'Chennai',
  locality: 'Anna Nagar'
}

const filterCriteria = {
  location: defaultLocation,
  centerType: 'Entrance',
  entranceExams: [],
  pageSize: 10,
  pageNo: 0
};

@Injectable({
  providedIn: 'root'
})
export class CoachingCenterService {

  private _schoolServiceUrl: string;
  private _adminServiceUrl: string;

  coachingCenterFilterInput: CoachingCenterFilterCriteria = filterCriteria;

  constructor(private httpClient: HttpClient) {
    this._schoolServiceUrl = environment.endpoints.schoolService;
    this._adminServiceUrl = environment.endpoints.adminService;
  }

  public createCoachingCenter(request): Observable<any>{
    return this.httpClient.post(`${this._adminServiceUrl}/api/esadmin/coaching/add`, request);
  }

  public editCoachingCenter(request): Observable<any>{
    return this.httpClient.post(`${this._adminServiceUrl}/api/esadmin/coaching/update`,request);
  }

  public getFilteredCenters(requestBody): Observable<SearchCoachingCentersResponse> {
    return this.httpClient.post<SearchCoachingCentersResponse>(`${this._schoolServiceUrl}/api/eduspeed/coaching/filter`,requestBody);
  }

  public findCoachingCenterById(id: string): Observable<CoachingCenterResponse>{
    return this.httpClient.get<CoachingCenterResponse>(`${this._schoolServiceUrl}/api/eduspeed/coaching/${id}`)
  }

  public findCCMapMarkers(city, locality): Observable<any>{
    return this.httpClient.get(`${this._schoolServiceUrl}/api/eduspeed/coaching/markers`,
    {
      params: {
        'city': city,
        'locality': locality
      }
    }
  )
  }

  public findCoachingCenterFilterLocations(): Observable<any> {
    return this.httpClient.get('assets/json/locations/coaching-centers.json');
  }

  public getCoachingCenterFilterInput() {
    return this.coachingCenterFilterInput;
  }

  public setCoachingCenterFilterInput(filter: CoachingCenterFilterCriteria) {
    this.coachingCenterFilterInput = JSON.parse(JSON.stringify(filter));
  }

  public resetCoachingCenterFilterInput() {
    this.coachingCenterFilterInput = JSON.parse(JSON.stringify(filterCriteria));
    return this.coachingCenterFilterInput;
  }
}
