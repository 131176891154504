import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Campaign, CategoriesResponse, Event, Events } from 'src/app/models/event';
import { environment } from 'src/environments/environment';

const EventCategories = [
  'Schools', 
  'Colleges', 
  'International studies', 
  'Others'
]

@Injectable({
  providedIn: 'root'
})
export class EventService {
  
  private _schoolServiceUrl = environment.endpoints.schoolService;
  private _adminServiceUrl = environment.endpoints.adminService;
  
  constructor(private http: HttpClient) { }

  public getEventCategories() {
    return EventCategories;
  }

  public createEvent(event) {
    return this.http.post(`${this._adminServiceUrl}/api/esadmin/campaign/add`, event);
  }
  public getEvents(pageNo,pageSize, sortBy, category?): Observable<Events> {
    let params = new HttpParams()
      .set('pageNo', pageNo)
      .set('pageSize', pageSize)
      .set('sortBy', sortBy);

    if (category) {
      params = params.set('category', category);
    }
    return this.http.get<Events>(`${this._schoolServiceUrl}/api/eduspeed/campaign/all`,{
      params: params
    })
    }

    public getEvent(id): Observable<Event> {
      return this.http.get<Event>(`${this._schoolServiceUrl}/api/eduspeed/campaign/${id}`);
    }

    updateEvent(event: Campaign) {
      return this.http.post(`${this._adminServiceUrl}/api/esadmin/campaign/update`, event)
    }

    searchEvents(event: Campaign) {
      return this.http.post(`${this._adminServiceUrl}/api/esadmin/campaign/search-event`, event)
    }
}
