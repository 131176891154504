<app-navbar></app-navbar>
<div class="inner-banner inner-admin">
  <div class="container">
    <div class="inner-title text-center">
      <h3>Edit Coaching Center</h3>
    </div>
  </div>
</div>

@if (displaySearchComponent) {
    <app-search-coaching-center usedIn="Edit" (centerDetails)="setCoachingCenterData($event)"></app-search-coaching-center>
} @else {
  <app-coaching-center-form [coachingCenter]="coachingCenterData" usedInComponent="Edit" (toggleSearchComponent)="toggleSearchComponent()"></app-coaching-center-form>
}