<app-navbar></app-navbar>

<div class="inner-banner inner-career">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Careers</h3>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>We Are Looking For Aspiring Candidates</span>
        </div>

        <div class="row pt-45">
            <div class="col-12">
                <div class="faq-item__quote--flex">
                    "Success or Fulfilment is not a destination, but the road that you're on, so the journey
                    matters!!"
                </div>
                <div class="faq-item">
                    Our values are bound with creating a sustainable and pleasant work environment emphasising
                    on happy and content minds at Eduspeed, and thus offer best in Industry work life balance like
                    hybrid work model, training and cross domain progression, paid vacation etc.
                    Eduspeed is an Equal Opportunity Employer (EEO) company that promotes fairness and
                    prohibits discrimination in the workplace based on protected characteristics.
                </div>
                <div class="faq-item">
                    We are looking for energetic professionals across domains, If you wish to join a level playing
                    and growth oriented ecosystem, feel free to drop in your profile, position looking for, and
                    current location info to <a href="mail:contact@eduspeed.org"> contact&#64;eduspeed.org </a>
                    We will evaluate and respond at the earliest possible timeframe.
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>