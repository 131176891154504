import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  title: string
  content: any
  displayActionButtons = false
  primaryButton: string
  secondaryButton: string
  alignActionButtons = 'right'

  constructor(private dialogRef: MatDialogRef<ModalComponent>,  @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.title = this.data.title
    this.content = this.data.content
    this.displayActionButtons = this.data.displayActionButtons
    this.primaryButton = this.data.primaryButton
    this.secondaryButton = this.data.secondaryButton
    this.alignActionButtons = this.data.alignActionButtons ?? this.alignActionButtons
  }
}
