import {Component, ElementRef, ViewChild, inject, OnInit} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {FormControl } from '@angular/forms';
import {Observable} from 'rxjs';
import {MatChipInputEvent} from '@angular/material/chips';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { InsightService } from 'src/app/services/insight/insight.service';
import { encode, decode } from 'js-base64';
import { ButtonAction } from '../../../../../models/general';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
declare let $: any
@Component({
  selector: 'create-blog',
  templateUrl: './create-blog.component.html',
  styleUrls: ['./create-blog.component.css']
})
export class CreateBlogComponent implements OnInit {
  blogCategory: string = '';
  announcementCategory:string = ''
  blog = {
    id: '',
    title: '',
    subTitle: '',
    contentHighLight: '',
    content: '',
    category: this.blogCategory,
    schoolRegistrationCode: undefined,
    tags: [],
    author: {
      name: '',
      bio: '',
      facebook: '',
      youtube: '',
      twitter: '',
      linkedIn: ''
    },
    isPremiumBlog: false,
    blogAnnouncementCategory: null,
    isSubscriberContent: false,
  }
  buttonAction: ButtonAction
  categories = [];
  announcementCategories = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl('');
  tags: string[] = [];
  isSchoolSpecificBlog = false  

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  announcer = inject(LiveAnnouncer);

  constructor(private insightService: InsightService, private announcementService: AnnouncementService) {
  }

  ngOnInit(): void {
    this.categories = this.insightService.retrieveBlogCategory();
    this.announcementCategories = this.announcementService.getCategories();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add tag
    if (value) {
      this.blog.tags.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.blog.tags.indexOf(tag);
    if (index >= 0) {
      this.blog.tags.splice(index, 1);
      this.announcer.announce(`Removed ${tag}`);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.blog.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  updateContent(event): void{
    this.blog.content = encode(event)
    this.createBlog()
  }

  saveContent(): void{
    this.buttonAction = ButtonAction.SAVE
  }

  toggleSelection(event){
    this.isSchoolSpecificBlog = !this.isSchoolSpecificBlog
    if(!this.isSchoolSpecificBlog) this.blog.schoolRegistrationCode = undefined
  }

  togglePremiumSelection(event){
    this.blog.isPremiumBlog = !this.blog.isPremiumBlog
    if(!this.blog.isPremiumBlog) this.blog.blogAnnouncementCategory = undefined
  }

  toggleSubcriberContent(event){
    this.blog.isSubscriberContent = !this.blog.isSubscriberContent
  }

  reset(){
    this.buttonAction = ButtonAction.RESET
    this.blog = {
      id: '',
      title: '',
      subTitle: '',
      contentHighLight: '',
      content: '',
      category: this.blogCategory,
      schoolRegistrationCode: undefined,
      tags: [],
      author: {
        name: '',
        bio: '',
        facebook: '',
        youtube: '',
        twitter: '',
        linkedIn: ''
      },
      isPremiumBlog: false,
      blogAnnouncementCategory: null,
      isSubscriberContent: false,
    }
  }

  createBlog(){
    this.start()
    const { id, title, subTitle, category, tags, content, schoolRegistrationCode } = this.blog
    if( !id || !title || !subTitle || !category || !tags.length || !content || (this.isSchoolSpecificBlog && !schoolRegistrationCode)){
      this.end()
      alert('Please fill in all the mandatory fields')
      return
    }
    this.blog.blogAnnouncementCategory = this.announcementCategory
    this.insightService.createBlog(this.blog).subscribe({
      next: () => {
        this.reset()
        this.end()
        alert('Blog Created Successfully!')
      },
      error: () => {
        this.end()
        alert('Error creating blog. Try again later.')
      }
      })
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }

}
