export interface RegisterUser {
    firstName: string,
    lastName: string,
    email: string,
    mobile: string,
    password: string,
    roles: string[],
    grade?: string,
    board?: string,
    registrationType: RegistrationType

}

export interface Login {
    email: string,
    password: string
}

export enum RegistrationType {
    FACEBOOK = 'FACEBOOK',
    GOOGLE = 'GOOGLE',
    SELF = 'SELF'
}
