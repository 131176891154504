import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { CoachingCenterDetails, CoachingCenterFilterCriteria } from 'src/app/models/coaching-center';
import { CoachingCenterService } from 'src/app/services/coaching-center/coaching-center.service';
import { MapsOrganizationsType } from 'src/app/models/maps';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;

@Component({
  selector: 'app-coaching-center',
  templateUrl: './coaching-center.component.html',
  styleUrls: ['./coaching-center.component.scss']
})
export class CoachingCentersComponent implements OnInit, OnDestroy{
  title: string = 'Coaching Centers'
  coachingCenters: CoachingCenterDetails[];
  mapsOraganizationType: MapsOrganizationsType
  totalPages = 0;
  totalRecords = 0;
  currentPage = 0;
  pageSize = 10;
  pageSizeOptions = [5, 10, 25];
  pageEvent1: PageEvent;

  showFilter: boolean = false;
  filterCriteria: CoachingCenterFilterCriteria;

  constructor(private coachingService: CoachingCenterService, private titleService: Title, private metaService: Meta) {
    document.body.style.backgroundColor = "#ededed";
    this.filterCriteria = coachingService.getCoachingCenterFilterInput();
    this.currentPage = this.filterCriteria.pageNo;
    this.pageSize = this.filterCriteria.pageSize;
    this.mapsOraganizationType = MapsOrganizationsType.COACHING_CENTER
  }

  ngOnInit(): void {
      this.getCoachingCenters();
      this.updatePageDetails();
  }

  ngOnDestroy(){
    document.body.style.backgroundColor = "white";
  }

  updatePageDetails() {
    this.titleService.setTitle('Best IIT, NEET, JEE, NATA, GRE, GMAT, GATE, IELTS Coaching center in TamilNadu 2024-2025');
    this.metaService.updateTag({ name: 'description', content: 'Eduspeed helps you find the best Coaching center for competitive exams - NEET, JEE Main, JEE Advanced, GATE, GMAT, GRE, SAT, NATA and academic coaching for CA and language proficiency classes like IELTS, TOEFL , both online and offline coaching centers.' });
    this.metaService.updateTag({ name: 'keywords', content: 'IIT coaching, NEET coaching, JEE coaching,  best coaching center online , Competitive exams coaching, IELTS Coaching, TOEFL Coaching, GRE Coaching, GMAT coaching, SAT Coaching, CA Coaching, CLAT Coaching, Entrance exam coaching, Accounting courses coaching , Board exam prep coaching, Language proficiency exam coaching, Recruitment exams coaching, find best coaching center'})
  }

  getCoachingCenters(){
    this.start()
      this.coachingService.getFilteredCenters(this.filterCriteria).subscribe({
        next: (data) => this.handleSuccessResponse(data),
        error: (err) => this.handleErrorResponse(err)
      })
  }

  updateCoachingCenterFilter() {
    this.coachingService.setCoachingCenterFilterInput(this.filterCriteria)
  }

  findCoachingCentersByFilter() {
    this.filterCriteria.pageNo = this.currentPage
    this.filterCriteria.pageSize = this.pageSize
    this.updateCoachingCenterFilter();
    this.getCoachingCenters();
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent1 = e;
    this.totalRecords = e.length;
    if(this.pageSize !== e.pageSize){
      this.pageSize = e.pageSize
      this.currentPage = 0
    } else {
      this.currentPage = e.pageIndex;
    }

    this.findCoachingCentersByFilter();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  applyFilter(updatedFilterCriteria: CoachingCenterFilterCriteria){
    this.toggleFilters();
    this.currentPage = 0

    if(Array.isArray(updatedFilterCriteria.centerType)) updatedFilterCriteria.centerType = updatedFilterCriteria.centerType[0];

    this.filterCriteria = updatedFilterCriteria
    this.findCoachingCentersByFilter();
  }

  handleSuccessResponse(response) {
    this.coachingCenters = [...response.coachingCenterDetails];
    this.totalPages = response.totalPages;
    this.totalRecords = response.totalCount;
    this.end()
  }

  handleErrorResponse(error: any): void{
    this.end()
    console.error('Error processing the request!', error)
  }

  resetFilter(){
    this.currentPage = 0
    this.filterCriteria = this.coachingService.resetCoachingCenterFilterInput();
    this.applyFilter(this.filterCriteria);
  }

  toggleFilters() {
    this.showFilter = !this.showFilter
  }
  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }
}
