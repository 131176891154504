import { Component, ElementRef, OnInit, TemplateRef, ViewChild, inject} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InsightService } from 'src/app/services/insight/insight.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { encode } from 'js-base64';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ButtonAction } from '../../../../../models/general';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
declare let $: any
@Component({
  selector: 'edit-blog',
  templateUrl: './edit-blog.component.html',
  styleUrls: ['./edit-blog.component.css']
})
export class EditBlogComponent implements OnInit {
  searchBlogCategory: string = null;
  buttonAction: ButtonAction
  searchBlog = {
    id: null,
    title: null,
    category: this.searchBlogCategory,
  }
  announcementCategory: string = '';
  blogCategory: string = '';
  blogStatus: string = '';
  blogContent: string = '';
  blog = {
    id: '',
    title: '',
    subTitle: '',
    contentHighLight: '',
    content: '',
    category: '',
    schoolRegistrationCode: undefined,
    tags: [],
    status: '',
    author: {
      name: '',
      bio: '',
      facebook: '',
      youtube: '',
      twitter: '',
      linkedIn: ''
    },
    isPremiumBlog: false,
    blogAnnouncementCategory: null,
    isSubscriberContent: false,
  }
  statuses = [
    'Active',
    'Inactive'
  ]
  categories = [];
  announcementCategories = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl('');
  tags: string[] = [];
  isSchoolSpecificBlog = false  
  blogs: MatTableDataSource<any>;
  displayedColumns: string[] = ['id', 'title', 'subTitle', 'category', 'action'];
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('blogForm', { static: true }) blogForm: TemplateRef<any>;  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  announcer = inject(LiveAnnouncer);

  
  constructor(private insightService: InsightService, public dialog: MatDialog, private announcementService: AnnouncementService) {
  }

  ngOnInit(): void {
    this.categories = this.insightService.retrieveBlogCategory();
    this.announcementCategories = this.announcementService.getCategories();
  }

  search(){
    this.start()
    const { id, title, category } = this.searchBlog
    if( !id && !title && !category){
      this.end()
      alert('Enter atleast one search criteria')
      return
    }
    this.blog.blogAnnouncementCategory = this.announcementCategory
    this.insightService.searchBlogs(this.searchBlog).subscribe({
      next: (res:any) => {
        this.end()
        this.blogs = new MatTableDataSource(res.payload)
        setTimeout(() => {
          this.blogs.paginator = this.paginator;
          this.blogs.sort = this.sort;
        })
      },
      error: () => {
        this.end()
        alert('Error retrieving blogs')
      }
    })
  }

  openUpdateBlog(element){
    this.insightService.getBlog(element.id).subscribe({
      next: (res:any) => {
        this.blog = res.payload
        this.blogCategory = this.blog.category
        this.blogStatus = this.blog.status
        this.blogContent = this.blog.content
        this.announcementCategory = this.blog.blogAnnouncementCategory
        const dialogRef = this.dialog.open(this.blogForm);
        dialogRef.afterClosed().subscribe(() => {
          this.buttonAction = ButtonAction.SAVE
        });
        this.buttonAction = undefined;
      }
    }) 
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.blogs.filter = filterValue.trim().toLowerCase();

    if (this.blogs.paginator) {
      this.blogs.paginator.firstPage();
    }
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add tag
    if (value) {
      this.blog.tags.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.blog.tags.indexOf(tag);
    if (index >= 0) {
      this.blog.tags.splice(index, 1);
      this.announcer.announce(`Removed ${tag}`);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.blog.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  updateContent(event): void{
    this.blog.content = encode(event)
    this.updateBlog()
  }

  saveContent(): void{
    this.buttonAction = ButtonAction.SAVE
  }

  toggleSelection(event){
    this.isSchoolSpecificBlog = !this.isSchoolSpecificBlog
    if(!this.isSchoolSpecificBlog) this.blog.schoolRegistrationCode = undefined
  }

  togglePremiumSelection(event){
    this.blog.isPremiumBlog = !this.blog.isPremiumBlog
    if(!this.blog.isPremiumBlog) this.blog.blogAnnouncementCategory = undefined
  }

  toggleSubcriberContent(event){
    this.blog.isSubscriberContent = !this.blog.isSubscriberContent
  }

  updateBlog(){
    this.start()
    const { status, id, title, subTitle, category, tags, content, schoolRegistrationCode } = this.blog
    if( !status || !id || !title || !subTitle || !category || !tags.length || !content || (this.isSchoolSpecificBlog && !schoolRegistrationCode)){
      this.end()
      alert('Please fill in all the mandatory fields')
      return
    }
    if(!this.blog.isPremiumBlog) this.blog.blogAnnouncementCategory = null;
    this.insightService.updateBlog(this.blog).subscribe({
      next: () => {
        this.dialog.closeAll()
        this.end()
        alert('Blog Updated Successfully!')
      },
      error: () => {
        this.end()
        alert('Error updating blog. Try again later.')
      }
    })
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }

}
