
<app-navbar></app-navbar>
<div class="inner-banner inner-admin">
  <div class="container">
      <div class="inner-title text-center">
          <h3>Admin</h3>
      </div>
  </div>
</div>
  <mat-card class="container first-typography background" style="padding-top: 30px;padding-bottom: 50px;padding-left: 50px;">
  <h2 class="title">Update Blog</h2>
  <form (action)="search()">
    <div class="row">
      <div class="row"><h3>Search Blogs</h3></div>
      <div class="col-4">
        <mat-form-field>
          <mat-label>Blog ID</mat-label>
          <input matInput [(ngModel)]="searchBlog.id" name="id">
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field>
          <mat-label>Title</mat-label>
          <input matInput [(ngModel)]="searchBlog.title" name="title">
        </mat-form-field>
        <span><i>*Wildcard search also works. Ex: %CBSE%</i></span>
      </div>
      <div class="col-4">
        <mat-form-field>
          <mat-label>Category</mat-label>
          <mat-select [(ngModel)]="searchBlogCategory" name="category" (ngModelChange)="searchBlog.category = searchBlogCategory">
            <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="action">
    <button mat-raised-button color="primary" (click)="search()" >Search</button>
  </div>
</mat-card>
<mat-card class="container first-typography background" style="margin-top: 50px;padding-bottom: 50px;padding-left: 50px;" *ngIf="blogs?.data">
  <div style="text-align: center;" *ngIf="blogs?.data?.length === 0">
    No blogs found for the given search criteria.
  </div>
  <div *ngIf="blogs?.data.length">
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
    <table mat-table [dataSource]="blogs" matSort>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
    
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
        <td mat-cell *matCellDef="let element"> {{element.title}} </td>
      </ng-container>
    
      <ng-container matColumnDef="subTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sub Title </th>
        <td mat-cell *matCellDef="let element"> {{element.subTitle}} </td>
      </ng-container>
    
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
        <td mat-cell *matCellDef="let element"> {{element.category}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element"> <button mat-raised-button color="primary" (click)="openUpdateBlog(element)" >Update</button> </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" #paginator></mat-paginator>
  </div>
</mat-card>

<ng-template #blogForm>
<h2 mat-dialog-title>Update Blog</h2>
<mat-dialog-content class="mat-typography">
  <form>
    <div class="row blog">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select required [(ngModel)]="blogStatus" name="status" (ngModelChange)="blog.status = blogStatus">
          <mat-option [value]="status" *ngFor="let status of statuses">{{ status }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row blog">
      <mat-form-field>
        <mat-label>Blog ID</mat-label>
        <input matInput required [(ngModel)]="blog.id" name="id">
      </mat-form-field>
    </div>
    <div class="row blog">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput required [(ngModel)]="blog.title" name="title">
      </mat-form-field>
    </div>
    <div class="row blog">
      <mat-form-field required>
        <mat-label>Sub Title</mat-label>
        <input matInput required [(ngModel)]="blog.subTitle" name="subTitle">
      </mat-form-field>
    </div>
    <div class="row blog">
      <mat-form-field>
        <mat-label>Category</mat-label>
        <mat-select required [(ngModel)]="blogCategory" name="category" (ngModelChange)="blog.category = blogCategory">
          <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field required>
        <mat-label>Author</mat-label>
        <input matInput [(ngModel)]="blog.author.name" name="author">
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field>
        <mat-label required>Biography</mat-label>
        <input matInput [(ngModel)]="blog.author.bio" name="bio">
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field>
        <mat-label>Facebook</mat-label>
        <input matInput [(ngModel)]="blog.author.facebook" name="facebook">
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field>
        <mat-label>Youtube</mat-label>
        <input matInput  [(ngModel)]="blog.author.youtube" name="youtube">
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field>
        <mat-label>Twitter</mat-label>
        <input matInput  [(ngModel)]="blog.author.twitter" name="twitter">
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field>
        <mat-label>LinkedIn</mat-label>
        <input matInput  [(ngModel)]="blog.author.linkedIn" name="linkedIn">
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field>
        <mat-label>Add tags for this blog</mat-label>
        <mat-chip-grid #chipGrid aria-label="Tag selection" [(ngModel)]="blog.tags" required name="tags">
          <div *ngFor="let tag of blog.tags; track tag">
            <mat-chip-row (removed)="remove(tag)" color="accent">
              {{tag}}
              <button matChipRemove [attr.aria-label]="'remove ' + tag">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </div>
        </mat-chip-grid>
        <input placeholder="Type a tag name and enter..." #tagInput [formControl]="tagCtrl"
          [matChipInputFor]="chipGrid"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"/>
      </mat-form-field>
    </div>
  

    <div class="row blog">
      <mat-slide-toggle
        required 
        (change)="toggleSelection($event)" 
        [checked]="isSchoolSpecificBlog"
        labelPosition="before"
        style="margin-bottom: 20px;">
        <span style="font-family: var(--fontFamily);">Is this blog specific to a school?</span>
      </mat-slide-toggle>

      <mat-form-field required *ngIf="isSchoolSpecificBlog">
        <mat-label>School Registration Code</mat-label>
        <input required matInput  matInput [(ngModel)]="blog.schoolRegistrationCode" name="schoolRegistrationCode">
      </mat-form-field>
    </div>
    <div class="row blog">
      <mat-slide-toggle
      required 
      (change)="togglePremiumSelection($event)" 
      [checked]="blog.isPremiumBlog"
      labelPosition="before"
      style="margin-bottom: 20px;">
      <span style="font-family: var(--fontFamily);">Is this a Premium Blog?</span>
    </mat-slide-toggle>

    <mat-form-field required *ngIf="blog.isPremiumBlog">
      <mat-label>Announcement Category</mat-label>
      <mat-select required [(ngModel)]="announcementCategory" name="announcmentCategory" (ngModelChange)="blog.blogAnnouncementCategory = announcementCategory">
        <mat-option [value]="category" *ngFor="let category of announcementCategories">{{ category }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row blog">
    <mat-slide-toggle
      required 
      (change)="toggleSubcriberContent($event)" 
      [checked]="blog.isSubscriberContent"
      labelPosition="before"
      style="margin-bottom: 20px;">
      <span style="font-family: var(--fontFamily);">Is this a Subscriber content blog?</span>
    </mat-slide-toggle>
  </div>
  </form>

  <text-editor [editordoc]="blogContent" [buttonAction]="buttonAction" (content)="updateContent($event)"></text-editor>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]=false>Cancel</button>
  <button mat-raised-button (click)="saveContent()" color="primary" cdkFocusInitial>Update</button>
</mat-dialog-actions>
</ng-template>
