import { Component, Input, OnInit } from '@angular/core'
import { UserService } from '../../../services/user/user.service'
import { MatDialog } from '@angular/material/dialog'
import { ModalComponent } from '../../../components/common/modal/modal/modal.component'
import { NavigationExtras, Router } from '@angular/router'
import { BasicSchoolDetails } from 'src/app/models/school'

@Component({
  selector: 'school-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.scss']
})
export class SchoolBasicDetailsComponent implements OnInit {
  isUserLoggedIn = false;
  @Input()
  school: BasicSchoolDetails; // SchoolBasicDetails;

  constructor(private userService: UserService, private dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.isUserLoggedIn = this.userService.isUserLoggedIn();
  }

  openSchoolDetails(): void{
    this.isUserLoggedIn ? this.router.navigateByUrl(`/school-details/${this.school.id}`) : this.enforceUserToLoginOrRegister()
  }

  enforceUserToLoginOrRegister(): void{
    const dialogRef = this.dialog.open(ModalComponent, {
      width: 'content',
      height: 'content',
      data: {
        displayActionButtons: true,
        alignActionButtons: 'end',
        content: `You need to be a registered user to view full details of a school. Kindly register or login to continue.`,
        primaryButton: ActionButton.LOGIN,
        secondaryButton: ActionButton.REGISTER
      }
    }
    )

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result === ActionButton.LOGIN || result === ActionButton.REGISTER){
        this.router.navigate([`/school-details/${this.school.id}`], this.getExtras(result))
      }
    })
  }

  getExtras(goToTab: ActionButton): NavigationExtras {
    return {
      state:{
          redirectOptions: {
            url: `/school-details/${this.school.id}`,
            goToTab
          }
      }
    }
  }

  addAsFavorite(){
    this.userService.addFavorite('karthik.itc@gmail.com', this.school.registrationCode).subscribe({
      next: (res) => {},
      error: (err) => console.error('Error while adding school as favorite', err)
    })
  }

  removeFromFavorite(){
    this.userService.removeFavorite('karthik.itc@gmail.com', this.school.registrationCode).subscribe({
      next: (res) => {},
      error: (err) => console.error('Error while removing school as favorite', err)
    })
  }

  updateFavorite(){
    this.school.favorite ? this.removeFromFavorite(): this.addAsFavorite()
    this.school.favorite = !this.school.favorite
  }
}

enum ActionButton {
  LOGIN = 'Login',
  REGISTER = 'Register'
}
