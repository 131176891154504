<div class="container" *ngIf="popularSchools?.length">
    <div class="row align-items-center">
        <div class="col-lg-7 col-md-6">
            <div class="section-title mb-45">
                <span>Parents Choice</span>
                <h2>Most Popular Schools</h2>
            </div>
        </div>

        <div class="col-lg-5 col-md-6">
            <div class="place-btn">
                <a routerLink="/schools?category=ALL" class="default-btn border-radius">Check out all schools <i class='bx bx-plus'></i></a>
            </div>
        </div>   
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let school of popularSchools.slice(0,9)">
            <div class="place-card">
                <a style="cursor: pointer;" (click)="openSchoolDetails(school)" class="place-images"><img src="images/{{school.registrationCode}}_1.jpg" alt="Images"></a>

                <div class="rating">
                    <school-rating [rating]="school.rating" [ratingPosition]="'right'"> </school-rating>
                </div>

                <div class="status-tag bg-dark-orange">
                    <a style="cursor: pointer;" (click)="openSchoolDetails(school)"><h3>Parents Choice</h3></a>
                </div>

                <div class="content">
                    <a style="cursor: pointer;" (click)="openSchoolDetails(school)"><h3> {{ school.name }} </h3></a><br>
                    <span><i class="flaticon-place"></i> {{ school.address }}</span>
                </div>
            </div>
        </div>
    </div>
</div>