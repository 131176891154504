<app-navbar></app-navbar>

<div class="inner-banner inner-news" style="background-position: center top;">
    <div class="container">
        <div class="inner-title text-center">
            <h3>News Alerts</h3>
        </div>
    </div>
</div>

<div>
<div id="wrapper">
    <div id="header">
      <ul class="categories">
        <li *ngFor="let category of categories"  [ngClass]="{'activeTab': selectedCategory === category.name}"><a style="cursor: pointer;" (click)="getAnnouncementsByCategory(category)">{{ category.name }}</a></li>
      </ul>
    </div>
    <div id="content">
      <div id="featured">
        <div *ngIf="!announcements?.length" style="padding: 50px;">
          No recent announcements available for this category at the moment. Please stay tuned.
        </div>

        <ng-container *ngIf="announcements?.length">
        <div class="carousel-container" (mouseenter)="pause()" (mouseleave)="resume()">
          <div class="carousel" id="myCarousel" [style.transform]="'translateX(' + slideOffset + 'px)'">
              <div class="carousel-item-local" *ngFor="let announcement of announcements; let i = index">
                <div class="post" [id]="getId(i)">
                  <h2><a style="cursor: pointer;">{{ announcement.title }}</a></h2>
                  <p class="details"> {{ announcement.publishDate | date: 'longDate' }} | <a style="cursor: pointer;">{{ announcement.category }}</a></p>
                  <div class="thumb"><a style="cursor: pointer;"><img src="images/{{ announcement.id }}.jpg" [alt]="announcement.title" /></a></div>
                  <p [innerHtml]="announcement.content"></p>
                  <p class="tags" *ngIf="announcement.tags">TAGS: <a style="cursor: pointer;" *ngFor="let tag of announcement.tags">#{{tag}} </a></p>
                  <div class="break"></div>
                </div>
                <a class="read-more" (click)="toggleRead(i)">{{expandText}}</a>
              </div>
            </div>
            <div class="owl-nav" *ngIf="showNavigation">
              <button type="button" role="presentation" class="owl-prev" (click) = "prev()"><i class="flaticon-left-arrow-1"></i></button>
              <button type="button" role="presentation" class="owl-next" (click) = "next()"><i class="flaticon-arrow-1"></i></button>
            </div>
        </div>
        </ng-container>
      </div>

      @if (premiumBlogs.length > 0) {

        <div class="blogs-carousel">
          <h2>Popular Blogs</h2>
          <div class="row flex-no-wrap-of-xs">
            <div class="col-lg-4 col-md-6" *ngFor="let blog of premiumBlogs; let i = index">
              <div class="blog-card">
                <a style="cursor: pointer;" (click)="openBlog(blog)"><img src="images/{{ blog.id }}.jpg" alt="Images" width="650" height="500"></a>
                <div class="content">
                  <span> {{ blog.publishDate | date: 'longDate' }} </span>
                  <h3><a style="cursor: pointer;" (click)="openBlog(blog)"> {{ blog.title }} <span *ngIf="blog.subTitle"> - {{ blog.subTitle }} </span></a></h3>
                  <a style="cursor: pointer;" (click)="openBlog(blog)" class="read-more">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>



    <div id="sidebar">
      <div class="box" *ngIf="announcements?.length" style="height: 500px;overflow: hidden;">
        <h2>Popular News</h2>
        <div class="marquee" >
        <ul class="popular">
          <li *ngFor="let digest of popularNews; let i = index">
            <a (click)="goToSlide(i)" style="cursor: pointer;">{{ digest }}</a>
          </li>
        </ul>
        </div>
      </div>
      <!--
      <div class="box">
        <h2>Flickr Images</h2>
        <div class="flickr"> <a href="#"><img src="assets/img/_flickr.jpg" alt="" /></a> <a href="#"><img src="assets/img/_flickr.jpg" alt="" /></a> <a href="#"><img src="assets/img/_flickr.jpg" alt="" /></a> <a href="#"><img src="assets/img/_flickr.jpg" alt="" /></a> <a href="#"><img src="assets/img/_flickr.jpg" alt="" /></a> <a href="#"><img src="assets/img/_flickr.jpg" alt="" /></a> </div>
      </div> -->
<!--
      <div class="box">
        <h2>Featured Videos</h2>
        <div class="video-content">
          <div class="video">
            <iframe src="https://www.youtube.com/embed/dedqno7n_eU" title="Why is it hard to get a job as a fresh graduate?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
        <div class="video-content">
          <div class="video">
            <iframe src="https://www.youtube.com/embed/lYf2ruRzMj0" title="How to pick the right school for your kid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
        <div class="video-content">
          <div class="video">
            <iframe src="https://www.youtube.com/embed/Lqr69sIBszU" title="Is Overseas Education getting costly??" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
      </div> -->

      <div class="box">
        <h2>Popular Listings</h2>
        <app-popular-listings orientation="Vertical" category="Bronze"></app-popular-listings>
      </div>

      <!-- <div class="box">
        <h2>Tag Cloud</h2>
        <div class="tags"> <a href="#">Link</a> </div>
      </div>

      <div class="l">
        <div class="box">
          <h2>Categories</h2>
          <ul>
            <li><a href="#">Advertising</a></li>
            <li><a href="#">Fashion</a></li>
            <li><a href="#">Gadgets</a></li>
            <li><a href="#">Lifestyle</a></li>
            <li><a href="#">Networking</a></li>
            <li><a href="#">News</a></li>
            <li><a href="#">Sports</a></li>
          </ul>
        </div>

        <div class="box">
          <h2>Meta</h2>
          <ul>
            <li><a href="#">Log in</a></li>
            <li><a href="#">Valid XHTML</a></li>
          </ul>
        </div>
      </div>

      <div class="r">
        <div class="box">
          <h2>Archives</h2>
          <ul>
            <li><a href="#">June 2009</a></li>
            <li><a href="#">May 2009</a></li>
            <li><a href="#">April 2009</a></li>
            <li><a href="#">March 2009</a></li>
            <li><a href="#">February 2009</a></li>
          </ul>
        </div>
        <div class="box">
          <h2>Blogroll</h2>
          <ul>
            <li><a href="#">Documentation</a></li>
            <li><a href="#">Suggest Ideas</a></li>
            <li><a href="#">Support Forum</a></li>
          </ul>
        </div>
      </div>  -->
    </div>
  </div>

  <div id="footer">
    <!-- <p class="l">Copyright &copy; 2009 - <a href="#">Website Name</a> &middot; All Rights Reserved | Template by: <a href="http://www.wpthemedesigner.com/">WordPress Designer</a> | Get More <a href="#">Free CSS Templates</a> </p> -->
  </div>
</div>

<app-footer></app-footer>
