<app-navbar></app-navbar>
<div class="inner-banner inner-admin">
  <div class="container">
    <div class="inner-title text-center">
      <h3>Admin</h3>
    </div>
  </div>
</div>


<mat-card class="container first-typography background"
  style="padding-top: 50px;padding-bottom: 50px;padding-left: 50px;">
  <h2 class="title">Create Event</h2>
  <form>
    <div class="row input">
      <mat-form-field>
        <mat-label>Event ID</mat-label>
        <input matInput required [(ngModel)]="event.id" name="id">
      </mat-form-field>
    </div>
    <div class="row input">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput required [(ngModel)]="event.name" name="name">
      </mat-form-field>
    </div>
    <div class="row input">
      <mat-form-field>
        <mat-label>Category</mat-label>
        <mat-select required [(ngModel)]="eventCategory" name="category"
          (ngModelChange)="event.category = eventCategory">
          <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row input">
      <mat-form-field>
        <mat-label>Form link</mat-label>
        <input matInput required [(ngModel)]="event.formLink" name="formLink">
      </mat-form-field>
    </div>
    <div class="row input">
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput required [matDatepicker]="startDatePicker" placeholder="Choose a start date" [value]="event.startDate" (dateChange)="startDateSelected($event)">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="row input">
      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput required [matDatepicker]="endDatePicker" placeholder="Choose an end date" [value]="event.endDate" (dateChange)="endDateSelected($event)">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>

  <text-editor (content)="updateContent($event)" [buttonAction]="buttonAction"></text-editor>

  <div class="action">
    <button mat-raised-button class="submit" (click)="reset()">Cancel</button>
    <button mat-raised-button color="primary" (click)="saveContent()">Submit</button>
  </div>
</mat-card>