<app-navbar></app-navbar>
<div class="inner-banner inner-admin">
  <div class="container">
      <div class="inner-title text-center">
          <h3>Admin</h3>
      </div>
  </div>
</div>
<!-- <div class="container first-typography background" style="padding-top: 30px;padding-bottom: 50px;"> -->
  <mat-card class="container first-typography background" style="padding-top: 30px;padding-bottom: 50px;padding-left: 50px;">
    <h2 class="title">Update Announcement</h2>
    <form (action)="search()">
      <div class="row">
        <div class="row"><h3>Search Annoncement</h3></div>
        <div class="col-4">
          <mat-form-field>
            <mat-label>Announcement ID</mat-label>
            <input matInput [(ngModel)]="announcement.id" name="id">
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field>
            <mat-label>Title</mat-label>
            <input matInput [(ngModel)]="announcement.title" name="title">
          </mat-form-field>
          <span><i>*Wildcard search also works. Ex: %CBSE%</i></span>
        </div>
        <div class="col-4">
          <mat-form-field>
            <mat-label>Category</mat-label>
            <mat-select [(ngModel)]="announcementCategory" name="category" (ngModelChange)="announcement.category = announcementCategory">
              <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>

    <div class="action">
      <button mat-raised-button color="primary" (click)="search()" >Search</button>
    </div>
  </mat-card>
  <mat-card class="container first-typography background" style="margin-top: 50px;padding-bottom: 50px;padding-left: 50px;" *ngIf="announcements?.data">
    <div style="text-align: center;" *ngIf="announcements?.data?.length === 0">
      No Announcements found for the given search criteria.
    </div>

    <div *ngIf="announcements?.data.length">
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
      </mat-form-field>
      <table mat-table [dataSource]="announcements" matSort>
  
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
      
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
          <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>
      
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
          <td mat-cell *matCellDef="let element"> {{element.category}} </td>
        </ng-container>
  
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element"> <button mat-raised-button color="primary" (click)="openUpdateAnnouncement(element)" >Update</button> </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" #paginator></mat-paginator>
    </div>
  </mat-card>

    <ng-template style="width: 75vw;" #announcementForm>
      <h2 mat-dialog-title>Update Announcement</h2>
      <mat-dialog-content class="mat-typography">
        <form>
          <div class="row announcement">
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select required [(ngModel)]="announcementStatus" name="status" (ngModelChange)="announcement.status = announcementStatus">
                <mat-option [value]="status" *ngFor="let status of statuses">{{ status }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row announcement">
            <mat-form-field>
              <mat-label>Title</mat-label>
              <input matInput required [(ngModel)]="announcement.title" name="title">
            </mat-form-field>
          </div>
          <div class="row announcement">
            <mat-form-field>
              <mat-label>Category</mat-label>
              <mat-select required [(ngModel)]="announcementCategory" name="category" (ngModelChange)="announcement.category = announcementCategory">
                <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>          
          <div class="row input">
            <mat-form-field>
              <mat-label>Add tags for this announcement</mat-label>
              <mat-chip-grid #chipGrid aria-label="Tag selection" [(ngModel)]="announcement.tags" name="tags">
                <div *ngFor="let tag of announcement.tags; track tag">
                  <mat-chip-row (removed)="remove(tag)" color="accent">
                    {{tag}}
                    <button matChipRemove [attr.aria-label]="'remove ' + tag">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                </div>
              </mat-chip-grid>
              <input placeholder="Type a tag name and enter..." #tagInput [formControl]="tagCtrl"
                [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"/>
            </mat-form-field>
          </div>
        </form>

        <text-editor [editordoc]="announcementContent" [buttonAction]="buttonAction" (content)="updateContent($event)"></text-editor>
      
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-raised-button [mat-dialog-close]=false>Cancel</button>
        <button mat-raised-button (click)="saveContent()" [mat-dialog-close]=true color="primary" cdkFocusInitial>Update</button>
      </mat-dialog-actions>
      </ng-template>
  