<!-- <ol class="paginator-container">
  <li (click)="navigateToPage(1)">First page</li>
  <li (click)="navigateToPage(index - 1)">Previous page</li>
  <li
    *ngFor="let page of pagination.pages; trackBy: trackByFn"
    class="paginator-number"
    [class.active]="page === pagination.index"
    (click)="navigateToPage(page)"
  >
    {{ page }}
  </li>
  <li (click)="navigateToPage(index + 1)">Next page</li>
  <li (click)="navigateToPage(maxPages)">Last page</li>
</ol> -->

<div class="pagination-area text-center">
      <a (click)="navigateToPage(index - 1)" class="prev page-numbers link"><i class="bx bx-chevron-left"></i></a>
      <span *ngFor="let page of pagination.pages; trackBy: trackByFn">
        <span class="page-numbers current"  *ngIf="page === pagination.index">{{ page }}</span>
        <a (click)="navigateToPage(page)"  *ngIf="page !== pagination.index"  class="page-numbers link">{{ page }}</a>
      </span>
      <a (click)="navigateToPage(index + 1)" class="next page-numbers link"><i class="bx bx-chevron-right"></i></a>
</div>