<app-navbar></app-navbar>

<div class="inner-banner inner-profile">
  <div class="container">
    <div class="inner-title text-center">
      <h3>My Favorite Schools</h3>
    </div>
  </div>
</div>

<div class="team-area pt-100 pb-70 container">
  <div style="width: 90%">
    <div *ngIf="loading" style="text-align: center;padding: 50px;">
      <mat-spinner></mat-spinner>
    </div>
    <div>
      <div *ngIf="!schools?.length">
        You don't have any schools added to your favorites list!
    </div>
    
    <div *ngIf="schools?.length">
      <div class="cards" *ngFor="let school of schools">
        <school-basic-details [school]="school"></school-basic-details>
      </div>
    </div>
    </div>
  </div>
</div>