import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'school-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class SchoolRatingComponent implements OnInit {
  
  allowedRatingRange = [1,2,3,4,5]

  @Input()
  rating: number

  @Input()
  votes: number = 0;

  @Input()
  ratingPosition? = 'left'


  constructor() { }

  ngOnInit(): void {}

  getRatingPercentage(): string{
    return `width: ${this.rating/5*100}%`
  }

}