import { Component, Inject, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router, ActivatedRoute, RoutesRecognized, NavigationEnd, NavigationCancel } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider, FacebookLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { UserService } from '../../../services/user/user.service'
import { RegisterUser, RegistrationType, Login } from '../../../libs/common/user/user'
import { filter, first, pairwise } from 'rxjs/operators'
import { LinkDataService } from 'src/app/libs/services/link-data.service'
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';

declare let $: any;

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {
  @ViewChild('tabs') tabGroup: MatTabGroup;
  registrationForm: FormGroup
  submitted = false
  user: SocialUser
  loggedIn: boolean
  userRegistrationRequest: RegisterUser
  isEmailAlreadyExists = false
  isMobileAlreadyExists = false
  loginEmail: string
  loginPassword: string
  isInCorrectCredentialSupplied = false
  isValidationPending = false
  redirectUrl: string
  queryParams: any
  isLoginTabActive = false
  isOtpError = false
  showOTP = false;
  tabIndex = 0;
  state: NavigationExtras
  roles = ['Parent', 'Student', 'Guest']
  grades = [
    { label: "Class VI", value: "GRADE_6" },
    { label: "Class VII", value: "GRADE_7" },
    { label: "Class VIII", value: "GRADE_8" },
    { label: "Class IX", value: "GRADE_9" },
    { label: "Class X", value: "GRADE_10" },
    { label: "Class XI", value: "GRADE_11" },
    { label: "Class XII", value: "GRADE_12" }
  ];
  boards = ["CBSE", "ICSE", "IGCSE", "IB", "NOIS", "NDA"]

  constructor(
    private authService: SocialAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private linkDataService: LinkDataService,
    public dialog: MatDialog,) {}

  ngOnInit(): void {
    this.initializeFormData()
    this.redirectUrl = ''
    console.log('this.linkDataService.getLinkData()', this.linkDataService.getLinkData())
    const extras = this.linkDataService.getLinkData()
    console.log(extras)
    this.router.events
    .pipe(filter(event => event instanceof NavigationCancel))
    .pipe(first())
    .subscribe(({url}: NavigationCancel) => {
      console.log('previous url', url)
    });
    if(extras?.state?.redirectOptions){
        const { url, queryParams, goToTab } = extras.state.redirectOptions
        this.redirectUrl = url
        this.tabIndex = goToTab === 'Register' ? 1 : 0
        this.queryParams = extras.queryParams ?? queryParams
    }
    this.authService.authState.subscribe({
      next: (user: SocialUser) => {
        this.user = user;
        console.log('Login Successful!')
        this.router.navigate([''], {state: {linkData: {user}}})
        this.loggedIn = (user != null);
      },
      error: (err) => { console.error('error has occured', err) }
    });

  }

  validationListener(){
    this.registrationForm.get('role').valueChanges.subscribe(role => {
      if (role === 'Student') {
        this.registrationForm.controls['grade'].setValidators([Validators.required]);
        this.registrationForm.controls['board'].setValidators([Validators.required]);
      } else {
        this.registrationForm.controls['grade'].clearValidators();
        this.registrationForm.controls['board'].clearValidators();
      }
      this.registrationForm.controls['grade'].updateValueAndValidity();
      this.registrationForm.controls['board'].updateValueAndValidity();
    });
  }

  initializeFormData(){
    this.registrationForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      role: ['', Validators.required],
      grade: [''],
      board: [''],
      otp: [''],
     },
     {
      validator: MatchValidator('password', 'confirmPassword')
     }
     );
     this.validationListener();
  }

  get f() {
    return this.registrationForm.controls
  }

  register() {
    this.submitted = true;

    if (this.registrationForm.invalid) {
      return;
    }

    const user: RegisterUser = {
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      email: this.f.email.value,
      mobile: this.f.mobile.value,
      password: this.f.password.value,
      registrationType: RegistrationType.SELF,
      roles:[this.f.role.value],
      grade: this.f.grade.value,
      board: this.f.board.value,
    }
    this.userService.register(user).subscribe({
      next: (res) => {
        this.showOTP = true
        this.registrationForm.controls['otp'].setValidators([Validators.required]);
        this.registrationForm.controls['otp'].updateValueAndValidity();
      },
      error: (err) => { this.handleErrorResponse(err) }
    })
  }

  verifyOTP(){
    this.userService.validateOTP(this.f.email.value, this.f.otp.value).subscribe(res=> {
      console.log('OTP success', res)
      alert('You have been successfully registered with Eduspeed!')
      this.router.navigate([this.redirectUrl], );
    }, err => {
      this.isOtpError = err.status === 400 && err.error.status === 'ERROR'
    })
  }

  forgotPassword(){
    const dialogRef = this.dialog.open(ForgotPasswordModalDialog, {
      width: '350px',
      height: 'content',
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
      data: {
        email: ''
      }
    }
    )

    dialogRef.afterClosed().subscribe(email => {
      console.log('The dialog was closed', email);
      const EMAIL_FORMAT = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

      if(EMAIL_FORMAT.test(email)){
          this.userService.requestTemporaryPassword(email).subscribe({
           error: (err) => {
            console.error('Error when invoking api', err)
          }})
      }
    })
  }

  signInWithSelfLogin(): void {
    this.isInCorrectCredentialSupplied = false
    this.isValidationPending = false
    this.start()
    this.userService.login(this.loginEmail, this.loginPassword).pipe(first())
    .subscribe({
        next: (res) => {
            this.end()
            this.router.navigate([this.redirectUrl], {queryParams: this.queryParams});
        },
        error: (err) => {
          console.log('err', err)
            this.end()
            this.isInCorrectCredentialSupplied = err.status === 401 && err.error.message === 'Bad credentials'
            this.isValidationPending = err.status === 403 && err.error.status === 'OTP Validation Pending'
            if(!this.isInCorrectCredentialSupplied && !this.isValidationPending ){
              alert(err.error.status)
            }

        }
      });
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
  }

  signOut(): void {
    this.authService.signOut()
  }

  handleErrorResponse(response: any){
    if(response.status === 400){
     this.isEmailAlreadyExists = response.error.key === 'error.user.email.already.exists'
     this.isMobileAlreadyExists = response.error.key === 'error.user.mobile.already.exists'
    }else {
      alert('Your request cannot be processed at the moment. Please try again later.')
    }
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }
}

function MatchValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          return;
      }
      matchingControl.setErrors(control.value !== matchingControl.value ? { mustMatch: true } : null);
  }

}

@Component({
  selector: 'forgot-password-dialog',
  template: `<div style="padding: 30px !important;font-family: var(--fontFamily);background-color: antiquewhite;">
              <h1 style="font-family: var(--fontFamily);font-weight: 700">Forgot Password ?</h1>
              <div>
                  Enter your email id you have registered with us and submit. We will send you the temporary password to your email id.<br><br> <input type="email" id="email" class="form-control" [(ngModel)]="data.email">
              </div>
              <div mat-dialog-actions align="end" style="margin-top:15px">
                <button mat-button [mat-dialog-close]="data.email" style="background-color: var(--mainColor);color: white !important;
                font-family: var(--fontFamily);">Submit</button>
              </div>
            </div>`,
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, FormsModule],
})

class ForgotPasswordModalDialog {
  constructor(public dialogRef: MatDialogRef<ForgotPasswordModalDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
