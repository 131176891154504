import {Component, ElementRef, ViewChild, OnInit, TemplateRef, inject} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { encode } from 'js-base64';
import { AnnouncementService } from '../../../../../services/announcement/announcement.service';
import { ButtonAction } from 'src/app/models/general';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { LiveAnnouncer } from '@angular/cdk/a11y';
declare let $: any
@Component({
  selector: 'edit-announcement',
  templateUrl: './edit-announcement.component.html',
  styleUrls: ['./edit-announcement.component.css']
})
export class EditAnnouncementComponent implements OnInit {
  announcementCategory: string = '';
  announcementContent: string = '';
  buttonAction: ButtonAction;
  announcementStatus: string = '';
  announcement = {
    id: '',
    status: '',
    title: '',
    category: this.announcementCategory,
    content: '',
    tags: []
  }
  statuses = [
    'Active',
    'Inactive'
  ]
  categories = [
    'School Zone', 
    'College Zone', 
    'Overseas Education', 
    'Daily Digest',
    'Health & Nutrition', 
    'Parenting tips', 
    'Neighbourhood'
  ].sort()
  separatorKeysCodes: number[] = [ENTER, COMMA];
  announcements: MatTableDataSource<any>
  displayedColumns: string[] = ['id', 'title', 'category', 'action'];
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('announcementForm', { static: true }) announcementForm: TemplateRef<any>;  
  announcementId: string | null
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  tagCtrl = new FormControl('');
  announcer = inject(LiveAnnouncer);

  constructor(private announcementService: AnnouncementService, public dialog: MatDialog) {
  }

  ngOnInit(): void {}

  search() {
    this.start()
    const {id, title, category, status} = this.announcement
    if( !id && !title && !category && !status ){
      this.end()
      alert('Enter atleast one search criteria')
      return
    }
    this.announcementService.searchAnnouncements(this.announcement).subscribe({
      next: (res:any) => {
        this.end()
        this.announcements = new MatTableDataSource(res.payload)
        setTimeout(() => {
          this.announcements.paginator = this.paginator
          this.announcements.sort = this.sort; 
        });
      },
      error: () => {
        this.end()
        alert('Error retrieving announcements')
      }
    })
  }

  openUpdateAnnouncement(element){
    this.announcementService.getAccouncementById(element.id).subscribe({
      next: (res:any) => {
        this.announcement = res.payload.announcementDetails[0]
        this.announcementCategory = this.announcement.category
        this.announcementContent = this.announcement.content
        this.announcementStatus = this.announcement.status
        const dialogRef = this.dialog.open(this.announcementForm);
        dialogRef.afterClosed().subscribe(() => {
          this.buttonAction = ButtonAction.SAVE
        });
        this.buttonAction = undefined;
      }
    }) 
  }

  updateAnnouncement(){
    this.start()
    const { id, title, category, content, status} = this.announcement
    if( !id || !title || !category || !content || !status ){
      this.end()
      alert('Please fill in all the mandatory fields')
      return
    }
    this.announcementService.updateAnnouncement(this.announcement).subscribe({
      next: () => {
        this.dialog.closeAll()
        this.end()
        alert('Announcement Updated Successfully!')
      },
      error: () => {
        this.end()
        alert('Error creating announcement. Try again later.')
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.announcements.filter = filterValue.trim().toLowerCase();

    if (this.announcements.paginator) {
      this.announcements.paginator.firstPage();
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add tag
    if (value) {
      this.announcement.tags.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.announcement.tags.indexOf(tag);
    if (index >= 0) {
      this.announcement.tags.splice(index, 1);
      this.announcer.announce(`Removed ${tag}`);
    }
  }

  updateContent(event): void{
    this.announcement.content = encode(event)    
    this.updateAnnouncement()
  }

  saveContent(): void{
    this.buttonAction = ButtonAction.SAVE    
  }
  
  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }

}
