<app-navbar></app-navbar>

<div class="inner-banner" style="background-image: url(images/{{coachingCenter?.id}}.jpg); background-size: contain; background-repeat: no-repeat;">
    <div class="container">
        <div class="inner-content">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="inner-banner-text">
                        <h1 style="font-size: 1.5rem;">{{ coachingCenter?.name }}</h1>
                        <ul>
                            <li><i class="flaticon-cursor"></i>{{ address.addressLine2 }}</li>
                            <li *ngIf="address.contact1"><i class="flaticon-telephone"></i> <a href="tel:{{ address.contact1 }}">{{ address.contact1 }}</a></li>
                            <li *ngIf="address.email1"><i class="flaticon-email-1"></i> <a href="mail:{{ address.email1 }}"> {{ address.email1 }} </a></li>
                        </ul>
                    </div>
                </div>

                <!-- <div class="col-lg-4">
                    <div class="banner-rating">
                        <ul>
                            <li class="rating-star" style="cursor: pointer;" (click)="scrollTo('reviews')">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span>{{ reviews?.length }} {{ reviews?.length>0 ? 'Reviews' : 'Review' }}</span>
                            </li>
                            <li><a style="color: white;">{{ rating }}</a></li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="banner-bottom">
            <div class="row">
                <div class="col-lg-4">
                    <!-- <div class="banner-tag">
                        <ul>
                            <li><a routerLink="/listing-details"><i class="flaticon-favorite"></i>Book Now</a></li>
                            <li><a (click)="scrollTo('addReview')" href="javascript:void(0);"><i class="flaticon-writing"></i> Add Review</a></li>
                        </ul>
                    </div> -->
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="banner-profile">
                        <img src="assets/img/residential_school.png" alt="Images">
                        <h3>{{ coachingCenter?.educationMode }}</h3>
                        <div class="banner-status">
                            <a href="javascript:void(0);"><h3>{{ coachingCenter?.instituteType }}</h3></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div>
                    <ul class="list">
                        <li><a href="index.html">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li><a (click)="backToSearch()" href="javascript:void(0);">Back To Search</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">{{ coachingCenter?.name }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="listing-details-area pt-45 pb-70 first-typography">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="listing-details-wrap">
                    <div class="listing-category">
                        <ul>
                            @if (coachingCenter?.address.mapLocation) {<li><a (click)="scrollTo('location')" href="javascript:void(0);"><i class="flaticon-place"></i>LOCATION</a></li>}
                            <!-- <li><a (click)="scrollTo('reviews')" href="javascript:void(0);"><i class="flaticon-writing"></i> REVIEWS</a></li> -->
                        </ul>
                    </div>
                    <div class="listing-widget listing-widget-desc">
                        <h3 class="title">About Coaching Center</h3>
                        <div class="about"> {{coachingCenter?.history}} </div>
                        <!-- <a routerLink="/listing-details" class="default-btn border-radius">Visit Website <i class='bx bx-plus'></i></a> -->
                        <div class="listing-features">
                            <h3 class="features-title">Highlights</h3>
                            <div class="row">
                                <div class="col-lg-2 col-md-3" *ngIf="coachingCenter?.yearsOfOperation">
                                    <div class="features-card">
                                        <i class="flaticon-diamond"></i>
                                        <h3>{{ coachingCenter.yearsOfOperation }} Years Of Operations</h3>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-3" *ngIf="highlighesEntrance">
                                    <div class="features-card">
                                        <i class="flaticon-event"></i>
                                        <h3>Entrance Exams courses offered</h3>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-3" *ngIf="highlighesAcademic">
                                    <div class="features-card">
                                        <i class="flaticon-hotel"></i>
                                        <h3>Academic courses offered</h3>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-3" *ngIf="highlighesRecruitment">
                                    <div class="features-card">
                                        <i class="flaticon-to-do-list"></i>
                                        <h3>Recruitment exams courses offered</h3>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-3" *ngIf="highlighesLanguagesOffered">
                                    <div class="features-card">
                                        <i class="flaticon-to-do-list"></i>
                                        <h3>Courses offered in multiple languages</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="padding:25px">
                          <span><b>Specialities: </b></span>  {{coachingCenter?.highlights}}
                        </div>
                    </div>

                    <div class="listing-widget" *ngIf="canDisplayAcheivements()">
                        <h3 class="title">Achievements & Accolades</h3>
                        <div style="padding:25px">
                            <div class="row">
                            @if (splitAchievements?.length > 0) {
                                @for (item of splitAchievements; track $index) {
                                    @if (item !== "") {
                                        <div class="achievement">
                                            <div class=""><img src="assets/icons/trophy.png" width="35px" height="35px"></div>
                                            <div class="">{{ item }}</div>
                                        </div>
                                        }
                                    }
                                }
                            </div>
                        </div>
                    </div>

                    @if (highlighesEntrance) {
                        <div class="listing-widget">
                            <h3 class="title">Entrance Exams</h3>
                            <table mat-table [dataSource]="dsClassesEntrance">
                                <ng-container matColumnDef="ccKey">
                                    <th mat-header-cell *matHeaderCellDef> Entrance Exam </th>
                                <td mat-cell *matCellDef="let element" [title]="element.ccKey"> {{element.ccKey | charLimit: 40}} </td>
                                </ng-container>
                                <ng-container matColumnDef="ccOnlineLive">
                                    <th mat-header-cell *matHeaderCellDef> Online - Live Teaching </th>
                                    <td mat-cell *matCellDef="let element" [attr.style]="getTdStyle(element.ccKey, 'live')" [attr.colspan] = "getKeyColSpan(element.ccKey, 'live')" style="text-align: center;">
                                    <img *ngIf="element.ccOnlineLive === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                    <img *ngIf="element.ccOnlineLive === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                    <span *ngIf="element.ccOnlineLive !== true && element.ccOnlineLive !== false">{{ element.ccOnlineLive }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ccOnlineRec">
                                    <th mat-header-cell *matHeaderCellDef> Online - Recorded </th>
                                    <td mat-cell *matCellDef="let element" [attr.style]="getTdStyle(element.ccKey, 'rec')" [attr.colspan] = "getKeyColSpan(element.ccKey, 'rec')" style="text-align: center;">
                                    <img *ngIf="element.ccOnlineRec === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                    <img *ngIf="element.ccOnlineRec === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                    <span *ngIf="element.ccOnlineRec !== true && element.ccOnlineRec !== false">{{ element.ccOnlineRec }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ccOffline">
                                    <th mat-header-cell *matHeaderCellDef> Offline </th>
                                    <td mat-cell *matCellDef="let element" [attr.style]="getTdStyle(element.ccKey, 'off')" [attr.colspan] = "getKeyColSpan(element.ccKey, 'off')" style="text-align: center;">
                                    <img *ngIf="element.ccOffline === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                    <img *ngIf="element.ccOffline === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                    <span *ngIf="element.ccOffline !== true && element.ccOffline !== false">{{ element.ccOffline }}</span>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="['ccKey','ccOnlineLive','ccOnlineRec','ccOffline']"></tr>
                                <tr mat-row *matRowDef="let row; columns: ['ccKey','ccOnlineLive','ccOnlineRec','ccOffline'];" class="table-row"></tr>
                            </table>
                        </div>
                    }

                    @if (highlighesAcademic) {

                        <div class="listing-widget">
                            <h3 class="title">Academics</h3>
                            <table mat-table [dataSource]="dsClassesAcademic">
                            <ng-container matColumnDef="ccKey">
                                <th mat-header-cell *matHeaderCellDef> Academics </th>
                                <td mat-cell *matCellDef="let element" [title]="element.ccKey"> {{element.ccKey | charLimit: 40}} </td>
                            </ng-container>
                            <ng-container matColumnDef="ccOnlineLive">
                                <th mat-header-cell *matHeaderCellDef> Online - Live Teaching </th>
                                <td mat-cell *matCellDef="let element" [attr.style]="getTdStyle(element.ccKey, 'live')" [attr.colspan] = "getKeyColSpan(element.ccKey, 'live')" style="text-align: center;">
                                  <img *ngIf="element.ccOnlineLive === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                  <img *ngIf="element.ccOnlineLive === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                  <span *ngIf="element.ccOnlineLive !== true && element.ccOnlineLive !== false">{{ element.ccOnlineLive }}</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ccOnlineRec">
                                <th mat-header-cell *matHeaderCellDef> Online - Recorded </th>
                                <td mat-cell *matCellDef="let element" [attr.style]="getTdStyle(element.ccKey, 'rec')" [attr.colspan] = "getKeyColSpan(element.ccKey, 'rec')" style="text-align: center;">
                                  <img *ngIf="element.ccOnlineRec === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                  <img *ngIf="element.ccOnlineRec === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                  <span *ngIf="element.ccOnlineRec !== true && element.ccOnlineRec !== false">{{ element.ccOnlineRec }}</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ccOffline">
                                <th mat-header-cell *matHeaderCellDef> Offline </th>
                                <td mat-cell *matCellDef="let element" [attr.style]="getTdStyle(element.ccKey, 'off')" [attr.colspan] = "getKeyColSpan(element.ccKey, 'off')" style="text-align: center;">
                                  <img *ngIf="element.ccOffline === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                  <img *ngIf="element.ccOffline === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                  <span *ngIf="element.ccOffline !== true && element.ccOffline !== false">{{ element.ccOffline }}</span>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="['ccKey','ccOnlineLive','ccOnlineRec','ccOffline']"></tr>
                            <tr mat-row *matRowDef="let row; columns: ['ccKey','ccOnlineLive','ccOnlineRec','ccOffline'];" class="table-row"></tr>
                            </table>
                        </div>
                    }

                    @if (highlighesLanguagesOffered) {
                        <div class="listing-widget">
                            <h3 class="title">Languages Offered</h3>
                            <table mat-table [dataSource]="dsClassesLanguage">
                                <ng-container matColumnDef="ccKey">
                                    <th mat-header-cell *matHeaderCellDef> Languages offered </th>
                                    <td mat-cell *matCellDef="let element" [title]="element.ccKey"> {{element.ccKey | charLimit: 40}} </td>
                                </ng-container>
                                <ng-container matColumnDef="ccOnlineLive">
                                    <th mat-header-cell *matHeaderCellDef> Online - Live Teaching </th>
                                    <td mat-cell *matCellDef="let element" [attr.style]="getTdStyle(element.ccKey, 'live')" [attr.colspan] = "getKeyColSpan(element.ccKey, 'live')" style="text-align: center;">
                                        <img *ngIf="element.ccOnlineLive === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                        <img *ngIf="element.ccOnlineLive === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                        <span *ngIf="element.ccOnlineLive !== true && element.ccOnlineLive !== false">{{ element.ccOnlineLive }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ccOnlineRec">
                                    <th mat-header-cell *matHeaderCellDef> Online - Recorded </th>
                                    <td mat-cell *matCellDef="let element" [attr.style]="getTdStyle(element.ccKey, 'rec')" [attr.colspan] = "getKeyColSpan(element.ccKey, 'rec')" style="text-align: center;">
                                        <img *ngIf="element.ccOnlineRec === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                        <img *ngIf="element.ccOnlineRec === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                        <span *ngIf="element.ccOnlineRec !== true && element.ccOnlineRec !== false">{{ element.ccOnlineRec }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ccOffline">
                                    <th mat-header-cell *matHeaderCellDef> Offline </th>
                                    <td mat-cell *matCellDef="let element" [attr.style]="getTdStyle(element.ccKey, 'off')" [attr.colspan] = "getKeyColSpan(element.ccKey, 'off')" style="text-align: center;">
                                        <img *ngIf="element.ccOffline === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                        <img *ngIf="element.ccOffline === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                        <span *ngIf="element.ccOffline !== true && element.ccOffline !== false">{{ element.ccOffline }}</span>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="['ccKey','ccOnlineLive','ccOnlineRec','ccOffline']"></tr>
                                <tr mat-row *matRowDef="let row; columns: ['ccKey','ccOnlineLive','ccOnlineRec','ccOffline'];" class="table-row"></tr>
                            </table>
                        </div>
                    }

                    @if (highlighesRecruitment) {

                        <div class="listing-widget">
                            <h3 class="title">Recruitment</h3>
                        <table mat-table [dataSource]="dsClassesRecruitment">
                            <ng-container matColumnDef="ccKey">
                                <th mat-header-cell *matHeaderCellDef> Recruitment</th>
                                <td mat-cell *matCellDef="let element" [title]="element.ccKey"> {{element.ccKey | charLimit: 40}} </td>
                            </ng-container>
                            <ng-container matColumnDef="ccOnlineLive">
                                <th mat-header-cell *matHeaderCellDef> Online - Live Teaching </th>
                                <td mat-cell *matCellDef="let element" [attr.style]="getTdStyle(element.ccKey, 'live')" [attr.colspan] = "getKeyColSpan(element.ccKey, 'live')" style="text-align: center;">
                                    <img *ngIf="element.ccOnlineLive === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                    <img *ngIf="element.ccOnlineLive === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                    <span *ngIf="element.ccOnlineLive !== true && element.ccOnlineLive !== false">{{ element.ccOnlineLive }}</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ccOnlineRec">
                                <th mat-header-cell *matHeaderCellDef> Online - Recorded </th>
                                <td mat-cell *matCellDef="let element" [attr.style]="getTdStyle(element.ccKey, 'rec')" [attr.colspan] = "getKeyColSpan(element.ccKey, 'rec')" style="text-align: center;">
                                  <img *ngIf="element.ccOnlineRec === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                  <img *ngIf="element.ccOnlineRec === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                  <span *ngIf="element.ccOnlineRec !== true && element.ccOnlineRec !== false">{{ element.ccOnlineRec }}</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ccOffline">
                                <th mat-header-cell *matHeaderCellDef> Offline </th>
                                <td mat-cell *matCellDef="let element" [attr.style]="getTdStyle(element.ccKey, 'off')" [attr.colspan] = "getKeyColSpan(element.ccKey, 'off')" style="text-align: center;">
                                    <img *ngIf="element.ccOffline === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                  <img *ngIf="element.ccOffline === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                  <span *ngIf="element.ccOffline !== true && element.ccOffline !== false">{{ element.ccOffline }}</span>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="['ccKey','ccOnlineLive','ccOnlineRec','ccOffline']"></tr>
                            <tr mat-row *matRowDef="let row; columns: ['ccKey','ccOnlineLive','ccOnlineRec','ccOffline'];" class="table-row"></tr>
                        </table>
                    </div>
                    }

                    <div class="listing-widget listing-map" id="location" *ngIf="coachingCenter?.address.mapLocation">
                        <h3 class="title">Map Location</h3>
                        <div [innerHtml]="map"></div>
                    </div>

                    <!-- <div class="listing-widget listing-comment" *ngIf="reviews" id="reviews">
                        <h3 class="title">User Reviews</h3>
                        <div style="text-align: center;" *ngIf="reviews.length">No reviews yet. Be the first to add review about this school.
                            <ul>
                                <li><a (click)="scrollTo('addReview')" href="javascript:void(0);"><i class="flaticon-writing"></i> Add Review</a></li>
                            </ul>
                        </div>
                        <ul *ngIf="reviews.length">
                            <li *ngFor="let user of reviews">
                                <img src="assets/icons/user.png" width="50" height="50" alt="Image">
                                <div class="content">
                                    <div>
                                        <div style="padding-left: 10%;">
                                            <h3>{{ user.name }}</h3>
                                            <span>{{ user.timeStamp | date }}</span>
                                            <p>{{ user.review }}</p>
                                        </div>
                                    </div>
                                    <div style="float: right;  margin-top: -60px;">
                                        <school-rating [rating]="user.rating" [ratingPosition]="'right'"> </school-rating>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div> -->

                </div>
            </div>

            <div class="col-lg-4">
                <div class="listing-details-side">
                    <div class="listing-widget-side">
                        <h3 class="title">Enquire this coaching center</h3>

                        <div class="listing-Booking-form">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="text" name="name" id="name" readonly class="form-control" value="{{user?.firstName}} {{user?.lastName}}">
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='flaticon-email-2'></i>
                                            <input type="email" name="email" id="email" readonly class="form-control" value="{{user?.email}}">
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='bx bx-phone'></i>
                                            <input type="text" name="phone_number" id="phone_number" readonly class="form-control" value="{{user?.mobile}}">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <i class='bx bx-envelope'></i>
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message" [(ngModel)]="enquiryMessage"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="col-lg-12 col-md-12 text-center">
                                <button type="submit" (click)="enquiry()" class="default-btn border-radius">Send Enquiry</button>
                            </div>
                        </div>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">General Information</h3>

                        <table mat-table [dataSource]="dsGeneralInformation">
                            <ng-container matColumnDef="generalInfo">
                              <td mat-cell *matCellDef="let element" class="no-border">{{element.generalInfo}}</td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; let even = even; columns: ['generalInfo'];"  [ngClass]="{gray: even}" class="table-row"></tr>
                        </table>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">Contact</h3>

                        <table mat-table [dataSource]="dsContact">
                            <ng-container matColumnDef="contact">
                              <td mat-cell *matCellDef="let element" class="no-border" [innerHTML]="element.contact"></td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; let even = even; columns: ['contact'];"  [ngClass]="{gray: even}" class="table-row"></tr>
                        </table>
                    </div>


                    <!-- <div class="listing-widget-side listing-blog-post"  id="addReview">
                        <h4 class="title">Add Review </h4>
                        <div style="padding-left: 10px;padding-right: 10px;padding-top: 15px;">
                            <div class="row" style="padding-bottom: 10px; font-family: var(--fontFamily);">
                                <div style="float: left;"><b>Your overall rating</b>
                                <div style="float: right;">
                                    <school-rating [rating]="overallRating"> </school-rating>
                                </div>
                            </div>
                        <div class="single-progressbar" style="padding-top: 10px;">
                            <h3><span style="float: left;">Academics</span> <span><img src="assets/icons/info.png"  width="15" height="15" matTooltip="Curriculum and learning material"></span><span style="float: right; padding-right: 5px;" matTooltip="Your rating in the scale of 1-5 where 5 being the best">{{ slider1.value }}</span></h3>
                            <mat-slider
                                class="rating-slider"
                                [max]="maxRating"
                                [min]="minRating"
                                [step]="step"
                                thumbLabel>
                                <input matSliderThumb (change)="ratingAcademics=getNumericValue(slider1.value); updateRating()" [(ngModel)]="ratingAcademics" #slider1>
                            </mat-slider>
                        </div>

                        <div class="single-progressbar">
                            <h3><span style="float: left;">Imparting Knowledge</span><span><img src="assets/icons/info.png"  width="15" height="15" matTooltip="Teaching and evaluation"></span><span style="float: right;padding-right: 5px;" matTooltip="Your rating in the scale of 1-5 where 5 being the best">{{ slider2.value }}</span></h3>
                            <mat-slider
                                class="rating-slider"
                                [max]="maxRating"
                                [min]="minRating"
                                [step]="step"
                                thumbLabel>
                                <input matSliderThumb (change)="ratingImparting=getNumericValue(slider2.value); updateRating()" [(ngModel)]="ratingImparting" #slider2>
                            </mat-slider>
                        </div>

                        <div class="single-progressbar">
                            <h3><span style="float: left;">Infrastructure facilities</span><span style="float: right;padding-right: 5px;" matTooltip="Your rating in the scale of 1-5 where 5 being the best">{{ slider3.value }}</span></h3>
                            <mat-slider
                                class="rating-slider"
                                [max]="maxRating"
                                [min]="minRating"
                                [step]="step"
                                thumbLabel>
                                <input matSliderThumb (change)="ratingInfraFacility=getNumericValue(slider3.value); updateRating()" [(ngModel)]="ratingInfraFacility" #slider3>
                            </mat-slider>
                        </div>

                        <div class="single-progressbar">
                            <h3><span style="float: left;"> Extra curricular activities</span><span style="float: right;padding-right: 5px;" matTooltip="Your rating in the scale of 1-5 where 5 being the best">{{ slider4.value }}</span></h3>
                            <mat-slider
                                class="rating-slider"
                                [max]="maxRating"
                                [min]="minRating"
                                [step]="step"
                                thumbLabel>
                                <input matSliderThumb (change)="ratingExtraCurricular=getNumericValue(slider4.value); updateRating()" [(ngModel)]="ratingExtraCurricular" #slider4>
                            </mat-slider>
                        </div>

                        <div class="single-progressbar">
                            <h3><span style="float: left;"> Growth oriented ecosystem</span><span style="float: right;padding-right: 5px;" matTooltip="Your rating in the scale of 1-5 where 5 being the best">{{ slider5.value }}</span></h3>
                            <mat-slider
                                class="rating-slider"
                                [max]="maxRating"
                                [min]="minRating"
                                [step]="step"
                                thumbLabel>
                                <input matSliderThumb (change)="ratingGrowth=getNumericValue(slider5.value); updateRating()" [(ngModel)]="ratingGrowth" #slider5>
                            </mat-slider>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="review" [(ngModel)]="review" class="form-control review" id="review" cols="30" rows="8" required placeholder="Your Review"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12" style="padding-top: 10px;">
                            <button type="submit" (click)="submitReview()" class="default-btn border-radius">Submit</button>
                        </div>
                    </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
