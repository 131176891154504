import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { InsightService } from 'src/app/services/insight/insight.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'guided-learning',
  templateUrl: './guided-learning-platform.component.html',
  styleUrls: ['./guided-learning-platform.component.scss']
})
export class GuidedLearningPlatformComponent implements OnInit {
  iFrameLink: SafeHtml;
  similarBlogs: any
  sherpaUrl = environment.sherpaAppUrl;
  constructor(private insightService: InsightService, private router: Router, private sanitizer: DomSanitizer, private titleService: Title, private metaService: Meta) {
    this.iFrameLink = this.sanitizer.bypassSecurityTrustHtml(`<iframe aria-label='Register for Foundation Coaching' frameborder="0" style="height:901px;width:-webkit-fill-available;border:none;" src='https://forms.zohopublic.in/zohopeople171/form/RegisterforFoundationCoaching/formperma/nxA1dPjsYDv-2U0DIR51LoZt547xeRt_E10sYAIlQCk'></iframe>`);
  }

  ngOnInit(): void {
    this.insightService.retrieveSimilarBlogs('Education Practices And Early Education').subscribe((res:any) => {
      this.similarBlogs = res.payload
    })
    this.updatePageDetails();
  }


  updatePageDetails() {
    this.titleService.setTitle('Online Guided Learning for Grades 6-9: Master Mathematics & Science');
    this.metaService.updateTag({ name: 'description', content: "Strengthen your child's foundation in Mathematics and Science with our online guided learning solutions for Grades 6-9. Enrol now for a 1-year program covering foundational and advanced concepts. Perfect for CBSE students." });
    this.metaService.updateTag({ name: 'keywords', content: 'Online Guided Learning, Grade 6-9 Mathematics, Grade 6-9 Science, CBSE Math and Science, Competitive Exams Preparation, Olympiad Foundation, Online Education, Guided Learning Platform'})
  }

  openSimilarBlog(blog: any){
    const queryParams = { category : encodeURIComponent(blog.category), blogId: blog.id}
    this.router.navigate(['/blog-details'], {queryParams})
  }

}
