import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'page-testimonials',
  templateUrl: './testimonials.page.component.html',
  styleUrls: ['./testimonials.page.component.scss']
})
export class TestimonialsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
