import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ProfilePage } from './components/pages/profile/profile.page';
import { CategoryComponent } from './components/pages/category/category.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsPageComponent } from './components/pages/testimonials/testimonials.page.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { SchoolsComponent } from './components/pages/schools/schools.component';
import { SchoolDetailsComponent } from './components/pages/school-details/school-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { VlogComponent } from './components/pages/vlog/vlog.component';
import { UserLoggedInGuard } from './guards/user-logged-in.guard'
import { FavoritesComponent } from './components/pages/favorites/favorites.component';
import { CreateBlogComponent } from './components/pages/admin/blog/create-blog/create-blog.component';
import { GuidedLearningPlatformComponent } from './components/pages/guided-learning-platform/guided-learning-platform.component';
import { CareersPage } from './components/pages/careers/careers.page';
import { AnnouncementsPage } from './components/pages/announcements/announcements.page';
import { EditBlogComponent } from './components/pages/admin/blog/edit-blog/edit-blog.component';
import { CreateAnnouncementComponent } from './components/pages/admin/announcements/create-announcement/create-announcement.component';
import { EditAnnouncementComponent } from './components/pages/admin/announcements/edit-announcement/edit-announcement.component';
import { CreateVlogComponent } from './components/pages/admin/vlog/create-vlog/create-vlog.component';
import { EditVlogComponent } from './components/pages/admin/vlog/edit-vlog/edit-vlog.component';
import { AdminGuard } from './guards/admin.guard';
import { DataEntryGuard } from './guards/data-entry.guard';
import { CreateCoachingCenterComponent } from './components/pages/admin/coaching-center/create-coaching-center/create-coaching-center.component';
import { CoachingCentersComponent } from './components/pages/coaching-centers/coaching-center.component';
import { CoachingCenterDetailsComponent } from './components/pages/coaching-center-details/coaching-center-details.component';
import { EditCoachingCenterComponent } from './components/pages/admin/coaching-center/edit-coaching-center/edit-coaching-center.component';
import { DuplicateCoachingCenterComponent } from './components/pages/admin/coaching-center/duplicate-coaching-center/duplicate-coaching-center.component';
import { EventsComponent } from './components/pages/events/events.component';
import { EventDetailsComponent } from './components/pages/event-details/event-details.component';
import { CreateEventComponent } from './components/pages/admin/events/create-event/create-event.component';
import { EditEventComponent } from './components/pages/admin/events/edit-event/edit-event.component';
import { EditUserComponent } from './components/pages/admin/user/edit-user/edit-user.component';

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'profile', component: ProfilePage, canActivate: [UserLoggedInGuard]},
    {path: 'category', component: CategoryComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'testimonials', component: TestimonialsPageComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'login-register', component: LoginRegisterComponent},
    {path: 'terms-condition', component: TermsConditionComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'schools', component: SchoolsComponent},
    {path: 'favorites', component: FavoritesComponent},
    {path: 'school-details/:schoolId', component: SchoolDetailsComponent, canActivate:[UserLoggedInGuard]},
    {path: 'blog', component: BlogComponent},
    {path: 'glp', component: GuidedLearningPlatformComponent},
    {path: 'blog/create', component: CreateBlogComponent, canActivate:[AdminGuard]},
    {path: 'blog/edit', component: EditBlogComponent, canActivate:[AdminGuard]},      
    {path: 'vlog/create', component: CreateVlogComponent, canActivate:[AdminGuard]},
    {path: 'vlog/edit', component: EditVlogComponent, canActivate:[AdminGuard]},    
    {path: 'user/edit', component: EditUserComponent, canActivate:[AdminGuard]},    
    {path: 'announcement/create', component: CreateAnnouncementComponent, canActivate:[AdminGuard]},
    {path: 'announcement/edit', component: EditAnnouncementComponent, canActivate:[AdminGuard]},    
    {path: 'coaching-center/create', component: CreateCoachingCenterComponent, canActivate:[DataEntryGuard]},
    {path: 'coaching-center/edit', component: EditCoachingCenterComponent, canActivate:[DataEntryGuard]},
    {path: 'coaching-center/duplicate', component: DuplicateCoachingCenterComponent, canActivate:[DataEntryGuard]},
    {path: 'event/create', component: CreateEventComponent, canActivate: [AdminGuard]},
    {path: 'event/edit', component: EditEventComponent, canActivate: [AdminGuard]},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'vlog', component: VlogComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'page-not-found', component: ErrorComponent},
    {path: 'careers', component: CareersPage},
    {path: 'announcements', component: AnnouncementsPage},
    {path: 'coaching-center', component: CoachingCentersComponent},
    {path: 'coaching-center-details/:coachingCenterId', component: CoachingCenterDetailsComponent, canActivate:[UserLoggedInGuard]},
    {path: 'events', component: EventsComponent},
    {path: 'event/:id', component: EventDetailsComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}