<app-navbar></app-navbar>
<div class="inner-banner inner-admin">
  <div class="container">
      <div class="inner-title text-center">
          <h3>Admin</h3>
      </div>
  </div>
</div>
<!-- <div class="container first-typography background" style="padding-top: 30px;padding-bottom: 50px;"> -->
  <mat-card class="container first-typography background" style="padding-top: 30px;padding-bottom: 50px;padding-left: 50px;">
  <h2 class="title">Create vlog</h2>
  <form>
    <div class="row vlog">
      <mat-form-field>
        <mat-label>vlog ID</mat-label>
        <input matInput required [(ngModel)]="vlog.vlogId" name="vlogId">
      </mat-form-field>
    </div>
    <div class="row vlog">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput required [(ngModel)]="vlog.title" name="title">
      </mat-form-field>
    </div>
    <div class="row vlog">
      <mat-form-field>
        <mat-label>Category</mat-label>
        <mat-select required [(ngModel)]="vlogCategory" name="category" (ngModelChange)="vlog.category = vlogCategory">
          <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row vlog">
      <mat-form-field>
        <mat-label>Youtube Link</mat-label>
        <input matInput required [(ngModel)]="vlog.youtubeLink" name="youtubeLink">
      </mat-form-field>
    </div>
    
  </form>

  <div class="action">
    <button mat-raised-button class="submit">Cancel</button>
    <button mat-raised-button color="primary" (click)="createVlog()" >Submit</button>
  </div>
</mat-card>
<!-- </div> -->
