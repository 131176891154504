import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router'; 
import { Category } from '../../../libs/common/schools/category'
import { SchoolService } from '../../../services/school/school.service';
import { UserService } from '../../../services/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../../components/common/modal/modal/modal.component'

@Component({
  selector: 'popular-schools',
  templateUrl: './popular-schools.component.html',
  styleUrls: ['./popular-schools.component.scss']
})
export class PopularSchoolsComponent implements OnInit {
  title: string;
  category: string;
  ratingRange: number[];
  isUserLoggedIn = false;
  popularSchools;

  constructor(public dialog: MatDialog, private router: Router, private schoolService: SchoolService, private userService: UserService) { 
    this.ratingRange = Array(5).fill(5).map((x,i)=>++i);
  }

  ngOnInit(): void {
    this.isUserLoggedIn = this.userService.isUserLoggedIn();
    this.getRecommendedSchools()
  }


  getRatingStyle(rating: number, index: number): string{
    const absoluteRating = Math.round(rating)
    return index <= absoluteRating ? 'bx bxs-star' : 'bx bxs-star i-color'
  }

  getRecommendedSchools(){
    this.schoolService.getPopularSchools().subscribe({
      next: (res) => this.popularSchools = res,
      error: (err) => console.error(err)
    })
  }

  openSchoolDetails(school: any): void{
    this.isUserLoggedIn ? this.router.navigateByUrl(`/school-details/${school.id}`) : this.enforceUserToLoginOrRegister(school.id)
  }

  enforceUserToLoginOrRegister(id: string): void{
    const dialogRef = this.dialog.open(ModalComponent, {
      width: 'content',
      height: 'content',
      data: {
        displayActionButtons: true,
        alignActionButtons: 'end',
        content: `You need to be a registered user to view full details of a school. Kindly register or login to continue.`,
        primaryButton: ActionButton.LOGIN,
        secondaryButton: ActionButton.REGISTER
      }
    }
    )

    dialogRef.afterClosed().subscribe(result => {
      if(result === ActionButton.LOGIN){
        this.router.navigate(['/login-register'], this.getExtras(ActionButton.LOGIN, id))
      }else if(result === ActionButton.REGISTER){
        this.router.navigateByUrl('/login-register', this.getExtras(ActionButton.REGISTER, id))
      }
    })
  }

  getExtras(goToTab: ActionButton, id: string): NavigationExtras {
    return { queryParams: { returnUrl: `/school-details/${id}`},
    state: { linkData: { goToTab }}}
  }

}

enum ActionButton {
  LOGIN = 'Login',
  REGISTER = 'Register'
}

