<div class="container-fluid" *ngIf="latestListings">
    <div class="section-title text-center">
        <span>School Lists</span>
        <h2>The Latest Listings Added</h2>
    </div>

    <div class="place-slider owl-carousel owl-theme pt-45">
        <div class="place-list-item" *ngFor="let listing of latestListings">
            <div class="images">
                <a  [routerLink]="['/school-details', listing.id]"  class="images-list"><img src="assets/img/temp/{{listing.image}}" alt="Images"></a>
                
                <!-- <div class="place-profile">
                    <img src="assets/img/place-list/place-profile.png" alt="Images">
                    <h3>By Alfred</h3>
                </div> -->

                <div class="place-status" [ngClass]="getStyle(listing.board)">
                    <a [routerLink]="['/school-details', listing.id]"><h3>{{ listing.board }}</h3></a>
                </div>

                <div class="place-tag">
                    <ul>
                        <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                    </ul>
                    <h3 class="title"><a>{{ listing.location }}</a></h3>
                </div>
            </div>

            <div class="content">
                <a [routerLink]="['/school-details', listing.id]"><h3>{{ listing.name }}</h3></a> 
                <p><i class="flaticon-place"></i>{{ listing.address }}</p>
                <!-- <ul class="place-rating">
                    <li><a routerLink="/">4.9</a></li>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star icon-color'></i>
                        <i class='bx bxs-star icon-color'></i>
                    </li>
                </ul> -->
            </div>
        </div>
    </div>
</div>