<div class="user-area">
    <div class="container-fluid m-0">
        <div class="row align-items-center">
            <div class="col-lg-7 col-xl-6  p-0">
                <div class="user-img user-bg">
                    <a href="/"><img src="assets/img/login.jpg" alt="Images"></a>
                </div>
            </div>

            <div class="col-lg-5 col-xl-6">
                <div class="user-section text-center">
                    <div class="user-content">
                        <h2>Welcome <a href="/"><b>To Eduspeed</b></a></h2>
                    </div>


                    <mat-tab-group mat-stretch-tabs mat-align-tabs="start" [selectedIndex]="tabIndex" #tabs>
                        <mat-tab>
                            <ng-template mat-tab-label class="tab-label"><i class="flaticon-contact"></i> Login</ng-template>
                            <div class="tabs_item current">
                                <div class="user-all-form">
                                    <div class="contact-form">
                                        <form id="contactForm">
                                            <div class="row">
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <input type="text" [(ngModel)]="loginEmail" name="name" id="name" class="form-control" required data-error="Please enter your Email" placeholder="Email">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt'></i>
                                                        <input class="form-control"  [(ngModel)]="loginPassword" type="password" name="password" placeholder="Password">
                                                    </div>
                                                    <div class="error" *ngIf="isInCorrectCredentialSupplied">Incorrect Password. Please enter your correct password.</div>
                                                    <div class="error" *ngIf="isValidationPending">Email id not verified for this account. Please contact us.</div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 text-center">
                                                    <button type="submit" (click)="signInWithSelfLogin()" class="default-btn  user-all-btn">Login</button>
                                                </div>
                                                <div class="col-lg-6 col-sm-6 form-condition">
                                                    <div class="agree-label">
                                                        <input type="checkbox" id="chb1">
                                                        <label for="chb1">Remember Me</label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <a class="forget" (click)="forgotPassword()">Forgot my password?</a>
                                                </div>
                                            </div>
                                        </form>
                                        <!-- <div class="social-option">
                                            <h3>Or Login With</h3>
                                            <ul>
                                                <li><a (click)="signInWithFB()">Facebook</a></li>
                                                <li><a href="#">Twitter</a></li>
                                                <li><asl-google-signin-button type='icon' size='medium'></asl-google-signin-button></li>
                                            </ul>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label><i class="flaticon-verify"></i> Register</ng-template>
                            <div class="tabs_item">
                                <div class="user-all-form">
                                    <div class="contact-form">
                                        <form [formGroup]="registrationForm">
                                            <div class="row">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <i class='bx bx-user-pin'></i>
                                                            <input type="text" formControlName="firstName" name="firstName" id="firstName" class="form-control" placeholder="First Name">
                                                            <div *ngIf="(f.firstName.touched || submitted ) && f.firstName.errors" class="error">
                                                                <div *ngIf="f.firstName.errors.required">First Name is required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <i class='bx bx-user-pin'></i>
                                                            <input type="text" formControlName="lastName" name="lastName" id="lastName" class="form-control" placeholder="Last Name">
                                                            <div *ngIf="(f.lastName.touched || submitted) && f.lastName.errors" class="error">
                                                                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-mobile'></i>
                                                        <input type="text" formControlName="mobile" name="mobile" id="mobile" class="form-control" placeholder="Mobile" (ngModelChange)="isMobileAlreadyExists = false">
                                                        <div *ngIf="(f.mobile.touched || submitted) && f.mobile.errors" class="error">
                                                            <div *ngIf="f.mobile.errors?.required">Mobile is required</div>
                                                            <div *ngIf="f.mobile.errors?.pattern">Please enter valid 10 digit mobile number</div>
                                                        </div>
                                                        <div *ngIf="isMobileAlreadyExists" class="error">This mobile number is already registered.</div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='flaticon-email-2'></i>
                                                        <input type="email" formControlName="email" name="email" id="email" class="form-control" placeholder="Email" (ngModelChange)="isEmailAlreadyExists = false">
                                                        <div *ngIf="(f.email.touched || submitted) && f.email.errors" class="error">
                                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                                        </div>
                                                        <div *ngIf="isEmailAlreadyExists" class="error">This email id is already in use</div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <mat-form-field style="width: 100%">
                                                        <mat-label>Register as</mat-label>
                                                        <mat-select required formControlName="role">
                                                            <mat-option *ngFor="let role of roles" [value]="role">
                                                                {{ role }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <div *ngIf="(f.role.touched || submitted) && f.role.errors" class="error" style="margin-top: -20px;margin-bottom: 20px;">
                                                        <div *ngIf="f.role.errors.required">Please select your role</div>
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="f.role.value === 'Student'">
                                                    <div class="col-6">
                                                        <mat-form-field style="width: 100%">
                                                            <mat-label>Select your grade</mat-label>
                                                            <mat-select required formControlName="grade">
                                                                <mat-option *ngFor="let grade of grades" [value]="grade.value">
                                                                    {{ grade.label }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <div *ngIf="(f.grade.touched || submitted) && f.grade.errors" class="error" style="margin-top: -20px;margin-bottom: 20px;">
                                                            <div *ngIf="f.grade.errors.required">Please select your grade</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <mat-form-field style="width: 100%">
                                                            <mat-label>Select your board</mat-label>
                                                            <mat-select required formControlName="board">
                                                                <mat-option *ngFor="let board of boards" [value]="board">
                                                                    {{ board }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <div *ngIf="(f.board.touched || submitted) && f.board.errors" class="error" style="margin-top: -20px;margin-bottom: 20px;">
                                                            <div *ngIf="f.board.errors.required">Please select your board</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <i class='bx bx-lock-alt'></i>
                                                            <input class="form-control" formControlName="password" type="password" name="password" placeholder="Password">
                                                            <div *ngIf="(f.password.touched || submitted) && f.password.errors" class="error">
                                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                                                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <i class='bx bx-lock-alt'></i>
                                                            <input class="form-control" formControlName="confirmPassword" type="password" name="confirmPassword" placeholder="Confirm Password">
                                                            <div *ngIf="(f.confirmPassword.touched || submitted) && f.confirmPassword.errors" class="error">
                                                                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                                                <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="showOTP" class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-mobile'></i>
                                                        <input type="text" formControlName="otp" name="otp" id="otp" class="form-control" (ngModelChange)="isOtpError = false" placeholder="Enter OTP received in your email">
                                                        <div *ngIf="f.otp.touched && f.otp.errors" class="error">
                                                            <div *ngIf="f.otp.errors?.required">OTP is required</div>
                                                        </div>
                                                        <div *ngIf="isOtpError" class="error">Incorrect or expired OTP</div>
                                                    </div>
                                                </div>
                                                <div *ngIf="!showOTP" class="col-lg-12 col-md-12 text-center">
                                                    <button type="submit" class="default-btn  user-all-btn" (click)="register()">Register </button>
                                                </div>
                                                <div *ngIf="showOTP" class="col-lg-12 col-md-12 text-center">
                                                    <button type="submit" class="default-btn  user-all-btn" (click)="verifyOTP()">Submit OTP</button>
                                                </div>
                                                <div class="col-12">
                                                    <p class="account-desc">Already have an account? <a style="cursor: pointer;" (click)="tabGroup.selectedIndex = 0">Login</a></p>
                                                </div>
                                            </div>
                                        </form>
                                        <!-- <div class="social-option">
                                            <h3>Or Register With</h3>
                                            <ul>
                                                <li><a href="#">Facebook</a></li>
                                                <li><a href="#">Google</a></li>
                                            </ul>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                      </mat-tab-group>


                    <!-- <div class="tab user-tab">
                        <ul class="tabs">
                            <li><a href="#"> <i class="flaticon-contact"></i> Login</a></li>
                            <li><a href="#"> <i class="flaticon-verify"></i> Register</a></li>
                        </ul>
                        <div class="tab_content current active">
                            <div class="tabs_item current">
                                <div class="user-all-form">
                                    <div class="contact-form">
                                        <form id="contactForm">
                                            <div class="row">
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <input type="text" [(ngModel)]="loginEmail" name="name" id="name" class="form-control" required data-error="Please enter your Email" placeholder="Email">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt'></i>
                                                        <input class="form-control"  [(ngModel)]="loginPassword" type="password" name="password" placeholder="Password">
                                                    </div>
                                                    <div class="error" *ngIf="isInCorrectCredentialSupplied">Incorrect Password. Please enter your correct password.</div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 text-center">
                                                    <button type="submit" (click)="signInWithSelfLogin()" class="default-btn  user-all-btn">Login</button>
                                                </div>
                                                <div class="col-lg-6 col-sm-6 form-condition">
                                                    <div class="agree-label">
                                                        <input type="checkbox" id="chb1">
                                                        <label for="chb1">Remember Me</label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <a class="forget" (click)="forgotPassword()">Forgot my password?</a>
                                                </div>
                                            </div>
                                        </form>
                                         <div class="social-option">
                                            <h3>Or Login With</h3>
                                            <ul>
                                                <li><a (click)="signInWithFB()">Facebook</a></li>
                                                <li><a href="#">Twitter</a></li>
                                                <li><asl-google-signin-button type='icon' size='medium'></asl-google-signin-button></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="user-all-form">
                                    <div class="contact-form">
                                        <form [formGroup]="registrationForm">
                                            <div class="row">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <i class='bx bx-user-pin'></i>
                                                            <input type="text" formControlName="firstName" name="firstName" id="firstName" class="form-control" placeholder="First Name">
                                                            <div *ngIf="(f.firstName.touched || submitted ) && f.firstName.errors" class="error">
                                                                <div *ngIf="f.firstName.errors.required">First Name is required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <i class='bx bx-user-pin'></i>
                                                            <input type="text" formControlName="lastName" name="lastName" id="lastName" class="form-control" placeholder="Last Name">
                                                            <div *ngIf="(f.lastName.touched || submitted) && f.lastName.errors" class="error">
                                                                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-mobile'></i>
                                                        <input type="text" formControlName="mobile" name="mobile" id="mobile" class="form-control" placeholder="Mobile" (ngModelChange)="isMobileAlreadyExists = false">
                                                        <div *ngIf="(f.mobile.touched || submitted) && f.mobile.errors" class="error">
                                                            <div *ngIf="f.mobile.errors?.required">Mobile is required</div>
                                                            <div *ngIf="f.mobile.errors?.pattern">Please enter valid 10 digit mobile number</div>
                                                        </div>
                                                        <div *ngIf="isMobileAlreadyExists" class="error">This mobile number is already registered.</div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='flaticon-email-2'></i>
                                                        <input type="email" formControlName="email" name="email" id="email" class="form-control" placeholder="Email" (ngModelChange)="isEmailAlreadyExists = false">
                                                        <div *ngIf="(f.email.touched || submitted) && f.email.errors" class="error">
                                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                                        </div>
                                                        <div *ngIf="isEmailAlreadyExists" class="error">This email id is already in use</div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <mat-form-field style="width: 100%">
                                                        <mat-label>Register as</mat-label>
                                                        <mat-select required formControlName="role">
                                                            <mat-option *ngFor="let role of roles" [value]="role">
                                                                {{ role }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <div *ngIf="(f.role.touched || submitted) && f.role.errors" class="error" style="margin-top: -20px;margin-bottom: 20px;">
                                                        <div *ngIf="f.role.errors.required">Please select your role</div>
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="f.role.value === 'Student'">
                                                    <mat-form-field style="width: 100%">
                                                        <mat-label>Select your grade</mat-label>
                                                        <mat-select required formControlName="grade">
                                                            <mat-option *ngFor="let grade of grades" [value]="grade">
                                                                {{ grade }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <div *ngIf="(f.grade.touched || submitted) && f.grade.errors" class="error" style="margin-top: -20px;margin-bottom: 20px;">
                                                        <div *ngIf="f.grade.errors.required">Please select your grade</div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <i class='bx bx-lock-alt'></i>
                                                            <input class="form-control" formControlName="password" type="password" name="password" placeholder="Password">
                                                            <div *ngIf="(f.password.touched || submitted) && f.password.errors" class="error">
                                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                                                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <i class='bx bx-lock-alt'></i>
                                                            <input class="form-control" formControlName="confirmPassword" type="password" name="confirmPassword" placeholder="Confirm Password">
                                                            <div *ngIf="(f.confirmPassword.touched || submitted) && f.confirmPassword.errors" class="error">
                                                                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                                                <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="showOTP" class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-mobile'></i>
                                                        <input type="text" formControlName="otp" name="otp" id="otp" class="form-control" (ngModelChange)="isOtpError = false" placeholder="Enter OTP received in your email">
                                                        <div *ngIf="f.otp.touched && f.otp.errors" class="error">
                                                            <div *ngIf="f.otp.errors?.required">OTP is required</div>
                                                        </div>
                                                        <div *ngIf="isOtpError" class="error">Incorrect or expired OTP</div>
                                                    </div>
                                                </div>
                                                <div *ngIf="!showOTP" class="col-lg-12 col-md-12 text-center">
                                                    <button type="submit" class="default-btn  user-all-btn" (click)="register()">Register </button>
                                                </div>
                                                <div *ngIf="showOTP" class="col-lg-12 col-md-12 text-center">
                                                    <button type="submit" class="default-btn  user-all-btn" (click)="verifyOTP()">Submit OTP</button>
                                                </div>
                                                <div class="col-12">
                                                    <p class="account-desc">Already have an account? <a href="#">Login</a></p>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="social-option">
                                            <h3>Or Register With</h3>
                                            <ul>
                                                <li><a href="#">Facebook</a></li>
                                                <li><a href="#">Google</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
