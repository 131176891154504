<app-navbar></app-navbar>
<div class="inner-banner inner-admin">
  <div class="container">
      <div class="inner-title text-center">
          <h3>Admin</h3>
      </div>
  </div>
</div>
<!-- <div class="container first-typography background" style="padding-top: 30px;padding-bottom: 50px;"> -->
  <mat-card class="container first-typography background" style="padding-top: 30px;padding-bottom: 50px;padding-left: 50px;">
    <h2 class="title">Update User</h2>
    <form (action)="search()">
      <div class="row">
        <div class="row"><h3>Search Users</h3></div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>Firstname</mat-label>
            <input matInput [(ngModel)]="searchUser.firstName" name="firstName">
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>Lastname</mat-label>
            <input matInput [(ngModel)]="searchUser.lastName" name="lastName">
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="searchUser.emailId" name="emailId">
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>Mobile</mat-label>
            <input matInput [(ngModel)]="searchUser.mobile" name="mobile">
          </mat-form-field>
          <span><i>*Wildcard search also works. Ex: %John%</i></span>
        </div>
      </div>
    </form>

    <div class="action">
      <button mat-raised-button color="primary" (click)="search()" >Search</button>
    </div>
  </mat-card>
  <mat-card class="container first-typography background" style="margin-top: 50px;padding-bottom: 50px;padding-left: 50px;" *ngIf="users?.data">
    <div style="text-align: center;" *ngIf="users?.data?.length === 0">
      No Users found for the given search criteria.
    </div>

    <div *ngIf="users?.data.length">
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
      </mat-form-field>
      <table mat-table [dataSource]="users" matSort>
  
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name</th>
          <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
        </ng-container>
      
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Id </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="mobile">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile </th>
          <td mat-cell *matCellDef="let element"> {{element.mobile}} </td>
        </ng-container>

        <ng-container matColumnDef="roles">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Roles </th>
          <td mat-cell *matCellDef="let element"> {{element.roles}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>
  
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element"> <button mat-raised-button color="primary" (click)="openUpdateUser(element)" >Update</button> </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" #paginator></mat-paginator>
    </div>
  </mat-card>

    <ng-template style="width: 75vw;" #userForm>
      <h2 mat-dialog-title>Update User</h2>
      <mat-dialog-content class="mat-typography">
        <form>
          <div class="row user">
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select required [(ngModel)]="user.status" name="status">
                <mat-option [value]="status" *ngFor="let status of statuses">{{ status }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row user">
            <mat-form-field>
              <mat-label>Firstname</mat-label>
              <input matInput required [(ngModel)]="user.firstName" name="firstName">
            </mat-form-field>
          </div>
          <div class="row user">
            <mat-form-field>
              <mat-label>Lastname</mat-label>
              <input matInput required [(ngModel)]="user.lastName" name="lastName">
            </mat-form-field>
          </div>
          <div class="row user">
            <mat-form-field>
              <mat-label>Mobile</mat-label>
              <input matInput required [(ngModel)]="user.mobile" name="mobile">
            </mat-form-field>
          </div>
          <div class="row user">
              <mat-slide-toggle
                required 
                name="profileActivated"
                [(ngModel)]="user.profileActivated"
                [checked]="user.profileActivated"
                labelPosition="before"
              >
                <span style="font-family: var(--fontFamily);">Activate Student Profile </span>
              </mat-slide-toggle>
          </div>
          <div class="row user">
            <mat-slide-toggle
              required 
              name="newsLetterActivated"
              [(ngModel)]="user.newsLetterActivated"
              [checked]="user.newsLetterActivated"
              labelPosition="before"
            >
              <span style="font-family: var(--fontFamily);">Activate Newsletter Choice</span>
            </mat-slide-toggle>
        </div>
          <div class="row user">
            <mat-slide-toggle
              required 
              name="emailVerified"
              [(ngModel)]="user.emailVerified"
              [checked]="user.emailVerified"
              labelPosition="before"
            >
              <span style="font-family: var(--fontFamily);">Email Verification Status</span>
            </mat-slide-toggle>
          </div>
        </form>
      
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-raised-button [mat-dialog-close]=false>Cancel</button>
        <button mat-raised-button (click)="saveContent()" color="primary" cdkFocusInitial>Update</button>
      </mat-dialog-actions>
    </ng-template>
  