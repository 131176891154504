export interface SchoolBasicDetails{
    name: string;
    id: number;
    image: string;
    isMostPopular: boolean;
    isFavorite: boolean;
    isRecommended: boolean;
    location: string;
    address: string;
    rating: number;
    votes: number;
    annualFee: number;
    establishmentYear: string,
    grouping: string,
    schoolType: string,
    board: string,
    about: string,
    gradesOffered: string
}