<div class="listing-section-right first-typography">
    <h3 class="title filter-header"> <i class="flaticon-filter"></i> Refine your search</h3>
    <div class="listing-right-form">
        <form>
            <div class="form-group">
                <div *ngIf="filterConfiguration.location">
                    <button (click)="filterConfiguration.location.isClosed = !filterConfiguration.location.isClosed" [ngClass]="!filterConfiguration.location.isClosed ? 'collapsible' : 'collapsible open'">{{ filterConfiguration.location.legend }}</button>
                    <div class="content" #collapsibleContent [ngClass]="filterConfiguration.location.isClosed ? 'content' : 'content expanded-content'">
                        <div class="form-group">
                            <mat-form-field appearance="fill" class="filter custom-select">
                              <mat-label>City</mat-label>
                              <mat-select [(value)]="city" (selectionChange)="setCity($event.value)">
                                <mat-option *ngFor="let city of cities" [value]="city">
                                  {{city}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          
                          <div class="form-group">
                            <mat-form-field appearance="fill" class="filter custom-select">
                              <mat-label>Locality</mat-label>
                              <mat-select [(value)]="locality" (selectionChange)="setLocality($event.value)">
                                <mat-option *ngFor="let locality of localities" [value]="locality">
                                  {{locality}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        
                    </div>
                    <hr class="dotted">
                </div>

                <div *ngIf="filterConfiguration.centerType">
                    <button (click)="filterConfiguration.centerType.isClosed = !filterConfiguration.centerType.isClosed" [ngClass]="!filterConfiguration.centerType.isClosed ? 'collapsible' : 'collapsible open'">{{ filterConfiguration.centerType.legend }}</button>
                    <div class="content" #collapsibleContent [ngClass]="filterConfiguration.centerType.isClosed ? 'content' : 'content expanded-content'">
                        <div class="form-group">
                            <mat-form-field appearance="fill" class="filter custom-select">
                              <mat-label>Center Type</mat-label>
                              <mat-select [(value)]="filterCriteria.centerType" (selectionChange)="onChange($event.value, 'type')">
                                <mat-option *ngFor="let centerType of filterConfiguration.centerType.options" [value]="centerType.value">
                                  {{centerType.label}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                    </div>                
                    <hr class="dotted">
                </div>          

                <div *ngIf="displayEntranceExams">
                    <button (click)="filterConfiguration.entranceExams.isClosed = !filterConfiguration.centerType.isClosed" [ngClass]="!filterConfiguration.entranceExams.isClosed ? 'collapsible' : 'collapsible open'">{{ filterConfiguration.entranceExams.legend }}</button>
                    <div class="content" #collapsibleContent [ngClass]="filterConfiguration.entranceExams.isClosed ? 'content' : 'content expanded-content'">
                        <mat-selection-list #centerType [(ngModel)]="filterCriteria.entranceExams" [multiple]="true" (ngModelChange)="onChange($event, 'exams')" name="centerType">
                            <mat-list-option *ngFor="let entranceExam of filterConfiguration.entranceExams.options" [value]="entranceExam.value" [title]="entranceExam.label">{{entranceExam.label}}</mat-list-option>
                        </mat-selection-list>
                    </div>
                    <hr class="dotted">
                </div>                
            </div>
        </form>
    </div>

    <div class="col-lg-12 col-md-12 text-center">
        <button type="submit" class="default-btn border-radius" (click)="resetFilter()">Clear Filter</button>
    </div>
</div>