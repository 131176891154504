<app-navbar></app-navbar>

<div class="inner-banner inner-bg6" style="background-position: center top;">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Events</h3>
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    @if (campaigns.length) {
    <div class="container">
        <div class="row">
            <div style="text-align: right;" *ngIf="categories.length">
                <mat-form-field class="field">
                    <mat-label>Filter By Category</mat-label>
                    <mat-select [(ngModel)]="category" name="status" (ngModelChange)="onCategorySelect()">
                      <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let campaign of campaigns; let i = index">
                    <div class="blog-card" style="cursor: pointer;" (click)="openEvent(campaign)">
                        <a><img src="images/{{campaign.id}}.jpg" alt="Images" width="650" height="500"></a>
                        <div class="content">
                            <span> {{ campaign.startDate | date: 'longDate' }} - {{ campaign.endDate | date: 'longDate' }} / <a style="cursor: pointer;" (click)="openEvent(campaign)"> {{ campaign.category }} </a></span>
                            <h3><a style="cursor: pointer;" (click)="openEvent(campaign)"> {{ campaign.name }}</a></h3>
                            <a  class= "default-btn border-radius" style="cursor: pointer;" (click)="openEvent(campaign)">Register</a>
                        </div>
                    </div>
            </div>


             <div class="col-lg-12 col-md-12">
                 <div class="pagination-area text-center">
                    <mat-paginator #paginator (page)="handlePageEvent($event)"
                        [length]="totalRecords" [pageSize]="pageSize" [pageIndex]="currentPage"
                        [showFirstLastButtons]="true" [pageSizeOptions]="[6,12,18]" aria-label="Select page">
                    </mat-paginator>
                 </div>
             </div>
        </div>
    </div>
    } @else {
        <div class="container">
            <div class="section-title text-center">
                <span>More events on the way</span>
            </div>

            <div class="row pt-45">
                <div class="col-12">
                    <p class="text-center">
                        Please <a href="#" (click)="scrollToSubscribeField($event)">subscribe</a> to get notified of events. Untill then, Explore other features of Eduspeed.
                    </p>
                </div>
            </div>
        </div>
    }
</div>

<app-footer></app-footer>
