<ng-container *ngIf="isHorizontal">
  <div class="container" *ngIf="filteredAds?.length">
    <div class="row align-items-center">
        <div class="col-lg-7 col-md-6">
          <div class="section-title mb-45">
                <h2>Popular Listings</h2>
              </div>
            </div>  
          </div>

          <div class="carousel-container">
      <div class="carousel">
        <div class="carousel-items">
          <div class="card" (click)="openAd(card.url)"  *ngFor="let card of filteredAds; let i = index" [style]="getCardStyles()">
            <img class="img--row" [src]="card.image" alt="Card Image">
            <div class="card-content">
              <h3>{{ card.title }}</h3>
              <p>{{ card.content }}</p>
            </div>
          </div>
        </div>
        <div class="owl-nav" [style]="displayHorizontalButtons()">
          <button type="button" role="presentation" class="owl-prev" (click) = "prevSlide()"><i class="flaticon-left-arrow-1"></i></button>
          <button type="button" role="presentation" class="owl-next" (click) = "nextSlide($event)"><i class="flaticon-arrow-1"></i></button>
        </div>
      </div>
    </div>
</div>
</ng-container>

<ng-container *ngIf="isVertical">
<div class="vertical-listing">
  <div class="card card--vertical" (click)="openAd(card.url)" *ngFor="let card of filteredAds; let i = index">
    <img class="img--col" [src]="card.image" alt="Card Image">
    <div class="card-content--col">
      <h4>{{ card.title }}</h4>
      <span>{{ card.content }}</span>
    </div>
  </div>
  <button type="button" class="prev" (click)="verticalNavClick('prev')" [disabled]="isPrevDisabled()"> &lt; prev</button>
  <button type="button" class="next" (click)="verticalNavClick('next')" [disabled]="isNextDisabled()">next &gt;</button>
</div>
</ng-container>