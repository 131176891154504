import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'user-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})
export class FavoritesComponent implements OnInit {
  loading = true
  schools: any
  constructor(private userService: UserService) { }

  ngOnInit() {
    this.loading = true
    this.userService.getFavoriteSchools('').subscribe({
      next: (res) => {
        this.schools = res.payload,
        this.loading = false
      },
      error: (error) => {
        this.loading = false
        console.error('Error when retrieving favorite schools', error)
      }
    })
  }

}
