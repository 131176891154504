import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Gallery, ImageItem } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { SchoolService } from '../../../services/school/school.service';
import { UserService } from '../../../services/user/user.service';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { Category } from 'src/app/libs';

declare let $: any;
@Component({
  selector: 'school-details',
  templateUrl: './school-details.component.html',
  styleUrls: ['./school-details.component.scss']
})
export class SchoolDetailsComponent implements OnInit {
  map: SafeHtml;
  schoolId: number
  school: any
  maxRating = 5
  minRating = 1
  step = 0.1
  ratingAcademics = 0
  ratingImparting = 0
  ratingInfraFacility = 0
  ratingExtraCurricular = 0
  ratingGrowth = 0
  overallRating = 0
  review = ''
  enquiryMessage = ''
  user;
  dsAcademics;
  dsCoCurricularActivities;
  dsExtraCurricularActivities;
  dsInfraAndFacilityYesNo;
  dsGeneralInformation;
  dsContact;
  dsAdmissionProcess;
  dsKnowledgeManagement;
  dsFeeStructure;
  isFeesAvailable = false
  dsFooterInfraAndFacility = []
  columnsInfraAndFacility: string[] = ['column1', 'column2', 'column3', 'column4'];
  showAddReview = true;
  imageCounts: number[]
  constructor(private activatedRoute: ActivatedRoute,
    private schoolService: SchoolService,
    private userService: UserService,
    public gallery: Gallery,
    public lightbox: Lightbox,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private metaService: Meta) { }

  ngOnInit(): void {
    this.start()
    this.user = this.userService.currentUserValue
    console.log('this.user', this.user)
    this.schoolId = +this.activatedRoute.snapshot.paramMap.get('schoolId');
    this.schoolService.getSchoolDetails(this.schoolId).subscribe(
      res => {
        this.school = res
        this.updateImageGallery()
        this.updateInfraAndFacilitiesSection()
        this.updateAcademicsSection()
        this.updateKnowledgeManagementSection()
        this.updateCoCurricularSection()
        this.updateExtraCurricularSection()
        this.isFeesDataAvailable() && this.updateFeeStructure()
        this.updateAdmissionProcess()
        this.updateContactSection()
        this.updateGeneralInfoSection()
        console.log('Retrieved school details', res)
        this.map = this.sanitizer.bypassSecurityTrustHtml(this.school.address.mapLocation)
        this.updatePageDetails();
        this.end()
      }
    )
  }

  updatePageDetails() {
    const title = `${this.school.name}, ${this.school.address.city}, ${this.school.board}, Detailed info - Fees, Reviews And Admission- Eduspeed`;
    const description = `${this.school.name}, ${this.school.address.addressLine2}, ${this.school.address.city}. Get details online about application & registration process, Admission process, Contact Number, Fee Structure, Academics, Co Curricular Activities, Extra Curricular Activities, Infrastructure And Facilities, Teaching Methodology at Eduspeed`
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'keywords', content: 'CBSE schools,ICSE schools,IGCSE schools,IB schools, Special Need Schools, International schools, Nursery schools, Primary schools, Middle schools, High schools, Day care, Preschools, Play schools, Boarding schools, Day cum boarding schools, Day schools, IIT coaching, NEET coaching, JEE coaching, Online school search, Competitive exams preparation, Educational resources, K12 education, School search, School admissions, School admission info, Admissions forms, School fees, Fee details, Teaching methodology, extracurricular, co-curricular activities, infrastructure details, Find schools nearby, locality filter, Online Guided Learning Platform, Guided Learning Coaching, Grade 6-9, Quick Search by Board'})
  }

  updateImageGallery(){
    const count = this.school.board === Category.IGCSE ? 4 : 4
    this.imageCounts = [...Array(count+1).keys()].slice(1);
    const images = this.imageCounts.map(e => new ImageItem({ src: `images/${this.school.registrationCode}_${e}.jpg`, thumb:`images/${this.school.registrationCode}_${e}.jpg` }));
    const lightboxRef = this.gallery.ref('lightbox');
    lightboxRef.load(images);
  }

  updateRating(){
    this.overallRating = (this.ratingAcademics + this.ratingImparting + this.ratingInfraFacility + this.ratingExtraCurricular + this.ratingGrowth) / 5
  }

  submitReview(){
    const payload = {
      overallRating: this.overallRating,
      ratingAcademics: this.ratingAcademics,
      ratingImparting: this.ratingImparting,
      ratingInfraFacility: this.ratingInfraFacility,
      ratingExtraCurricular: this.ratingExtraCurricular,
      ratingGrowth: this.ratingGrowth,
      registrationCode: this.school.registrationCode,
      review: this.review
    }
    this.userService.addReview('karthik.itc@gmail.com', payload).subscribe({
      next: (res) => { this.showAddReview = false; this.ngOnInit() },
      error: (err) => console.error('Error when adding review', err)
    })
  }

  getAddress(): string{
    return `${this.school?.address.addressLine1}, ${this.school?.address.addressLine2}, ${this.school?.address.city} - ${this.school?.address.pincode}, ${this.school?.address.state}.`
  }

  getPhone(): string{
    let phone = ''
    const { phone1, phone2 } = this.school.contact
    if(phone1){
      phone+= phone1
    }
    if(phone2) {
      phone = `${phone}
      ${phone2}`
    }
    return phone
  }

  getEmail(): string{
    let email = ''
    const { email1, email2 } = this.school.contact
    if(email1){
      email+= email1
    }
    if(email2) {
      email = `${email}
      ${email2}`
    }
    return email
  }

  getNumericValue(value: string){
    return parseFloat(value)
  }

  isFeesDataAvailable(){
    this.isFeesAvailable = this.school && Object.values(this.school.fees).some((f:number)=> f ? f>0 : false )
    return this.isFeesAvailable
  }

  getStudentsCount(){
    return parseInt(this.school?.aboutSchool.studentsCount, 10)
  }

  getYesOrNoContent(value: string): boolean | string {
    if(value === 'Yes'){
      return true
    }else if(value === 'No'){
      return false
    }else {
      return value
    }
  }

  backToSearch(){

  }

  enquiry(){
    console.log('Enquiry Message', this.enquiryMessage)
    const request = {
      name: `${this.user.firstName} ${this.user.lastName}`,
      email: this.user.email,
      phone: this.user.mobile,
      subject: `Enquiry received for school | Registration Code: ${this.school.registrationCode} | Name: ${this.school.name}`,
      message: this.enquiryMessage,
      registrationCode: this.school.registrationCode
    }
    this.schoolService.enquireSchool(request).subscribe({
      next: (res) => {
        alert(`Thank you for your interest. We have submitted your enquiry to the school ${this.school.name}. You will receive a response from us shortly`)
      },
      error: (err) => {
        alert('Please try again later')
      }
    })
  }


  scrollTo(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
  }

  updateInfraAndFacilitiesSection(): void{
    const { infrastructureAndFacility } = this.school
    const yesNoFilteredData = Object.keys(infrastructureAndFacility).filter(key => this.isBooleanValue(infrastructureAndFacility[key])).sort((a,b) => a.localeCompare(b))
    const stringFilteredData = Object.keys(infrastructureAndFacility).filter(key => this.isStringValue(infrastructureAndFacility[key])).sort((a,b) => a.localeCompare(b))
    const offset = Math.round(yesNoFilteredData.length / 2);
    let data = [];
      for(let i=0; (i<=offset && i <= yesNoFilteredData.length) ; i++){
        data.push({
          column1: INFRA_AND_FACILITIES.get(yesNoFilteredData[i]),
          column2: this.getYesOrNoContent(infrastructureAndFacility[yesNoFilteredData[i]]),
        })
      }

    if(yesNoFilteredData.length > offset){
      for (let i = offset; i < yesNoFilteredData.length; i++) {
        const key = yesNoFilteredData[i]
        data[i-offset] = {
          ...data[i-offset],
          column3: INFRA_AND_FACILITIES.get(key),
          column4: this.getYesOrNoContent(infrastructureAndFacility[key]),
        }
      }
    }

    console.log('stringFilteredData', stringFilteredData)

    stringFilteredData.forEach(sKey => {
      this.dsFooterInfraAndFacility.push({
        columnx: INFRA_AND_FACILITIES.get(sKey),
        columny: this.getYesOrNoContent(infrastructureAndFacility[sKey]),
      })
    })

    console.log('dsFooterInfraAndFacility', this.dsFooterInfraAndFacility)

    this.columnsInfraAndFacility = yesNoFilteredData.length > offset ? ['column1', 'column2', 'column3', 'column4', ] :  ['column1', 'column2'];
    this.dsInfraAndFacilityYesNo = data;
    }

    updateAcademicsSection(): void {
      const { academic } = this.school
      this.dsAcademics = Object.keys(academic).filter(key => ACADEMICS.get(key) && ACADEMICS.get(key).trim()).map(key => {
        return {
          acKey: ACADEMICS.get(key),
          acValue: academic[key]
        }
      }).sort((a,b) => a.acKey.localeCompare(b.acKey))
    }

    updateCoCurricularSection(): void {
      const { coCurricularActivity } = this.school
      this.dsCoCurricularActivities = Object.keys(coCurricularActivity).filter(key => CO_CURRICULAR.get(key) && CO_CURRICULAR.get(key).trim()).map(key => {
        return {
          ccKey: CO_CURRICULAR.get(key),
          ccValue: this.getYesOrNoContent(coCurricularActivity[key])
        }
      }).sort((a,b) => a.ccKey.localeCompare(b.ccKey))
    }

    updateExtraCurricularSection(): void {
      const { extraCurricularActivity } = this.school
      const booleanValues = Object.keys(extraCurricularActivity).filter(key => EXTRA_CURRICULAR.get(key) && this.isBooleanValue(extraCurricularActivity[key])).map(key => {
        return {
          ecKey: EXTRA_CURRICULAR.get(key),
          ecValue: this.getYesOrNoContent(extraCurricularActivity[key])
        }
      }).sort((a,b) => a.ecKey.localeCompare(b.ecKey))

      const StringValues = Object.keys(extraCurricularActivity).filter(key => EXTRA_CURRICULAR.get(key) && this.isStringValue(extraCurricularActivity[key])).map(key => {
        return {
          ecKey: EXTRA_CURRICULAR.get(key),
          ecValue: this.getYesOrNoContent(extraCurricularActivity[key])
        }
      }).sort((a,b) => a.ecKey.localeCompare(b.ecKey))
      this.dsExtraCurricularActivities = booleanValues.concat(StringValues)
    }

    updateAdmissionProcess(): void {
      const { admissionProcess } = this.school
      this.dsAdmissionProcess = Object.keys(admissionProcess).map(key => {
        return {
          apKey: ADMISSION_PROCESS.get(key),
          apValue: admissionProcess[key]
        }
      }).sort((a,b) => a.apKey.localeCompare(b.apKey))
    }

    updateContactSection(): void {
      const { contact } = this.school
      this.dsContact = [
        { contact: 'Address'},
        { contact: this.getAddress()},
        { contact: 'Phone'},
        { contact: this.getPhone()},
        { contact: 'Email'},
        { contact: this.getEmail()},
        { contact: 'Website'},
        { contact: contact.website},
      ]
    }

    updateGeneralInfoSection(): void {
      const { aboutSchool } = this.school
      let contactArray = [];
        if(aboutSchool.visionAndMission){
          contactArray.push({generalInfo: 'Vision And Mission'})
          contactArray.push({generalInfo: aboutSchool.visionAndMission})
        }
        if(aboutSchool.principleName){
          contactArray.push({generalInfo: 'Principal Name'})
          contactArray.push({generalInfo: aboutSchool.principleName})
        }
        if(aboutSchool.founderInfo){
          contactArray.push({generalInfo: 'Founders Info'})
          contactArray.push({generalInfo: aboutSchool.founderInfo})
        }
        if(aboutSchool.timings){
          contactArray.push({generalInfo: 'School Timings'})
          contactArray.push({generalInfo: aboutSchool.timings})
        }
        if(this.getStudentsCount()){
          contactArray.push({generalInfo: 'Students Count'})
          contactArray.push({generalInfo: this.getStudentsCount()})
        }
        if(aboutSchool.studentTeacherRatio){
          contactArray.push({generalInfo: 'Student Teacher Ratio'})
          contactArray.push({generalInfo: aboutSchool.studentTeacherRatio})
        }
        if(aboutSchool.yearsOfOperation){
          contactArray.push({generalInfo: 'Years Of Operation'})
          contactArray.push({generalInfo: aboutSchool.yearsOfOperation})
        }

        this.dsGeneralInformation = contactArray;
    }

    updateKnowledgeManagementSection(): void {
      const { knowledgeManagement } = this.school
      this.dsKnowledgeManagement = [
        {
          grade: 'Kindergarten',
          teachType: knowledgeManagement.teachMethodKindergarten ?? 'Data not available',
          testType: knowledgeManagement.testTypeKg ?? 'Data not available'
        },
        {
          grade: 'Primary',
          teachType: knowledgeManagement.teachMethodPrimary ?? 'Data not available',
          testType: knowledgeManagement.testTypePrimary ?? 'Data not available'
        },
        {
          grade: 'Middle School',
          teachType: knowledgeManagement.teachMethodMiddleSchool ?? 'Data not available',
          testType: knowledgeManagement.testTypeMiddleSchool ?? 'Data not available'
        },
        {
          grade: 'High School',
          teachType: knowledgeManagement.teachMethodHighSchool ?? 'Data not available',
          testType: knowledgeManagement.testTypeHighSchool ?? 'Data not available'
        },
        {
          grade: 'Senior Secondary',
          teachType: knowledgeManagement.teachMethodHigherSecondary ?? 'Data not available',
          testType: knowledgeManagement.testTypeHigherSecondary ?? 'Data not available'
        }
      ]

    }

    updateFeeStructure(): void {
      const { kindergarten, primarySchool, middleSchool, highSchool, seniorSecondary } = this.school.fees
      this.dsFeeStructure = [
        { grade: 'Kindergarten', fees: kindergarten },
        { grade: 'Primary', fees: primarySchool },
        { grade: 'Middle School', fees: middleSchool },
        { grade: 'High School', fees: highSchool },
        { grade: 'Higher Secondary', fees: seniorSecondary }
      ]
    }


    isStringValue(value: string) : boolean {
      return typeof this.getYesOrNoContent(value) === 'string'
    }

    isBooleanValue(value: string) : boolean {
      return typeof this.getYesOrNoContent(value) === 'boolean'
    }

    canDisplayAcheivements(): boolean{
      const defaultValues = ['NA', '0']
      if(this.school?.achievement){
        const { achievements, grade10TopScorePer, grade12TopScorePer } = this.school?.achievement
        return achievements?.trim() || !defaultValues.includes(grade10TopScorePer) || !defaultValues.includes(grade12TopScorePer)
      }
      return false
    }

    start(){
      $('.preloader').fadeIn('slow');
    }

    end(){
      $('.preloader').fadeOut('slow');
    }

  }

const ACADEMICS = new Map<string, string>([
  ['genreOfBooks', 'Genre Of Books'],
  ['highSchoolSubjects', 'High School Subjects'],
  ['higherSecondarySubjects', 'Higher Secondary Subjects'],
  ['secondLanguagesOffered', 'Second Languages'],
  ['thirdLanguagesOffered', 'Third Languages'],
  ['librarySize', 'Library Size'],
  ['integratedCourseAvailabilityInd', 'Integrated Course Availability']
])

const ADMISSION_PROCESS = new Map<string, string>([
  ['applicationMode', 'Application Mode (Offline/Online)'],
  ['entranceExamInd', 'Entrance Exam'],
  ['faceToFaceSessionInd', 'Face-to-Face Session'],
  ['admissionWindowJanToMarInd', 'Admission Window (Jan to Mar)'],
  ['admissionWindowNovToFebInd', 'Admission Window (Nov to Feb)'],
  ['admissionWindowNovToJanInd', 'Admission Window (Nov to Jan)'],
  ['anytimeAdmissionInd', 'Anytime Admission'],
  ['vijayadasamiAdmissionInd', 'Vijayadasami Admission'],
  ['pastSchoolCertificatesRequiredInd', 'Past Schools Certificates Required?'],
  ['aadhaarInd', 'Aaddhaar Required?']
])

const CO_CURRICULAR = new Map<string, string>([
  ['bookReading', 'Book Reading'],
  ['debates', 'Debates'],
  ['eduTours', 'Education Tours'],
  ['fieldTrips', 'Field Trips'],
  ['rolePlay', 'Role Play'],
  ['scienceFestivals', 'Science Festivals'],
  ['storyTelling', 'Story Telling'],
  ['talentHunt', 'Talent Hunt']
])

const EXTRA_CURRICULAR = new Map<string, string>([
  ['annualDay', 'Annual Day'],
  ['artExihibitions', 'Art Exhibitions'],
  ['scienceExihibitions', 'Science Exhibitions'],
  ['clubActivites', 'Club Activities'],
  ['danceClasses', 'Dance Classes'],
  ['literatureFestival', 'Literature Festivals'],
  ['singingClasses', 'Singing Classes'],
  ['musicInstrClasses', 'Music Instrument Classes'],
  ['ncc', 'NCC'],
  ['nssRedCross', 'NSS/Red Cross'],
  ['scouts', 'Scout'],
  ['otherExtraActivity', 'Other Extra Activity'],
  ['sportsActivities', 'Sports Activities'],
  ['otherSportsActivities', 'Other Sports Activities'],
  ['sportsDay', 'Sports Day']
])

const INFRA_AND_FACILITIES = new Map<string, string>([
  ['cafeteriaVegFood','Cafeteria Veg Food'],
  ['cafeteriaNonVegFood','Cafeteria Non-veg Food'],
  ['cafeteriaSnacks','Cafeteria Snacks'],
  ['cafeteriaBreakfast','Cafeteria Breakfast'],
  ['cafeteriaLunch','Cafeteria Lunch'],
  ['roboticsLab','Robotics Lab'],
  ['physicsLab','Physics Lab'],
  ['chemistryLab','Chemistry Lab'],
  ['biologyLab','Biology Lab'],
  ['computerLab','Computer Lab'],
  ['englishLab','English Lab'],
  ['mathsLab','Mathematics Lab'],
  ['auditorium','Auditorium'],
  ['avRoom','Audio Video Room'],
  ['acClassRoom','Air-Conditioned Class Room'],
  ['swimmingPool','Swimming Pool'],
  ['cricketNets','Cricket Nets'],
  ['basketBallCourt','Basket Ball Court'],
  ['footballGround','Football Ground'],
  ['tennisCourt','Tennis Court'],
  ['badmintonCourt','Badminton Cour'],
  ['softMatCourt','Softmat Court'],
  ['tableTennis','Table Tennis'],
  ['childPlayArea','Child Playarea'],
  ['cctvSurveillance','CCTV Survelliance'],
  ['securityGuards','Security Guards'],
  ['otherSplInfra','Other Special Infra'],
  ['transportationAvailability','Transportation Availability'],
  ['busFacility','Bus Facility'],
  ['infirmaryAvl','Infirmary Availability'],
  ['dayCareFacility','Day Care Facility'],
  ['trainedNurse','Trained Nurse'],
]);
