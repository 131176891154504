<app-navbar></app-navbar>

<div class="inner-banner inner-aboutus">
    <div class="container">
        <div class="inner-title text-center">
            <h1>About Eduspeed</h1>
            <h2>Holistic Educational Guidance, Educational Resources, Guided Learning and School Search</h2>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="row">
                <div class="col-9" style="padding: 25px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
                    <div class="about-content">
                        <div class="section-title" style="padding-bottom: 20px;">
                            <span>About Us</span>
                            <h2>Why Eduspeed?</h2>
                            <div>Eduspeed aims at providing Edu broadcasting services and learning platform
                                solutions for K12 grades.
                                With seasoned educational professionals in our core team, our focus is to provide a
                                holistic educational guidance platform both in terms of information and concept
                                learning.

                                We work closely with all stakeholders to bring in quality data on K12 education for
                                you to explore and decide on education needs and aspirations.</div>
                        </div>
                        <div class="container about-img">
                            <img src="assets/img/about/about1.png" alt="image">
                        </div>
                        <div class="section-title" style="padding-bottom: 20px;">
                        <h3>Vision:</h3>
                        <div>
                            We strive to bring accessibility of mastery in foundation of science and mathematics to mass
                            students segment, especially to girl students (to support our bit in gender equality) and thus
                            support 100K enlightened students annually by year 2027 pursuing their higher
                            studies/research and subsequent career choices in STEM industry (Science, Technology,
                            Engineering & Mathematics)
                        </div>
                        <h3>Mission:</h3>
                        <div>Develop and broadcast cutting edge concept learning techniques and materials catering to
                            K12 school communities across the state and country. We emphasise both our material and
                            guidance in line with innovation, problem solving and critical thinking.
                        </div>
                        </div>
                        <div>
                            Subscribe to our newsletter (link) to stay updated on edu trends happening both local and globally.
                        </div>
                    </div>
                </div>
                <div class="col-3" *ngIf="similarBlogs">
                    <div class="blog-widget-left">
                        <div class="blog-widget" >
                            <h3 class="title">Our Blogs</h3>
                            <div class="widget-popular-post">
                                <article class="item" *ngFor="let similarBlog of similarBlogs">
                                    <div style="cursor: pointer;" (click)="openSimilarBlog(similarBlog)"><span class="full-image" role="img"><img src="images/{{ similarBlog.id }}.jpg" alt="Images"></span></div>
                                    <div class="info">
                                        <span>{{ similarBlog.publishDate | date: 'longDate' }}</span>
                                        <h4 class="title-text"><a style="cursor: pointer;" (click)="openSimilarBlog(similarBlog)">{{ similarBlog.title }}</a></h4>
                                        <p>{{ similarBlog.subTitle }}</p>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="choose-area">
    <div class="container">
        <div class="section-title text-center">
            <span>Choose Reasons</span>
            <h2>Why subscribe to our services?</h2>
        </div>

        <div class="choose-width pt-100 pb-70">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="choose-card">
                        <i class="flaticon-phone-call"></i>
                        <h3>Institutions Info</h3>
                        <p>If you are a parent, and want to know the best edu offerings in the
                            city when it comes to K12 education, Eduspeed offers all the needed data and helps
                            you fulfilling your quest for the right school.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="choose-card">
                        <i class="flaticon-web-page"></i>
                        <h3>Guided Training Programs</h3>
                        <p>We offer tailor made foundation trainings (Grade 8
                            onwards) related to entrance and competitive exams like NEET, JEE, thus supporting
                            students to better prepare and overcome the anxiety related to competitive exams.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="choose-card">
                        <i class="flaticon-support"></i>
                        <h3>Educational Vlogs And Trends</h3>
                        <p>Eduspeed offers you tons of edu articles and blogs to
                            understand and get aquatinted on the trends, topics, news on the universe of
                            education. Our ultimate goal is to be assist you in your quest for knowledge as a
                            friend/pal;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="video-area video-area-bg">
    <div class="container">
        <div class="video-content">
            <h2>Are You  Ready To Start Your Journey?</h2>
            <a href="https://www.youtube.com/watch?v=mg-WKB0wcoc" class="play-btn"><i class='bx bx-play' ></i></a>
        </div>
    </div>
</div>

<div class="counter-area">
    <div class="container">
        <div class="counter-bg">
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>1254</h3>
                        <span>New Visitors every week</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>257</h3>
                        <span>Unique Visitors every week</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>6</h3>
                        <span>Won Amazing Awards</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>20</h3>
                        <span>New Listing Every Week</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<section class="process-area">
    <div class="process-into process-into-2  pt-100 pb-70">
        <div class="container">
            <div class="section-title text-center">
                <span>Process</span>
                <h2>See <b>How It Works</b></h2>
            </div>

            <div class="row pt-45">
                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number1">1</div>
                        <i class="flaticon-position"></i>
                        <div class="content">
                            <h3>Search schools on your requirements</h3>
                            <p>Search schools using your key requirements based on location, curriculum, offerings,
                                amenities, infrastructure etc.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number2 active">2</div>
                        <i class="flaticon-to-do-list"></i>
                        <div class="content">
                            <h3>Filter out the top schools of our interest</h3>
                            <p>From our wide database of schools and its offerings, filter out a list of schools that matches
                                your needs and child's aspirations. Mark them as favourites for further references.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="process-item">
                        <div class="process-item-number number3">3</div>
                        <i class="flaticon-box"></i>
                        <div class="content">
                            <h3>Take action</h3>
                            <p>From each selected/favourite schools, make an action to either enquiry directly about the
                                school on additional data, or plan a school visit. We help you in every step until admission
                                process.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="process-line-2"><img src="assets/img/shape/process-line2.png" alt="Images"></div>
    </div>
</section>


<div class="client-area pt-100">
    <div class="container">
        <div class="client-bg">
            <div class="client-slider owl-carousel owl-theme">
                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial1.jpg" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Hemant Kumar</h3>
                                <span>Pallavaram, Chennai</span>
                                <p>Eduspeed immensely helped in identifying the right Kindergarten school for my son. Thanks for the support!</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial2.jpg" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Vidya S</h3>
                                <span>Kelambakkam, Chennai</span>
                                <p>Got all round support from search until admission to school through Eduspeed team, Keep up the good work!</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial3.jpg" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Raman V & Nithya</h3>
                                <span>East Tambaram, Chennai</span>
                                <p>As a young parent, i find the articles at Eduspeed on various edu trends very interesting and crisp read. Will highly recommend my friends to subscribe to Eduspeed.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="application-area-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="application-content">
                    <div class="section-title">
                        <span>Download app</span>
                        <h2>Get More In Our Application Sit Back And Enjoy</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                    </div>

                    <div class="application-btn">
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/google-play.svg" alt="svg">
                            <div class="btn-content">
                                <span>ANDROID APP ON</span>
                                <h3>Google Play</h3>
                            </div>
                        </a>
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/company.svg" alt="svg">
                            <div class="btn-content">
                                <span>DOWNLOAD ON THE</span>
                                <h3>App Store</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="application-img-two">
                    <img src="assets/img/mobile2.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer></app-footer>
