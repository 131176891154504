import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-coaching-center',
  templateUrl: './edit-coaching-center.component.html',
  styleUrl: './edit-coaching-center.component.scss'
})
export class EditCoachingCenterComponent implements OnInit {
  
  displaySearchComponent = true;
  coachingCenterData
  
  constructor() { }
  
  ngOnInit(): void { }
  
  setCoachingCenterData($event: any) {
    if($event) this.toggleSearchComponent();
    this.coachingCenterData = $event
  }
  
  toggleSearchComponent() {
    this.displaySearchComponent = !this.displaySearchComponent;
  }
  
}
