import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-duplicate-coaching-center',
  templateUrl: './duplicate-coaching-center.component.html',
  styleUrl: './duplicate-coaching-center.component.scss'
})
export class DuplicateCoachingCenterComponent implements OnInit{
  displaySearchComponent = true;
  coachingCenterData

  constructor() { }

  ngOnInit(): void { }
  
  setCoachingCenterData($event: any) {
    if($event) this.toggleSearchComponent();
    this.coachingCenterData = $event
  }
  
  toggleSearchComponent(){
    this.displaySearchComponent = !this.displaySearchComponent;
  }
}
