import {Component,inject, OnInit} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl } from '@angular/forms';
import { encode } from 'js-base64';
import { AnnouncementService } from '../../../../../services/announcement/announcement.service';
import { ButtonAction } from 'src/app/models/general';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipInputEvent } from '@angular/material/chips';
declare let $: any

@Component({
  selector: 'create-announcement',
  templateUrl: './create-announcement.component.html',
  styleUrls: ['./create-announcement.component.css']
})
export class CreateAnnouncementComponent implements OnInit {
  announcementCategory: string = '';
  announcement = {
    id: '',
    title: '',
    content: '',
    tags: [],
    category: this.announcementCategory,
  }
  categories = [
    'School Zone', 
    'College Zone', 
    'Overseas Education', 
    'Daily Digest',
    'Health & Nutrition',
    'Parenting tips', 
    'Neighbourhood'
  ].sort()
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl('');
  isSchoolSpecificBlog = false 
  buttonAction: ButtonAction 

  announcer = inject(LiveAnnouncer);

  constructor(private announcementService: AnnouncementService) {
  }

  ngOnInit(): void {
    
  }

  updateContent(event): void{
    this.announcement.content = encode(event)
    this.createAnnouncement()
  }

  saveContent(): void{
    this.buttonAction = ButtonAction.SAVE
  }

  createAnnouncement(){
    this.start()
    const { id, title, category, content } = this.announcement
    if( !id || !title || !category || !content){
      this.end()
      alert('Please fill in all the mandatory fields')
      return
    }
    this.announcementService.createAnnouncement(this.announcement).subscribe({
      next: () => {
        this.reset()
        this.end()
        alert('Announcement Created Successfully!')
      },
      error: () => {
        this.end()
        alert('Error creating announcement. Try again later.')
      }
    })
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add tag
    if (value) {
      this.announcement.tags.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.announcement.tags.indexOf(tag);
    if (index >= 0) {
      this.announcement.tags.splice(index, 1);
      this.announcer.announce(`Removed ${tag}`);
    }
  }

  reset(){
    this.announcementCategory = undefined
    this.buttonAction = ButtonAction.RESET
    this.announcement = {
      id: '',
      title: '',
      content: '',
      tags: [],
      category: '',
    }
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }

}
