<footer class="footer-area footer-bg2">
    <div class="footer-middle pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget">
                        <a routerLink="/" class="logo"><img src="assets/img/logo/Eduspeed.png" alt="Logo"></a>
                        <p>Eduspeed</p>
                        <ul class="footer-contact-list">
                            <li>01 FA, 1 Floor, IIT Madras Research Park, Kanagam road, Taramani, Chennai, Tamilnadu 600113, India</li>
                            <li><span>Toll-free : </span> <a href="tel:1800-3090-468">1800-3090-468</a></li> 
                            <li><span>Email : </span> <a href="mailto:contact@eduspeed.org"> contact&#64;eduspeed.org</a></li> 
                        </ul>
                        <ul class="social-link">
                            <li><a href="https://www.facebook.com/eduspeeddigital" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="https://www.instagram.com/eduspeed_digital/" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="https://www.linkedin.com/company/eduspeed-technologies-pvt-ltd" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                            <li><a href="https://www.youtube.com/@eduspeedDigital" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="footer-widget pl-1">
                        <h3>SUPPORT</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/profile">My Account</a></li>
                            <li><a routerLink="/faq">FAQ's</a></li>
                            <li><a routerLink="/login-register" *ngIf="unregisteredUser">Register</a></li>
                            <li><a routerLink="/contact">Help & Support</a></li>
                        </ul>
                        <div style="margin-top: 20px;">
                            <ul class="footer-list" style="font-size: 0.7rem;">
                                <li *ngFor="let city of popularCities"><a style="cursor: pointer;" (click)="navigateToSchoolsByCity(city, 'CBSE')">CBSE Schools In {{ city }}</a></li>
                            </ul>
                        </div>
                       
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget pl-5">
                        <h3>QUICK LINKS</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/about">About Us</a></li>
                            <li><a routerLink="/faq">FAQ's</a></li>
                            <li><a routerLink="/contact">Contact Us</a></li>
                            <li><a routerLink="/login-register" *ngIf="unregisteredUser">Register</a></li>
                        </ul>

                        <div style="margin-top: 20px;">
                            <ul class="footer-list" style="font-size: 0.7rem;">
                                <li *ngFor="let city of popularCities"><a style="cursor: pointer;" (click)="navigateToSchoolsByCity(city, 'IGCSE')">IGCSE Schools In {{ city }}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6" *ngIf="unregisteredUser">
                    <div class="footer-widget">
                        <h3>NEWSLETTER</h3>
                        <p>To get the latest news and latest updates from us</p>
                        <div class="footer-form">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="email" [(ngModel)]="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Your Email*">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" (click)="subscribe()" class="default-btn">SUBSCRIBE now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copy-right-area copy-right-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="copy-right-list">
                    <ul>
                        <li><a routerLink="/terms-condition" target="_blank">Terms of Use</a></li> 
                        <li><a routerLink="/privacy-policy" target="_blank">Privacy Policy</a></li> 
                        <li><a routerLink="/blog" target="_blank">Blog</a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>