import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PopularListingsService } from 'src/app/services/popular-listings/popular-listings.service';

enum ListingCategory{
  GOLD = 'Gold',
  SILVER = 'Silver',
  BRONZE = 'Bronze'
}

@Component({
  selector: 'app-popular-listings',
  templateUrl: './popular-listings.component.html',
  styleUrls: ['./popular-listings.component.scss']
})
export class PopularListingsComponent implements OnInit, OnDestroy{
  @Input() orientation: any;
  @Input() category: any;
  isHorizontal: boolean = false;
  isVertical: boolean = false;
  verticalNavigation: boolean = false;
  title: string;
  currentIndex: number = 0;
  timerId;
  verticalIndex:number = 0;
  
  allAds;       //All ads present
  eligibleAds;  //Eligible ads to be displayed in particular component
  filteredAds;  //If pagination is enabled, we use this


  constructor(private listingService: PopularListingsService) { 
  }
  
  ngOnInit(): void {
    this.getPopularListings()
    
    this.isHorizontal =  (this.orientation ==='Horizontal');
    this.isVertical =  (this.orientation ==='Vertical');
  }
  
  getPopularListings(){
    this.listingService.getPopularLisings().subscribe((res:any) => {
      this.allAds = res;

      this.filterUsingCategory();
      this.isHorizontal && this.startCarousel();
  
      this.isVertical && this.verticalConfiguration();
    }
    )
  }
  filterUsingCategory() {
    if(this.category === ListingCategory.GOLD){
      this.eligibleAds = this.allAds.filter((data) => data.category === ListingCategory.GOLD)
      this.filteredAds = [...this.eligibleAds];
    }
    else if(this.category === ListingCategory.SILVER){
      this.eligibleAds = this.allAds.filter((data) => (data.category === ListingCategory.GOLD || data.category === ListingCategory.SILVER))
    }
    else if(this.category === ListingCategory.BRONZE) {
      this.eligibleAds = [...this.allAds];
    }
  }

  openAd(link:string) {
    window.open(link, "_blank");
  }

  // Horizontal carousel configuration
  startCarousel() {
    if(this.filteredAds.length > 3){
      this.timerId = setInterval(() => {
        this.nextSlide();
      }, 5000);
    }
  }
  
  stopCarousel() {
    clearInterval(this.timerId);
  }

  prevSlide(): void {
    this.currentIndex = (this.currentIndex === 0) ? this.filteredAds.length - 1 : this.currentIndex - 1;
    this.updateCarousel();
  }
  
  // Move to the next slide
  nextSlide(event?): void {
    if(event !== undefined){
      this.stopCarousel();
      this.currentIndex = (this.currentIndex === this.filteredAds.length - 1) ? 0 : this.currentIndex + 1;
      this.updateCarousel();
      this.startCarousel()
    } else {
      this.currentIndex = (this.currentIndex === this.filteredAds.length - 1) ? 0 : this.currentIndex + 1;
      this.updateCarousel();
    }
  }
  
  updateCarousel(): void {
    const itemWidth = 33.33; // Width of each carousel item in percentage
    const translateX = -this.currentIndex * itemWidth; // Calculate the translation amount
    document.querySelector('.carousel-items').setAttribute('style', `transform: translateX(${translateX}%);`);
  }

  displayHorizontalButtons() {
    if(this.filteredAds.length > 3) {
      return "display: 'visible'";
    }
    return "display: none";
  }

  getCardStyles() {
    if(this.filteredAds.length < 3) {
      return 'width: 100%';
    }
  }
  //Vertical configuration
  verticalConfiguration() {
    if(this.eligibleAds.length > 5) {
      !this.verticalNavigation;
      this.filteredAds = this.eligibleAds.slice(0,5);
    } else {
      this.filteredAds = [...this.eligibleAds];
    }
  }

  isPrevDisabled() {
    if(this.verticalIndex == 0) {
      return true;
    } 
    return false;
  }

  isNextDisabled() {
    if((this.verticalIndex*5 + 5) >= this.eligibleAds.length ){
      return true;
    }
    return false;
  }

  verticalNavClick(direction: string) {
    if(direction === 'prev'){
      this.verticalIndex--;
      this.filteredAds = this.eligibleAds.slice(this.verticalIndex*5, this.verticalIndex*5 + 5);
    }
    if(direction === 'next'){
      this.verticalIndex++;
      this.filteredAds = this.eligibleAds.slice(this.verticalIndex*5, this.verticalIndex*5 + 5);
    }
  }

  ngOnDestroy() {
    clearInterval(this.timerId)
  }
}