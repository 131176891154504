<div class="listing-section-right first-typography">
    <h3 class="title"> <i class="flaticon-filter"></i> Filters</h3>
    <div class="listing-right-form">
        <form>
            <div class="form-group">
                <div *ngIf="filterConfiguration.location">
                    <button (click)="filterConfiguration.location.isClosed = !filterConfiguration.location.isClosed" [ngClass]="!filterConfiguration.location.isClosed ? 'collapsible' : 'collapsible open'">{{ filterConfiguration.location.legend }}</button>
                    <div class="content" #collapsibleContent [ngClass]="filterConfiguration.location.isClosed ? 'content' : 'content expanded-content'">
                        <mat-form-field class="filter custom-select">
                          <mat-label>State</mat-label>
                          <mat-select [(value)]="filterCriteria.location.state" (selectionChange)="setState($event.value)">
                            <mat-option *ngFor="let state of states" [value]="state">
                              {{state}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field class="filter custom-select">
                          <mat-label>City</mat-label>
                          <mat-select [(value)]="filterCriteria.location.city" (selectionChange)="setCity($event.value)">
                            <mat-option *ngFor="let city of getSchoolCities(filterCriteria.location.state)" [value]="city">
                              {{city}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                    <hr class="dotted">
                </div>

                <div *ngIf="filterConfiguration.board">
                    <button (click)="filterConfiguration.board.isClosed = !filterConfiguration.board.isClosed" [ngClass]="!filterConfiguration.board.isClosed ? 'collapsible' : 'collapsible open'">{{ filterConfiguration.board.legend }}</button>
                    <div class="content" #collapsibleContent [ngClass]="filterConfiguration.board.isClosed ? 'content' : 'content expanded-content'">
                        <mat-selection-list #board [(ngModel)]="filterCriteria.boards" [multiple]="false" (ngModelChange)="onChange($event)" name="board">
                            <mat-list-option *ngFor="let board of filterConfiguration.board.options" [value]="board.value">{{board.label}}</mat-list-option>
                        </mat-selection-list>
                    </div>
                    <hr class="dotted">
                </div>

                <div *ngIf="filterConfiguration.grade">
                    <button (click)="filterConfiguration.grade.isClosed = !filterConfiguration.grade.isClosed" [ngClass]="!filterConfiguration.grade.isClosed ? 'collapsible' : 'collapsible open'">{{ filterConfiguration.grade.legend }}</button>
                    <div class="content" #collapsibleContent [ngClass]="filterConfiguration.grade.isClosed ? 'content' : 'content expanded-content'">
                      <mat-selection-list #grades [(ngModel)]="filterCriteria.grades" (ngModelChange)="onChange($event)" name="grades">
                            <mat-list-option *ngFor="let grade of filterConfiguration.grade.options" [value]="grade.value">{{grade.label}}</mat-list-option>
                      </mat-selection-list>
                    </div>
                    <hr class="dotted">
                </div>

            </div>
        </form>
    </div>

    <div class="facilities-list">
        <h3 class="facilities-title">Facilities</h3>
        <mat-selection-list #facilities [(ngModel)]="filterCriteria.facilities" (ngModelChange)="onChange($event)" name="facility">
            <mat-list-option *ngFor="let facility of filterConfiguration.facilities.options" [value]="facility.value">{{facility.label}}</mat-list-option>
        </mat-selection-list>
    </div>

    <div class="col-lg-12 col-md-12 text-center">
        <button type="submit" class="default-btn border-radius" (click)="resetFilter()">Clear Filter</button>
    </div>
</div>
