<app-navbar></app-navbar>

<div class="slider-area owl-carousel owl-theme">
    <div class="slider-item inner-home">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="banner-form-area banner-form-mt">
    <div class="container">
        <div class="banner-form border-radius">
            <form>
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="form-group">
                            <i class='flaticon-place'></i>
                            <div class="form-control">
                                <ng-select class="filter" placeholder="{{'State'}}" name="state" (change)="setState($event)" [items]="states" bindLabel="item" bindValue="item">
                                    <ng-template ng-label-tmp1 let-item="item">
                                        {{item}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4">
                        <div class="form-group">
                            <i class='flaticon-place'></i>
                            <div class="form-control">
                                <ng-select class="filter" placeholder="{{'City'}}" name="city" (change)="setCity($event)" [items]="getCities(state)" bindLabel="name" bindValue="name">
                                    <ng-template ng-label-tmp2 let-item="item">
                                        {{item}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4">
                        <div class="form-group">
                            <i class='flaticon-category'></i>
                            <div class="form-control">
                                <ng-select class="filter"  placeholder="{{'Board'}}" (change)="setBoard($event)"  [name]="board" [items]="boards" [(ngModel)]="board" bindLabel="item" bindValue="item">
                                    <ng-template ng-label-tmp3 let-item="item">
                                        {{item.name}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0">
                        <button type="submit" (click)="search()" class="default-btn border-radius">Search <i class="flaticon-loupe"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<section class="category-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>School Categories</span>
            <h1>Find the Best Schools and Competitive Exam Coaching Centers</h1>
        </div>

        <div class="row category-bg">
            <div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a [routerLink]="['/schools']" [queryParams]="{category: 'cbse'}"><img src="/assets/img/category/cbse.png" alt="Find CBSE Schools | Eduspeed"></a>
                    <a [routerLink]="['/schools']" [queryParams]="{category: 'cbse'}"><h3>CBSE Schools</h3></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a [routerLink]="['/schools']" [queryParams]="{category: 'igcse'}"><img src="/assets/img/category/icse.png" alt="Find IGcSE Schools | Eduspeed"></a>
                    <a [routerLink]="['/schools']" [queryParams]="{category: 'igcse'}"><h3>IGcSE SChools</h3></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a [routerLink]="['/schools']" [queryParams]="{category: 'special'}"><img src="/assets/img/category/public.png" alt="Find Special needs Schools | Eduspeed"></a>
                    <a [routerLink]="['/schools']" [queryParams]="{category: 'special'}"><h3>Special Need Schools</h3></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a [routerLink]="['/schools']" [queryParams]="{category: 'play'}"><img src="/assets/img/category/play.png" alt="Find Play Schools | Eduspeed"></a>
                    <a [routerLink]="['/schools']" [queryParams]="{category: 'play'}"><h3>Play Schools</h3></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a [routerLink]="['/schools']" [queryParams]="{category: 'ib'}"><img src="/assets/img/category/international.png" alt="Find Internation Schools | Eduspeed"></a>
                    <a [routerLink]="['/schools']" [queryParams]="{category: 'ib'}"><h3>International Schools</h3></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a [routerLink]="['/schools']" [queryParams]="{category: 'boarding'}"><img src="/assets/img/category/christian.png" alt="Find Boarding Schools | Eduspeed"></a>
                    <a [routerLink]="['/schools']" [queryParams]="{category: 'boarding'}"><h3>Boarding Schools</h3></a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="place-area pt-100 pb-70">
    <!-- <popular-schools></popular-schools> -->
    <app-popular-listings orientation="Horizontal" category="Gold"></app-popular-listings>
 </div>

<section class="process-area process-bg pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Process</span>
            <h2>See How It Works</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="process-card">
                    <i class="flaticon-position icon-bg"></i>
                    <h3>Search schools on your requirements</h3>
                    <p>Search schools using your key requirements based on location, curriculum, offerings,
                        amenities, infrastructure etc.</p>
                    <div class="process-number">1</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="process-card">
                    <i class="flaticon-to-do-list icon-bg"></i>
                    <h3>Filter out the top schools of our interest</h3>
                    <p>From our wide database of schools and its offerings, filter out a list of schools that matches
                        your needs and child's aspirations. Mark them as favourites for further references.</p>
                    <div class="process-number active">2</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="process-card">
                    <i class="flaticon-box icon-bg"></i>
                    <h3>Take action</h3>
                    <p>From each selected/favourite schools, make an action to either enquiry directly about the
                        school on additional data, or plan a school visit. We help you in every step until admission
                        process.</p>
                    <div class="process-number">3</div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="video-area video-area-bg">
    <div class="container">
        <div class="video-content">
            <h2>Are You  Ready To Start Your Journey?</h2>
            <a href="https://www.youtube.com/watch?v=mg-WKB0wcoc" class="play-btn"><i class='bx bx-play' ></i></a>
        </div>
    </div>
</div>


<!-- Stats fil be hidden for now -->
<!-- <div class="counter-area">
    <div class="container">
        <div class="counter-bg">
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>1254</h3>
                        <span>New Visitors every week</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>257</h3>
                        <span>New Visitors every day</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>6</h3>
                        <span>Won Amazing Awards</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>20</h3>
                        <span>New Listing Every Week</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="place-list-area pt-100 pb-70">
    <latest-listings></latest-listings>
</section>


<section class="testimonial-area pb-70" *ngIf="false">
    <testimonials></testimonials>
</section>

<app-footer></app-footer>
