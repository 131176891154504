<div class="form-group">
    <label>Sort by:</label>
</div>
<div class="col-lg-7 col-sm-8">
    <div class="form-group">
        <i class='flaticon-filter'></i>
        <select class="form-control">
            <option>Alphabetical Order</option>
            <option>Distance</option>
            <option>Rating</option>
            <option>Recommended</option>
        </select>	
    </div>
</div>