import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'

const vlogCategories = [
  'Education Practices And Early Education', 
  'Sports', 
  'Child And Family Wellness', 
  'Higher Education',
  'Competitive Exams', 
  'Tech Corner', 
  'Daily Digest-Current Trends', 
  'GK Corner', 
  'Parenting', 
  'General', 
  'CBSE Notifications', 
  'UGC Circulars', 
  'Important Days', 
  'Announcements'
].sort();

const blogCategories = [
  'Education Practices And Early Education', 
  'Child And Family Wellness', 
  'Higher Education',
  'Competitive Exams', 
  'CBSE Notifications', 
  'UGC Circulars', 
  'Coaching Institutes',
  'Know Your Institution'
].sort();
@Injectable({
  providedIn: 'root'
})
export class InsightService {

  private _schoolServiceUrl: string;
  private _adminServiceUrl: string;

  constructor(private httpClient: HttpClient) {
    this._schoolServiceUrl = environment.endpoints.schoolService
    this._adminServiceUrl = environment.endpoints.adminService  
  }

  public createBlog(request: any){
    return this.httpClient.post(`${this._adminServiceUrl}/api/esadmin/insight/add-blog`, request)
  }  
  
  public updateBlog(request: any){
    return this.httpClient.post(`${this._adminServiceUrl}/api/esadmin/insight/update-blog`, request)
  }  
  
  public createVlog(request: any){
    return this.httpClient.post(`${this._adminServiceUrl}/api/esadmin/insight/add-vlog`, request)
  }  

  public updateVlog(request: any){
    return this.httpClient.post(`${this._adminServiceUrl}/api/esadmin/insight/update-vlog`, request)
  }

  public getBlog(blogId: string) {
    return this.httpClient.get(`${this._schoolServiceUrl}/api/eduspeed/insight/blog?id=${blogId}`)
  }

  public retrieveBlogs(category: string, tag: string, sortBy: string, sortByOrder: string, pageSize: number = 6, pageNo: number = 0) {
    let url = `${this._schoolServiceUrl}/api/eduspeed/insight/blogs?pageNo=${pageNo}&pageSize=${pageSize}`
    if(category){
      url = `${url}&category=${category}`
    }
    if(tag){
      url = `${url}&tag=${tag}`
    }
    if(sortBy){
      url = `${url}&sortBy=${sortBy}`
    }
    if(sortByOrder){
      url = `${url}&sortByOrder=${sortByOrder}`
    }
    return this.httpClient.get(url)
  }

  public retrieveSimilarBlogs(category: string) {
    return this.httpClient.get(`${this._schoolServiceUrl}/api/eduspeed/insight/similar-blogs?category=${category}`)
  }

  public retrieveBlogCategoryAndCount() {
    return this.httpClient.get(`${this._schoolServiceUrl}/api/eduspeed/insight/category`)
  }

  retrieveBlogCategory() {
    return blogCategories;
  }

  retrieveVlogCategory() {
    return vlogCategories;
  }

  public retrieveVlogs(category: string, sortBy: string, sortByOrder: string,pageSize: number = 6, pageNo: number = 0) {
    let url = `${this._schoolServiceUrl}/api/eduspeed/insight/vlogs?pageNo=${pageNo}&pageSize=${pageSize}`
    if(category) {
      url = `${url}&category=${category}`
    }
    if(sortBy){
      url = `${url}&sortBy=${sortBy}`
    }
    if(sortByOrder){
      url = `${url}&sortByOrder=${sortByOrder}`
    }
    return this.httpClient.get(url)
  }

  public addComment(request: any){
    return this.httpClient.post(`${this._schoolServiceUrl}/api/eduspeed/insight/comment`, request)
  }

  public searchBlogs(payload) {
    let url = `${this._adminServiceUrl}/api/esadmin/insight/search-blogs`  
    return this.httpClient.post(url, payload)
  }

  public searchVlogs(payload) {
    let url = `${this._adminServiceUrl}/api/esadmin/insight/search-vlogs`  
    return this.httpClient.post(url, payload)
  }
}
