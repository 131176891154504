<div class="container body">
    <table class="table">
        <tbody>
            <tr>
                <td rowspan="5" class="school-image"> <a style="cursor: pointer;" (click)="openSchoolDetails()"><img src="images/{{school.registrationCode}}_1.jpg" [alt]="school.name + ', ' + school.location + ', ' + school.city" style="width: 100%"></a> </td>
                <td colspan="3"> <h3><a style="cursor: pointer;font-family: var(--fontFamily);" (click)="openSchoolDetails()">{{ school.name }}</a></h3> </td>
                <td colspan="1" style="top: 13px; position: relative;" *ngIf="isUserLoggedIn">
                    <div class="stage" (click)="updateFavorite()">
                        <div class="heart"  [ngClass]="school.favorite ? 'is-active' : ''"></div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="4" class="address"> <i class="flaticon-place"></i> <span> {{ school.address }}</span> </td>
            </tr>
            <tr >
                <td colspan="4" class="rating" (click)="openSchoolDetails()">
                    <school-rating
                        [rating]="school.rating"
                        [votes]="school.votes">
                    </school-rating>
                 </td>
            </tr>
            <tr>
                <td class="features">
                    <span><img src="../../../../assets/img/place-list/school.png"></span>
                    <span class="heading">School Type</span>
                    <span>{{school.schoolTypes}}</span>
                </td>
                <td class="features">
                    <span><img src="../../../../assets/img/place-list/board.png"></span>
                    <span class="heading">Board</span>
                    <span>{{school.board}}</span>
                </td>
                <td class="features">
                    <span><img src="../../../../assets/img/place-list/gender.png"></span>
                    <span class="heading">Gender</span>
                    <span>{{school.genderGrouping}}</span>
                </td>
                <td class="features">
                    <span><img src="../../../../assets/img/place-list/grade.png"></span>
                    <span class="heading">Grades</span>
                    <span>{{school.gradesOffered}}</span>
                </td>
            </tr>
            <tr>
                <td class="features">
                    <span class="heading">Annual Fee</span>
                    <span>{{ school.feeStructure.highSchool || 'Data not available' }}</span>
                </td>
                <td class="features" style="width: 140px;padding-left: 20px;">
                    <span class="heading">Operational Years</span>
                    <span>{{ school.yearsOfOperation  || 'Data not available' }}</span>
                </td>
                <td> </td>
                <td style="width: 165px;top: -20px; position: relative;"><a style="cursor: pointer;" (click)="openSchoolDetails()" class="default-btn border-radius">View Details</a></td>
            </tr>
            <!-- <tr>
                <td colspan="5" style="top: -30px;position: relative;"> {{school.about}} </td>
            </tr> -->
        </tbody>
    </table>
    <div style="margin-bottom: 20px;"> {{school.about}}</div>
</div>
