import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Observable, startWith, map } from 'rxjs';
import { PincodeValidator } from 'src/app/libs';
import { LatitudeValidator, LongitudeValidator } from 'src/app/libs/common/validators/maps-coordinates-validator';
import { CoachingCenterService } from 'src/app/services/coaching-center/coaching-center.service';
import { UsedIn } from '../search-coaching-center/search-coaching-center.component';

@Component({
  selector: 'app-coaching-center-form',
  templateUrl: './coaching-center-form.component.html',
  styleUrl: './coaching-center-form.component.scss'
})
export class CoachingCenterFormComponent implements OnInit {

  @Input()
  coachingCenter: any;
  @Input()
  usedInComponent: string
  @Output()
  toggleSearchComponent = new EventEmitter();
  formTitle: string;

  educationModes = ['Online - Live Teaching', 'Online - Recorded', 'Offline']
  instituteLocations = ['Within the city', 'All over state', 'Pan India', 'International']
  teachingMediums = ['Tamil', 'Hindi', 'English', 'Marathi', 'Telugu','Malayalam', 'Kannada', 'Bengali', 'Gujarati', 'Others' ]
  typeOfInstitutes = ['School', 'Training Institute', 'Bridging Portal']
  coursesOffered = ['No', 'Online - Live Teaching', 'Online - Recorded', 'Offline', 'Data not available']
  subjectsOffered = ['Physics', 'Chemistry', 'Maths', 'Biology', 'English', 'Humanities', 'Accounts', 'Commerce','History', 'Geography','Economics','Computer Science']
  states =['Tamilnadu'];
  cities = ['Chennai', 'Coimbatore', 'Madurai', 'Trichy', 'Salem', 'Karur', 'Vellore'];

  grade10SubjectDisplay = false;
  grade12SubjectDisplay = false;

  headFilteredCities: Observable<string[]>;
  headFilteredStates: Observable<string[]>;
  branchFilteredCities: Observable<string[]>;
  branchFilteredStates: Observable<string[]>;

  basicDetailsFormGroup = this.formBuilder.group({
    id: ['', Validators.required],
    name: ['', Validators.required],
    educationMode: ['', Validators.required],
    instituteType: [['Schoool'], Validators.required],
    history: [null],
    founderInfo:[null],
    yearsOfOperation: [null],
    locations: ['', Validators.required],
    studentsCount: [null],
    studentTeacherRatio: [null],
    highlights: [null],
    awardsAndRecognition: [null],
    teachingMedium: ['', Validators.required],
    universityTieUp: [null],
    achievements: [null]
  });

  addressFormGroup: FormGroup = this.formBuilder.group({
    headOfficeAddressLine1: ['', Validators.required],
    headOfficeAddressLine2: ['', Validators.required],
    headOfficeCity: ['', Validators.required],
    headOfficeState: ['', Validators.required],
    headOfficePincode: ['', [Validators.required, PincodeValidator]],
    headOfficeContact1: ['', Validators.required],
    headOfficeContact2: [null],
    headOfficeContact3: [null],
    headOfficeEmail1: ['', [Validators.required, Validators.email]],
    headOfficeEmail2: [null],
    headOfficeWebsite: [null],
    branchOfficeAddressLine1: [null],
    branchOfficeAddressLine2: [null],
    branchOfficeLocality: [null],
    branchOfficeCity: [null],
    branchOfficeState: [null],
    branchOfficePincode: [null, PincodeValidator],
    branchOfficeContact1: [null],
    branchOfficeContact2: [null],
    branchOfficeContact3: [null],
    branchOfficeEmail1: ['', Validators.email],
    branchOfficeEmail2: [null],
    branchOfficeWebsite: [null],
    branchOfficeLatitude: [null, LatitudeValidator],
    branchOfficeLongitude: [null, LongitudeValidator],
    mapLocation: [null]
  });

  languageFormGroup = this.formBuilder.group({
    english: ['', Validators.required], 
    tamil: ['', Validators.required],
    hindi: ['', Validators.required],
    french: ['', Validators.required],
    germany: ['', Validators.required],
    mandarin: ['', Validators.required],
    japanese: ['', Validators.required],
    urdu: ['', Validators.required],
    arabic: ['', Validators.required],
    korean : ['', Validators.required],
    spanish: ['', Validators.required],
    italian: ['', Validators.required],
    sanskrit: ['', Validators.required],
    malayalam: ['', Validators.required],
    telugu: ['', Validators.required],
    kannada: ['', Validators.required],
    others: [null],
  });

  academicsFormGroup = this.formBuilder.group({
    chAct: ['', Validators.required],
    cma: ['', Validators.required],
    csc: ['', Validators.required],
    acca: ['', Validators.required],
    grade10: ['', Validators.required],
    grade10Subjects : [null],
    grade12: ['', Validators.required],
    grade12Subjects : [null],
    ugCourses: ['', Validators.required],
    pgCourses: ['', Validators.required],
    others: [null]
  });

  entranceExamFormGroup = this.formBuilder.group({
    neetUnderGraduate: ['', Validators.required],
    neetPostGraduate: ['', Validators.required],
    jeeMain: ['', Validators.required],
    jeeAdvance: ['', Validators.required],
    clat: ['', Validators.required],
    ipmat: ['', Validators.required],
    lsat: ['', Validators.required],
    sat: ['', Validators.required],
    gate: ['', Validators.required],
    cat: ['', Validators.required],
    nda: ['', Validators.required],
    nid: ['', Validators.required],
    nata: ['', Validators.required],
    nift: ['', Validators.required],
    bitsat: ['', Validators.required],
    viteee: ['', Validators.required],
    cuet: ['', Validators.required],
    gmat: ['', Validators.required],
    toefl: ['', Validators.required],
    ielts: ['', Validators.required],
    gre: ['', Validators.required],
    mcat: ['', Validators.required],
    nchmjee: ['', Validators.required],
    others: [null]
  });

  recruitmentFormGroup = this.formBuilder.group({
    netSet: ['', Validators.required],
    rrb: ['', Validators.required],
    ssc: ['', Validators.required],
    ese: ['', Validators.required],
    iese: ['', Validators.required],
    banks: ['', Validators.required],
    ibps: ['', Validators.required],
    rbi: ['', Validators.required],
    nabard: ['', Validators.required],
    cse: ['', Validators.required],
    tnet: ['', Validators.required],
    tnpsc: ['', Validators.required],
    others: [null]
  });

  basicFormInitialValue = this.basicDetailsFormGroup.value;
  addressFormInitialValue = this.addressFormGroup.value;
  languageFormInitialValue = this.languageFormGroup.value;
  academicsFormInitialValue = this.academicsFormGroup.value;
  entranceFormInitialValue = this.entranceExamFormGroup.value;
  recruitmentFormInitialValue = this.recruitmentFormGroup.value;

  constructor(private formBuilder: FormBuilder, private coachingService: CoachingCenterService, private dialog: MatDialog) { }

  ngOnInit() {

    this.headFilteredCities = this._filterAutoComplete(this.addressFormGroup,'headOfficeCity' ,'City')
    this.headFilteredStates = this._filterAutoComplete(this.addressFormGroup,'headOfficeState' ,'State')
    this.branchFilteredCities = this._filterAutoComplete(this.addressFormGroup,'branchOfficeCity' ,'City')
    this.branchFilteredStates = this._filterAutoComplete(this.addressFormGroup,'branchOfficeState' ,'State')

    this.academicsFormGroup.get('grade10').valueChanges.subscribe((value) => {
      if(value != '') this.grade10SubjectDisplay = !value.includes('No');
    })

    this.academicsFormGroup.get('grade12').valueChanges.subscribe((value) => {
      if(value != '') this.grade12SubjectDisplay = !value.includes('No');
    })

    this.entranceExamFormGroup.disable();
    this.academicsFormGroup.disable();
    this.languageFormGroup.disable();
    this.recruitmentFormGroup.disable();

    if(this.coachingCenter) {
      this.UpdateFormValues();
    }

    if(this.usedInComponent === UsedIn.EDIT){
      this.formTitle = 'Edit Coaching Center'
    } else if(this.usedInComponent === UsedIn.CREATE){
      this.formTitle = 'Create Coaching Center'
    } else if(this.usedInComponent === UsedIn.DUPLICATE) {
      this.formTitle = 'Duplicate Coaching Center'
    }
  }

  private _filterCities(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.cities.filter(city => city.toLowerCase().includes(filterValue));
  }

  private _filterStates(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.states.filter(state => state.toLowerCase().includes(filterValue));
  }

  submit(stepper: MatStepper){
    if( (this.recruitmentFormGroup.enabled && this.recruitmentFormGroup.valid) || this.recruitmentFormGroup.disabled ) {
      let request = this.constructRequest()
      if(this.usedInComponent === UsedIn.DUPLICATE || this.usedInComponent === UsedIn.CREATE){
        this.coachingService.createCoachingCenter(request).subscribe({
          next: (data) => {
            alert(data.payload);
            stepper.reset();
            this.resetAllForms();
            this.toggleSearchComponent.emit(true);
          }, 
          error: () => {
            alert('Failed to add the coaching center. Please try again');
          }
        });
        request = undefined;
      } else if(this.usedInComponent === UsedIn.EDIT){
        request = this.updateEditRequest(request)
        this.coachingService.editCoachingCenter(request).subscribe({
          next: (data) => {
            alert(data.payload);
            stepper.reset();
            this.resetAllForms();
            this.toggleSearchComponent.emit(true);
          }, 
          error: () => {
            alert('Failed to edit the coaching center. Please try again');
          }
        });
        request = undefined;
      }
    } 
    else {
      alert('Please fill all the required fields');
    }
  }

  constructRequest(){
    return {
      ...this.constructResponseFromFormGroup(this.basicDetailsFormGroup),
      address: this.constructResponseFromFormGroup(this.addressFormGroup),
      classesEntrance: this.constructResponseFromFormGroup(this.entranceExamFormGroup),
      classesAcademic: this.constructResponseFromFormGroup(this.academicsFormGroup),
      classesLanguage: this.constructResponseFromFormGroup(this.languageFormGroup),
      classesRecruitment: this.constructResponseFromFormGroup(this.recruitmentFormGroup),
    }
  }

  constructResponseFromFormGroup(formGroup: FormGroup): object{
    //If the form group is disabled, reset the form to its default state for removal of any selected values
    if(formGroup.disabled) {
      return null;
    }

    const response = {}
    Object.keys(formGroup.controls)
    .filter(key => typeof key === 'string')
    .forEach(key => {
      response[key] = this.getStringValue(formGroup.get(key).value)
      if(response[key] == 'Data not available') {
        response[key] = null;
      }
    })
    return response
  }

  getStringValue(value: string[] | string): string {
    return Array.isArray(value) ? value.join(',') : value
  }

  updateEditRequest(request) {
    request['status'] = this.coachingCenter.status;
    request['registeredSince'] = this.coachingCenter.registeredSince;
    return request
  }

  handleStepperReset(stepper: MatStepper) {
    const resetConfirmed = window.confirm('Are you sure you want to reset?');
    if(resetConfirmed) {
      stepper.reset();
    }
  }

  isFinalPage(stepper: MatStepper) {
    return (stepper.selectedIndex===5) ? true : false
  }

  toggleForm(form: FormGroup) {
    if(form.disabled) {
      form.enable()
    } else {
      form.disable()
    }
  }

  getFormInitialValue(form: FormGroup) {
    if(form == this.languageFormGroup) return this.languageFormInitialValue
    if(form == this.academicsFormGroup) return this.academicsFormInitialValue
    if(form == this.entranceExamFormGroup) return this.entranceFormInitialValue
    if(form == this.recruitmentFormGroup) return this.recruitmentFormInitialValue
  }

  resetAllForms() {
    this.basicDetailsFormGroup.reset(this.basicFormInitialValue);
    this.addressFormGroup.reset(this.addressFormInitialValue);
    this.languageFormGroup.reset(this.languageFormInitialValue);
    this.recruitmentFormGroup.reset(this.recruitmentFormInitialValue);
    this.academicsFormGroup.reset(this.academicsFormInitialValue);
    this.entranceExamFormGroup.reset(this.entranceFormInitialValue);
  }

  private _filterAutoComplete(form: FormGroup, formControl: string, type: string) {
    return form.get(formControl).valueChanges
                .pipe(
                  startWith(''),
                  map(value => {
                    if(type === 'City')
                      return this._filterCities(value)
                    else if(type === 'State')
                      return this._filterStates(value)
                  })
                );
  }

  UpdateFormValues() {
    const keys = Object.keys(this.coachingCenter);
    this.basicDetailsFormGroup.patchValue(this.coachingCenter);
    keys.forEach(key => {
      if(typeof this.coachingCenter[key] !== 'object'){
        if(inputSelectKeys.has(key)){
          this.basicDetailsFormGroup.get(key).setValue(this.coachingCenter[key].split(','))
        }
      }
      else {
        if(key === 'address'){
          this.addressFormGroup.patchValue(this.coachingCenter.address);
        } else if (key === 'classesAcademic') {
          if(this.coachingCenter[key] !== null) {
            this.academicsFormGroup.enable()
            const formGroupKeys = Object.keys(this.coachingCenter[key]);
            formGroupKeys.forEach(formKey => {
              if(formKey !== 'others') {
                this.coachingCenter[key][formKey] = this.coachingCenter[key][formKey]?.split(',') || ['Data not available'];
              }
            });
          }
          this.academicsFormGroup.patchValue(this.coachingCenter[key]);
        } else if (key === 'classesEntrance') {
          if(this.coachingCenter[key] !== null) {
            this.entranceExamFormGroup.enable()
            const formGroupKeys = Object.keys(this.coachingCenter[key]);
            formGroupKeys.forEach(formKey => {
              if(formKey !== 'others') {
                this.coachingCenter[key][formKey] = this.coachingCenter[key][formKey]?.split(',') || ['Data not available'];
              }
            });
          }
          this.entranceExamFormGroup.patchValue(this.coachingCenter[key]);
        } else if (key === 'classesLanguage') {
          if(this.coachingCenter[key] !== null) {
            this.languageFormGroup.enable()
            const formGroupKeys = Object.keys(this.coachingCenter[key]);
            formGroupKeys.forEach(formKey => {
              if(formKey !== 'others') {
                this.coachingCenter[key][formKey] = this.coachingCenter[key][formKey]?.split(',') || ['Data not available'];
              }
            });
          }
          this.languageFormGroup.patchValue(this.coachingCenter[key]);
        } else if (key === 'classesRecruitment') {
          if(this.coachingCenter[key] !== null) {
            this.recruitmentFormGroup.enable()
            const formGroupKeys = Object.keys(this.coachingCenter[key]);
            formGroupKeys.forEach(formKey => {
              if(formKey !== 'others') {
                this.coachingCenter[key][formKey] = this.coachingCenter[key][formKey]?.split(',') || ['Data not available'];
              }
            });
          }
          this.recruitmentFormGroup.patchValue(this.coachingCenter[key]);
        }
      }
    }); 
  }
}

const inputSelectKeys: Set<String> = new Set([
  'educationMode','instituteType','locations','teachingMedium'
])

const inputFormKeys: Map<String,String> = new Map([
  ['classesEntrance', 'entranceExamFormGroup'],
  ['classesAcademic','academicsFormGroup'],
  ['classesLanguage','languageFormGroup'],
  ['classesRecruitment','recruitmentFormGroup']
]
)