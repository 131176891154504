<!-- Information Bar -->
<div class="info-bar">
    <div>📞 Call Us : +91-91500 14060</div>
</div>
<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo/Eduspeed.png" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container-fluid">
            <nav class="container-max navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/Eduspeed.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Edu Directory</a>


                            <ul class="dropdown-menu">
                                <li class="nav-item"><a  [routerLink]="['/schools']" [queryParams]="{category: 'cbse'}" class="nav-link" routerLinkActive="active" >CBSE Schools</a></li>

                                <li class="nav-item"><a  [routerLink]="['/schools']" [queryParams]="{category: 'igcse'}" class="nav-link" routerLinkActive="active" >IGCSE Schools</a></li>
                                <li class="nav-item"><a  [routerLink]="['/coaching-center']" class="nav-link" routerLinkActive="active">Coaching Center</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Insights </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" [queryParams]="{category: 'Competitive Exams'}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blogs</a></li>

                                <li class="nav-item"><a routerLink="/vlog" [queryParams]="{category: 'Competitive Exams'}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Vlogs</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/announcements" class="nav-link">News Alerts</a>
                        </li>

                        <li class="nav-item">
                            <a a routerLink="/glp" class="nav-link">Guided Learning Platform</a>

                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact</a></li>
                        <li class="nav-item"><a routerLink="/careers" class="nav-link">Careers</a></li>
                        <li class="nav-item"><a routerLink="/events" class="nav-link">Events</a></li>

                        <li class="nav-item" *ngIf="isLoggedIn">
                            <a href="javascript:void(0)" class="nav-link">My Account </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/profile" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Profile</a></li>
                                <li class="nav-item"><a routerLink="/favorites" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Favorites</a></li>
                                <li class="nav-item"><a style="cursor:pointer" (click)="logout()" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Logout</a></li>
                            </ul>
                        </li>

                        <li class="nav-item" *ngIf="isAdminUser || isDataEntryUser">
                            <a href="javascript:void(0)" class="nav-link">Admin</a>

                            <ul class="dropdown-menu">
                                <ng-container *ngIf="isAdminUser && !isDataEntryUser">
                                    <li class="nav-item"><a style="cursor:pointer" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">User</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/user/edit" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Update</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item"><a style="cursor:pointer" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/blog/create" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Create</a></li>
                                            <li class="nav-item"><a routerLink="/blog/edit" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Edit</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item"><a style="cursor:pointer" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Vlog</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/vlog/create" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Create</a></li>
                                            <li class="nav-item"><a routerLink="/vlog/edit" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Edit</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item"><a style="cursor:pointer" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Announcements</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/announcement/create" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Create</a></li>
                                            <li class="nav-item"><a routerLink="/announcement/edit" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Edit</a></li>
                                        </ul>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="isAdminUser || isDataEntryUser">
                                    <li class="nav-item"><a style="cursor:pointer" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coaching Center</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/coaching-center/create" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Create</a></li>
                                            <li class="nav-item"><a routerLink="/coaching-center/duplicate" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Duplicate</a></li>
                                            <li class="nav-item"><a routerLink="/coaching-center/edit" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Edit</a></li>
                                        </ul>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="isAdminUser">
                                    <li class="nav-item"><a style="cursor:pointer" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/event/create" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Create</a></li>
                                            <li class="nav-item"><a routerLink="/event/edit" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Edit</a></li>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ul>
                        </li>
                    </ul>

                    <div class="side-nav d-in-line align-items-center">
                        <div class="side-item">
                            <div class="user-btn" *ngIf="!isLoggedIn">
                                <a routerLink="/login-register">Login <i class="flaticon-contact"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="circle-inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container" style="justify-content: end;">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">
                        <!-- <div class="side-item">
                            <div class="cart-btn">
                                <a routerLink="/cart">
                                    <i class="flaticon-shopping-bags"></i>
                                    <span>0</span>
                                </a>
                            </div>
                        </div> -->

                        <div class="side-item">
                            <div class="search-box">
                                <i class="flaticon-loupe"></i>
                            </div>
                        </div>

                        <div class="side-item">
                            <div class="user-btn" *ngIf="!isLoggedIn">
                                <a routerLink="/login-register"><i class="flaticon-contact"></i></a>
                            </div>
                        </div>

                        <!-- <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/" class="default-btn border-radius">Add Listing <i class='bx bx-plus'></i></a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>

            <div class="search-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
