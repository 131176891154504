import { Component, Input, OnInit } from '@angular/core';
import { SchoolBasicDetails } from 'src/app/libs';

@Component({
  selector: 'latest-listings',
  templateUrl: './latest-listings.component.html',
  styleUrls: ['./latest-listings.component.scss']
})
export class LatestListingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getStyle(board): string {
    let background;
    switch (board) {
      case 'CBSE':
        background = 'bg-color-blue'
        break;
      case 'IGSCE':
        background = 'bg-color-heliotrope'
        break;
      case 'State Board':
        background = 'bg-color-green'
        break;
      case 'ICSE':
        background = 'bg-color-heliotrope'
        break;

      default:
        background = 'bg-dark-orange'
        break;
    }
    return background;

  }

  latestListings
   /* = [
     {
      id: 1,
      name: 'ORDNANCE CLOTHING FACTORY SCHOOL',
      address: 'AVADI CHENNAI TAMIL NADU',
      rating: 2,
      votes: 5,
      location: 'Chennai',
      isRecommended: true,
      annualFee: 40000,
      image: '01.jpeg',
      establishmentYear: "2008",
      board: "CBSE",
    },
    {
      id: 2,
      name: 'MAHARISHI VIDYA MANDIR',
      address: 'MALLIGESHWARI STREET, NOLAMBUR',
      rating: 3,
      votes: 15,
      location: 'Chennai',
      isMostPopular: true,
      image: '02.jpg',
      annualFee: 100000,
      establishmentYear: "1990",
      board: "CBSE",
    },
    {
      id: 3,
      name: 'KENDRIYA VIDYALAYA NO.1',
      address: 'A F S TAMBARAM SELAIYUR CHENNAI TAMIL NADU',
      rating: 4.7,
      votes: 35,
      location: 'Chennai',
      isRecommended: true,
      image: '03.jpg',
      annualFee: 30000,
      establishmentYear: "1980",
      board: "State Board",
    },
    {
      id: 4,
      name: 'JAIGOPAL GARODIA VIVEKANANDA VIDYALAYA',
      address: '7TH STREET , ANNA NAGAR',
      rating: 1,
      votes: 10,
      location: 'Chennai',
      isMostPopular: false,
      image: '04.jpg',
      annualFee: 50000,
      establishmentYear: "2001",
      board: "IGSCE",
    },
    {
      id: 5,
      name: 'A.M.M. SCHOOL',
      address: 'KOTTURPURAM, CHENNAI',
      rating: 4.4,
      votes: 76,
      location: 'Chennai',
      isRecommended: true,
      image: '05.jpg',
      annualFee: 20000,
      establishmentYear: "1995",
      board: "CBSE",
    },
    {
      id: 6,
      name: 'RAILWAY HIGHER SECONDARY SCHOOL',
      address: 'NO. 26 SIRUVALLUR MAIN ROAD, PERAMBUR , CHENNAI- 600011',
      rating: 4.6,
      votes: 52,
      location: 'Perambur, Chennai',
      isMostPopular: false,
      image: '06.jpg',
      annualFee: 150000,
      establishmentYear: "2010",
      board: "ICSE",
    },
    {
      id: 7,
      name: 'Anjuham higher Secondary School',
      address: 'Jubliee Road , West Mambalam',
      rating: 4.9,
      votes: 25,
      location: 'Chennai',
      isMostPopular: false,
      image: '04.jpg',
      annualFee: 80000,
      establishmentYear: "1975",
      board: 'State Board'
    },
    {
      id: 8,
      name: 'G.K.M. SCHOOL',
      address: 'Perungalathur, Chennai',
      rating: 4.4,
      votes: 35,
      location: 'Chennai',
      isMostPopular: true,
      image: '05.jpg',
      annualFee: 40000,
      establishmentYear: "1993",
      board: 'State Board'
    },
    {
      id: 9,
      name: 'Saraswathy Vidayala School',
      address: '',
      rating: 4.6,
      votes: 35,
      location: 'Saidapet, Chennai- 600011',
      isMostPopular: false,
      image: '06.jpg',
      annualFee: 200000,
      establishmentYear: "1999",
      board: 'State Board'
    } 
  ]*/ 

}
