import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'

const categories = [
  'School Zone', 
  'College Zone', 
  'Overseas Education', 
  'Daily Digest',
  'Health & Nutrition',
  'Parenting tips', 
  'Neighbourhood'
].sort()
@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
schoolServiceUrl: string;
adminServiceUrl: string;
constructor(private httpClient: HttpClient) { 
  this.schoolServiceUrl = environment.endpoints.schoolService;
  this.adminServiceUrl = environment.endpoints.adminService;
}

public createAnnouncement(request: any){
  return this.httpClient.post(`${this.adminServiceUrl}/api/esadmin/announcement/add`, request)
}  

public updateAnnouncement(request: any){
  return this.httpClient.post(`${this.adminServiceUrl}/api/esadmin/announcement/update`, request)
}

public getAnnouncement(announcementId: string) {
  return this.httpClient.get(`${this.schoolServiceUrl}/api/eduspeed/announcement/${announcementId}`)
}

public retrieveAnnouncements(category: string, days: number) {
  return this.httpClient.get(`${this.schoolServiceUrl}/api/eduspeed/announcement/category-days?category=${encodeURIComponent(category)}&days=${days}`)
}

public searchAnnouncements(request: any) {
  return this.httpClient.post(`${this.adminServiceUrl}/api/esadmin/announcement/search-announcement`, request)
}

public getAccouncementById(id: string) {
  return this.httpClient.get(`${this.schoolServiceUrl}/api/eduspeed/announcement/id?pageNo=0&pageSize=10&sortBy=id&id=${id}`)
}

public getCategories() {
  return categories;
}

public getPremiumBlogs(category: string) {
  return this.httpClient.get(`${this.schoolServiceUrl}/api/eduspeed/announcement/blog?category=${category}&sortBy=publishDate&sortByOrder=dsc`);
}
}
