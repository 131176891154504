<div class="basic-details-grid">
    <div id="image">
        <a style="cursor: pointer;" (click)="openCoachingCenterDetails()">
            <img class="thumbnail" src="images/{{coachingCenter.id}}.jpg" [alt]="coachingCenter.name + ', ' + coachingCenter.address.branchOfficeLocality + ', '+coachingCenter.address.branchOfficeCity">
        </a>
    </div>
    <div id="title">
        <h3>
            <a style="cursor: pointer;font-family: var(--fontFamily); font-weight: 600;"
                (click)="openCoachingCenterDetails()">{{ coachingCenter.name }}
            </a>
        </h3>
    </div>
    <div id="address" class="address"> <i class="flaticon-place"></i> <span> {{ coachingCenterAddress }}</span></div>
    <div id="description" style="padding-top: 25px; text-align: justify;">{{coachingCenter.history | charLimit }} </div>
    <div id="view" style="padding-top: 25px; padding-left: 10px"> <a style="cursor: pointer;"
            (click)="openCoachingCenterDetails()" class="default-btn border-radius">View Details</a> </div>
    <div id="instituteType" class="features"><span><img src="../../../../assets/img/place-list/school.png"></span>
        <span class="heading">Institute Type</span>
        <span>{{coachingCenter.instituteType}}</span>
    </div>
    <div id="education" class="features"><span><img src="../../../../assets/img/place-list/board.png"></span>
        <span class="heading">Education</span>
        <span>{{coachingCenter.educationMode}}</span>
    </div>
    <div id="teachingMedium" class="features"><span><img src="../../../../assets/img/place-list/notes.png"></span>
        <span class="heading">Teaching Medium</span>
        <span>{{coachingCenter.teachingMedium}}</span>
    </div>
    <div id="entrance" class="features"><span class="heading">Entrance Exams</span>
        <span>
            <img src="{{coachingCenter.classesEntrance ? 'assets/icons/yes.png' : 'assets/icons/no.png'}}" width="15"
                height="15" matTooltip="{{coachingCenter.classesEntrance ? 'Available' : 'Not Available'}}">
        </span>
    </div>
    <div id="academic" class="features"> <span class="heading">Academics</span>
        <span>
            <img src="{{coachingCenter.classesAcademic ? 'assets/icons/yes.png' : 'assets/icons/no.png'}}" width="15"
                height="15" matTooltip="{{coachingCenter.classesAcademic ? 'Available' : 'Not Available'}}">
        </span>
    </div>
    <div id="language" class="features"><span class="heading">Language Classes</span>
        <span>
            <img src="{{coachingCenter.classesLanguage ? 'assets/icons/yes.png' : 'assets/icons/no.png'}}" width="15"
                height="15" matTooltip="{{coachingCenter.classesLanguage ? 'Available' : 'Not Available'}}">
        </span>
    </div>
    <div id="recruitment" class="features"> <span class="heading">Recruitment Exams</span>
        <span>
            <img src="{{coachingCenter.classesRecruitment ? 'assets/icons/yes.png' : 'assets/icons/no.png'}}" width="15"
                height="15" matTooltip="{{coachingCenter.classesRecruitment ? 'Available' : 'Not Available'}}">
        </span>
    </div>
</div>
