import { Injectable } from '@angular/core';
import { Router, NavigationExtras, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user/user.service';

@Injectable({ providedIn: 'root' })
export class UserLoggedInGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let extras = this.router.getCurrentNavigation().extras;
    const user = this.userService.currentUserValue;
    if (user) {
      return true;
    }

    if (!extras?.state?.redirectOptions) {
      if (state.url.startsWith('/blog-details')) {
        let category, blogId;
        const params = state.url.split('?')[1]
        console.log('params', params)
        params.split('&').forEach(q => {
          const val = q.split('=')
          if (val[0] === 'category') {
            category = val[1]
          } else if (val[0] === 'blogId') {
            blogId = val[1]
          }
        })
        extras = {
          state: {
            redirectOptions: {
              url: '/blog-details',
              queryParams: {
                category,
                blogId
              }
            }
          },
        }
      } else if (state?.url.startsWith('/school-details')) {
        extras = {
          state: {
            redirectOptions: {
              url: state.url,
              goToTab: "Login"
            }
          },
        }
      }
    }

    
    // not logged in so redirect to login page with the return url
    const navigationExtras: NavigationExtras = { state: { linkData: extras }, queryParams: extras.queryParams }
    this.router.navigateByUrl('/login-register', navigationExtras)
    return false;
  }
}