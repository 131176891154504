import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PincodeValidator } from '../../../../../libs/index';
import { CoachingCenterService } from 'src/app/services/coaching-center/coaching-center.service';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { Observable, map, startWith } from 'rxjs';
import { LatitudeValidator, LongitudeValidator } from 'src/app/libs/common/validators/maps-coordinates-validator';

@Component({
  selector: 'app-create-coaching-center',
  templateUrl: './create-coaching-center.component.html',
  styleUrls: ['./create-coaching-center.component.css'],
})
export class CreateCoachingCenterComponent implements OnInit {

 ngOnInit(): void {
     
 }
}