import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isLoggedIn = false;
  isAdminUser = false;
  isDataEntryUser = false;
  constructor(private userService: UserService, private router: Router) { 
    this.userService.currentUser.subscribe(x => this.isLoggedIn = x ? true: false);
    this.isAdminUser = this.userService.isAdminUser();
    this.isDataEntryUser = this.userService.isDataEntryUser();
  }

  ngOnInit(): void {
  }

  logout() {
    this.userService.logout();
    this.router.navigate(['/']);
  }

}
