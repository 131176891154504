<app-navbar></app-navbar>

<div class="inner-banner inner-bg51">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Vlogs</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Vlogs</li>
            </ul>
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>{{subTitle}}</span>
            <h1>{{ mainTitle1 }}<b>{{ mainTitle2 }}</b></h1>
        </div>
        <div class="row">
            <div style="text-align: right;" *ngIf="categories.length">
                <mat-form-field class="field">
                    <mat-label>Filter By Category</mat-label>
                    <mat-select [(ngModel)]="category" name="status" (ngModelChange)="onCategorySelect()">
                      <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let vlog of vlogs; let i = index">
                    <div class="blog-card">
                        <iframe [src]="vlog.youtubeLink | safe" title="Why is it hard to get a job as a fresh graduate?" width="100%" height="250px" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <div class="content">
                            <span> {{ vlog.publishDate | date: 'longDate' }} / <a target="_blank" [href]="vlog.youtubeLink | safe"> {{ vlog.category }} </a></span>
                            <h3><a target="_blank" [href]="vlog.youtubeLink | safe"> {{ vlog.title }} <span *ngIf="vlog.subTitle"> - {{ vlog.subTitle }} </span></a></h3>
                        </div>
                    </div>
            </div>


             <div class="col-lg-12 col-md-12">
                 <div class="pagination-area text-center">
                    <mat-paginator #paginator (page)="handlePageEvent($event)"
                        [length]="totalRecords" [pageSize]="pageSize" [pageIndex]="currentPage"
                        [showFirstLastButtons]="true" [pageSizeOptions]="[6,12,18]" aria-label="Select page">
                    </mat-paginator>
                 </div>
             </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
