import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationExtras, Router } from '@angular/router';
const OK = 'OK'
const EMAIL_FORMAT = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  unregisteredUser = true
  email= ''
  popularCities = ['Chennai', 'Kanchipuram', 'Coimbatore', 'Trichy', 'Salem', 'Madurai', 'Kodaikanal', 'Ooty', 'Tirunelveli']
  constructor(private userService: UserService, private snackbar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
    this.unregisteredUser = !this.userService.isUserLoggedIn()
  }

  navigateToSchoolsByCity(city: string, board: string, state: string = 'Tamilnadu'){
    const extras: NavigationExtras = {
      state: {
        linkData: {
          state,
          board,
          city
        }
      }
    }
    this.router.navigate([`/schools`], extras)
  }

  subscribe(): void{
    if(!this.email) {
      alert("Email cannot be blank. Please enter your email")
      return
    }
    if(!EMAIL_FORMAT.test(this.email)){
      alert("Please enter valid email")
      return
    }
    this.userService.subscribeToNewsLetter(this.email).subscribe({
      next: () => {
        this.email = ''
        this.snackbar.open('Email id has been successfully registered for newsletter', OK)
      },
      error: () => {
        this.snackbar.open('Please retry again later', OK)
      }
    })
  }

}
