import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { InsightService } from 'src/app/services/insight/insight.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  similarBlogs: any
  constructor(private insightService: InsightService, private router: Router, private metaService: Meta, private titleService: Title) { }

  ngOnInit(): void {
    this.insightService.retrieveSimilarBlogs('Child And Family Wellness').subscribe((res:any) => {
      this.similarBlogs = res.payload
    })
    this.updatePageDetails()
  }

  updatePageDetails() {
    this.titleService.setTitle('About Us - Eduspeed | Innovative K12 Educational Solutions');
    this.metaService.updateTag({ name: 'description', content: 'Eduspeed offers a comprehensive educational platform that supports students and parents through information, training, and guidance, with a strong emphasis on STEM education and innovative learning methods.' });
    this.metaService.updateTag({ name: 'keywords', content: 'Eduspeed, K12 education solutions, educational broadcasting services, STEM education, foundation training programs, NEET JEE preparation, educational blogs, school search, education trends, holistic educational guidance, gender equality in education'})
  }

  openSimilarBlog(blog: any){
    const queryParams = { category : encodeURIComponent(blog.category), blogId: blog.id}
    this.router.navigate(['/blog-details'], {queryParams})
  }

}
