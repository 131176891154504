<app-navbar></app-navbar>

<div class="inner-banner inner-bg8">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Terms & Conditions</h3>
        </div>
    </div>
</div>

<div class="terms-conditions-area ptb-100">
    <div class="container">
        <div class="single-content">
            <h3>PLEASE READ THE FOLLOWING USER AGREEMENT TERMS AND CONDITIONS CAREFULLY:</h3>
            <p>We welcome you to Eduspeed and we invite you to access and use our mobile app and our
                Site, subject to the rules that follow.
            </p>
            <p>Eduspeed.in (<b>"Eduspeed"</b>) requires that all visitors (<b>"you"</b>) to our Site (www.eduspeed.in)
                or
                who use our mobile app (collectively referred to as the <b>"Site"</b>), agree to the terms of this
                User Agreement ("UA"), in order to access or use the Site and the services provided through
                it (the <b>"Services"</b>). The Services include email newsletters, online content, schools listings,
                Training programs, offers from business partners, mobile applications, and other services of
                use to our entity partners. <b>By accessing or using the Site or Services, you signify that you
                    have read, understood, and agree to be bound by this UA, and to the collection and use of
                    your information as set forth in the Eduspeed Privacy Policy, which is hereby incorporated
                    by reference.</b>
            </p>
            <p>
                As used in this UA, "<b>we</b>," "<b>our,</b>" and "<b>us</b>" means Eduspeed unless otherwise
                indicated. The
                word "<b>you</b>" includes generally both individuals using the Site for personal purposes
                ("<b>Consumer Users</b>") or legal entities who use the Site for commercial or civic purposes, such
                as schools, colleges or educational institutions (referred to collectively as "<b>Entity Users</b>"). In
                this UA, any reference to "you" will mean both Consumer Users and Entity Users ("<b>Users</b>"),
                unless we specify one or the other for any particular provision within the UA. Additions and
                changes to the content on the Site or to the software used to power it will be covered by
                this UA and will become effective to those things upon publication to the Site.
            </p>
        </div>
        <div class="single-content">
            <h3>I. Who is Eduspeed?</h3>
            <p>Eduspeedis a company with a business address located at Kanagam Road, Taramani,
                Chennai; India. We provide an online platform designed to facilitate easy access to online-
                based services for Consumer Users and Entity Users. For instance, Consumer Users can use
                our Site to explore data that we make available about schools and colleges, workplaces, and
                places to explore, and get useful information from our partner schools, colleges, along with
                paid offerings like training programs for students. Our Entity Users can use our Site and the
                related Services to advertise their school, college, institutions, or list facts about their town
                or city, provide links to their respective websites and get useful data from the extensive
                Eduspeed data base. An Entity User can use the Site by registering and claiming a school or
                service location, or by becoming a Premium Member under a separate services agreement
                with us (referred to as a "<b>Services Agreement</b>"), which provides the Entity User with
                significantly more data and content allowances to reach target populations. There are fees
                associated with becoming a Premium Member, and these fees, as well as additional terms
                and conditions, are set forth in the separate Services Agreement that you must enter into
                with us. Please contact us at sales&#64;eduspeed.in to obtain more information on becoming a
                Premium Member. On the Site, we use our brand name "Eduspeed".</p>
        </div>
        <div class="single-content">
            <h3>II. Privacy Policy.</h3>
            <p>We are committed to protecting the privacy of any personal information you provide to us
                when you use the Site. Please see our Privacy policy for details. This UA includes and
                incorporates by reference the Eduspeed Privacy Policy.</p>
        </div>
        <div class="single-content">
            <h3>III. Account Creation.</h3>
            <p>The Eduspeed Site accounts are designed for use only by Consumer Users 13 years of age or
                older, their parents, and others 13 years of age or older interested in using the Services for
                non-commercial, personal use. Entity Users are also for designated representatives
                of schools, work places, and other entities whose services relate to the Services and who
                want to claim and update their particular entity page on our Site.</p>
            <p>Users are the sole creators of their accounts and Eduspeed does not independently verify
                the information provided, except that Eduspeed does reserve the right to verify Entity User
                account information and updates. You are responsible for maintaining the confidentiality of
                your Eduspeed account and password. You shall be responsible for all uses of your account
                and password, whether or not authorized by you. You agree to immediately notify Eduspeed
                of any unauthorized use of your account or password.</p>
            <p>You also agree that we or third parties acting on our behalf may send you emails regarding
                important information regarding your use of the Site and other electronic communications
                such as newsletters, promotional materials and so on. You may opt out of receiving the
                newsletters and other promotional messages at any time by clicking the unsubscribe link on
                the email, but you may not opt-out of receiving important items regarding the use of the
                Site or your account and the like. Please see our Privacy Policy for your opt-out rights and
                how to exercise them. All suggestions and comments you provide become the intellectual
                property of Eduspeed.</p>
        </div>
        <div class="single-content">
            <h3>IV. Consumer Users of Eduspeed Services.</h3>
            <p>Consumer Users of the Services may be prompted to express their interest in a school, work
                place, or other provider of products or services on our Site, via a checkbox, button click, or
                the like, or by adding a school or work place to your list for your account (by typing it in,
                clicking "Add to List", or clicking an icon that we may provide for this purpose). Depending
                on your contact preferences, we may attempt to inform these third party providers of your
                interest and express consent (where you provide such consent) to receive certain
                communications, including text messages or calls, in accordance with Eduspeed Privacy
                policy.</p>
            <p><b>Your use of the Eduspeed Services does not take the place of any school application or
                    employment application and does not guarantee the admittance into a school, job
                    interview or placement, or any other desired result. Eduspeed does not make any
                    guarantees, representations, warranties, or promises that any Eduspeed Services will
                    assist you in getting admitted into a school or institution, hired by a work place, or in
                    obtaining any other desired result.</b></p>
        </div>
        <div class="single-content">
            <h3>V. User-Generated Content.</h3>
            <p>Eduspeed , through its forums and surveys, provides Users an opportunity to post content
                to the Eduspeed Services. Eduspeed does not necessarily endorse, support, sanction,

                encourage, or agree with content posted by Users. By submitting, posting or consenting to
                our use of any User-generated content, you grant to Eduspeed and its affiliates a non-
                exclusive, perpetual, irrevocable, royalty-free, worldwide, sublicensable and transferable
                license to copy, publish, translate, modify, reformat, create derivative works from all or any
                portion of the User-generated content.</p>
            <p>You acknowledge that by posting User-generated content on the Site, we are merely acting
                as a passive conduit for its publication and distribution and we are not undertaking any
                obligation or liability related to it. All information, data, text, software, music, sound,
                photographs, video, messages, or other materials uploaded, posted or transmitted on the
                Site, whether publicly uploaded or posted or privately transmitted, is the sole responsibility
                of the person from whom such content originated.</p>
            <p>If you are an Entity User, you acknowledge and understand that a fundamental aspect of our
                Services is the ability of Consumer Users to share their experiences at each school, in a
                particular place to live, or work place. You do not have control or input into how and what
                Consumer User-generated content we use and display on the Site. Eduspeed reserves the
                right to remove reviews that do not follow the content guidelines or have been submitted
                from an unverified account (i.e., the authenticity of the email address for the account is
                verified by the Consumer User).</p>
            <p>However, you may report any objectionable User-generated content or other violations by
                emailing support&#64;eduspeed.com or by clicking the "Report" link (when available) next to
                User-generated content on the Site.</p>
            <p>If you upload, post, or otherwise transmit content to the Site, regardless of what kind of
                User you are, you acknowledge that any such submissions may be edited, removed,
                modified, published, transmitted, used and displayed by Eduspeed or third parties in any
                medium now existing or later invented, and for any purpose, including commercial uses. You
                waive any moral rights you may have in having the material edited, removed, modified,
                published, transmitted, used or displayed in a manner not agreeable to you.</p>
        </div>
        <div class="single-content">
            <h3>VI. Binding Agreement.</h3>
            <p>By accessing or using the Site or Services, you acknowledge that you have read and
                understood the terms of this UA, and agree to be bound by this UA, and by all related
                technical specifications, security procedures, rules of access, and any other terms and
                conditions set forth by us from time to time as posted here or through a link on the Site, or
                through other means that we publish to you. The use of the Site (including, without
                limitation, any content posted on it or software made available), by you, or anyone else
                authorized by you, is prohibited except as allowed by the terms and conditions contained in
                this UA. <b>We may restrict your use of the Site without warning at our discretion.</b> If you are
                an Entity User, you also agree to remain bound by the terms and conditions of any separate

                Service Agreement that you have entered into with us and which governs the commercial
                relationship between us.</p>
            <p>
                A. You represent, warrant at all times that:<br><br>
                (1) All information you submit is truthful and accurate, and you will promptly update it as it
                may change from time to time.<br>
                (2) For Consumer Users, you are a Indian citizen, at least age 13 or older, and you are of age
                to enter into a legally binding contract when using the Site.<br>
                (3) For Entity Users, the person subscribing to our Services and creating an account is
                employed by the institution or entity that you have designated and such person is
                authorized to act on the entity's behalf in an official capacity with respect to the Services
                and this Site.<br>
                (4) Your use of the Site does not and will not violate any laws or regulations that may apply
                to you, or any agreements that you have with someone else.<br>
                (5) Any content that you insert or upload to the Site does not infringe the copyright,
                trademark, publicity/privacy right, and/or other intellectual property or proprietary rights of
                any person.<br><br>
                B. You also agree to the following:<br><br>
                (1) You will not use the services provided by us or the Site for any commercial "for profit"
                purpose outside of the relationship that we have with you, without our prior written
                consent.<br>
                (2) For Consumer Users, you will not advertise or sell any goods or services through the Site.
                This includes your agreeing not to upload to, post, share, or otherwise publish through the
                Site any content that is commercial in nature, including but not limited to promotional
                material, advertising, or solicitation for goods or services.<br>
                (3) For Entity Users, any advertisements or promotions will be conducted strictly in
                accordance with the terms of the Service Agreement between you and us.<br>
                (4) You will not operate, conduct, or promote, directly or indirectly, raffles, lotteries or other
                similar gaming activities, whether for charitable purposes or otherwise.<br>
                (5) You will not upload, post or distribute any content which, in our sole determination, is
                inappropriate. This includes content that is false, indecent, defamatory, disparaging to the
                reputation of any individual or business, obscene, offensive, threatening, profane, harmful
                to minors in any way, threatening, invasive of privacy or publicity rights, abusive, illegal,
                harassing, violent, tasteless, denigrating, insensitive, pornographic or sexually oriented, or
                otherwise objectionable, or would constitute or encourage a criminal offense or other illegal
                activity, and/or violate the rights of any party or any law.<br>
                (6) While using the Site, you must comply with all applicable domestic and international
                laws, statues, ordinances, and regulations.<br>
                (7) We may, at our sole discretion and with or without notice to you, remove or edit any
                content that we deem to be unlawful, obscene, or in violation of this UA. We may, but are
                not obligated to, remove any other content that we determine in our sole discretion is
                fraudulent, threatening, libelous, defamatory, or otherwise objectionable, or infringes or

                violates any party's intellectual property or other proprietary rights. Our failure to block or
                remove any content, however, does not mean that we endorse it or make any
                representation or guarantee regarding such content.<br>
                (8) You will not distribute or post spam, unsolicited or bulk electronic communications,
                chain letters, or pyramid schemes; distribute viruses or any other technologies that may
                harm us, or the interests or property of any of our other users or business partners; bypass
                our system security measures, interfere with the working of our services or the Site, or
                impose an unreasonable or disproportionately large load on our infrastructure.<br>
                (9) You will not harvest or otherwise collect information about other Users or visitors to the
                Site, including email addresses, without their consent or in violation of our Privacy Policy or
                in violation of the Service Agreement that you have with us.<br>
                (10) You understand and agree that you do not have the authority to act on our behalf, to
                speak for us, or to in any way obligate us with respect to any third parties.
                Further, nothing in this UA obligates us to follow the Guidelines with respect to reported
                content; we have no obligation to conduct reviews of User content, and we reserve the
                right, for any reason or no reason, to refuse to act under the Guidelines or otherwise with
                respect to any reported content.
            </p>
        </div>

        <div class="single-content">
            <h3>VII. Our Proprietary Materials.</h3>
            <p>A. You may use the Site, including the content on it solely for the purposes stated on the
                Site itself and as described in this UA and in accordance with any Service Agreement that
                you have with us.<br>
                B. No text, logo, graphic, sound, image, photograph or software published on or underlying
                the Site may be copied, modified, published, sold or otherwise distributed, transferred or
                used without our prior express written permission, which we are not required to grant, even
                if you ask. <br>
                C. Except as otherwise expressly allowed by this UA or for Entity Users, in any separate
                Service Agreement that you have with us, you may not alter, modify, copy, distribute (for
                compensation or otherwise), transmit, display, perform, reproduce, reuse, post, publish,
                license, hyperlink to, promote, frame, download, cache, store for subsequent use, create
                derivative works from, transfer, or sell any information or content displayed on or obtained
                from the Site, in any manner.<br>
                D. We own all of the intellectual property connected with the Site. This includes, but is not
                limited to all copyrights, trademarks, patents, derivative works, moral rights, trade secrets,
                and proprietary information in all content that appears on the Site (other than the content
                of our third party business partners and advertisers who have permission to appear on the
                Site), and in all software that we develop in connection with the Site and our services. You
                agree not to copy, alter, frame, reverse engineer, distribute, publish, sell, prepare derivative<br>

                works based on, or commercially exploit the software and/or any content, without express
                written permission from us.<br>
                E. All Site content and functionality, and all software underlying the Site, may be changed or
                updated by us from time to time, without notice. Although Eduspeed cannot monitor the
                conduct of Users of the Site, it is a violation of this UA to use the Site or any information
                obtained from it in order to harass, abuse, or harm another person, or in order to contact,
                advertise to, solicit or sell to any person featured on the Site, except for Entity Users as may
                be expressly permitted by your Service Agreement with us.</p>
        </div>
        <div class="single-content">
            <h3>VIII. Third Party Website.</h3>
            <p>The Site may provide links to other websites or apps (each, a "<b>Linked Site</b>") as a
                convenience to you. These links do not mean that we review or endorse these Linked Sites
                or any services offered through them. Such Linked Sites are not maintained by us and we do
                not monitor or accept any responsibility for them. As such, we are not and will not be
                responsible for the content, products, services, policies or activities of those Linked Sites.
                Any dealings that you have with advertisers or other third-parties who may be found on any
                Linked Site are between you and the advertiser, for which you assume full responsibility,
                and you acknowledge that we are not liable for any loss or claim you may have against an
                advertiser. The inclusion of any link to a Linked Site on our Site does not imply an
                endorsement by Eduspeed of the Linked Site.</p>
        </div>
        <div class="single-content">
            <h3>IX. Third Party Website Seeking to Hyperlink to the Site.</h3>
            <p>We welcome appropriate hyperlinks to our Site by responsible third parties. By publishing a
                hyperlink to our Site on your website or app, you warrant to us that the content of your
                website or app is appropriate and consistent with the stated purposes of our Site and of
                Eduspeed. If this is not or will not be the case, then you do not have our permission to link
                to our Site, and doing so will constitute a violation of this UA. In addition, we reserve the
                right in our sole discretion to rescind our permission granted in this UA to link to our Site.</p>
            <p>Use of any Eduspeed trademark, service mark or other intellectual property or content
                provided by us for a hyperlink to our Site is permitted and licensed by Eduspeed solely for
                the purposes described on the Site and in this UA, and you shall not otherwise copy, modify,
                publish, use or distribute such trademarks, service marks or other intellectual property or
                content. Upon request by Eduspeed, you shall immediately remove any hyperlink to the
                Site, and discontinue use of any trademarks, service marks or other intellectual property or
                content provided by Eduspeed. As used in this section, "you" means any third party, even if
                you are not a User.</p>
        </div>
        <div class="single-content">
            <h3>X. Warranty and Legal Disclaimers.</h3>
            <p>We make reasonable efforts to provide a Site that is safe and accurate and Services that are
                useful to you, but we make no warranties. We retain the right to restrict use of the Site in
                any manner we deem appropriate.</p>
            <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT: YOUR USE OF THE SITE, THE CONTENT,
                THE SERVICES AND SOFTWARE IS AT YOUR SOLE RISK. ALL CONTENT, SERVICES AND
                SOFTWARE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. EXCEPT AS MAY BE
                PROVIDED IN A SEPARATE, WRITTEN SERVICES AGREEMENT BETWEEN US AND YOU,
                EDUSPEED EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND
                WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED
                WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                EDUSPEEDMAKES NO WARRANTY THAT (i) THE CONTENT, SERVICES AND SOFTWARE ARE
                ACCURATE, TIMELY, UNINTERRUPTED, VIRUS-FREE OR ERROR-FREE; (ii) THE RESULTS THAT
                MAY BE OBTAINED FROM THE USE OF THE CONTENT, SERVICES OR SOFTWARE WILL BE
                RELIABLE; AND (iii) ANY ERRORS IN THE SERVICES OR SOFTWARE WILL BE CORRECTED.</p>
            <p>Notwithstanding the foregoing, Eduspeed observes, and expects third party providers to
                observe and to strictly adhere to, all central, state, and local laws, rules and regulations,
                including, without limitation.</p>
        </div>

        <div class="single-content">
            <h3>XI. Limitation of Liability.</h3>
            <p>IN NO EVENT SHALL EDUSPEED, ITS EMPLOYEES, AGENTS, AFFILIATES, DIRECTORS, OFFICERS,
                REPRESENTATIVES, SUBCONTRACTORS, ADVISORS, OR ANYONE ELSE WHO HAS BEEN
                INVOLVED IN THE CREATION, PRODUCTION, OR DELIVERY OF THE SITE (COLLECTIVELY
                "EDUSPEED AND ITS AFFILIATES") BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING
                WITHOUT LIMITATION DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
                EXEMPLARY DAMAGES, DAMAGES OR LOSS OF PRODUCTS, USE OF DATA OR OTHER
                INTANGIBLE LOSSES RESULTING FROM THE USE OF ANY OF THE SITE OR ANY LINKED SITE (AS
                DEFINED IN THIS UA), OR INABILITY TO USE THE SITE, SERVICES, CONTENT OR SOFTWARE.
                YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SITE IS PREDICATED UPON YOUR
                WAIVER OF ANY RIGHT TO SUE EDUSPEED AND ITS AFFILIATES OR TO PARTICIPATE IN ANY
                CLASS ACTION SUIT FOR ANY LOSSES OR DAMAGES RESULTING FROM YOUR USE OF THE
                SITE OR SERVICES, UNLESS AND EXCEPT AS, BUT ONLY IN ACCORDANCE WITH, ANY
                SEPARATE, WRITTEN SERVICES AGREEMENT THAT YOU HAVE WITH US.</p>
            <p>YOU UNDERSTAND AND AGREE THAT EDUSPEED AND ITS AFFILIATES WILL NOT BE LIABLE
                FOR ANY DAMAGES CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
                INTERRUPTION, DEFECT, DELAY IN OPERATION OF TRANSMISSION, COMPUTER VIRUS, OR
                EQUIPMENT OR NETWORK FAILURE WHATSOEVER, AND THAT EDUSPEED AND ITS
                AFFILIATES WILL NOT BE LIABLE WHATSOEVER FOR ANY DAMAGES OR INJURY THAT RESULT
                FROM THE USE OF, OR THE INABILITY TO USE, THE CONTENT ON THE SITE, EVEN IF THERE IS
                NEGLIGENCE BY EDUSPEED OR AN AUTHORIZED EDUSPEED REPRESENTATIVE, OR YOU HAVE
                BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR BOTH.</p>
            <p>EDUSPEED NEITHER WARRANTS NOR REPRESENTS THAT YOUR USE OF THE SERVICES,
                CONTENT, SOFTWARE OR ANY OTHER MATERIALS DISPLAYED ON THE SITE WILL NOT
                INFRINGE RIGHTS OF THIRD PARTIES.</p>
        </div>
        <div class="single-content">
            <h3>XII. Resolution of Disputes.</h3>
            <p><b>You and Eduspeed agree that any claim or dispute at law or equity that may arise
                    between us relating in any way to this UA or your use of or access to the Site or related
                    Services will be resolved in accordance with the provisions set forth in this Resolution of
                    Disputes Section. Please read this Section carefully. It affects your rights and will have a
                    substantial impact on how claims you and we have against each other are resolved.</b><br>
                <b>A. Applicable Law:</b> This UA is governed by the laws defined by Indian state, without regard
                to its choice of law or conflicts of law provisions, If any provision is deemed by a court of
                competent jurisdiction to be unlawful or unenforceable, it will not affect the validity and
                enforceability of the remaining provisions.<br>
                <b>B. Agreement to Arbitrate:</b> You and Eduspeed each agree that any and all disputes or claims
                that may arise between you and us relating in any way to or arising out of this or previous
                versions of the UA or your use of or access to the Site or any related Services shall be
                resolved exclusively through final and binding arbitration, rather than in court, except that
                you may assert claims in small claims court, if your claims qualify.
            </p>
        </div>

    </div>
</div>

<app-footer></app-footer>