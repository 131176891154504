<div class="maps-container">
    <div class="map-holder" id="map"></div>
    <button class="toggle-fullscreen" (click)="openModal()"><i class='bx bx-expand'></i></button>
</div>

@if (toggleFullscreen) {
<div class="popup-container">
    <div class="popup-content">
        <div class="filter-search-bar">
            <div class="title"><span style="color: var(--mainColor)"><i class="flaticon-place"></i></span> Coaching centers in {{locality}}, {{city}} </div>
            <span class="close" (click)="closeModal()">&times;</span>
        </div>
        <div class="modal-map-container" id="modal-map"></div>
    </div>
</div>

}