import { Component, OnInit } from '@angular/core';
import { InsightService } from '../../../services/insight/insight.service';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ModalComponent } from '../../common/modal/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

declare let $: any;
const BLOGS_PER_PAGE = 6;

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  totalPageScale = 0
  pageEvent1: PageEvent
  totalRecords: number
  pageSize: number
  currentPage: number = 0
  loading: boolean
  searchMode: any
  blogs: any
  isUserLoggedIn = false
  category: string
  tag: string
  subTitle = 'Edu Digest'
  mainTitle1 = 'All Blog'
  mainTitle2 = 'Posts'
  categories= []
  constructor(private insightService: InsightService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UserService,
    private http: HttpClient,
    private metaService: Meta,
    private titleService: Title,
    ) { }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.isUserLoggedIn = this.userService.isUserLoggedIn();
    const category = this.activatedRoute.snapshot.queryParamMap.get('category')
    const tag = this.activatedRoute.snapshot.queryParamMap.get('tag')
    if(category){
      this.category = decodeURIComponent(category);
      this.subTitle = 'Category'
      this.mainTitle1 = 'Related Blogs By Category '
      this.mainTitle2 = this.category
    }
    if(tag){
      this.tag = decodeURIComponent(tag);
      this.subTitle = 'Tag'
      this.mainTitle1 = 'Related Blogs By Tag #'
      this.mainTitle2 = this.tag
    }
    this.getBlogs()
    this.getBlogCategories();
  }

  updatePageDetails() {
    this.http.get<any>('assets/json/blog-page-details.json').pipe(
        map(data => data.find(item => item.category === this.category))
    ).subscribe({
        next: (blog) => {
            this.titleService.setTitle( blog.title );
            this.metaService.updateTag({ name: 'description', content: blog.metaDescription});
            this.metaService.updateTag({ name: 'keywords', content: blog.metaKeywords});
        }
    })
  }

  getBlogs(): void {
   this.start()
    this.insightService.retrieveBlogs(this.category, this.tag, 'publishDate', 'dsc', this.pageSize, this.currentPage).subscribe((res:any) => {
      this.blogs = res.payload.blogs;
      this.totalRecords = res.payload.totalCount
      this.updatePageDetails()
      this.end()
      this.setPaginationData()
    })
  }

  getBlogCategories(): void {
    this.categories = this.insightService.retrieveBlogCategory();
  }

  onCategorySelect(category){
    this.category = category;
    const queryParams = { category : encodeURIComponent(this.category)}
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams});
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }


  setPaginationData(){
    const { length } = this.blogs
    const quotient = Math.floor(length / BLOGS_PER_PAGE)
    const dividend = length % BLOGS_PER_PAGE
    this.totalPageScale = dividend > 0 ? quotient + 1 : quotient
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent1 = e;
    this.totalRecords = e.length;
    this.pageSize = e.pageSize;
    this.currentPage = e.pageIndex;
    this.getBlogs()
  }

  openBlog(blog: any): void {
    this.router.navigate(['/blog-details'], this.getExtras(blog))
  }


  getExtras(blog: any): NavigationExtras {
    return {
      state:{
          redirectOptions: {
            url: '/blog-details',
            queryParams: {
              category: blog.category,
              blogId: blog.id
            }
          }
      },
      queryParams: {
        category: blog.category,
        blogId: blog.id
      }
    }
  }
}

enum ActionButton {
  LOGIN = 'Login',
  REGISTER = 'Register'
}
