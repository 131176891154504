import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { CoachingCenterDetails } from 'src/app/models/coaching-center';
import { MapsOrganizationsType } from 'src/app/models/maps';
import { CoachingCenterService } from 'src/app/services/coaching-center/coaching-center.service';
import { SchoolService } from 'src/app/services/school/school.service';
import { environment } from 'src/environments/environment';

export interface MapsInstitutesDataModel {
  id: string
  name: string
  latitude: number
  longitude: number
}
@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrl: './google-maps.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleMapsComponent implements OnInit, OnChanges{

  @Input() organization: MapsOrganizationsType;
  @Input() location: any;
  mapsMarkersLocation: MapsInstitutesDataModel[];
  states
  state= ''
  city= ''
  locality = ''
  address: string;
  
  map: google.maps.Map;
  geocoder: google.maps.Geocoder;
  markersInfo: MapsInstitutesDataModel[]
  toggleFullscreen: boolean = false;

  constructor(private schoolService: SchoolService, private coachingService: CoachingCenterService) {}

  ngOnInit(): void { 
  }
  
  ngOnChanges(): void {
    this.state = this.location.state;
    this.city = this.location.city;
    this.locality = this.location.locality;
    this.setAddress();
    this.initMap()
  }

  infowindow: google.maps.InfoWindow;

  mapOptions: google.maps.MapOptions = {
    zoom: 12,
    fullscreenControl: false,
    mapId: environment.googleMapsMapId
  };

  loader = new Loader({
    apiKey: environment.googleMapsApiKey,
    version: "weekly",
    libraries: ['geocoding','maps', 'marker'],
  });
  
  async initMap(): Promise<void> {

    const mapHtmlId = !this.toggleFullscreen ? 'map' : 'modal-map'

    this.loader.importLibrary('maps').then(({Map}) => {
      this.map = new Map(document.getElementById(mapHtmlId), this.mapOptions);
    })
    this.loader.importLibrary('geocoding').then(({Geocoder}) => {
      this.geocoder = new Geocoder()
      let response = this.geocoder.geocode({
        address: this.address
      }, (results,status) => {
         if(status === 'OK'){
          this.map.setCenter(results[0].geometry.location)
  
          this.handleMarkers()
        } else {
          console.log(status);
        }
      })
    })

  }

  
  handleMarkers() {
    
    this.coachingService.findCCMapMarkers(this.city, this.locality).subscribe({
      next: (markers) => {
        this.markersInfo = markers.payload
        this.addMarkers()
      },
      error: (err) => console.log(err)
    })

  }
  async addMarkers(){
    
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    let infoWindow = new google.maps.InfoWindow();
    this.markersInfo.forEach(element => {
      const marker = new AdvancedMarkerElement({
        map: this.map,
        position : {lat: element.latitude, lng: element.longitude},
        title: element.name,
      })
      const externalHref = `../coaching-center-details/${element.id}`;
      const contentString = 
        `<div class="info-window">
          <h3>${element.name}</h3>
          <a href="${externalHref}">Open<span><i class="link-external"></i></span></a>
         </div>`;

      marker.addListener('click', () => {
        infoWindow.close();
        infoWindow.setContent(contentString);

        infoWindow.open({
          anchor: marker,
          map: this.map
        })
      })
    });

    
  }

  setAddress() {
    if(this.locality != '')
      this.address = `${this.locality}, ${this.city}, ${this.state}`;
    else 
    this.address = `${this.city}, ${this.state}`;    
}

openModal() {
  this.toggleFullscreen = !this.toggleFullscreen
  this.initMap()
}

closeModal() {
  this.toggleFullscreen = !this.toggleFullscreen
}
}
