<mat-card class="container first-typography background"
  style="padding-top: 30px;padding-bottom: 50px;padding-left: 50px;">
  <h2 class="title">{{formTitle}}</h2>

  <mat-stepper orientation="horizontal" linear=true #stepper>
    <mat-step [stepControl]="basicDetailsFormGroup" matStep>
      <form [formGroup]="basicDetailsFormGroup" class="form">
        <ng-template matStepLabel>Institute</ng-template>
        <mat-form-field class="field">
          <mat-label>Coaching Center ID</mat-label>
          <input matInput placeholder="ID" formControlName="id" required>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name of the Institute" formControlName="name" required>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Education Mode</mat-label>
          <mat-select required formControlName="educationMode" multiple>
            <mat-option [value]="mode" *ngFor="let mode of educationModes">{{ mode }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Type Of Institute</mat-label>
          <mat-select required formControlName="instituteType" multiple>
            <mat-option [value]="type" *ngFor="let type of typeOfInstitutes">{{ type }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>History of Institute</mat-label>
          <textarea matInput placeholder="Write about History of Institute" class="textarea" formControlName="history"></textarea>
        </mat-form-field>

        <mat-form-field class="field">
          <mat-label>Founder's Info</mat-label>
          <input matInput placeholder="Founders' info/Promoters' Info" formControlName="founderInfo">
        </mat-form-field>

        <mat-form-field class="field">
          <mat-label>Years Of Operation</mat-label>
          <input matInput placeholder="Ex: 40+" formControlName="yearsOfOperation">
        </mat-form-field>

        <mat-form-field class="field">
          <mat-label>Institute Locations</mat-label>
          <mat-select required formControlName="locations" multiple>
            <mat-option [value]="instituteLocation" *ngFor="let instituteLocation of instituteLocations">{{ instituteLocation }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="field">
          <mat-label>Number Of Students</mat-label>
          <input matInput placeholder="Ex: 400" formControlName="studentsCount">
        </mat-form-field>

        <mat-form-field class="field">
          <mat-label>Student to Teacher Ratio</mat-label>
          <input matInput placeholder="Ex: 10:1" formControlName="studentTeacherRatio">
        </mat-form-field>

        <mat-form-field class="field">
          <mat-label>Awards And Recognitions</mat-label>
          <textarea matInput placeholder="Capture awards and recognitions here" formControlName="awardsAndRecognition" class="textarea"></textarea>
        </mat-form-field>

        <mat-form-field class="field">
          <mat-label>Medium Of Teaching</mat-label>
          <mat-select required formControlName="teachingMedium" multiple>
            <mat-option [value]="teachingMedium" *ngFor="let teachingMedium of teachingMediums">{{ teachingMedium }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="field">
          <mat-label>Tieup With University/Institution</mat-label>
          <input matInput formControlName="universityTieUp">
        </mat-form-field>

        <mat-form-field class="field">
          <mat-label>Academic Achievements</mat-label>
          <textarea matInput placeholder="#AIR 4 CA Final - Nov 2023" formControlName="achievements" class="textarea"></textarea>
        </mat-form-field> 
        
        <mat-form-field class="field">
          <mat-label>Highlights & Specialities</mat-label>
          <textarea matInput placeholder="#AIR 4 CA Final - Nov 2023" formControlName="highlights" class="textarea"></textarea>
        </mat-form-field> 
        
        <div style="text-align:left;">
          <span style="float:right;">
            <button mat-raised-button color="primary" matStepperNext>Next</button>
          </span>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="addressFormGroup">
      <form [formGroup]="addressFormGroup" class="form">
        <ng-template matStepLabel>Address</ng-template>
            <h4><b>Head Office</b></h4>
            <mat-form-field class="field">
              <mat-label>Street Name/ No</mat-label>
              <input matInput placeholder="Head Office Address Line 1" formControlName="headOfficeAddressLine1" required>
            </mat-form-field>
            <mat-form-field class="field">
              <mat-label>Locality</mat-label>
              <input matInput placeholder="Head Office Address Line 2" formControlName="headOfficeAddressLine2" required>
            </mat-form-field>
            <div class="row">
              <div class="col-4">
                <mat-form-field class="field">
                  <mat-label>City</mat-label>
                  <input type="text" matInput formControlName="headOfficeCity" [matAutocomplete]="autoHeadCity">
                  <mat-autocomplete #autoHeadCity="matAutocomplete">
                    <mat-option *ngFor="let city of headFilteredCities | async" [value]="city">
                      {{ city }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field class="field">
                  <mat-label>State</mat-label>
                  <input type="text" matInput formControlName="headOfficeState" [matAutocomplete]="autoHeadState">
                  <mat-autocomplete #autoHeadState="matAutocomplete">
                    <mat-option *ngFor="let state of headFilteredStates | async" [value]="state">
                      {{ state }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field class="field">
                  <mat-label>Pincode</mat-label>
                  <input matInput placeholder="Head Office Pincode" formControlName="headOfficePincode" required>
                  <mat-error *ngIf="addressFormGroup.controls['headOfficePincode'].hasError('invalidPincode')" class="error">
                    Pincode not valid
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
    
            <div class="row">
              <div class="col-6">
                <mat-form-field class="field">
                  <mat-label>Email 1</mat-label>
                  <input matInput placeholder="Head Office Email1" formControlName="headOfficeEmail1" required>
                  <mat-error *ngIf="addressFormGroup.controls['headOfficeEmail1'].hasError('email')" class="error">
                    Email not valid
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="field">
                  <mat-label>Email 2</mat-label>
                  <input matInput placeholder="Head Office Email2" formControlName="headOfficeEmail2">
                  <mat-error *ngIf="addressFormGroup.controls['headOfficeEmail2'].hasError('email')" class="error">
                    Email not valid
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
    
            <div class="row">
              <div class="col-4">
                <mat-form-field>
                  <mat-label>Contact 1</mat-label>
                  <input matInput placeholder="Mobile/Landline" formControlName="headOfficeContact1" required>
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field>
                  <mat-label>Contact 2</mat-label>
                  <input matInput placeholder="Mobile/Landline" formControlName="headOfficeContact2">
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field>
                  <mat-label>Contact 3</mat-label>
                  <input matInput placeholder="Mobile/Landline" formControlName="headOfficeContact3">
                </mat-form-field>
              </div>
            </div>
    
            <mat-form-field class="field">
              <mat-label>Website</mat-label>
              <input matInput placeholder="Head Office Website of the Institute" formControlName="headOfficeWebsite">
            </mat-form-field><br><br>
            <h4><b>Branch Office</b></h4>
            <mat-form-field class="field">
              <mat-label>No/ Street Name</mat-label>
              <input matInput placeholder="Branch Office Address Line 1" formControlName="branchOfficeAddressLine1">
            </mat-form-field>
            <mat-form-field class="field">
              <mat-label>Street name/ Landmark</mat-label>
              <input matInput placeholder="Branch Office Address Line 2" formControlName="branchOfficeAddressLine2">
            </mat-form-field>
            <mat-form-field class="field">
              <mat-label>Locality</mat-label>
              <input matInput placeholder="Locality" formControlName="branchOfficeLocality">
            </mat-form-field>
            <div class="row">
              <div class="col-4">
                <mat-form-field class="field">
                  <mat-label>City</mat-label>
                  <input type="text" matInput formControlName="branchOfficeCity" [matAutocomplete]="autoBranchCity">
                  <mat-autocomplete #autoBranchCity="matAutocomplete">
                    <mat-option *ngFor="let city of branchFilteredCities | async" [value]="city">
                      {{ city }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field class="field">
                  <mat-label>State</mat-label>
                  <input type="text" matInput formControlName="branchOfficeState" [matAutocomplete]="autoBranchState">
                  <mat-autocomplete #autoBranchState="matAutocomplete">
                    <mat-option *ngFor="let state of branchFilteredStates | async" [value]="state">
                      {{ state }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field class="field">
                  <mat-label>Pincode</mat-label>
                  <input matInput placeholder="Branch Office Pincode" formControlName="branchOfficePincode">
                  <mat-error *ngIf="addressFormGroup.controls['branchOfficePincode'].hasError('invalidPincode')" class="error">
                    Pincode not valid
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
    
            <div class="row">
              <div class="col-6">
                <mat-form-field class="field">
                  <mat-label>Email 1</mat-label>
                  <input matInput placeholder="Branch Office Email" formControlName="branchOfficeEmail1">
                  <mat-error *ngIf="addressFormGroup.controls['branchOfficeEmail1'].hasError('email')" class="error">
                    Email not valid
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="field">
                  <mat-label>Email 2</mat-label>
                  <input matInput placeholder="Branch Office Email" formControlName="branchOfficeEmail2">
                  <mat-error *ngIf="addressFormGroup.controls['branchOfficeEmail2'].hasError('email')" class="error">
                    Email not valid
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
    
            <div class="row">
              <div class="col-4">
                <mat-form-field>
                  <mat-label>Contact 1</mat-label>
                  <input matInput placeholder="Mobile/Landline" formControlName="branchOfficeContact1">
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field>
                  <mat-label>Contact 2</mat-label>
                  <input matInput placeholder="Mobile/Landline" formControlName="branchOfficeContact2">
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field>
                  <mat-label>Contact 3</mat-label>
                  <input matInput placeholder="Mobile/Landline" formControlName="branchOfficeContact3">
                </mat-form-field>
              </div>
            </div>
    
            <mat-form-field class="field">
              <mat-label>Website</mat-label>
              <input matInput placeholder="Branch Office Website" formControlName="branchOfficeWebsite">
            </mat-form-field>

            <div class="row">
              <div class="col-6">
                <mat-form-field class="field">
                  <mat-label>Latitude</mat-label>
                  <input matInput placeholder="Google maps latitude coords" formControlName="branchOfficeLatitude">
                  <mat-error *ngIf="addressFormGroup.controls['branchOfficeLatitude'].hasError('invalidLatitude')" class="error">
                    Latitude not valid
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="field">
                  <mat-label>Longitude</mat-label>
                  <input matInput placeholder="Google maps longitude coords" formControlName="branchOfficeLongitude">
                  <mat-error *ngIf="addressFormGroup.controls['branchOfficeLongitude'].hasError('invalidLongitude')" class="error">
                    Longitude not valid
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <mat-form-field class="field">
              <mat-label>Google Map Location</mat-label>
              <input matInput placeholder="Map location in iFrame format" formControlName="mapLocation">
            </mat-form-field>
            <div style="text-align:left;">
              <button mat-raised-button color="secondary" matStepperPrevious>Back</button>
              <span style="float:right;">
                <button mat-raised-button color="primary" matStepperNext>Next</button>
              </span>
            </div>
      </form>
    </mat-step>
    <mat-step  [stepControl]="entranceExamFormGroup">
      <mat-slide-toggle
        required 
        (change)="toggleForm(entranceExamFormGroup)" 
        [checked]="entranceExamFormGroup.enabled"
        labelPosition="before"
        style="margin-bottom: 20px;">
        <span style="font-family: var(--fontFamily);">Entrance exam applicable?</span>
      </mat-slide-toggle>
      <form [formGroup]="entranceExamFormGroup" class="form">
        <ng-template matStepLabel>Entrance Exam</ng-template>
        <mat-form-field class="field">
          <mat-label>NEET-UG (National Eligibility Cum Entrance Test)</mat-label>
          <mat-select required formControlName="neetUnderGraduate" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>NEET-PG (National Eligibility Cum Entrance Test)</mat-label>
          <mat-select required formControlName="neetPostGraduate" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>JEE-Main (Joint Entrance Examination)</mat-label>
          <mat-select required formControlName="jeeMain" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>JEE-Advance (Joint Entrance Examination)</mat-label>
          <mat-select required formControlName="jeeAdvance" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>CLAT-Common-Law Admission Test</mat-label>
          <mat-select required formControlName="clat" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>IPMAT-Integrated Programme in Management Aptitude Test</mat-label>
          <mat-select required formControlName="ipmat" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>LSAT</mat-label>
          <mat-select required formControlName="lsat" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>SAT- Scholastic Aptitude Test</mat-label>
          <mat-select required formControlName="sat" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>GATE - Graduate Aptitude Test in Engineering</mat-label>
          <mat-select required formControlName="gate" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>CAT - Common Entrance Test</mat-label>
          <mat-select required formControlName="cat" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>NDA-National Defence Academy</mat-label>
          <mat-select required formControlName="nda" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>NID Design Aptitude Test-The National Institute of Design Entrance</mat-label>
          <mat-select required formControlName="nid" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>NATA - National Aptitude Test in Architecture</mat-label>
          <mat-select required formControlName="nata" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>NIFT - Entrance Exam</mat-label>
          <mat-select required formControlName="nift" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>BITSAT</mat-label>
          <mat-select required formControlName="bitsat" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>VITEEE</mat-label>
          <mat-select required formControlName="viteee" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>CUET - Central University Entrance Test</mat-label>
          <mat-select required formControlName="cuet" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>GMAT</mat-label>
          <mat-select required formControlName="gmat" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>TOEFL - The Test of English as a Foreign Language</mat-label>
          <mat-select required formControlName="toefl" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>IELTS-International English Language Testing System</mat-label>
          <mat-select required formControlName="ielts" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>GRE -The Graduate Record Examination</mat-label>
          <mat-select required formControlName="gre" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>MCAT</mat-label>
          <mat-select required formControlName="mcat" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>NCHMJEE - Entrance Exam for Hotel Management</mat-label>
          <mat-select required formControlName="nchmjee" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Others</mat-label>
          <input matInput placeholder="Specify if any" formControlName="others">
        </mat-form-field>
        <div style="text-align:left;">
          <button mat-raised-button color="secondary" matStepperPrevious>Back</button>
          <span style="float:right;">
            <button mat-raised-button color="primary" matStepperNext>Next</button>
          </span>
        </div>
      </form>
    </mat-step>
    <mat-step  [stepControl]="academicsFormGroup">
      <mat-slide-toggle
        required 
        (change)="toggleForm(academicsFormGroup)" 
        [checked]="academicsFormGroup.enabled"
        labelPosition="before"
        style="margin-bottom: 20px;">
        <span style="font-family: var(--fontFamily);">Academics exam applicable?</span>
      </mat-slide-toggle>
      <form [formGroup]="academicsFormGroup" class="form">
        <ng-template matStepLabel>Academics</ng-template>
        <mat-form-field class="field">
          <mat-label>Chartered Accountant CA</mat-label>
          <mat-select required formControlName="chAct" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Certified Management Accountant- CMA/ICWA</mat-label>
          <mat-select required formControlName="cma" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Company Secretary Course- CS</mat-label>
          <mat-select required formControlName="csc" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Association of Certified Chartered Accountants - ACCA</mat-label>
          <mat-select required formControlName="acca" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Grade 10</mat-label>
          <mat-select required formControlName="grade10" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field" *ngIf="grade10SubjectDisplay">
          <mat-label>Grade 10 - If yes, For which subjects?</mat-label>
          <mat-select formControlName="grade10Subjects" multiple>
            <mat-option [value]="subject" *ngFor="let subject of subjectsOffered">{{ subject }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Grade 12</mat-label>
          <mat-select required formControlName="grade12" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field" *ngIf="grade12SubjectDisplay">
          <mat-label>Grade 12 - If yes, For which subjects?</mat-label>
          <mat-select formControlName="grade12Subjects" multiple>
            <mat-option [value]="subject" *ngFor="let subject of subjectsOffered">{{ subject }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Undergraduation Courses </mat-label>
          <mat-select required formControlName="ugCourses" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Postgraduation Courses </mat-label>
          <mat-select required formControlName="pgCourses" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Others</mat-label>
          <input matInput placeholder="Specify if any" formControlName="others">
        </mat-form-field>
        <div style="text-align:left;">
          <button mat-raised-button color="secondary" matStepperPrevious>Back</button>
          <span style="float:right;">
            <button mat-raised-button color="primary" matStepperNext>Next</button>
          </span>
        </div>  
      </form>
    </mat-step>
    <mat-step  [stepControl]="languageFormGroup">
      <mat-slide-toggle
        required 
        (change)="toggleForm(languageFormGroup)" 
        [checked]="languageFormGroup.enabled"
        labelPosition="before"
        style="margin-bottom: 20px;">
        <span style="font-family: var(--fontFamily);">Language applicable?</span>
      </mat-slide-toggle>
      <form [formGroup]="languageFormGroup" class="form">
        <ng-template matStepLabel>Language</ng-template>
        <mat-form-field class="field">
          <mat-label>English</mat-label>
          <mat-select required formControlName="english" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Tamil</mat-label>
          <mat-select required formControlName="tamil" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Hindi</mat-label>
          <mat-select required formControlName="hindi" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>French</mat-label>
          <mat-select required formControlName="french" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>German</mat-label>
          <mat-select required formControlName="germany" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Mandarin - Chinese</mat-label>
          <mat-select required formControlName="mandarin" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Japanese</mat-label>
          <mat-select required formControlName="japanese" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Urdu</mat-label>
          <mat-select required formControlName="urdu" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Arabic</mat-label>
          <mat-select required formControlName="arabic" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Korean</mat-label>
          <mat-select required formControlName="korean" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Spanish</mat-label>
          <mat-select required formControlName="spanish" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Italian</mat-label>
          <mat-select required formControlName="italian" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Sanskrit</mat-label>
          <mat-select required formControlName="sanskrit" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Malayalam</mat-label>
          <mat-select required formControlName="malayalam" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Telugu</mat-label>
          <mat-select required formControlName="telugu" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Kannada</mat-label>
          <mat-select required formControlName="kannada" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Others</mat-label>
          <input matInput placeholder="Specify if any" formControlName="others">
        </mat-form-field>
        <div style="text-align:left;">
          <button mat-raised-button color="secondary" matStepperPrevious>Back</button>
          <span style="float:right;">
            <button mat-raised-button color="primary" matStepperNext>Next</button>
          </span>
        </div>
      </form>
    </mat-step>
    <mat-step  [stepControl]="recruitmentFormGroup">
      <mat-slide-toggle
        required 
        (change)="toggleForm(recruitmentFormGroup)" 
        [checked]="recruitmentFormGroup.enabled"
        labelPosition="before"
        style="margin-bottom: 20px;">
        <span style="font-family: var(--fontFamily);">Recruitment applicable?</span>
      </mat-slide-toggle>
      <form [formGroup]="recruitmentFormGroup" class="form">
        <ng-template matStepLabel>Recruitment</ng-template>
        <mat-form-field class="field">
          <mat-label>National Eligibility Test/NET/ State Eligibility Test-SET/JRF</mat-label>
          <mat-select required formControlName="netSet" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Railway recruitment Board-RRB</mat-label>
          <mat-select required formControlName="rrb" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Staff Selection for different Cadres- SSC</mat-label>
          <mat-select required formControlName="ssc" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Engineering Services Examinations</mat-label>
          <mat-select required formControlName="ese" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Indian Economic Service Examination</mat-label>
          <mat-select required formControlName="iese" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>SBI & other bank Exams, JAIIB/CAIIB</mat-label>
          <mat-select required formControlName="banks" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>IBPS Clerk Exams</mat-label>
          <mat-select required formControlName="ibps" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Reserve Bank of India Services Board- RBI Exams</mat-label>
          <mat-select required formControlName="rbi" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>National Bank for Agriculture & Rural Development (NABARD)- Exams</mat-label>
          <mat-select required formControlName="nabard" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Civil service Exam/UPSC</mat-label>
          <mat-select required formControlName="cse" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Teachers Eligibility Test/TNTET/CTET</mat-label>
          <mat-select required formControlName="tnet" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Tamilnadu Public Service Commission - TNPSC</mat-label>
          <mat-select required formControlName="tnpsc" multiple>
            <mat-option [value]="course" *ngFor="let course of coursesOffered">{{ course }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label>Others</mat-label>
          <input matInput placeholder="Specify if any" formControlName="others">
        </mat-form-field>
      </form>
      <div>
        <button mat-raised-button color="secondary" matStepperPrevious>Back</button>
      </div>
    </mat-step>
  </mat-stepper>
  <div class="field">
    <button mat-raised-button color="secondary" (click)="handleStepperReset(stepper)">Reset</button>
    <button mat-raised-button color="primary" (click)="submit(stepper)" *ngIf="isFinalPage(stepper)" style="float:right">Submit</button>
  </div>
</mat-card>