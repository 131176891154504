import {Component, ElementRef, ViewChild, OnInit, TemplateRef} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { UserDetails } from 'src/app/models/user';
declare let $: any
@Component({
  selector: 'edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  searchUser = {
    firstName: null,
    lastName: null,
    emailId: null,
    mobile: null,
  }
  user: UserDetails;
  statuses = [
    'Active',
    'Inactive'
  ]

  roles = [
    'Student',
    'Parent',
    'Admin'
  ]
  separatorKeysCodes: number[] = [ENTER, COMMA];
  users: MatTableDataSource<UserDetails>;
  displayedColumns: string[] = ['firstName', 'email', 'mobile', 'roles', 'status', 'action'];
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('userForm', { static: true }) userForm: TemplateRef<any>;  
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;


  constructor(private userService: UserService, public dialog: MatDialog) {}

  ngOnInit(): void {}

  search() {
    this.start()
    const {firstName, lastName, mobile, emailId} = this.searchUser
    if( !firstName && !lastName && !mobile && !emailId){
      this.end()
      alert('Enter atleast one search criteria')
      return
    }
    this.userService.searchUsers(this.searchUser).subscribe({
      next: (res:any) => {
        this.end()
        this.users = new MatTableDataSource(res.payload)
        setTimeout(() => {
          this.users.paginator = this.paginator;
          this.users.sort = this.sort;
        })
      },
      error: () => {
        this.end()
        alert('Error retrieving Users')
      }
    })
  }

  openUpdateUser(element){
    this.user = this.users.data.find(user => user.email === element.email)
    this.dialog.open(this.userForm);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.users.filter = filterValue.trim().toLowerCase();

    if (this.users.paginator) {
      this.users.paginator.firstPage();
    }
  }

  saveContent(): void{
    this.updateUser();
  }

  updateUser(){
    this.start()
    console.log('this.user',this.user)
    const { firstName, roles, email, status} = this.user
    if( !firstName || !email || !roles || !status){
      this.end()
      alert('Please fill in all the mandatory fields')
      return
    }
    this.userService.updateUser(this.user).subscribe({
      next: (res) => {
        this.dialog.closeAll()
        this.end()
        console.log('success repo', res)
        alert(res.payload)
      },
      error: (err) => {
        this.end()
        console.error('err==', err)
        alert('Error creating User. Try again later.')
      }
    })
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }

}
