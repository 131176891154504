export class User {
    id: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    token?: string;
    email: string;
}

export enum Role {
   ADMIN = 'Admin',
   SUPER_ADMIN = 'SuperAdmin',
   DATA_ENTRY = 'DataEntry',
   PARENT = 'Parent',
   STUDENT = 'Student',
   GUEST = 'Guest',
   PUBLIC = 'Public'
}

export interface UserDetails {
    firstName: string,
    lastName: string,
    email: string,
    mobile: number,
    status: string,
    roles: string [],
    registeredSince: Date,
    registeredThrough: string,
    profileActivated: boolean,
    newsLetterActivated: boolean,
    emailVerified: boolean
}