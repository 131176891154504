import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Validators, Editor, Toolbar } from 'ngx-editor';
import { ButtonAction } from '../../../models/general';

@Component({
  selector: 'text-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit, OnChanges {

  @Output() 
  content = new EventEmitter();
  tags = ['tag1', 'tag2'];
  @Input('editordoc')
  editordoc? = ``;
  @Input('buttonAction')
  buttonAction?: ButtonAction

  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  form: FormGroup;

  get doc(): AbstractControl {
    return this.form.get('editorContent');
  }

  constructor() { }

  ngOnInit(): void {
    this.editor = new Editor();
    this.form  = new FormGroup({
      editorContent: new FormControl(
        { value: this.editordoc, disabled: false },
        Validators.required()
      ),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const value = changes['buttonAction']?.currentValue
    if(value === ButtonAction.SAVE){
      this.content.emit(this.doc.value)
    }else if(value === ButtonAction.RESET){
      this.editordoc = ''
    }
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
