<app-navbar></app-navbar>
<div class="inner-banner inner-admin">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Admin</h3>
        </div>
    </div>
</div>

<mat-card class="container first-typography background"
    style="padding-top: 30px;padding-bottom: 50px;padding-left: 50px;">
    <h2 class="title">Update Event</h2>
    <form (action)="search()">
        <div class="row">
            <div class="row">
                <h3>Search Event</h3>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label>Event ID</mat-label>
                    <input matInput [(ngModel)]="event.id" name="id">
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput [(ngModel)]="event.name" name="title">
                </mat-form-field>
                <span><i>*Wildcard search also works. Ex: %CBSE%</i></span>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label>Category</mat-label>
                    <mat-select [(ngModel)]="eventCategory" name="category"
                        (ngModelChange)="event.category = eventCategory">
                        <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>

    <div class="action">
        <button mat-raised-button color="primary" (click)="search()">Search</button>
    </div>
</mat-card>
<mat-card class="container first-typography background"
    style="margin-top: 50px;padding-bottom: 50px;padding-left: 50px;" *ngIf="events?.data">
    <div style="text-align: center;" *ngIf="events?.data?.length === 0">
        No Events found for the given search criteria.
    </div>

    <div *ngIf="events?.data.length">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
        </mat-form-field>
        <table mat-table [dataSource]="events" matSort>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                <td mat-cell *matCellDef="let element"> {{element.category}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element"> <button mat-raised-button color="primary"
                        (click)="openUpdateEvent(element)">Update</button> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" #paginator></mat-paginator>
    </div>
</mat-card>

<ng-template style="width: 75vw;" #eventForm>
    <h2 mat-dialog-title>Update Event</h2>
    <mat-dialog-content class="mat-typography">
        <form>
            <div class="row event">
                <mat-form-field>
                    <mat-label>Status</mat-label>
                    <mat-select required [(ngModel)]="eventStatus" name="status"
                        (ngModelChange)="event.status = eventStatus">
                        <mat-option [value]="status" *ngFor="let status of statuses">{{ status }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row event">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput required [(ngModel)]="event.name" name="title">
                </mat-form-field>
            </div>
            <div class="row event">
                <mat-form-field>
                    <mat-label>Category</mat-label>
                    <mat-select required [(ngModel)]="eventCategory" name="category"
                        (ngModelChange)="event.category = eventCategory">
                        <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row event">
                <mat-form-field>
                    <mat-label>Form link</mat-label>
                    <input matInput required [(ngModel)]="event.formLink" name="formLink">
                </mat-form-field>
            </div>
            <div class="row event">
                <mat-form-field>
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="startDatePicker" placeholder="Choose a start date" [value]="event.startDate" (dateChange)="startDateSelected($event)">
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="row event">
                <mat-form-field>
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="endDatePicker" placeholder="Choose an end date" [value]="event.endDate" (dateChange)="endDateSelected($event)">
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>
            </div>

        </form>

        <text-editor [editordoc]="eventContent" [buttonAction]="buttonAction"
            (content)="updateContent($event)"></text-editor>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button [mat-dialog-close]=false>Cancel</button>
        <button mat-raised-button (click)="saveContent()" [mat-dialog-close]=true color="primary"
            cdkFocusInitial>Update</button>
    </mat-dialog-actions>
</ng-template>