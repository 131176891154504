import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CoachingCenterDetails, CoachingCenterResponse, LocalAddress, Reviews } from 'src/app/models/coaching-center';
import { User } from 'src/app/models/user';
import { CoachingCenterService } from 'src/app/services/coaching-center/coaching-center.service';
import { SchoolService } from 'src/app/services/school/school.service';
import { UserService } from 'src/app/services/user/user.service';

declare let $: any;

@Component({
  selector: 'coaching-center-details',
  templateUrl: './coaching-center-details.component.html',
  styleUrl: './coaching-center-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoachingCenterDetailsComponent implements OnInit{
  map: SafeHtml;

  user;
  coachingCenterid: string;
  coachingCenter: CoachingCenterDetails;
  reviews: Reviews[]
  rating: number = 0;
  address: LocalAddress = {
    addressLine1 : '',
    addressLine2: '',
    city: '',
    contact1: '',
    contact2: '',
    contact3: '',
    email1: '',
    email2:'',
    pincode:'',
    state: '',
    website: ''
  };
  splitAchievements: string[];
  highlighesEntrance: boolean = false;
  highlighesAcademic: boolean = false;
  highlighesRecruitment: boolean = false;
  highlighesLanguagesOffered: boolean = false;

  maxRating = 5
  minRating = 1
  step = 0.1
  ratingAcademics = 0
  ratingImparting = 0
  ratingInfraFacility = 0
  ratingExtraCurricular = 0
  ratingGrowth = 0
  overallRating = 0
  review = ''
  enquiryMessage = ''

  showAddReview = true;
  dsGeneralInformation;
  dsContact;
  dsClassesEntrance;
  dsClassesAcademic;
  dsClassesLanguage;
  dsClassesRecruitment;


  constructor(private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private coachingService: CoachingCenterService,
              private sanitizer: DomSanitizer,
              private schoolService: SchoolService,
              private cdr: ChangeDetectorRef,
              private router: Router,
              private metaService: Meta,
              private titleService: Title) {}

  ngOnInit(): void {
    this.start()
    this.user = this.userService.currentUserValue;
    this.coachingCenterid = this.activatedRoute.snapshot.paramMap.get('coachingCenterId');
    this.coachingService.findCoachingCenterById(this.coachingCenterid).subscribe({
      next: (res) => {
        this.coachingCenter = res.payload.coachingCenter;
        if(res.payload.reviews !== null) this.reviews = res.payload.reviews;
        this.setAddress();
        // this.calculateRatings();
        this.updateAchievements();
        this.updateGeneralInfoSection()
        this.updateContactSection()
        this.findAvailCoursesOffered()
        this.dsClassesEntrance = this.highlighesEntrance && this.updateCoursesOffered('entrance');
        this.dsClassesAcademic = this.highlighesAcademic && this.updateCoursesOffered('academic');
        this.dsClassesLanguage = this.highlighesLanguagesOffered && this.updateCoursesOffered('languages');
        this.dsClassesRecruitment = this.highlighesRecruitment && this.updateCoursesOffered('recruitment');
        this.map = this.sanitizer.bypassSecurityTrustHtml(this.coachingCenter.address.mapLocation)
        this.cdr.detectChanges();
        this.updatePageDetails();
        this.end();
      },
      error: err => {
        console.log(err);
        this.end()
      }
    }
    )
  }

  updatePageDetails() {
    this.titleService.setTitle('Find the Best Coaching Center for competitive entrance exams - IIT/NEET/JEE Coaching, language proficiency tests/exams, Recruitments exams online at Eduspeed | Eduspeed');
    this.metaService.updateTag({ name: 'description', content: 'Name of the Coaching center, locality, City. Get details online and Find the best coaching centers for competitive exams like GMAT, GRE, SAT, TOEFL, and IELTS. Explore comprehensive classroom, online live, and recorded programs with top faculty. Discover top institutes offering specialized coaching for entrance exams in various subjects and languages.' });
    this.metaService.updateTag({ name: 'keywords', content: 'Competitive exam coaching, Entrance exam preparation, IIT coaching, NEET coaching, JEE coaching, Medical entrance coaching, Engineering entrance coaching, Law entrance exam coaching, Management entrance exam coaching, CAT coaching, CLAT coaching, GRE coaching, GMAT coaching, SAT coaching, Banking exam coaching, SSC exam coaching, UPSC coaching, Civil services exam preparation,  Railway recruitment exam coaching, Government job exam coaching, Coaching for competitive exams, Online coaching classes, Classroom coaching centers, One-on-one tutoring for exams, Test series and mock exams Study materials for entrance exams, Coaching institute reviews Best coaching centers for exams, Exam preparation courses, Coaching for Olympiads and scholarships'})
  }

  findAvailCoursesOffered() {
    this.highlighesEntrance = this.coachingCenter.classesEntrance !== null
    this.highlighesAcademic = this.coachingCenter.classesAcademic !== null
    this.highlighesLanguagesOffered = this.coachingCenter.classesLanguage !== null
    this.highlighesRecruitment = this.coachingCenter.classesRecruitment !== null

  }

  setAddress() {
    const address = this.coachingCenter.address;
    if(address.branchOfficeAddressLine1 !== ""){
      this.address.addressLine1 = address.branchOfficeAddressLine1;
      this.address.addressLine2 = address.branchOfficeAddressLine2;
      this.address.city = address.branchOfficeCity;
      this.address.contact1 = address.branchOfficeContact1;
      this.address.contact2 = address.branchOfficeContact2;
      this.address.contact3 = address.branchOfficeContact3;
      this.address.email1 = address.branchOfficeEmail1;
      this.address.email2 = address.branchOfficeEmail2;
      this.address.pincode = address.branchOfficePincode;
      this.address.state = address.branchOfficeState;
      this.address.website = address.branchOfficeWebsite;
    } else {
      this.address.addressLine1 = address.headOfficeAddressLine1;
      this.address.addressLine2 = address.headOfficeAddressLine2;
      this.address.city = address.headOfficeCity;
      this.address.contact1 = address.headOfficeContact1;
      this.address.contact2 = address.headOfficeContact2;
      this.address.contact3 = address.headOfficeContact3;
      this.address.email1 = address.headOfficeEmail1;
      this.address.email2 = address.headOfficeEmail2;
      this.address.pincode = address.headOfficePincode;
      this.address.state = address.headOfficeState;
      this.address.website = address.headOfficeWebsite;
    }
  }

  calculateRatings(){
    if(this.reviews !== undefined){
      let ratings = 0;
      this.reviews.forEach(element => {
        ratings += element.rating;
      });
      return ratings/this.reviews.length
    }
  }

  scrollTo(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
  }

  backToSearch(){
    this.router.navigate(['/coaching-center'])
  }

  enquiry(){
    const request = {
      name: `${this.user.firstName} ${this.user.lastName}`,
      email: this.user.email,
      phone: this.user.mobile,
      subject: `Enquiry received for Coaching center | Registration Code: ${this.coachingCenter.id} | Name: ${this.coachingCenter.name}`,
      message: this.enquiryMessage,
      registrationCode: this.coachingCenter.id
    }
    this.schoolService.enquireSchool(request).subscribe({
      next: (res) => {
        alert(`Thank you for your interest. We have submitted your enquiry to the coaching center ${this.coachingCenter.name}. You will receive a response from us shortly`)
      },
      error: (err) => {
        alert('Please try again later')
      }
    })
  }

  updateAchievements() {
    if(this.coachingCenter.achievements !== null){
      this.splitAchievements = this.coachingCenter.achievements.split("\n");
      this.splitAchievements.map((element) => element.trim())
    }
  }

  updateGeneralInfoSection(): void {
    const aboutCoachingCenter  = this.coachingCenter
    let generalInfoArray = [];
      if(aboutCoachingCenter.founderInfo){
        generalInfoArray.push({generalInfo: 'Founder Info'})
        generalInfoArray.push({generalInfo: aboutCoachingCenter.founderInfo})
      }
      if(aboutCoachingCenter.locations){
        generalInfoArray.push({generalInfo: 'Locations'})
        generalInfoArray.push({generalInfo: aboutCoachingCenter.locations})
      }
      if(this.getStudentsCount()){
        generalInfoArray.push({generalInfo: 'Students Count'})
        generalInfoArray.push({generalInfo: this.getStudentsCount()})
      }
      if(aboutCoachingCenter.studentTeacherRatio){
        generalInfoArray.push({generalInfo: 'Student Teacher Ratio'})
        generalInfoArray.push({generalInfo: aboutCoachingCenter.studentTeacherRatio})
      }
      if(aboutCoachingCenter.yearsOfOperation){
        generalInfoArray.push({generalInfo: 'Years Of Operation'})
        generalInfoArray.push({generalInfo: aboutCoachingCenter.yearsOfOperation})
      }

      this.dsGeneralInformation = generalInfoArray;
  }

  updateContactSection(): void {
    const { address } = this.coachingCenter
    this.dsContact = [
      { contact: 'Address'},
      { contact: this.getAddress()},
      { contact: 'Phone'},
      { contact: this.getPhone()},
      { contact: 'Email'},
      { contact: `<a href = mail:${this.getEmail()}> ${this.getEmail()} </a>`},
      { contact: 'Website'},
      { contact: address.branchOfficeWebsite !== "" ? `<a href="${address.branchOfficeWebsite}" target="_blank">${address.branchOfficeWebsite} </a>`: `<a href="${address.headOfficeWebsite}">${address.headOfficeWebsite} </a>`},
    ]
  }

  updateCoursesOffered(courses: string) {
    const courseResponseTag = COURSES.get(courses);
    const coachingData = this.coachingCenter[courseResponseTag];

    let COURSE_LABEL = this.COURSES_ABBRIVATION.get(courses);

    const dataSource = Object.keys(coachingData).map(key => {
      if(coachingData[key] != null){
        if((courseResponseTag === 'classesAcademic' && (key === 'grade10Subjects' || key === 'grade12Subjects')) || key === 'others'){
          if(coachingData[key] !== ''){
            return {
              ccKey: `${COURSE_LABEL.get(key)}`,
              ccOnlineLive: coachingData[key] || '',
            }
          }
        } else {
          return {
            ccKey: COURSE_LABEL.get(key),
            ccOnlineLive: this.getYesOrNoContent(coachingData[key], 'Online - Live Teaching'),
            ccOnlineRec: this.getYesOrNoContent(coachingData[key], 'Online - Recorded'),
            ccOffline: this.getYesOrNoContent(coachingData[key], 'Offline'),
          }
        }
      }
    })
    .filter(key => key !== undefined)
    .sort((a, b) => {
      if (a.ccOnlineRec !== undefined && b.ccOnlineRec === undefined) {
        return -1;
      } else if (a.ccOnlineRec === undefined && b.ccOnlineRec !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });

    return dataSource;
  }

  getStudentsCount(){
    return parseInt(this.coachingCenter?.studentsCount, 10)
  }

  getAddress(): string{
    if(this.coachingCenter.address.branchOfficeAddressLine1 !== "")
      return `${this.coachingCenter?.address.branchOfficeAddressLine1}, ${this.coachingCenter?.address.branchOfficeAddressLine2}, ${this.coachingCenter?.address.branchOfficeCity} - ${this.coachingCenter?.address.branchOfficePincode}, ${this.coachingCenter?.address.branchOfficeState}.`
    return `${this.coachingCenter?.address.headOfficeAddressLine1}, ${this.coachingCenter?.address.headOfficeAddressLine2}, ${this.coachingCenter?.address.headOfficeCity} - ${this.coachingCenter?.address.headOfficePincode}, ${this.coachingCenter?.address.headOfficeState}.`

  }

  getPhone(): string{
    let phone = ''
    const { branchOfficeContact1, branchOfficeContact2, branchOfficeContact3, headOfficeContact1, headOfficeContact2, headOfficeContact3 } = this.coachingCenter.address
    if(branchOfficeContact1){
      phone+= `<a href="tel:${branchOfficeContact1}">${branchOfficeContact1}, </a>`
      if(branchOfficeContact2) {
        phone = `${phone}
        <a href="tel:${branchOfficeContact2}">${branchOfficeContact2}, </a>`
        if(branchOfficeContact3) {
          phone = `${phone}
          <a href="tel:${branchOfficeContact3}">${branchOfficeContact3}, </a>`
        }
      }
    }
    else {
      if(headOfficeContact1){
        phone+= `<a href="tel:${headOfficeContact1}">${headOfficeContact1}, </a>`
      if(headOfficeContact2) {
        phone = `${phone}
        <a href="tel:${headOfficeContact2}">${headOfficeContact2}, </a>`
        if(headOfficeContact3) {
          phone = `${phone}
          <a href="tel:${headOfficeContact3}">${headOfficeContact3}, </a>`
        }
      }
      }
    }
    return phone
  }

  getEmail(): string{
    let email = ''
    const { branchOfficeEmail1, branchOfficeEmail2, headOfficeEmail1, headOfficeEmail2 } = this.coachingCenter.address
    if(branchOfficeEmail1){
      email+= branchOfficeEmail1
      if(branchOfficeEmail2) {
        email = `${email}
        ${branchOfficeEmail2}`
      }
    }
    else {
      if(headOfficeEmail1){
        email+= headOfficeEmail1
        if(headOfficeEmail2) {
          email = `${email}
          ${headOfficeEmail2}`
        }
      }
    }
    return email;
  }

  updateRating(){
    this.overallRating = (this.ratingAcademics + this.ratingImparting + this.ratingInfraFacility + this.ratingExtraCurricular + this.ratingGrowth) / 5
  }

  getNumericValue(value: string){
    return parseFloat(value)
  }

  submitReview(){
    const payload = {
      overallRating: this.overallRating,
      ratingAcademics: this.ratingAcademics,
      ratingImparting: this.ratingImparting,
      ratingInfraFacility: this.ratingInfraFacility,
      ratingExtraCurricular: this.ratingExtraCurricular,
      ratingGrowth: this.ratingGrowth,
      registrationCode: this.coachingCenter.id,
      review: this.review
    }
    this.userService.addReview('karthik.itc@gmail.com', payload).subscribe({
       next: (res) => { this.showAddReview = false; this.ngOnInit() },
      error: (err) => console.error('Error when adding review', err)
    })
  }

  getYesOrNoContent(value: string, mode: string): boolean | string {

    if(value === null){
      if(mode === null) {
        return true;
      }
      return false;
    }
    const modes = value.split(',');
    if(modes.includes(mode)){
      return true
    }
    return false
  }

  canDisplayAcheivements(): boolean{
    if(this.coachingCenter?.achievements){
      const { achievements } = this.coachingCenter
      return achievements?.trim() !== ''
    }
    return false
  }

getKeyColSpan(key: string, type: string) {
  console.log(key);
  if (key.includes('Grade 10 - Subjects') || key.includes('Grade 12 - Subjects') || key.includes('Others')) {
    if (type === 'live') {
      return "3";
    } else {
      return "0";
    }
  }
  return "1";
}

getTdStyle(key: string, type: string) {
  console.log('style:', key);

  if (key.includes('Grade 10 - Subjects') || key.includes('Grade 12 - Subjects') || key.includes('Others')) {

    if(type !== 'live') return "padding: 0;"
    else return "font-size:12px"
  }

}

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }

  COURSES_ABBRIVATION = new Map<string, Map<string,string>>([
    ['entrance', ENTRANCE_EXAMS],
    ['academic', ACADEMIC_EXAMS],
    ['languages',LANGUAGES_OFFERED],
    ['recruitment',RECRUITMENT_EXAMS]
  ])
}

const COURSES = new Map<string, string>([
  ['entrance', 'classesEntrance'],
  ['academic', 'classesAcademic'],
  ['languages','classesLanguage'],
  ['recruitment','classesRecruitment']
])

const ENTRANCE_EXAMS = new Map<string, string>([
  ['neetUnderGraduate','NEET-UG (National Eligibility Cum Entrance Test)'],
  ['neetPostGraduate','NEET-PG (National Eligibility Cum Entrance Test)'],
  ['jeeMain','JEE-Main (Joint Entrance Examination)'],
  ['jeeAdvance','JEE-Advance (Joint Entrance Examination)'],
  ['clat','CLAT-Common-Law Admission Test'],
  ['ipmat','IPMAT-Integrated Programme in Management Aptitude Test'],
  ['lsat','LSAT'],
  ['sat','SAT- Scholastic Aptitude Test'],
  ['gate','GATE - Graduate Aptitude Test in Engineering'],
  ['cat','CAT - Common Entrance Test'],
  ['nda','NDA-National Defence Academy'],
  ['nid','NID Design Aptitude Test-The National Institute of Design Entrance'],
  ['nata','NATA - National Aptitude Test in Architecture'],
  ['nift','NIFT - Entrance Exam'],
  ['bitsat','BITSAT'],
  ['viteee','VITEEE'],
  ['cuet','CUET - Central University Entrance Test'],
  ['gmat','GMAT'],
  ['toefl','TOEFL - The Test of English as a Foreign Language'],
  ['ielts','IELTS-International English Language Testing System'],
  ['gre','GRE -The Graduate Record Examination'],
  ['mcat','MCAT'],
  ['nchmjee', 'NCHMJEE - Entrance Exam for Hotel Management'],
  ['others','Others']
])

const ACADEMIC_EXAMS = new Map<string,string>([
  ['chAct','Chartered Accountant CA'],
  ['cma','Certified Management Accountant- CMA/ICWA'],
  ['csc','Company Secretary Course- CS'],
  ['acca','Association of Certified Chartered Accountants - ACCA'],
  ['grade10','Grade 10'],
  ['grade10Subjects', 'Grade 10 - Subjects'],
  ['grade12','Grade 12'],
  ['grade12Subjects', 'Grade 12 - Subjects'],
  ['ugCourses','Undergraduation Courses'],
  ['pgCourses','Postgraduation Courses'],
  ['others','Others']
])

const RECRUITMENT_EXAMS = new Map<string,string>([
  ['netSet','National Eligibility Test/NET/ State Eligibility Test-SET/JRF'],
  ['rrb','Railway recruitment Board-RRB'],
  ['ssc','Staff Selection for different Cadres- SSC'],
  ['ese','Engineering Services Examinations'],
  ['iese','Indian Economic Service Examination'],
  ['banks','SBI & other bank Exams, JAIIB/CAIIB'],
  ['ibps','IBPS Clerk Exams'],
  ['rbi','Reserve Bank of India Services Board- RBI Exams'],
  ['nabard','National Bank for Agriculture & Rural Development (NABARD)- Exams'],
  ['cse','Civil service Exam/UPSC'],
  ['tnet','Teachers Eligibility Test/TNTET/CTET'],
  ['tnpsc','Tamilnadu Public Service Commission - TNPSC'],
  ['others','Others']
])

const LANGUAGES_OFFERED = new Map<string,string>([
  ['english','English'],
  ['tamil','Tamil'],
  ['hindi','Hindi'],
  ['french','French'],
  ['germany','Germany'],
  ['mandarin','Mandarin'],
  ['japanese','Japanese'],
  ['urdu','Urdu'],
  ['arabic','Arabic'],
  ['korean','Korean'],
  ['spanish','Spanish'],
  ['italian','Italian'],
  ['sanskrit','Sanskrit'],
  ['malayalam','Malayalam'],
  ['telugu','Telugu'],
  ['kannada','Kannada'],
  ['others','Others']
])
