
<div class="rows">
    <div class="block" *ngIf="ratingPosition === 'left'"><span style="color: var(--mainColor);">{{ rating | number:'1.1-1' }}</span></div>
    <div class="block" style="margin-top: -7px;margin-left: 5px;">
        <div class="rating">
            <div class="rating-top" [style]="getRatingPercentage()">
                <span *ngFor="let rating of allowedRatingRange">★</span>
            </div>
            <div class="rating-bottom">
                <span *ngFor="let rating of allowedRatingRange">★</span>
            </div>
        </div>
    </div>
    <div class="right" *ngIf="ratingPosition === 'right'"><span style="color: var(--mainColor);">{{ rating | number:'1.1-1' }}</span></div>
    <div class="votes" style="font-size: 12px;" *ngIf="votes"><span>({{votes}} {{(votes > 1) ? 'Reviews' : 'Review'}})</span></div>
  </div>