<app-navbar></app-navbar>

<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Our Privacy Policy</h3>
        </div>
    </div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="single-content">
            <h3>Welcome to Eduspeed Privacy & Policy</h3>
            <p align="justify">Eduspeed Technologies (“Eduspeed”, “we”, “us”, or “ours”) is committed to protecting your
                data that are deemed privacy. We have prepared this Privacy Policy and Notice (“Policy”) to
                describe our practices regarding the collection and use of the personal information of
                individuals who interact with our website/apps apps across devices (collectively referred to
                as our “Site”) and to inform you of your rights with respect to our data collection practices.
                This Policy provides information about the types of information that we collect, and what
                we may do with that information. It also describes with whom we share it, and how we keep
                it secure.
            </p>
            <p>In this Policy, we will outline and describe the following with respect to the personal
                information that we collect:</p>
            <ul style="list-style-type:square">
                <li>Categories of information we collect and its sources</li>
                <li>How we use your information – our purpose for collection</li>
                <li>With whom we share your information</li>
                <li>Opt-out – your choices</li>
                <li>How long we keep your information, your right to delete it and correct it</li>
                <li>Security of your information</li>
                <li>Questions and contact information</li>
                <li>Children</li>
                <li>Effective date of this Privacy Policy and changes</li>
            </ul>

            <p>The services that we provide and to which this Policy applies include online services such as
                email newsletters, online content including relevant targeted ads and mobile applications
                for various medium or devices in use now or in the future (together, the “Services”).</p>
        </div>
        <div class="single-content">
            <h3>I. Categories of Information we collect and its sources</h3>
            <h4>A. Information you provide:</h4>
            <p>If you create a login account on our Site, the personal information that you provide includes
                your name, mobile number and email address, and such other information as we may
                require. Also, if you contact us, we collect additional information such as your location, and
                any personal information that you provide in your contact request. We also collect your
                personal data when you call us on our service number. This includes information, such as
                your name and contact information, your phone number as well as the content of your
                message.
            </p>
            <p>We also collect other information in the form of the content that you choose to submit
                during your use of our Site. For example, if you write a review or do an enquiry , you may be
                asked for additional information, such as high school graduation year, that Eduspeed may
                share with schools for which you have expressed interest or writing a review about. Other
                information that you may choose to submit when using the Services or our Site includes
                your age, details of academic and/or work history, work place, preferences and goals related
                to the Eduspeed Services, test scores and other personally sensitive information entered
                into your user account. Likewise, if you post in a user review or opinion survey, or comment
                on one of our articles our blogs, the information that you post becomes public, and is a part
                of the information about you that we collect. This information may include your personal
                comments and information of a topic that you choose to submit.</p>
        </div>
        <div class="single-content">
            <h4>B. Information collected automatically:</h4>
            <p>
                Whether or not you create an account with us, certain information that identifies you may
                be passively collected and stored on our or our service providers’ server logs, including your
                Internet Protocol (“IP”) address, browser type, operating system and information regarding
                what pages you accessed when you visited our Site, and when (e.g., the date and time of
                your visit to our Site), and the information for which you searched and which you viewed.
            </p>
            <p>
                We also use “Cookies” and other tracking technologies to make our online services more
                useful to you and to tailor the experience with us to meet your special interests and needs.
                If you choose to disable Cookies, some aspects of our Services may perform differently, for
                instance, you will need to re-enter your information each time you return to use the
                Services.
            </p>
        </div>
        <div class="single-content">
            <h4>C. Information collected from third parties:</h4>
            <p>We may also collect information about you from any social network or other platform (such
                as from Facebook, Google, Twitter or other third-party social network sites) that you may
                have connected to or from. This information includes your login and profile information, and
                the actual social media networks you used. Further, we may associate this personal
                information obtained from these sources with the other personal information we have
                collected about you from other sources as described in this Policy. We do not control or
                supervise how these third-party sources process your personal information, and any request
                that you have regarding the disclosure of your personal information from them to us should
                be made directly to those third parties (Google or Facebook).</p>
            <p>In addition to the information that we collect as described above, we also collect
                information about you from our third-party integrators and vendors, such as Google
                Analytics and similar services. Personal data that we collect through these channels includes
                for example, IP address, access times, browser type and language, device type, device
                identifiers.</p>
        </div>
        <div class="single-content">
            <h3>II. How we use your information – our purpose for collection</h3>
            <p>For each of the categories of personal information set forth in Section I above, we use your
                personal information in order to provide you with meaningful and relevant Services, which
                includes information about schools, locality specialities and which courses that might
                interest you. In addition, we may use your personal information for the following purposes:</p>
            <ul style="list-style-type:disc">
                <li>To share our regular updates through newsletters;</li>
                <li>to respond to any questions or comments that you submitted to us on our Site or
                    otherwise;</li>
                <li>to carry out any obligations entered into between us, such as providing you with
                    Services that you have requested;</li>
                <li>to notify you of changes to our Services;</li>
                <li>to seek your views or comments on the Services we provide and send you
                    communications by post, e-mail or text message which you have requested or that
                    may be of interest to you, including newsletters, or promotions of our Services or
                    events;</li>
                <li>to measure the adequate performance of our interactions with you;</li>
                <li>to facilitate your use of your web account on the Site; or</li>
                <li>to otherwise provide, create and maintain a trusted online environment and comply
                    with our legal obligations.</li>
            </ul>
            <p>In using your personal information, we may share some or all of it with authorized
                representatives of schools and other entities that you may designate whose services relate

                to the Eduspeed Services. Please refer to Section III of this Policy below for a full description
                of our information sharing practices.</p>
        </div>
        <div class="single-content">
            <h3>III. With whom we share your information</h3>
            <h4>A.  Per your instruction or use of the Services/Consents:</h4>
            <p>We may share all categories of your personal information to selected outside companies,
                either by selling it or otherwise transferring it, to advertisers and other business partners
                that assist us in providing you with meaningful and relevant Services (each, collectively an
                “Advertiser”). Our Advertisers include realtors, schools, work places, and social media
                platforms such as Facebook. The sharing of your personal information with these
                Advertisers is conducted in accordance with the contact preferences that you set when you
                create your user profile, or when you express an interest in any content displayed on our
                Site, or when you enter a response to any affirmative authorization that you provide by
                typing it in a space provided or clicking on an icon designed to show your interest in an item
                on the web page or in the app.</p>
            <p>In addition, any personal information you share publicly through our Site may be indexed
                through third party search engines, such as Google. We do not control the practices of third-
                party search engines, and they may use caches containing your information, including any
                outdated information. You acknowledge that personal information that you submit when
                you participate in an opinion survey or user review, a blog or any online message board that
                we provide through our Site or Services may be available, via the Internet, around the
                world. We cannot prevent the disclosure to or use (or misuse) of such personal information
                by others, nor can we ensure it is deleted if you later ask us to delete your personal
                information as provided for in this Policy.</p>
        </div>
        <div class="single-content">
            <h4>B. Third party processors:</h4>
            <p>We also share your personal information with third party processors who act on our behalf
                (including contractors and service providers) to provide our web and app services and to
                help with our operations on our behalf (“Processors”). These Processors need access to and
                use of your personal information, and include third parties to provide us with technical
                support, to give us additional personal data about you, and to perform analytics and other
                work that we may need to outsource. These Processors are bound by law and/or contract to
                protect the confidentiality and security of your personal information.  They only process
                your personal information to provide requested services for us, and only act on our
                documented instructions. In addition, we use certain third-party Processors, such as
                Google’s reCAPTCHA service, to validate login credentials of individuals and to frustrate
                bots;</p>
            <p>We also share all categories of your personal information with other business partners that
                perform certain functions for us (“Business Partners”).  These Business Partners are
                themselves responsible to determine the purposes and/or means of the processing your
                personal information and for the lawfulness of that processing.  They include financial
                institutions, and other professional advisors.</p>
        </div>
        <div class="single-content">
            <h4>C. Third party websites:</h4>
            <p>We provide links to other websites, such as social networking sites (e.g., Facebook, Twitter
                and Google), or our third-party partner websites (such as scholarship providers, schools, and
                Business Partners), and these other websites may provide links to our Site. Third party sites
                operate according to their own terms of use and privacy policies and Eduspeed has no
                control over these third-party websites. <b>If you wish to opt-out of any information sharing
                    conducted by any third-party website that you link to from us, or to us from them, you
                    must do so in accordance with their specific opt-out policies and procedures. By using our
                    Site and Services, you acknowledge and agree that we are not responsible for the
                    availability of such third-party sites, and do not endorse and are not responsible or liable
                    for any content, advertising, products or other materials on or available from such sites.
                    You further acknowledge and agree that Eduspeed will not be responsible or liable,
                    directly or indirectly, for any damage or loss caused or alleged to be caused by or in
                    connection with use of or reliance on any content, advertising, products, or other
                    materials on or available from such sites.</b></p>
            <p></p>
        </div>
        <div class="single-content">
            <h4>D. De-identified information about you:</h4>
            <p>We also share aggregated or de-identified information (i.e., information that does not
                personally identify you directly), or statistical information about you, including statistical
                data and historical use data, with others for a variety of purposes, including for their own
                uses, for example, for improving their services for you and others.</p>
        </div>
        <div class="single-content">
            <h4>E. As required by law or legitimate business interest:</h4>
            <p>In addition, we may disclose any or all categories of your personal information where such
                disclosure is necessary for compliance with a legal obligation to which we are subject, or in
                order to protect your vital interests or the vital interests of another natural person.
                Likewise, we may disclose all such categories of your personal information to our
                professional advisers as is reasonably necessary for the purposes of managing risks,
                obtaining professional advice, or the establishment, exercise or defense of legal claims,
                whether in court proceedings or in an administrative or out-of-court procedure. Further, we
                may share some or all of your personal information in connection with or during negotiation
                of any merger or similar transaction involving sale or transfer of some or all of our business
                or assets. If another company acquires our company or assets, that company will possess

                the personal information collected by us and will assume the rights and obligations
                regarding your personal information as described in this Privacy Policy.</p>
        </div>
        <div class="single-content">
            <h3>IV. Privacy for users under 18 years</h3>
            <p><b>Special rule for users under 18 years of age</b> – So long as we have actual knowledge that you
                are under the age of 18, we will not sell your personally identifiable information to our
                Advertisers unless you “opt in” by requesting us to do so. You can opt in to information
                sharing with Advertisers by requesting that we share your information with schools or
                related entities for which you have expressed an interest or with parties that you wish to be
                considered for a scholarship. We may ask you or the person acting on your behalf (e.g., a
                parent or legal guardian) to verify your identity. Likewise, any public information that you
                post on the Site, including user reviews or opinion surveys, are considered public and you
                will be considered to have opted in to the sharing of such public information.  You may also
                request that we share your personally identifiable information with our Advertisers by
                contacting us at reach.eduspeed&#64;outlook.com , and telling us that you wish to opt in.</p>
        </div>
        <div class="single-content">
            <h3>V. How long we keep your personal information, your right to delete and correct it</h3>
            <p>We retain your personal information as reasonably necessary to fulfill the purposes for
                which we collected it and you provided it, and to comply with our legal obligations. In no
                event will we keep your personal information for longer than is reasonably necessary for the
                purposes defined in this Policy. You also have the right to request that we delete your
                personal information, subject to certain exceptions, such as our need to prevent and protect
                against fraud or other illegal activity, to complete business transactions with you, and for
                such other purposes as allowed by law. You can correct or update your personal information
                by updating your profile or by contacting us. For more information on where and how long
                your personal information is stored, your requests to delete it or correct it, please contact
                us at the address set forth below in Section</p>
        </div>
        <div class="single-content">
            <h3>VII. Security of your information</h3>
            <p>To help protect the privacy of your Personal Data collected by us, we maintain reasonable
                physical, technical and administrative safeguards. We update and test our security
                technology on an ongoing basis. We restrict access to your personal information to those
                employees who need to know that information in order to provide Services to you. In
                addition, we train our employees about the importance of confidentiality and maintaining
                the data privacy and security of your information.</p>
        </div>
        <div class="single-content">
            <h3>VIII. Limitation of liability</h3>
            <p>YOU UNDERSTAND AND AGREE THAT ANY DISPUTE OVER THE PRIVACY OF YOUR
                INFORMATION IS SUBJECT TO THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY AND
                THE EDUSPEED USER AGREEMENT (INCLUDING ANY INDEMNIFICATION AND LIMITATIONS
                ON DAMAGES CONTAINED IN THAT AGREEMENT).</p>
        </div>
        <div class="single-content">
            <h3>IX. Questions, concerns or complaints – Contact Details</h3>
            <p>If you have questions about our privacy practices and this Privacy Policy, or if you wish to
                correct any of your information or exercise your rights under the law or this Policy, you can
                contact us as follows:</p>
            <p>Compliance officer<br>
                Eduspeed Technologies<br>
                Kanagam Road, Taramani, Chennai-113<br>
                Email: reach.eduspeed&#64;outlook.com</p>
            <p>Please be specific when telling us what your request is.</p>
        </div>
        <div class="single-content">
            <h3>X. A note about Children</h3>
            <p>Children under the age of 13 are not permitted to use the Eduspeed Site or Services and we
                do not intentionally collect or maintain personal information from those who are under 13
                years old. Protecting the privacy of children is very important to us. Thus, if we obtain actual
                knowledge that a user is under 13, we will take steps to remove that user’s personal
                information from our databases. We recommend that children between the ages of 13 and
                18 obtain their parent’s permission before submitting information over the Internet. By
                using the Eduspeed Site or Services, you are representing that you are at least 18 years old,
                or that you are at least 13 years old and have your parents’ permission to use the website
                and any Services offered by us.</p>
            <h4><b>The Effective Date of this Privacy Policy is: July 30, 2023</b></h4>
        </div>
    </div>
</div>

<app-footer></app-footer>