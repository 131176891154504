import { Component, OnInit } from '@angular/core';

import { InsightService } from '../../../services/insight/insight.service';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any


const VLOGS_PER_PAGE = 6;
@Component({
  selector: 'vlog',
  templateUrl: './vlog.component.html',
  styleUrls: ['./vlog.component.scss']
})
export class VlogComponent implements OnInit {
  totalPageScale = 0;
  currentPageNumber = 1;
  constructor(private insightService: InsightService, private router: Router ,private activatedRoute: ActivatedRoute,
                private titleService: Title, private metaService: Meta) { }
  vlogs = [];
  categories= []
  pageEvent1: PageEvent
  totalRecords: number
  pageSize: number
  currentPage: number = 0
  loading: boolean
  searchMode: any
  isUserLoggedIn = false
  category: string
  tag: string
  subTitle = 'Edu Digest'
  mainTitle1 = 'All Vlog'
  mainTitle2 = 'Posts'
  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const category =  this.activatedRoute.snapshot.queryParamMap.get('category');
    if(category) {
      this.category = decodeURIComponent(category)
      this.subTitle = 'Category'
      this.mainTitle1 = 'Related Vlogs By Category '
      this.mainTitle2 = this.category
    }
    this.setPaginationData();
    this.retrieveVlogs();
    this.getVlogCategories()
  }

  updatePageDetails() {
    this.titleService.setTitle(`${this.category}, Concept Learning: Essential Tips and Knowledge Videos | Eduspeed`);
    this.metaService.updateTag({ name: 'description', content: 'Explore a variety of educational concept learning videos covering competitive exams, safety tips, scientific principles, and more. Enhance your knowledge with expert insights on topics such as lightning safety, earthquake zones, GRE preparation, and CBSE science. Find out everything you need to know through our extensive video collection.' });
    this.metaService.updateTag({ name: 'keywords', content: 'Competitive exams, concept learning, lightning safety tips, lightning rods, destructive earthquakes, Richter scale, India seismic zones, thunderstorm safety, charged body, crab eye protection, laser light effects, periscope principles, plane mirror images, visibility in dark, chemical effects of electric current, electroplating, CBSE Grade 8 Science, GRE overview, GRE test purpose, GRE syllabus, applying for GRE.'})
  }

  setPaginationData(){
    const { length } = this.vlogs
    const quotient = Math.floor(length / VLOGS_PER_PAGE)
    const dividend = length % VLOGS_PER_PAGE
    this.totalPageScale = dividend > 0 ? quotient + 1 : quotient
  }

  retrieveVlogs(){
    this.start();
    this.insightService.retrieveVlogs(this.category,'publishDate', 'dsc', this.pageSize, this.currentPage).subscribe({
      next: (res: any) => {
        this.totalRecords = res.payload.totalCount
        this.vlogs = res.payload.vlogs.map(vlog => {
          const link = vlog.youtubeLink.split('?v=')
          console.log(link)
          vlog.youtubeLink = `https://www.youtube.com/embed/${link[1]}`
          return vlog
        });
        this.updatePageDetails();
        this.end();
      },
      error: (err) => {
        console.error('Error retreiving vlogs', err)
        this.end();
      }
    })
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent1 = e;
    this.totalRecords = e.length;
    this.pageSize = e.pageSize;
    this.currentPage = e.pageIndex;
    this.retrieveVlogs()
  }

  onCategorySelect(){
    const queryParams = { category : encodeURIComponent(this.category)}
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams});
  }

  getVlogCategories(): void {
    this.categories = this.insightService.retrieveVlogCategory()
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }
}
