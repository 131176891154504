import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { Campaign, Category } from "src/app/models/event";
import { EventService } from "src/app/services/events/event-service.service";

declare let $: any;

@Component({
    selector: "app-events",
    templateUrl: "./events.component.html",
    styleUrls: ["./events.component.scss"],
})
export class EventsComponent implements OnInit {
    totalRecords: number = 0;
    pageSize: number = 6;
    currentPage: number = 0;
    sortBy: string = 'id';
    pageEvent1: PageEvent;
    campaigns: Campaign[] = [];

    categories = []
    category: any = null;
    constructor(private router: Router, private eventService: EventService){}
    ngOnInit(): void {
      this.getEventsByFilter();
      this.getFilterCategories();
    }

    getEventsByFilter() {
      this.start()
      this.eventService.getEvents(this.currentPage, this.pageSize, this.sortBy, this.category).subscribe({
        next: (data) => {
          this.campaigns = [...data.payload.campaign];
          this.totalRecords = data.payload.totalCount;
          this.end()
        },
        error: (err) => {
          console.error(err)
          this.end()
        }
      })
    }

    getFilterCategories() {
      this.categories = this.eventService.getEventCategories();
    }

    onCategorySelect() {
      this.getEventsByFilter();
    }
    openEvent(event: any) {
      this.router.navigate(['event',event.id])
    }

    handlePageEvent(e: PageEvent) {
      this.pageEvent1 = e;
      this.totalRecords = e.length;
      this.pageSize = e.pageSize;
      this.currentPage = e.pageIndex;
      this.getEventsByFilter();
    }

    scrollToSubscribeField(event: Event): void {
        event.preventDefault();
        const element = document.getElementById('email');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          element.focus();
        }
      }

    start(){
      $('.preloader').fadeIn('slow');
    }

    end(){
      $('.preloader').fadeOut('slow');
    }
}
