
  <div style="padding:30px !important">
    <h1 *ngIf="title">{{ title }}</h1>
    <div  [innerHtml]="content"></div>
    <!-- You need to be a registered user to view full details of school. Kindly register here. <br>
    If you are already a registered user login here. -->
    <div mat-dialog-actions [align]="alignActionButtons" *ngIf="displayActionButtons" style="margin-top:15px">
      <button *ngIf="primaryButton" mat-button [mat-dialog-close]="data.primaryButton">{{ primaryButton }}</button>
      <button *ngIf="secondaryButton" mat-button [mat-dialog-close]="data.secondaryButton">{{ secondaryButton }}</button>
    </div>
  </div>

