import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { UserService } from '../../../services/user/user.service'
import { MatSnackBar } from '@angular/material/snack-bar';

const OK = 'OK'

@Component({
  selector: 'profile-page',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss']
})
export class ProfilePage implements OnInit {

  profileForm: FormGroup
  submitted = false
  isMobileChanged = false
  isMobileAlreadyExists = false
  isEmailAlreadyExists = false
  showChangePassword = false
  user: any
  toggleMobileInput: boolean = false;
  toggleUserRoleInput: boolean = false;
  roles = ['Parent', 'Student', 'Guest']
  grades = [
    { label: "Class VI", value: "GRADE_6" },
    { label: "Class VII", value: "GRADE_7" },
    { label: "Class VIII", value: "GRADE_8" },
    { label: "Class IX", value: "GRADE_9" },
    { label: "Class X", value: "GRADE_10" },
    { label: "Class XI", value: "GRADE_11" },
    { label: "Class XII", value: "GRADE_12" }
  ];
  boards = ["CBSE", "ICSE", "IGCSE", "IB", "NOIS", "NDA"]

  constructor(private formBuilder: FormBuilder,
    private userService: UserService, private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.initializeFormData()
    const user1 = this.userService.currentUserValue
    this.user = user1
    this.profileForm.get('role').setValue(this.user.roles[0])
  }

  initializeFormData(){
    this.profileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      role: ['',Validators.required],
      grade: [''],
      board: ['']
     },
     {
      validator: MatchValidator('password', 'confirmPassword')
     }
     );
  }

  get f() {
    return this.profileForm.controls
  }

  changeNewsLetterPreference(event: any) {
    console.log(event.checked)
    this.userService.updateNewsLetterPreference(this.user.email, event.checked).subscribe(()=> {
      this.snackbar.open('Newsletter preference updated successfully', OK)
    })
  }

  changePassword(){
    console.log(this.f.password.value)
    this.userService.updatePassword(this.user.email, this.f.password.value).subscribe(()=> {
      this.showChangePassword = false;
      this.snackbar.open('Password updated successfully', OK)
    })
  }

  updateMobileNumber() {
    if(this.f.mobile.valid){
        this.userService.updateMobileNumber(this.user.email, this.f.mobile.value).subscribe({
            next: () => {
                this.user.mobile = this.f.mobile.value;
                this.toggleMobileInput = false;
                this.snackbar.open('Mobile number updated successfully', OK);
            },
            error: (error) =>{
                if(error.status === 400) {
                    this.isMobileAlreadyExists = error.error.key === 'error.user.mobile.already.exists'
                } else {
                    alert('Your request cannot be processed at the moment. Please try again later.')
                }
            }
        })
    } else {
        return;
    }

  }

  updateUserRole() {
    const requestBody = {
        email: this.user.email,
        roles: [this.f.role.value],
        grade: this.f.grade.value,
        board: this.f.board.value
    }

    this.userService.updateUserRole(requestBody).subscribe({
        next: () => {
            this.user.roles = this.f.role.value;
            this.toggleUserRoleInput = false;
            this.snackbar.open('Roles updated successfully', OK);
        },
        error: (error) =>{
            alert('Your request cannot be processed at the moment. Please try again later.')
        }
    })
  }

}


function MatchValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      matchingControl.setErrors(control.value !== matchingControl.value ? { mustMatch: true } : null);
  }
}
