import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Campaign } from 'src/app/models/event';
import { EventService } from 'src/app/services/events/event-service.service';

declare let $: any;

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit{
  eventId: string;
  event: Campaign;
  contentHtml: SafeHtml;
  iFrameLink: SafeHtml;
  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.eventId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getEventById(this.eventId);
  }

  getEventById(id) {
    this.start();
    this.eventService.getEvent(id).subscribe({
      next: (data) => {
        this.event = data.payload.campaign;
        this.contentHtml = this.sanitizer.bypassSecurityTrustHtml(this.event.content);
        this.iFrameLink = this.sanitizer.bypassSecurityTrustHtml(this.event.formLink);
        this.end();
      },
      error: (error) => {
        console.error(error);
        this.end();
      },
    })
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }
}
