import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { ProfilePage } from './components/pages/profile/profile.page';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsPageComponent } from './components/pages/testimonials/testimonials.page.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ShopComponent } from './components/pages/shop/shop.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { SchoolsComponent } from './components/pages/schools/schools.component';
import { SchoolDetailsComponent } from './components/pages/school-details/school-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { SchoolBasicDetailsComponent } from './components/schools/basic-details/basic-details.component'
import { ModalComponent } from './components/common/modal/modal/modal.component'
import { SchoolFilterComponent } from './components/schools/filter/filter.component'
import { SchoolListingSortComponent } from './components/schools/sort/sort.component';
import { SchoolRatingComponent } from './components/schools/rating/rating.component';
import { FilterCheckboxComponent } from './components/schools/filter/checkbox.component';
import { collapsibleComponent } from './components/common/collapsible/collapsible.component';
import { LatestListingsComponent } from './components/pages/latest-listings/latest-listings.component';
import { PopularSchoolsComponent } from './components/pages/popular-schools/popular-schools.component';
import { TestimonialsComponent } from './components/schools/testimonials/testimonials.component';
import { VlogComponent } from './components/pages/vlog/vlog.component';
import { SocialLoginModule, GoogleSigninButtonModule, GoogleLoginProvider, FacebookLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { NumberedPaginationModule } from './components/numbered-pagination/numbered-pagination.module'
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { GalleryConfig, GALLERY_CONFIG } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { FavoritesComponent } from './components/pages/favorites/favorites.component';
import { NgxEditorModule } from 'ngx-editor';
import { EditorComponent } from './components/common/editor/editor.component';
import { CreateBlogComponent } from './components/pages/admin/blog/create-blog/create-blog.component';
import { EditBlogComponent } from './components/pages/admin/blog/edit-blog/edit-blog.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { GuidedLearningPlatformComponent } from './components/pages/guided-learning-platform/guided-learning-platform.component';
import { CareersPage } from './components/pages/careers/careers.page';
import { AnnouncementsPage } from './components/pages/announcements/announcements.page';
import { CreateAnnouncementComponent } from './components/pages/admin/announcements/create-announcement/create-announcement.component';
import { EditAnnouncementComponent } from './components/pages/admin/announcements/edit-announcement/edit-announcement.component';
import { CreateVlogComponent } from './components/pages/admin/vlog/create-vlog/create-vlog.component';
import { EditVlogComponent } from './components/pages/admin/vlog/edit-vlog/edit-vlog.component';
import { SafePipe } from './libs/common/pipes/SafePipe';
import { MatSortModule } from '@angular/material/sort';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { CreateCoachingCenterComponent } from './components/pages/admin/coaching-center/create-coaching-center/create-coaching-center.component';
import { EventsComponent } from './components/pages/events/events.component';
import { EventDetailsComponent } from './components/pages/event-details/event-details.component';
import { PopularListingsComponent } from './components/pages/popular-listings/popular-listings.component';
import { EditCoachingCenterComponent } from './components/pages/admin/coaching-center/edit-coaching-center/edit-coaching-center.component';
import { SearchCoachingCenterComponent } from './components/pages/admin/coaching-center/search-coaching-center/search-coaching-center.component';
import { CoachingCenterFormComponent } from './components/pages/admin/coaching-center/coaching-center-form/coaching-center-form.component';
import { DuplicateCoachingCenterComponent } from './components/pages/admin/coaching-center/duplicate-coaching-center/duplicate-coaching-center.component';
import { CoachingCentersComponent } from './components/pages/coaching-centers/coaching-center.component';
import { CoachingCenterFilterComponent } from './components/coaching-center/filter/filter.component';
import { CoachingCenterDetailsComponent } from './components/pages/coaching-center-details/coaching-center-details.component';
import { CoachingCenterBasicDetailsComponent } from './components/coaching-center/basic-details/basic-details.component';
import { CharLimitPipe } from './pipes/char-limit/char-limit.pipe';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';
import { CreateEventComponent } from './components/pages/admin/events/create-event/create-event.component';
import { EditEventComponent } from './components/pages/admin/events/edit-event/edit-event.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { EditUserComponent } from './components/pages/admin/user/edit-user/edit-user.component';

export const config : GalleryConfig = {
  ...GALLERY_CONFIG,
  disableThumb: true
}

const MATERIAL_MODULES = [MatCardModule, MatSlideToggleModule, MatDialogModule, MatButtonModule, 
  MatSelectModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, MatSnackBarModule, 
  MatListModule, MatSliderModule, MatTableModule, MatDividerModule, MatTooltipModule, MatAutocompleteModule, 
  MatChipsModule, MatIconModule, MatPaginatorModule, MatTabsModule, MatSortModule, MatStepperModule, MatExpansionModule, MatDatepickerModule, MatNativeDateModule]


@NgModule({
  declarations: [
    SafePipe,
    AppComponent,
    PreloaderComponent,
    HomeComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    NavbarComponent,
    FooterComponent,
    AboutComponent,
    TeamComponent,
    PricingComponent,
    CategoryComponent,
    FaqComponent,
    TestimonialsComponent,
    TestimonialsPageComponent,
    ErrorComponent,
    LoginRegisterComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
    ComingSoonComponent,
    ShopComponent,
    CartComponent,
    CheckoutComponent,
    ProductsDetailsComponent,
    SchoolsComponent,
    SchoolDetailsComponent,
    BlogComponent,
    BlogDetailsComponent,
    VlogComponent,
    ContactComponent,
    SchoolBasicDetailsComponent,
    SchoolFilterComponent,
    SchoolListingSortComponent,
    SchoolRatingComponent,
    FilterCheckboxComponent,
    collapsibleComponent,
    LatestListingsComponent,
    PopularSchoolsComponent,
    ProfilePage,
    FavoritesComponent,
    ModalComponent,
    EditorComponent,
    CreateBlogComponent,
    EditBlogComponent,
    GuidedLearningPlatformComponent,
    CareersPage,
    AnnouncementsPage,
    CreateAnnouncementComponent,
    EditAnnouncementComponent,
    CreateVlogComponent,
    EditVlogComponent,
    CreateCoachingCenterComponent,
    EventsComponent,
    EventDetailsComponent,
    EditCoachingCenterComponent,
    DuplicateCoachingCenterComponent,
    SearchCoachingCenterComponent,
    CoachingCenterFormComponent,
    PopularListingsComponent,
    CoachingCentersComponent,
    CoachingCenterFilterComponent,
    CoachingCenterBasicDetailsComponent,
    CoachingCenterDetailsComponent,
    CharLimitPipe,
    GoogleMapsComponent,
    CreateEventComponent,
    EditEventComponent,
    EditUserComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SocialLoginModule,
    NumberedPaginationModule,
    GoogleSigninButtonModule,
    LightboxModule,
    NgxEditorModule,
    MATERIAL_MODULES
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('228720787127-v5j1ridfu038v636qld09b0m5siatcok.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('2009136969424933')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    {
      provide: GALLERY_CONFIG,
      useValue: {
        autoHeight: true,
        imageSize: 'cover',
        thumb: false,
        loop: false,
      } as GalleryConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {provide: 'googleTagManagerId', useValue: 'G-9KQYN26D1C'}
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
