import { AbstractControl } from "@angular/forms";

export function LongitudeValidator(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) return null;
    const longitude = parseFloat(control.value);
    if (isNaN(longitude) || longitude < -180 || longitude > 180) {
        return { 'invalidLongitude': { value: true } };
    }
    return null;
};

export function LatitudeValidator(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) return null;
    const latitude = parseFloat(control.value);
    if (isNaN(latitude) || latitude < -90 || latitude > 90) {
        return { 'invalidLatitude': { value: true } };
    }
    return null
}
