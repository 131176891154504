import { Component, OnInit } from '@angular/core';
import { InsightService } from '../../../services/insight/insight.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';
import { User } from 'src/app/models/user';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  user: User
  blog: any
  similarBlogs: any
  blogCategories: any
  message = ''
  authorImage = 'assets/img/blog/writer.png'
  blogId;
  blogCategory;
  restrictContent: boolean = false;

  constructor(private insightService: InsightService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private titleService: Title,
    private metaService: Meta) { }

  ngOnInit(): void {
    this.start()
    this.user = this.userService.currentUserValue
    this.blogId = this.activatedRoute.snapshot.queryParamMap.get('blogId');
    this.blogCategory = this.activatedRoute.snapshot.queryParamMap.get('category');
    this.getSimilarBlogs(this.blogCategory)
    this.getBlogsCategories()
    this.blogId && this.insightService.getBlog(this.blogId).subscribe((res:any) => {
      this.blog = { ...res.payload }
      if(this.blog.author?.name?.toUpperCase().includes('EDUSPEED')) {
        this.authorImage = 'assets/img/blog/es_blog.png'
      }
      this.restrictContentHandler();
      this.updatePageDetails();
      this.end()
      const blockQuote = document.getElementsByTagName('blockquote')
      if(blockQuote?.item(0)){
        blockQuote.item(0).className = 'blockquote'
      }
    }, () => {
      alert('error while fetching the blog, please try again later');
      this.router.navigate(['/blog']);
    })
  }

  updatePageDetails() {
    this.titleService.setTitle( `${this.blog.title}` );
    // this.metaService.updateTag({ name: 'description', content: `${this.blog.}`});
    // this.metaService.updateTag({ name: 'keywords', content: blog.metaKeywords});
  }

  getSimilarBlogs(category: string){
    this.insightService.retrieveSimilarBlogs(category).subscribe((res:any) => {
      this.similarBlogs = res.payload
    })
  }

  getBlogsCategories(): void {
    this.insightService.retrieveBlogCategoryAndCount().subscribe((res: any) => {
      this.blogCategories = res.payload
    })
  }

  addComment(): void {
    const request = {
      blogId: this.blog.id,
      name: `${this.user.firstName} ${this.user.lastName}`,
      comments: this.message
    }
    this.insightService.addComment(request).subscribe({
      error: (err) => {
        console.error('Error when adding comment', err)
      }
    })
  }

  openBlogsByCategory(category: string){
    const queryParams = { category : encodeURIComponent(category)}
    this.router.navigate(['/blog'], {queryParams})
  }

  openBlogsByTag(tag: string){
    const queryParams = { tag : encodeURIComponent(tag)}
    this.router.navigate(['/blog'], {queryParams})
  }

  openSimilarBlog(blog: any){
    const queryParams = { category : encodeURIComponent(blog.category), blogId: blog.id}
    this.router.navigate(['/blog-details'], {queryParams})
  }

  restrictContentHandler() {
    if(this.blog.isSubscriberContent === true && !this.user) {
      this.restrictContent = true;
    }
  }

  goToLogin() {
    const extras = this.getExtras('Login');
    const navigationExtras = { state: {linkData: extras}, queryParams: extras.queryParams }
    this.router.navigate(['/login-register'], navigationExtras )
  }

  getExtras(goToTab): NavigationExtras {
    return {
      state:{
          redirectOptions: {
            url: '/blog-details',
            goToTab,
            queryParams: {
              category: this.blogCategory,
              blogId: this.blogId
            }
          }
      }
    }
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }
}
