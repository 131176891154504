import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinkDataService {

constructor() { }

getLinkData(): any {
  return window.history.state?.linkData ?? {}
}

setLinkData(linkData: Object): void{
  if(!window.history.state){
    window.history.pushState({}, null)
  }
  const newData = {
    ...window.history.state,
    linkData
  }
  window.history.pushState(newData, null)
}

}
