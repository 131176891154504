<app-navbar></app-navbar>

<div class="inner-banner inner-profile">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Profile</h3>
        </div>
    </div>
</div>




<div class="team-area pt-100 pb-70 container">
    <div style="width: 80%">
        <mat-card style="background-color: inherit; padding: 3%">
            <h2>Personal Details</h2>
            <form [formGroup]="profileForm">
                <div class="row">
                        <div class="col-12 space">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-4 label">
                                        <label>First Name</label>
                                    </div>
                                    <div class="col-4">
                                        <label>{{user.firstName}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 space">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-4 label">
                                        <label>Last Name</label>
                                    </div>
                                    <div class="col-4">
                                        <label>{{user.lastName}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 space">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-4 label">
                                        <label>Mobile</label>
                                    </div>
                                    <div class="col-8">
                                        @if (user.mobile != null) {
                                            <label>{{user.mobile}}</label>
                                        } @else {
                                            @if (!toggleMobileInput) {
                                                <button class="btn" (click)="toggleMobileInput = !toggleMobileInput"><i class="bx bx-edit"></i></button>
                                            } @else {
                                                <div class="form-group">
                                                    <input type="text" formControlName="mobile" name="mobile" id="mobile" class="form-control" placeholder="Mobile" (ngModelChange)="isMobileAlreadyExists = false">
                                                    <div *ngIf="(f.mobile.touched || submitted) && f.mobile.errors" class="error">
                                                        <div *ngIf="f.mobile.errors?.required">Mobile is required</div>
                                                        <div *ngIf="f.mobile.errors?.pattern">Please enter valid 10 digit mobile number</div>
                                                    </div>
                                                    <div *ngIf="isMobileAlreadyExists" class="error">This mobile number is already registered.</div>
                                                </div>
                                                <div class="row" style="margin-top: 20px;">
                                                    <div class="col-lg-4 col-md-6">
                                                        <button type="submit" class="default-btn  user-all-btn" style="width:165px" (click)="toggleMobileInput = !toggleMobileInput">Cancel</button>
                                                    </div>
                                                    <div class="col-lg-4 col-md-6">
                                                        <button type="submit" class="default-btn  user-all-btn" style="width:165px" (click)="updateMobileNumber()">Update Password </button>
                                                    </div>

                                                </div>
                                            }
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 space">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-4 label">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-4">
                                        <label>{{user.email}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 space">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-4 label">
                                        <label>Role</label>
                                    </div>
                                    <div class="col-8">
                                            <label>{{user.roles}}</label>
                                            @if (user.roles == null || user.roles[0] == 'Guest') {
                                                <button class="btn" (click)="toggleUserRoleInput = !toggleUserRoleInput"><i class="bx bx-edit"></i></button>
                                            }
                                        @if (toggleUserRoleInput) {
                                            <div class="row">
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <mat-form-field style="width: 100%">
                                                        <mat-label>Select your role</mat-label>
                                                        <mat-select required formControlName="role">
                                                            <mat-option *ngFor="let role of roles" [value]="role">
                                                                {{ role }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <div *ngIf="(f.role.touched || submitted) && f.role.errors" class="error" style="margin-top: -20px;margin-bottom: 20px;">
                                                        <div *ngIf="f.role.errors.required">Please select your role</div>
                                                    </div>
                                                </div>
                                            </div>
                                            @if (f.role.value === 'Student') {
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <mat-form-field style="width: 100%">
                                                            <mat-label>Select your board</mat-label>
                                                            <mat-select required formControlName="board">
                                                                <mat-option *ngFor="let board of boards" [value]="board">
                                                                    {{ board }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <div *ngIf="(f.board.touched || submitted) && f.board.errors" class="error" style="margin-top: -20px;margin-bottom: 20px;">
                                                            <div *ngIf="f.board.errors.required">Please select your board</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <mat-form-field style="width: 100%">
                                                            <mat-label>Select your grade</mat-label>
                                                            <mat-select required formControlName="grade">
                                                                <mat-option *ngFor="let grade of grades" [value]="grade.value">
                                                                    {{ grade.label }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <div *ngIf="(f.grade.touched || submitted) && f.grade.errors" class="error" style="margin-top: -20px;margin-bottom: 20px;">
                                                            <div *ngIf="f.grade.errors.required">Please select your grade</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            </div>
                                                <div class="row" style="margin-top: 20px;">
                                                    <div class="col-lg-4 col-md-6">
                                                        <button type="submit" class="default-btn  user-all-btn" style="width:165px" (click)="toggleUserRoleInput = !toggleUserRoleInput">Cancel</button>
                                                    </div>
                                                    <div class="col-lg-4 col-md-6">
                                                        <button type="submit" class="default-btn  user-all-btn" style="width:165px" (click)="updateUserRole()">Update Role</button>
                                                    </div>

                                                </div>
                                            }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 space">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-4 label">
                                        <label>Subscribe News Letter</label>
                                    </div>
                                    <div class="col-4">
                                        <section>
                                            <mat-slide-toggle
                                                color="accent"
                                                [checked]="user.newsLetter"
                                                (change)="changeNewsLetterPreference($event)"
                                            >
                                            </mat-slide-toggle>
                                          </section>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div class="space">
                        <div class="col-lg-12 col-md-12" *ngIf="!showChangePassword">
                            <button type="submit" class="default-btn  user-all-btn" (click)="showChangePassword = !showChangePassword">Change Password</button>
                        </div>
                    </div>
                    <div class="row" *ngIf="showChangePassword">
                        <div class="col-4">
                            <div class="form-group">
                                <input class="form-control" formControlName="password" type="password" name="password" placeholder="Password">
                                <div *ngIf="(f.password.touched || submitted) && f.password.errors" class="error">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                    <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <input class="form-control" formControlName="confirmPassword" type="password" name="confirmPassword" placeholder="Confirm Password">
                                <div *ngIf="(f.confirmPassword.touched || submitted) && f.confirmPassword.errors" class="error">
                                    <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                    <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                                </div>
                            </div>
                        </div><br><br>
                        <div class="row" style="margin-top: 20px;">
                            <div class="col-lg-4 col-md-6">
                                <button type="submit" class="default-btn  user-all-btn" style="width:165px" (click)="showChangePassword = !showChangePassword">Cancel</button>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <button type="submit" class="default-btn  user-all-btn" style="width:165px" (click)="changePassword()">Update Password </button>
                            </div>

                        </div>
                    </div>


                </div>
                <br><br>
                <div style="font-style: italic;">
                    Your personal details are protected under our <a routerLink="/privacy-policy"> privacy policy</a>, If you wish to update any of your personal data, write an email to <a href="mail:contact@eduspeed.org"> contact&#64;eduspeed.org </a>
                </div>
            </form>
          </mat-card>
    </div>

</div>

<app-footer></app-footer>
