<app-navbar></app-navbar>

<div class="inner-banner" style="background-image: url(images/{{school?.registrationCode}}_{{1}}.jpg);">
    <div class="container">
        <div class="inner-content">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="inner-banner-text">
                        <h1 style="font-size: 1.5rem;">{{ school?.name }}</h1>
                        <ul>
                            <li><i class="flaticon-cursor"></i>{{ school?.address.addressLine2 }}</li>
                            <li *ngIf="school?.contact.phone1"><i class="flaticon-telephone"></i> <a href="tel:{{ school.address.phone1 }}">{{ school.contact.phone1 }}</a></li>
                            <li *ngIf="school?.contact.email1"><i class="flaticon-email-1"></i> <a href="mail:{{ school.contact.email1 }}"> {{ school.contact.email1 }} </a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="banner-rating">
                        <ul>
                            <li class="rating-star" style="cursor: pointer;" (click)="scrollTo('reviews')">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span>{{ school?.reviews.length }} {{ school?.reviews.length>0 ? 'Reviews' : 'Review' }}</span>
                            </li>
                            <li><a style="color: white;">{{ school?.rating }}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-bottom">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="banner-tag">
                        <ul>
                            <!-- <li><a routerLink="/listing-details"><i class="flaticon-favorite"></i>Book Now</a></li> -->
                            <li><a (click)="scrollTo('addReview')" href="javascript:void(0);"><i class="flaticon-writing"></i> Add Review</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="banner-profile">
                        <img src="assets/img/residential_school.png" alt="Images">
                        <h3>{{ school?.educationModes.join(', ') }}</h3>
                        <div class="banner-status">
                            <a href="javascript:void(0);"><h3>{{ school?.board }}</h3></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div>
                    <ul class="list">
                        <li><a href="index.html">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li><a (click)="backToSearch()" href="javascript:void(0);">Back To Search</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">{{ school?.name }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="listing-details-area pt-45 pb-70 first-typography">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="listing-details-wrap">
                    <div class="listing-category">
                        <ul>
                            <li><a (click)="scrollTo('location')" href="javascript:void(0);"><i class="flaticon-place"></i>LOCATION</a></li>
                            <li><a (click)="scrollTo('gallery')" href="javascript:void(0);"><i class="flaticon-gallery"></i> GALLERY</a></li>
                            <li><a (click)="scrollTo('reviews')" href="javascript:void(0);"><i class="flaticon-writing"></i> REVIEWS</a></li>
                        </ul>
                    </div>
                    <div class="listing-widget listing-widget-desc">
                        <h3 class="title">About School</h3>
                        <div class="about"> <h2>{{school?.aboutSchool.about}}</h2> </div>
                        <!-- <a routerLink="/listing-details" class="default-btn border-radius">Visit Website <i class='bx bx-plus'></i></a> -->
                        <div class="listing-features">
                            <h3 class="features-title">Highlights</h3>
                            <div class="row">
                                <div class="col-lg-2 col-md-3" *ngIf="school?.academic.integratedCourseAvailabilityInd === 'Yes'">
                                    <div class="features-card">
                                        <i class="flaticon-writing"></i>
                                        <h3>NEET/JEE Preparation</h3>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-3" *ngIf="school?.extraCurricularActivity.clubActivites">
                                    <div class="features-card">
                                        <i class="flaticon-buildings"></i>
                                        <h3>Club Activities</h3>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-3" *ngIf="school?.aboutSchool.yearsOfOperation">
                                    <div class="features-card">
                                        <i class="flaticon-diamond"></i>
                                        <h3>{{ school.aboutSchool.yearsOfOperation }} Years Of Operations</h3>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-3" *ngIf="school?.academic.thirdLanguagesOffered">
                                    <div class="features-card">
                                        <i class="flaticon-quote"></i>
                                        <h3>3rd Language Learning</h3>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-3" *ngIf="school?.infrastructureAndFacility.transportationAvailability === 'Yes'">
                                    <div class="features-card">
                                        <i class="flaticon-parking"></i>
                                        <h3>School Transport</h3>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-3" *ngIf="school?.infrastructureAndFacility.cafeteriaLunch==='Yes'">
                                    <div class="features-card">
                                        <i class="flaticon-coffee-cup"></i>
                                        <h3>Cafeteria</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="padding:25px">
                          <span><b>Specialities: </b></span>  {{school?.aboutSchool?.highlightsAndSpecialities}}
                        </div>
                    </div>

                    <div class="listing-widget" *ngIf="canDisplayAcheivements()">
                        <h3 class="title">Achievements & Accolades</h3>
                        <div style="padding:25px">
                            <div class="row" *ngIf="school.achievement.achievements?.trim()">
                                <div class="col-1"><img src="assets/icons/trophy.png" width="35px" height="35px"></div>
                                <div class="col-11">{{ school.achievement.achievements }}</div>
                            </div>
                            <div class="row" *ngIf="school.achievement.grade12TopScorePer && school.achievement.grade12TopScorePer !== 'NA' && school.achievement.grade12TopScorePer !== '0'">
                                <div class="col-1"><img src="assets/icons/trophy.png" width="35px" height="35px"></div>
                                <div class="col-11">Grade 12 top scoring percentage:  {{ school.achievement.grade12TopScorePer }}</div>
                            </div>
                            <div class="row" *ngIf="school.achievement.grade10TopScorePer && school.achievement.grade10TopScorePer !== 'NA' && school.achievement.grade10TopScorePer !== '0'">
                                <div class="col-1"><img src="assets/icons/trophy.png" width="35px" height="35px"></div>
                                <div class="col-11">Grade 10 top scoring percentage: {{ school.achievement.grade10TopScorePer }}</div>
                            </div>
                            <div class="row" *ngIf="school?.aboutSchool?.accolades">
                                <div class="col-1"><img src="assets/icons/trophy.png" width="35px" height="35px"></div>
                                <div class="col-11"> {{ school.aboutSchool.accolades }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="listing-widget">
                        <h3 class="title">Academics</h3>
                        <table mat-table [dataSource]="dsAcademics">
                            <ng-container matColumnDef="acKey">
                              <td mat-cell *matCellDef="let element"> {{element.acKey}} </td>
                            </ng-container>
                            <ng-container matColumnDef="acValue">
                              <td mat-cell *matCellDef="let element"> {{element.acValue}} </td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; columns: ['acKey','acValue'];" class="table-row"></tr>
                          </table>
                    </div>

                    <div class="listing-widget">
                        <h3 class="title">Knowledge Management</h3>
                        <table mat-table [dataSource]="dsKnowledgeManagement">
                            <ng-container matColumnDef="grade">
                                <th mat-header-cell *matHeaderCellDef> Grade </th>
                              <td mat-cell *matCellDef="let element"> {{element.grade}} </td>
                            </ng-container>
                            <ng-container matColumnDef="teachType">
                                <th mat-header-cell *matHeaderCellDef> Teaching Methodology </th>
                              <td mat-cell *matCellDef="let element"> {{element.teachType}} </td>
                            </ng-container>
                            <ng-container matColumnDef="testType">
                                <th mat-header-cell *matHeaderCellDef> Testing Methodology </th>
                                <td mat-cell *matCellDef="let element"> {{element.testType}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="['grade','teachType','testType']"></tr>
                            <tr mat-row *matRowDef="let row; columns: ['grade','teachType','testType'];" class="table-row"></tr>
                        </table>
                    </div>

                    <div class="listing-widget">
                        <h3 class="title">Co Curricular Activities</h3>
                        <table mat-table [dataSource]="dsCoCurricularActivities">
                            <ng-container matColumnDef="ccKey">
                              <td mat-cell *matCellDef="let element"> {{element.ccKey}} </td>
                            </ng-container>
                            <ng-container matColumnDef="ccValue">
                              <td mat-cell *matCellDef="let element" style="text-align: right;">
                                <img *ngIf="element.ccValue === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                <img *ngIf="element.ccValue === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                <span *ngIf="element.ccValue !== true && element.ccValue !== false">{{ element.ccValue }}</span>
                              </td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; columns: ['ccKey','ccValue'];" class="table-row"></tr>
                          </table>
                    </div>

                    <div class="listing-widget">
                        <h3 class="title">Extra Curricular Activities</h3>
                        <table mat-table [dataSource]="dsExtraCurricularActivities">
                            <ng-container matColumnDef="ecKey">
                              <td mat-cell *matCellDef="let element"> {{element.ecKey}} </td>
                            </ng-container>
                            <ng-container matColumnDef="ecValue">
                              <td mat-cell *matCellDef="let element" style="text-align: right;">
                                <img *ngIf="element.ecValue === true" src="assets/icons/yes.png"  width="15" height="15" matTooltip="Available">
                                <img *ngIf="element.ecValue === false" src="assets/icons/no.png"  width="15" height="15" matTooltip="Not Available">
                                <span *ngIf="element.ecValue !== true && element.ecValue !== false">{{ element.ecValue }}</span>
                              </td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; columns: ['ecKey','ecValue'];" class="table-row"></tr>
                          </table>
                    </div>

                    <div class="listing-widget">
                        <h3 class="title">Infrastructure And Facilities</h3>
                        <table mat-table [dataSource]="dsInfraAndFacilityYesNo">

                            <ng-container matColumnDef="column1">
                              <!-- <th mat-header-cell *matHeaderCellDef> No. </th> -->
                              <td mat-cell *matCellDef="let element"> {{element.column1}} </td>
                            </ng-container>

                            <ng-container matColumnDef="column2">
                                <td mat-cell *matCellDef="let element">
                                    <img *ngIf="element.column2 === true" src="assets/icons/yes.png"  width="15" height="15"  matTooltip="Available">
                                    <img *ngIf="element.column2 === false" src="assets/icons/no.png"  width="15" height="15"  matTooltip="Not Available">
                                    <span *ngIf="element.column2 !== true && element.column2 !== false">{{ element.column2 }}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="column3">
                              <td mat-cell *matCellDef="let element"> {{element.column3}} </td>
                            </ng-container>

                            <ng-container matColumnDef="column4">
                                <td mat-cell *matCellDef="let element">
                                    <img *ngIf="element.column4 === true" src="assets/icons/yes.png"  width="15" height="15"  matTooltip="Available">
                                    <img *ngIf="element.column4 === false" src="assets/icons/no.png"  width="15" height="15"  matTooltip="Not Available">
                                    <span *ngIf="element.column4 !== true && element.column4 !== false">{{ element.column4 }}</span>
                                </td>
                            </ng-container>

                            <ng-container *ngFor="let footerEntry of dsFooterInfraAndFacility; let footerEntryIndex = index">
                                <ng-container matColumnDef="column1-{{footerEntryIndex}}">
                                    <td mat-footer-cell *matFooterCellDef >
                                        {{ footerEntry.columnx }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="column2-{{footerEntryIndex}}">
                                    <td mat-footer-cell *matFooterCellDef >
                                        {{ footerEntry.columny }}
                                    </td>
                                </ng-container>
                            </ng-container>

                            <ng-container matColumnDef="otherSpecialInfra">
                                <td mat-footer-cell *matFooterCellDef colspan="4">
                                  <span><b>Other Special Infrastructure</b></span> {{ school?.infrastructureAndFacility?.otherSplInfra }}
                                </td>
                            </ng-container>

                            <!-- <ng-container matColumnDef="disclaimer1">
                                <td mat-footer-cell *matFooterCellDef colspan="2">
                                Please note that the cost of items displayed are completely and totally made up.2
                            </td>
                            </ng-container> -->

                            <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
                            <tr mat-row *matRowDef="let row; columns: columnsInfraAndFacility;" class="table-row"></tr>
                            <div *ngFor="let footerEntry of dsFooterInfraAndFacility; let footerEntryIndex = index">
                                <tr mat-footer-row *matFooterRowDef="['column1-'+footerEntryIndex, 'column2-'+footerEntryIndex]"  class="table-footer"></tr>
                            </div>
                            <tr mat-footer-row *matFooterRowDef="['otherSpecialInfra']" ></tr>
                          </table>
                    </div>

                    <div class="listing-widget gallery-slider" id="gallery">
                        <h3 class="title">Gallery / Photos</h3>

                        <div class="gallery-slider-area owl-carousel owl-theme">
                            <div class="Gallery-item" *ngFor="let count of [1,2,3,4]" (click)="lightbox.open(count)">
                                <img class="thumbnail" src="images/{{school?.registrationCode}}_{{count}}.jpg" alt="Images">
                            </div>
                        </div>
                    </div>

                    <div class="listing-widget listing-map" id="location" *ngIf="school?.address.mapLocation">
                        <h3 class="title">Map Location</h3>
                        <div [innerHtml]="map"></div>

                        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d735257.9205168115!2d-75.33502765384733!3d40.30050562979537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2a07dcfd9d0ce20e!2sUs%20Rt%2040%20%40%20Governors%20Sq%20Plaza%202!5e0!3m2!1sen!2sbd!4v1596709319151!5m2!1sen!2sbd"></iframe> -->
                    </div>

                    <div class="listing-widget listing-comment" *ngIf="school?.reviews" id="reviews">
                        <h3 class="title">User Reviews</h3>
                        <div style="text-align: center;" *ngIf="!school.reviews.length">No reviews yet. Be the first to add review about this school.
                            <ul>
                                <li><a (click)="scrollTo('addReview')" href="javascript:void(0);"><i class="flaticon-writing"></i> Add Review</a></li>
                            </ul>
                        </div>
                        <ul *ngIf="school.reviews.length">
                            <li *ngFor="let user of school.reviews">
                                <img src="assets/icons/user.png" width="50" height="50" alt="Image">
                                <div class="content">
                                    <div>
                                        <div style="padding-left: 10%;">
                                            <h3>{{ user.name }}</h3>
                                            <span>{{ user.timeStamp | date }}</span>
                                            <p>{{ user.review }}</p>
                                        </div>
                                    </div>
                                    <div style="float: right;  margin-top: -60px;">
                                        <school-rating [rating]="user.rating" [ratingPosition]="'right'"> </school-rating>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>

            <div class="col-lg-4">
                <div class="listing-details-side">
                    <div class="listing-widget-side">
                        <h3 class="title">Enquire This School</h3>

                        <div class="listing-Booking-form">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="text" name="name" id="name" readonly class="form-control" value="{{user?.firstName}} {{user?.lastName}}">
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='flaticon-email-2'></i>
                                            <input type="email" name="email" id="email" readonly class="form-control" value="{{user?.email}}">
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='bx bx-phone'></i>
                                            <input type="text" name="phone_number" id="phone_number" readonly class="form-control" value="{{user?.mobile}}">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <i class='bx bx-envelope'></i>
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message" [(ngModel)]="enquiryMessage"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="col-lg-12 col-md-12 text-center">
                                <button type="submit" (click)="enquiry()" class="default-btn border-radius">Send Enquiry</button>
                            </div>
                        </div>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">General Information</h3>

                        <table mat-table [dataSource]="dsGeneralInformation">
                            <ng-container matColumnDef="generalInfo">
                              <td mat-cell *matCellDef="let element" class="no-border"> {{element.generalInfo}} </td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; let even = even; columns: ['generalInfo'];"  [ngClass]="{gray: even}" class="table-row"></tr>
                        </table>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">Contact</h3>

                        <table mat-table [dataSource]="dsContact">
                            <ng-container matColumnDef="contact">
                              <td mat-cell *matCellDef="let element" class="no-border"> {{element.contact}} </td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; let even = even; columns: ['contact'];"  [ngClass]="{gray: even}" class="table-row"></tr>
                        </table>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">Fee Structure</h3>
                        <h3 *ngIf="!isFeesAvailable" class="price-title"><span style="float: none">Data not available</span></h3>
                        <div *ngIf="isFeesAvailable" style="margin-left: 15px;margin-bottom: 5px;font-size: x-small;font-style: italic;color: indianred;">* Fees mentioned are for per annum</div>
                        <table *ngIf="isFeesAvailable" mat-table [dataSource]="dsFeeStructure">
                            <ng-container matColumnDef="grade">
                              <td mat-cell *matCellDef="let element"> {{element.grade}} </td>
                            </ng-container>
                            <ng-container matColumnDef="fees">
                                <td mat-cell *matCellDef="let element"> {{element.fees  | currency:'INR': 'symbol'}} </td>
                              </ng-container>
                            <tr mat-row *matRowDef="let row; columns: ['grade', 'fees'];" class="table-row"></tr>
                        </table>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">Admission Process</h3>
                        <table mat-table [dataSource]="dsAdmissionProcess">
                            <ng-container matColumnDef="apKey">
                              <td mat-cell *matCellDef="let element"> {{element.apKey}} </td>
                            </ng-container>
                            <ng-container matColumnDef="apValue">
                              <td mat-cell *matCellDef="let element"> {{element.apValue}} </td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; columns: ['apKey','apValue'];" class="table-row"></tr>
                        </table>
                    </div>


                    <div class="listing-widget-side listing-blog-post" *ngIf="school?.blogs.length">
                        <h3 class="title">Blogs From School</h3>

                        <ul class="listing-widget-post">
                            <li *ngFor="let blog of school.blogs; ">
                                <div class="img">
                                    <img src="/images/blogs/{{school?.registrationCode}}/blogs/{{blog.title}}.jpg" alt="Images">
                                </div>

                                <div class="content">
                                    <span>{{blog.category}}</span>
                                    <h4>{{blog.title}}</h4>
                                    <p><i class="flaticon-writing"></i> {{blog.dateCreated}}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="listing-widget-side listing-blog-post"  id="addReview">
                        <h4 class="title">Add Review </h4>
                        <div style="padding-left: 10px;padding-right: 10px;padding-top: 15px;">
                            <div class="row" style="padding-bottom: 10px; font-family: var(--fontFamily);">
                                <div style="float: left;"><b>Your overall rating</b>
                                <div style="float: right;">
                                    <school-rating [rating]="overallRating"> </school-rating>
                                </div>
                            </div>
                        <div class="single-progressbar" style="padding-top: 10px;">
                            <h3><span style="float: left;">Academics</span> <span><img src="assets/icons/info.png"  width="15" height="15" matTooltip="Curriculum and learning material"></span><span style="float: right; padding-right: 5px;" matTooltip="Your rating in the scale of 1-5 where 5 being the best">{{ slider1.value }}</span></h3>
                            <mat-slider
                                class="rating-slider"
                                [max]="maxRating"
                                [min]="minRating"
                                [step]="step"
                                thumbLabel>
                                <input matSliderThumb (change)="ratingAcademics=getNumericValue(slider1.value); updateRating()" [(ngModel)]="ratingAcademics" #slider1>
                            </mat-slider>
                        </div>

                        <div class="single-progressbar">
                            <h3><span style="float: left;">Imparting Knowledge</span><span><img src="assets/icons/info.png"  width="15" height="15" matTooltip="Teaching and evaluation"></span><span style="float: right;padding-right: 5px;" matTooltip="Your rating in the scale of 1-5 where 5 being the best">{{ slider2.value }}</span></h3>
                            <mat-slider
                                class="rating-slider"
                                [max]="maxRating"
                                [min]="minRating"
                                [step]="step"
                                thumbLabel>
                                <input matSliderThumb (change)="ratingImparting=getNumericValue(slider2.value); updateRating()" [(ngModel)]="ratingImparting" #slider2>
                            </mat-slider>
                        </div>

                        <div class="single-progressbar">
                            <h3><span style="float: left;">Infrastructure facilities</span><span style="float: right;padding-right: 5px;" matTooltip="Your rating in the scale of 1-5 where 5 being the best">{{ slider3.value }}</span></h3>
                            <mat-slider
                                class="rating-slider"
                                [max]="maxRating"
                                [min]="minRating"
                                [step]="step"
                                thumbLabel>
                                <input matSliderThumb (change)="ratingInfraFacility=getNumericValue(slider3.value); updateRating()" [(ngModel)]="ratingInfraFacility" #slider3>
                            </mat-slider>
                        </div>

                        <div class="single-progressbar">
                            <h3><span style="float: left;"> Extra curricular activities</span><span style="float: right;padding-right: 5px;" matTooltip="Your rating in the scale of 1-5 where 5 being the best">{{ slider4.value }}</span></h3>
                            <mat-slider
                                class="rating-slider"
                                [max]="maxRating"
                                [min]="minRating"
                                [step]="step"
                                thumbLabel>
                                <input matSliderThumb (change)="ratingExtraCurricular=getNumericValue(slider4.value); updateRating()" [(ngModel)]="ratingExtraCurricular" #slider4>
                            </mat-slider>
                        </div>

                        <div class="single-progressbar">
                            <h3><span style="float: left;"> Growth oriented ecosystem</span><span style="float: right;padding-right: 5px;" matTooltip="Your rating in the scale of 1-5 where 5 being the best">{{ slider5.value }}</span></h3>
                            <mat-slider
                                class="rating-slider"
                                [max]="maxRating"
                                [min]="minRating"
                                [step]="step"
                                thumbLabel>
                                <input matSliderThumb (change)="ratingGrowth=getNumericValue(slider5.value); updateRating()" [(ngModel)]="ratingGrowth" #slider5>
                            </mat-slider>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="review" [(ngModel)]="review" class="form-control review" id="review" cols="30" rows="8" required placeholder="Your Review"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12" style="padding-top: 10px;">
                            <button type="submit" (click)="submitReview()" class="default-btn border-radius">Submit</button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
