import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'school-listing-sort',
  templateUrl: './sort.component.html',
})
export class SchoolListingSortComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}