<app-navbar></app-navbar>

<div class="inner-banner inner-contact">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
        </div>
    </div>
</div>

<div class="contact-area">
    <div class="container">
        <div class="contact-max">
            <div class="row">
                <div class="col-lg-5 col-md-6">
                    <div class="contact-card">
                        <i class="flaticon-position"></i>
                        <h3>Address:</h3>
                        <h3>01 FA, 1 Floor, IIT Madras Research Park</h3>
                        <h3>Kanagam road, Taramani, Chennai 600113.</h3>
                        <h3>Tamilnadu, India.</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="contact-card">
                        <i class="flaticon-email"></i>
                        <h3><a href="mailto:contact@eduspeed.org">Email : contact&#64;eduspeed.org</a></h3>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 offset-md-3 offset-lg-0">
                    <div class="contact-card">
                        <i class="flaticon-phone-call"></i>
                        <h3>Toll free number:</h3>
                        <h3><a href="tel:1800-3090-468">1800-3090-468</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-map">
    <div class="container-fluid m-0 p-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.686573775009!2d80.24105437507684!3d12.991887587325376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d7d6641728d%3A0xddfdb15c6538d927!2sIIT%20Madras%20Research%20Park!5e0!3m2!1sen!2sin!4v1703569002521!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        <div class="contact-wrap">
            <div class="contact-form">
                <span>SEND MESSAGE</span>
                <h2>Connect With Us</h2>

                <form id="contactForm" [formGroup]="enquiryForm">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="text" name="name" id="name" formControlName="name" class="form-control" required placeholder="Name*">
                                <div *ngIf="(f.name.touched) && f.name.errors" class="error">
                                    <div *ngIf="f.name.errors.required">Name is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="email" name="email" id="email" formControlName="email" class="form-control" required placeholder="E-mail*">
                                <div *ngIf="(f.email.touched) && f.email.errors" class="error">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-phone'></i>
                                <input type="text" name="phone_number" id="phone_number" formControlName="mobile"  required class="form-control" placeholder="Mobile*">
                                <div *ngIf="(f.mobile.touched) && f.mobile.errors" class="error">
                                    <div *ngIf="f.mobile.errors?.required">Mobile is required</div>
                                    <div *ngIf="f.mobile.errors?.pattern">Please enter valid 10 digit mobile number</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-file'></i>
                                <input type="text" name="msg_subject" id="msg_subject" formControlName="subject"  class="form-control" required placeholder="Subject*">
                                <div *ngIf="(f.subject.touched) && f.subject.errors" class="error">
                                    <div *ngIf="f.subject.errors.required">Subject is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <i class='bx bx-envelope'></i>
                                <textarea name="message" class="form-control" id="message" formControlName="message"  cols="30" rows="8" required placeholder="Message*"></textarea>
                                <div *ngIf="(f.message.touched) && f.message.errors" class="error">
                                    <div *ngIf="f.message.errors.required">Message Required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="default-btn border-radius" (click)="enquiry()">Send Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>