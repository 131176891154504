<div class="container-fluid">
    <div class="section-title text-center">
        <span>Testimonials</span>
        <h2>What our users feel about Eduspeed</h2>
    </div>

    <div class="testimonial-slider owl-carousel owl-theme">
        <div class="testimonial-item testimonial-item-bg" *ngFor="let testimonial of testimonials">
            <h3>{{ testimonial.name }}</h3>
            <span>{{ testimonial.locations }}</span>
            <p>{{ testimonial.comments }}</p>
            <ul class="rating">
                <li><i class='bx bxs-star'></i></li>
                <li><i class='bx bxs-star'></i></li>
                <li><i class='bx bxs-star'></i></li>
                <li><i class='bx bxs-star'></i></li>
                <li><i class='bx bxs-star'></i></li>
            </ul>
            <div class="testimonial-top">
                <i class='bx bxs-quote-left'></i>
            </div>
        </div>
    </div>
</div>
