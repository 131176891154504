import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegisterUser, Login } from '../../libs/common/user/user'
import { User, Role, UserDetails } from '../../models/user'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  schoolServiceUrl:string
  adminServiceUrl:string

  private currentUserSubject: BehaviorSubject<User>
  public currentUser: Observable<User>

  constructor(private httpClient: HttpClient) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
      this.schoolServiceUrl = environment.endpoints.schoolService
      this.adminServiceUrl = environment.endpoints.adminService
  }

  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  public isUserLoggedIn(): boolean {
    return this.currentUserValue !== undefined && this.currentUserValue !== null;
  }

  public isAdminUser(): boolean {
    if(this.currentUserValue?.token){
      const roles = (JSON.parse(atob(this.currentUserValue.token.split('.')[1]))).roles
      return roles.includes(Role.ADMIN) || roles.includes(Role.SUPER_ADMIN)
    }
    return false
  }

  public isDataEntryUser(): boolean {
    if(this.currentUserValue?.token) {
      const roles = (JSON.parse(atob(this.currentUserValue.token.split('.')[1]))).roles
      return roles.includes(Role.DATA_ENTRY)
    }
    return false
  }

  private isTokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp ?? 0;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }


  register(user: RegisterUser){
    return this.httpClient.post(`${this.schoolServiceUrl}/api/eduspeed/user/register`, user)
    .pipe(map((user: User) => {
      // store user details and jwt token in session storage to keep user logged in between page refreshes
      sessionStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);
      return user;
  }));
  }


  validateOTP(email: string, otp: string){
    return this.httpClient.get(`${this.schoolServiceUrl}/api/eduspeed/user/validate?email=${email}&otp=${otp}`)
    .pipe(map(res => res));
  }

  subscribeToNewsLetter(email: string){
    return this.httpClient.put(`${this.schoolServiceUrl}/api/eduspeed/user/subscribe?email=${email}`, {})
  }

  updateNewsLetterPreference(email: string, isNewsLetterRequired: boolean){
    return this.httpClient.put(`${this.schoolServiceUrl}/api/eduspeed/user/newsletter?email=${email}&isNewsLetterRequired=${isNewsLetterRequired}`, {})
    .pipe(map(res => res));
  }

  updatePassword(email: string, password: string){
    return this.httpClient.put(`${this.schoolServiceUrl}/api/eduspeed/user/password?email=${email}&password=${password}`, {})
    .pipe(map(res => res));
  }

  requestTemporaryPassword(email: string){
    return this.httpClient.put(`${this.schoolServiceUrl}/api/eduspeed/user/forgotPassword?email=${email}`, {})
    .pipe(map(res => res));
  }

  updateMobileNumber(email: string, mobileNumber: number) {
    return this.httpClient.put(`${this.schoolServiceUrl}/api/eduspeed/user/mobile?email=${email}&mobile=${mobileNumber}`, {})
    .pipe(map(res=>res));
  }

  updateUserRole(request: any){
    return this.httpClient.put(`${this.schoolServiceUrl}/api/eduspeed/user/roles`, request)
    .pipe(map(res=>res));
  }

  login(email: string, password: string) {
    return this.httpClient.post(`${this.schoolServiceUrl}/api/eduspeed/user/login`, {email, password})
          .pipe(map((user: User) => {
              // store user details and jwt token in session storage to keep user logged in between page refreshes
              sessionStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
              return user;
          }));
  }

  logout() {
      // remove user from session storage to log user out
      sessionStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
  }

  getFavoriteSchools(email1?: string): Observable<any>{
    const { email } = this.currentUserValue
    return this.httpClient.get<any>(`${this.schoolServiceUrl}/api/eduspeed/user/favorites?email=${email}`)
  }

  addFavorite(email: string, registrationCode: string){
    return this.httpClient.put(`${this.schoolServiceUrl}/api/eduspeed/user/add-favorite?email=${email}&registrationCode=${registrationCode}`, {})
  }

  removeFavorite(email: string, registrationCode: string){
    return this.httpClient.delete(`${this.schoolServiceUrl}/api/eduspeed/user/remove-favorite?email=${email}&registrationCode=${registrationCode}`, {})
  }

  addReview(email: string, request: any){
    return this.httpClient.post(`${this.schoolServiceUrl}/api/eduspeed/user/add-review?email=${email}`, request)
  }

  enquireSchool(request: any): Observable<string>{
    return this.httpClient.post<string>(`${this.schoolServiceUrl}/api/eduspeed/user/enquiry`, request)
  }

  searchUsers(request: any): Observable<UserDetails>{
    return this.httpClient.post<UserDetails>(`${this.adminServiceUrl}/api/esadmin/user/search-users`, request)
  }

  updateUser(request: UserDetails): Observable<any>{
    return this.httpClient.post<any>(`${this.adminServiceUrl}/api/esadmin/user/update`, request)
  }

}
