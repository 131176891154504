import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { config } from './config/filter.configuration';
import { Category } from '../../../libs';
import { SchoolService } from '../../../services/school/school.service';
import { FilterCriteria } from '../../pages/schools/schools.component';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'school-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class SchoolFilterComponent implements OnInit {
  myControl = new FormControl('');
  myControl1 = new FormControl('');
  isActive = false
  locations: any
  state: any
  states = []
  city: any
  @Input('conditions')
  filterConditions?: any
  @Input('filterCriteria')
  filterCriteria: FilterCriteria
  @Output()
  filters = new EventEmitter();
  @Output()
  reset = new EventEmitter();


  filterConfiguration: any
  private debounceSubject: Subject<any> = new Subject<any>();
  constructor(private schoolService: SchoolService) {
    this.getSchoolLocations()
    this.debounceSubject.pipe(
      debounceTime(1500) // Debounce time in milliseconds (1.5 second in this case)
    ).subscribe(() => {
      this.emitFilterCriteria();
    });
  }

  ngOnInit(): void {
    this.applyMatchingFilter()
  }

  getSchoolLocations(){
    this.schoolService.getAllRegions().subscribe({
      next: (locations) => {
        this.locations = locations.payload
        this.states = Object.keys(this.locations)
      },
      error: (err) => console.error('Error occured when retrieving location data', err)
    })
  }

  applyMatchingFilter(): void{
    this.filterConfiguration = { ...config }
    console.log('filterData',this.filterConditions)
    if(this.filterConditions.category !== Category.ALL){
        delete this.filterConfiguration.board
    }
    if(this.filterConditions.referrer === 'home'){
      delete this.filterConfiguration.location
    }
  }

  onChange(event: any): void{
    this.debounceSubject.next(event);
  }

  setState(state: any): void{
    this.state = state
    console.log(this.state)
  }

  setCity(city: string): void{
    this.city = city
    this.filterCriteria.location = {
      state: this.state,
      city: this.city
    }
    this.emitFilterCriteria()
  }

  getSchoolCities(state: string): any {
    if(state == undefined) {
        return [];
    }
    return this.locations[state];
  }

  emitFilterCriteria(){
    this.filters.emit(this.filterCriteria)
  }

  resetFilter(){
    this.reset.emit()
  }

}



