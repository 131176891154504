import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CoachingCenterFilterCriteria } from 'src/app/models/coaching-center';
import { config } from './filter.configuration';
import { CoachingCenterService } from 'src/app/services/coaching-center/coaching-center.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'coaching-center-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class CoachingCenterFilterComponent implements OnInit{
  
  state: string;
  states = [];
  cities = [];
  localities =[]
  city: string;
  locality: string;
  locations: any;
  disableCenterType = false;
  displayEntranceExams = false;
  @Input('filterCriteria') 
  filterCriteria: CoachingCenterFilterCriteria;
  @Output()
  filters = new EventEmitter();
  @Output()
  reset = new EventEmitter();

  filterConfiguration: any;

  private debounceSubject: Subject<any> = new Subject<any>();
  constructor(private coachingService: CoachingCenterService) {
    this.getLocations();
    this.debounceSubject.pipe(
      debounceTime(1500) // Debounce time in milliseconds (1.5 second in this case)
    ).subscribe(() => {
      this.emitFilterCriteria();
    });
  }
  
  ngOnInit(): void {
    this.applyMatchingFilter();
    this.state = this.filterCriteria.location.state
    this.city = this.filterCriteria.location.city
    this.locality = this.filterCriteria.location.locality
    this.filterCriteria.centerType = null
  }

  getLocations() {
    this.coachingService.findCoachingCenterFilterLocations().subscribe({
      next: (locations) => {
        
        this.locations = locations.payload
        this.states = Object.keys(this.locations)
        this.setCities()
        this.setLocalities()
      },
      error: (err) => console.error('Error occured when retrieving location data', err)
    })
  }

  setCities(){
    if(this.state !== '') {
      this.cities = Object.keys(this.locations[this.state]) 
    }
  }

  setLocalities(){
    if(this.city !== '') {
      this.localities = this.locations[this.state][this.city];
    }
  }

  applyMatchingFilter() {
    this.filterConfiguration = { ...config }
  }

  onChange(event: any, mode: string): void{
    if(event === undefined) {
      this.displayEntranceExams = false;
      return;
    }
    if(mode === 'type'){
      this.filterCriteria.centerType = event;
      if(event !== "Entrance"){
        this.filterCriteria.entranceExams = [];
        this.displayEntranceExams = false
        this.emitFilterCriteria()
      }
      else {
        this.displayEntranceExams = true;
      }
    } else if(mode ==='exams') {
      this.debounceSubject.next(event);
    }

  }

  setState(state: any): void{
    this.state = state 
    this.setCities()
  }

  setCity(city: string): void{
    this.city = city
    this.locality = undefined
    this.disableCenterType = true
    this.setLocalities()
  }

  setLocality(locality: string) {
    this.locality = locality;
    this.filterCriteria.centerType = 'Entrance'
    this.filterCriteria.location = {
      state: this.state,
      city: this.city,
      locality: this.locality
    }    
    this.disableCenterType = false;
    this.emitFilterCriteria()
  }

  emitFilterCriteria(){
    this.filters.emit(this.filterCriteria)
  }

  resetFilter(){
    this.disableCenterType = false;
    this.displayEntranceExams = false;
    this.reset.emit()
  }
}
