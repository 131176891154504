import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
  testimonials: Testimonial[];
  constructor() {}
  
  ngOnInit(): void {
    this.testimonials = [
      {
        name: 'Hemant Kumar',
        location: 'Pallavaram, Chennai',
        rating: 5,
        comments: 'Eduspeed immensely helped in identifying the right Kindergarten school for my son. Thanks for the support!'
      },
      {
        name: 'Vidya S',
        location: 'Kelambakkam, Chennai',
        rating: 5,
        comments: 'Got all round support from search until admission to school through Eduspeed team, Keep up the good work!'
      },
      {
        name: 'Raman V & Nithya',
        location: 'East Tambaram, Chennai',
        rating: 3,
        comments: 'As a young parent, i find the articles at Eduspeed on various edu trends very interesting and crisp read. Will highly recommend my friends to subscribe to Eduspeed.'
      }
    ]
  }
}

export interface Testimonial{
  name: string,
  location: string,
  rating: number,
  comments: string
}
