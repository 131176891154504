<app-navbar></app-navbar>

<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Frequently Asked Questions</h3>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>FAQ</span>
            <h2>Eduspeed <b>FAQ</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-6">
                <div class="faq-item">
                    <h3>Q: What are the cities currently served by Eduspeed?</h3>
                    <p><strong>A:</strong>We are currently serving Chennai city and will be expanding our services to other cities in
                        South India soon, So stay tuned and subscribe for our Newsletter to get regular updates.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-item">
                    <h3>Q: What are the major curriculum of schools Eduspeed is offering info about?</h3>
                    <p><strong>A:</strong>We work predominantly with CBSE and IGCSE curriculum based schools. But we intend to
                        extend our offerings based on edu trends and demand we foresee on certain niche
                        educational offerings.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-item">
                    <h3>Q: What are the objective of Guided Training Platform offerings?</h3>
                    <p><strong>A:</strong>The main objective of guided training offering is to start early preparation for competitive
                        exams like NEET, JEE purely based on concept learning. The value of this product is that it
                        also aids in improving scores tremendously in regular academics as well.
                        Sign up for demo session of the product via this enquiry form.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-item">
                    <h3>Q: Can I register my child as a user in Eduspeed platform?  </h3>
                    <p><strong>A:</strong>If you are child is 13 years or older, you can sign up for your child, provided parent consent
                        through additional emailer and accepting privacy conditions. Children below 13 years are
                        not allowed to get registered in our platform.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-item">
                    <h3>Q: Can I get customized support in terms of my edu requirements?</h3>
                    <p><strong>A:</strong>Yes, of course, feel free to make an enquiry through our contact us page for taking this
                        forward.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>