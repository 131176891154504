import { Component, OnInit } from '@angular/core';
import { AnnouncementService } from '../../../services/announcement/announcement.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { categories } from './announcements.configuration';
// import { OwlOptions } from 'ngx-owl-carousel';
declare let $: any
const DAILY_DIGEST = 'Daily Digest'
@Component({
  selector: 'announcements',
  templateUrl: './announcements.page.html',
  styleUrls: ['./announcements.page.scss']
})
export class AnnouncementsPage implements OnInit {

  categories: any;
  selectedCategory = ''
  announcements;
  popularNews;
  expandText = 'Read more';

  slideOffset = 0;
  currentIndex = 0;
  interval: any;
  totalWidth = 0;
  offsetWidth = 650;
  showNavigation = true;

  postExpanded = false;
  previousIndex = 0;

  premiumBlogs = []
  filteredBlogs = []


  constructor(private announcementService: AnnouncementService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
                this.categories = categories;
    }

  ngOnInit(): void {
    const announcementId = this.activatedRoute.snapshot.queryParamMap.get('id');
    announcementId ? this.getAnnouncementById(announcementId) : this.getAnnouncementsByCategory(this.categories[0])
    this.getPremiumBlogs(this.categories[0]);
  }

  getAnnouncementById(id: string){
    this.showNavigation = false;
    const $announcement = this.announcementService.getAccouncementById(id)
    const $popularNews = this.announcementService.retrieveAnnouncements(this.categories[0], 60)
    forkJoin({announcement: $announcement, popularNews: $popularNews}).subscribe({
     next: ({announcement, popularNews}) => {
      this.announcements = announcement['payload'].announcementDetails
      this.setPopularNews(popularNews['payload'].announcementDetails)
      },
      error: (err) => console.error('Error retreiving announcement', err)
    })
  }

  getAnnouncementsByCategory(category: any): void{
    this.selectedCategory = category.name
    const days = category.days;
    this.stop();
    this.announcementService.retrieveAnnouncements(this.selectedCategory, days).subscribe({
      next: (res: any) => {
        this.announcements = res.payload.announcementDetails
        if(this.announcements?.length > 0) {
          this.showNavigation = true
          this.startCarousel()
          this.setPopularNews(this.announcements)
        }
      },
      error: (err) => console.error('Error retreiving announcements', err)
    })
    this.getPremiumBlogs(category);
  }

  setPopularNews(announcements): void {
    this.popularNews = [];
    announcements?.forEach(element => {
      this.popularNews.push(element.title);
    });
  }

  getPremiumBlogs(category: any) {
    this.announcementService.getPremiumBlogs(category.name).subscribe({
      next: (res: any) => {
        this.premiumBlogs = res.payload;
      },
      error: (err) => {
        console.error(err);
      }
    })
  }

  openBlog(blog: any): void {
    this.router.navigate(['/blog-details'], this.getExtras(blog))
  }

  getExtras(blog: any): NavigationExtras {
    return {
      state:{
          redirectOptions: {
            url: '/blog-details',
            queryParams: {
              category: blog.category,
              blogId: blog.id
            }
          }
      },
      queryParams: {
        category: blog.category,
        blogId: blog.id
      }
    }
  }

  getId(index: number): string {
    return `post-${index}`;
  }

  startCarousel() {
    this.interval = setInterval(() => {
      this.next();
    }, 3000);
  }

  pause() {
    clearInterval(this.interval);
  }

  resume() {
    this.startCarousel();
  }

  stop() {
    clearInterval(this.interval);
  }

  next() {
    this.currentIndex = (this.currentIndex + 1) % this.announcements?.length;
    this.slideOffset = -this.currentIndex * this.offsetWidth; // Assuming each slide has a width of 600px
    (this.postExpanded) && this.toggleRead(this.previousIndex) // Close only when the post is expanded
  }

  prev() {
    this.currentIndex = (this.currentIndex - 1 + this.announcements?.length) % this.announcements?.length;
    this.slideOffset = -this.currentIndex * this.offsetWidth; // Assuming each slide has a width of 600px
    (this.postExpanded) && this.toggleRead(this.previousIndex) // Close only when the post is expanded
  }

  goToSlide(index: number) {
    if(!this.showNavigation) {
      this.getAnnouncementsByCategory(this.categories[0])
      return
    }
    if (index >= 0 && index < this.announcements?.length) {
      this.currentIndex = index;
      this.slideOffset = -this.currentIndex * this.offsetWidth; // Assuming each slide has a width of 600px
      ((this.currentIndex != this.previousIndex) && this.postExpanded) && this.toggleRead(this.previousIndex) //close if we select other slide and if the current slide is expanded
    }
  }

  toggleRead(index:number) :void {
    const el = document.getElementById(`post-${index}`);
    this.postExpanded = !this.postExpanded;
    el.style.height = this.postExpanded ? 'auto' : '';
    this.expandText = this.postExpanded ? 'Read less' : 'Read more'
    this.previousIndex = index;
  }
}
