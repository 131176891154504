import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'
import { Observable, map } from 'rxjs';
import { SearchSchoolsResponse } from 'src/app/models/school';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {
  schoolServiceUrl: string;

  constructor(private httpClient: HttpClient) {
    this.schoolServiceUrl = environment.endpoints.schoolService
  }

  public getSchoolListByFilter(board: string, pageNo: number, state?: string, city?: string): Observable<any>{
    if(board === 'ALL') board = undefined
    return this.httpClient.get(`${this.schoolServiceUrl}/api/eduspeed/school?pageSize=10&pageNo=0`)
  }

  getAllRegions(): Observable<any>{
    return this.httpClient.get('assets/json/locations/schools.json');
  }

  getSchoolsByGeography(state: string, city: string, board: string, pageNo: number, pageSize: number = 10): Observable<SearchSchoolsResponse>{
    let url = `${this.schoolServiceUrl}/api/eduspeed/school/location?state=${state}&city=${city}&pageSize=${pageSize}&pageNo=${pageNo}`
    if(board !== 'ALL') url += `&board=${board}`
    return this.httpClient.get<SearchSchoolsResponse>(url)
  }

  getSchoolsByBoard(board: string, pageNo: number, pageSize: number = 10): Observable<SearchSchoolsResponse>{
    return this.httpClient.get<SearchSchoolsResponse>(`${this.schoolServiceUrl}/api/eduspeed/school/board/${board}?pageSize=${pageSize}&pageNo=${pageNo}`)
  }

  getSchoolDetails(id: number): Observable<any>{
    return this.httpClient.get(`${this.schoolServiceUrl}/api/eduspeed/school/${id}`);
  }

  getSchoolByFilter(filterData: any): Observable<SearchSchoolsResponse>{
    return this.httpClient.post<SearchSchoolsResponse>(`${this.schoolServiceUrl}/api/eduspeed/school/filter`, filterData)
  }

  enquireSchool(request: any): Observable<string>{
    return this.httpClient.post<string>(`${this.schoolServiceUrl}/api/eduspeed/school/enquiry`, request)
  }

  getPopularSchools(): Observable<any>{
    return this.httpClient.get(`${this.schoolServiceUrl}/api/eduspeed/school/recommended`);
  }

}
