
export enum Option {
    RADIO_BUTTON = 'RADIO_BUTTON',
    CHECKBOX = 'CHECKBOX',
    DROPDOWN = 'DROPDOWN'
}

export enum FilterGroup {
    HOME = 'HOME',
    CATEGORY = 'CATEGORY',
    ALWAYS = 'ALWAYS'
}

export const config = {
    board: {
        legend: 'Board Type',
        type: Option.RADIO_BUTTON,
        group: FilterGroup.HOME,
        isClosed: false,
        options: [
            {
                label: 'CBSE',
                value: 'cbse'
            },
            {
                label: 'IGCSE',
                value: 'igcse'
            }
        ]
    },
    location: {
        legend: 'Location',
        type: Option.DROPDOWN,
        group: FilterGroup.CATEGORY,
        isClosed: false,
        options: []

    },
    schoolType: {
        legend: 'School Type',
        type: Option.RADIO_BUTTON,
        group: FilterGroup.ALWAYS,
        isClosed: false,
        options: [
            {
                label: 'Residential',
                value: 'residential'
            },
            {
                label: 'Day School',
                value: 'daySchool'
            }
        ]
    },
    fees: {
        legend: 'Annual Fees',
        type: Option.CHECKBOX,
        group: FilterGroup.ALWAYS,
        isClosed: false,
        options: [
            {
                label: 'Less than 1 Lakh',
                value: 'under1lakh'
            },
            {
                label: '1 Lakh to 3 Lakh',
                value: 'above1lakhUnder3lakhs'
            },
            {
                label: '3 Lakh to 5 Lakh',
                value: 'above3lakhUnder5lakhs'
            },
            {
                label: 'Above 5 Lakh',
                value: 'above5lakh'
            }
        ]
    },
    ratings: {
        legend: 'Ratings',
        type: Option.RADIO_BUTTON,
        group: FilterGroup.ALWAYS,
        isClosed: false,
        options: [
            {
                label: '1 Star or higher',
                value: '1'
            },
            {
                label: '2 Stars or higher',
                value: '2'
            },
            {
                label: '3 Stars or higher',
                value: '3'
            },
            {
                label: '4 Stars or higher',
                value: '4'
            },
            {
                label: '5 Stars',
                value: '5'
            },
        ]
    },
    grouping: {
        legend: 'Grouping',
        type: Option.RADIO_BUTTON,
        group: FilterGroup.ALWAYS,
        isClosed: false,
        options: [
            {
                label: 'Boys',
                value: 'boys'
            },
            {
                label: 'Girls',
                value: 'girls'
            },
            {
                label: 'Co-Education',
                value: 'coed'
            }
        ]
    },
    grade:{
        legend: 'Grades',
        type: Option.RADIO_BUTTON,
        group: FilterGroup.ALWAYS,
        isClosed: false,
        options: [
            {
                label: 'Kindergarten',
                value: 'kindergarten'
            },
            {
                label: 'Class I - V',
                value: 'primary'
            },
            {
                label: 'Class VI - XIII',
                value: 'middleSchool'
            },
            {
                label: 'Class IX - X',
                value: 'highSchool'
            },
            {
                label: 'Class XI - XII',
                value: 'seniorSecondary'
            }
        ]
    },
    facilities: {
        legend: 'Facilities',
        type: Option.CHECKBOX,
        group: FilterGroup.ALWAYS,
        isClosed: false,
        options: [
            {
                label: 'Transportation',
                value: 'transportation'
            },
            {
                label: 'Cafeteria',
                value: 'cafeteria'
            },
            {
                label: 'CCTV Surveillance',
                value: 'cctv'
            },
            {
                label: 'Availability of Infirmary',
                value: 'infirmaryAvailability'
            },
            {
                label: 'Hostel facility',
                value: 'hostel'
            },
            {
                label: 'NEET/JEE trainings',
                value: 'integratedCoursesForNEETAndJEE'
            }, 
            {
                label: 'Football Ground',
                value: 'footballGround'
            }, 
            {
                label: 'Basketball Court',
                value: 'basketBallCourt'
            }, 
            {
                label: 'Cricket Nets',
                value: 'cricketNets'
            }, 
            {
                label: 'Swimming Pool',
                value: 'swimmingPool'
            }, 
            {
                label: 'Tennis/badminton court',
                value: 'badminton'
            },
        ]
    }
}