export const categories = [
    {
        name: 'School Zone',
        days: 60
    },
    {
        name: 'College Zone',
        days: 60
    },
    {
        name: 'Overseas Education',
        days: 60
    },
    {
        name: 'Daily Digest',
        days: 60
    },
    {
        name: 'Health & Nutrition',
        days: 365
    },
    {
        name: 'Parenting tips',
        days: 365
    },
    {
        name: 'Neighbourhood',
        days: 60
    }
];
