import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { LinkDataService } from '../../../libs/services/link-data.service';
import { SchoolService } from '../../../services/school/school.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  locations: any
  states = [];
  state: any
  city: string
  boards = ['All', 'CBSE', 'IGCSE']
  board = this.boards[0]

  constructor(private router: Router, private linkDataService: LinkDataService, private schoolService: SchoolService, private titleService: Title, private metaService: Meta) {
    this.getSchoolLocations()
  }

  ngOnInit(): void {
    this.titleService.setTitle('Eduspeed: Find Top CBSE , IGCSE Schools, IIT/NEET/JEE Coaching Centers & Educational Resources, Your Online Guided Learning platform For Grades 6-9');
    this.metaService.updateTag({ name: 'description', content: 'Eduspeed helps you find top CBSE, ICSE, IGCSE, and IB schools near you, from Nursery to International levels. Get details on admissions, fees, docs required, teaching methodology, extracurriculars and more. Discover IIT, NEET, and JEE coaching institutes nearby. Benefit from our online guided learning for Grades 6-9, focusing on Mathematics and Science for competitive exams and Olympiads.' });
    this.metaService.updateTag({ name: 'keywords', content: 'CBSE schools,ICSE schools,IGCSE schools,IB schools, Special Need Schools, International schools, Nursery schools, Primary schools, Middle schools, High schools, Day care, Preschools, Play schools, Boarding schools, Day cum boarding schools, Day schools, IIT coaching, NEET coaching, JEE coaching, Online school search, Competitive exams preparation, Educational resources, K12 education, School search, School admissions, School admission info, Admissions forms, School fees, Fee details, Teaching methodology, extracurricular, co-curricular activities, infrastructure details, Find schools nearby, locality filter, Online Guided Learning Platform, Guided Learning Coaching, Grade 6-9, Quick Search by Board'});
  }

  getSchoolLocations(){
    this.schoolService.getAllRegions().subscribe({
      next: (locations) => {
        this.locations = locations.payload
        this.states = Object.keys(this.locations)
      },
      error: (err) => console.error('Error occured when retrieving location data', err)
    })
  }

  setState(state: string): void{
    this.state = state
  }

  setCity(city: string): void{
    this.city = city
  }

  getCities(state: any): any {
    if(state === undefined) {
        return [];
    }
    return this.locations[state];
  }

  setBoard(board: string): void{
    this.board = board
  }
  search(){
    if(!this.state && !this.city){
      alert('Please select State and City to refine your search!')
      return
    }
    if(!this.city) {
      alert('Please select City to refine your search!')
      return
    }

    this.router.navigateByUrl('/schools', this.getExtras());

  };


  getExtras(): NavigationExtras {
    return {
      queryParams: {
        category: this.board,
      },
      state: {
        linkData: {
            state: this.state,
            city: this.city,
            board: this.board.toUpperCase()
        }
      }
    }
  }

}
