<app-navbar></app-navbar>
<div class="inner-banner inner-admin">
  <div class="container">
      <div class="inner-title text-center">
          <h3>Admin</h3>
      </div>
  </div>
</div>
<!-- <div class="container first-typography background" style="padding-top: 30px;padding-bottom: 50px;"> -->
  <mat-card class="container first-typography background" style="padding-top: 30px;padding-bottom: 50px;padding-left: 50px;">
    <h2 class="title">Update vlog</h2>
    <form (action)="search()">
      <div class="row">
        <div class="row"><h3>Search Vlogs</h3></div>
        <div class="col-4">
          <mat-form-field>
            <mat-label>Vlog ID</mat-label>
            <input matInput [(ngModel)]="searchVlog.id" name="id">
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field>
            <mat-label>Title</mat-label>
            <input matInput [(ngModel)]="searchVlog.title" name="title">
          </mat-form-field>
          <span><i>*Wildcard search also works. Ex: %CBSE%</i></span>
        </div>
        <div class="col-4">
          <mat-form-field>
            <mat-label>Category</mat-label>
            <mat-select [(ngModel)]="searchVlogCategory" name="category" (ngModelChange)="searchVlog.category = searchVlogCategory">
              <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>

    <div class="action">
      <button mat-raised-button color="primary" (click)="search()" >Search</button>
    </div>
  </mat-card>
  <mat-card class="container first-typography background" style="margin-top: 50px;padding-bottom: 50px;padding-left: 50px;" *ngIf="vlogs?.data">
    <div style="text-align: center;" *ngIf="vlogs?.data?.length === 0">
      No vlogs found for the given search criteria.
    </div>

    <div *ngIf="vlogs?.data.length">
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
      </mat-form-field>
      <table mat-table [dataSource]="vlogs" matSort>
  
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
      
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
          <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>
      
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
          <td mat-cell *matCellDef="let element"> {{element.category}} </td>
        </ng-container>
  
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element"> <button mat-raised-button color="primary" (click)="openUpdateVlog(element)" >Update</button> </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" #paginator></mat-paginator>
    </div>
  </mat-card>

    <ng-template style="width: 75vw;" #vlogForm>
      <h2 mat-dialog-title>Update Vlog</h2>
      <mat-dialog-content class="mat-typography">
        <form>
          <div class="row vlog">
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select required [(ngModel)]="vlogStatus" name="status" (ngModelChange)="vlog.status = vlogStatus">
                <mat-option [value]="status" *ngFor="let status of statuses">{{ status }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row vlog">
            <mat-form-field>
              <mat-label>Title</mat-label>
              <input matInput required [(ngModel)]="vlog.title" name="title">
            </mat-form-field>
          </div>
          <div class="row vlog">
            <mat-form-field>
              <mat-label>Category</mat-label>
              <mat-select required [(ngModel)]="vlogCategory" name="category" (ngModelChange)="vlog.category = vlogCategory">
                <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row vlog">
            <mat-form-field>
              <mat-label>Youtube Link</mat-label>
              <input matInput required [(ngModel)]="vlog.youtubeLink" name="link">
            </mat-form-field>
          </div>
        </form>
      
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-raised-button [mat-dialog-close]=false>Cancel</button>
        <button mat-raised-button (click)="saveContent()" color="primary" cdkFocusInitial>Update</button>
      </mat-dialog-actions>
    </ng-template>
  