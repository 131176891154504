import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { encode } from 'js-base64';
import { ButtonAction } from 'src/app/models/general';
import { EventService } from 'src/app/services/events/event-service.service';
import { Campaign } from 'src/app/models/event';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
declare let $: any

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrl: './create-event.component.scss'
})
export class CreateEventComponent implements OnInit{
  eventCategory: string = '';
  event : Campaign
  = {
    id: '',
    name: '',
    content: '',
    startDate: null,
    endDate: null,
    formLink: '',
    category: this.eventCategory,

  }
  categories = [];
  
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl('');
  tags: string[] = [];
  isSchoolSpecificBlog = false 
  buttonAction: ButtonAction 

  constructor(private eventService: EventService) {
  }

  ngOnInit(): void {
      this.categories = this.eventService.getEventCategories();
  }

  startDateSelected(event: MatDatepickerInputEvent<Date>) {
    this.event.startDate = event.value;
  }

  endDateSelected(event: MatDatepickerInputEvent<Date>) {
    this.event.endDate = event.value;
  }

  updateContent(event): void{
    this.event.content = encode(event)
    this.createEvent()
  }

  saveContent(): void{
    this.buttonAction = ButtonAction.SAVE
  }

  createEvent(){
    this.start()
    
    const { id, name, category, content, formLink, startDate, endDate } = this.event
    if( !id || !name || !category || !content || !formLink || !startDate || !endDate){
      this.end()
      alert('Please fill in all the mandatory fields')
      return
    }
    this.event.formLink = encode(this.event.formLink);
    this.eventService.createEvent(this.event).subscribe({
      next: () => {
        this.reset()
        this.end()
        alert('Event Created Successfully!')
      },
      error: () => {
        this.end()
        alert('Error in creating event. Try again later.')
      }
    })
  }

  reset(){
    this.eventCategory = undefined
    this.buttonAction = ButtonAction.RESET
    this.event = {
      id: '',
      name: '',
      content: '',
      category: '',
      formLink: '',
      startDate: null,
      endDate: null
    }
  }

  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }
}
