<app-navbar></app-navbar>

<div class="inner-banner inner-bg-glp">
    <div class="container">
        <div class="inner-title text-center">
            <!-- <h3>Guided Learning Platform</h3> -->
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="row">
                <div class="col-lg-9 col-md-8 col-sm-12 mb-4" style="padding: 25px; height: fit-content; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
                    <div class="about-content">
                        <div class="section-title" style="padding-bottom: 20px;">
                            <h1>Online Guided learning: Grade 6-9</h1>
                            <div>
                                <h2 style="font-size: 16px; font-weight: 400;">
                                    Strong foundation in Mathematics and Science for Grades 6-9 is critical for establishing a solid groundwork in
                                    concepts
                                    for competitive exams and Olympiads.
                                </h2>
                                <div>
                                    Subscribe to our unique guided learning solutions and give your child limitless opportunities.
                                </div>

                                <h3>Eligibility:</h3>
                                <ul>
                                    <li>Students studying in Grade 6-9 in CBSE affiliated schools</li>
                                    <li>Medium of study: English</li>
                                    <li>Mode of study: Online</li>
                                    <li>Duration: 1 year program (Foundation & Advanced)</li>
                                </ul>
                                Fill in the below enquiry form to get started:
                            </div>
                        </div>
                    </div>
                    <div [innerHtml]="iFrameLink"></div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="blog-widget-left">
                        <div class="blog-widget">
                            <h3 class="title">What we intend?</h3>
                            <div class="widget-popular-post">
                                <article class="item">
                                    <div class="container about-img">
                                        <a [href]="sherpaUrl" target="_blank"><img src="assets/img/guided-learning/glp.png" alt="image"></a>
                                    </div>
                                    <!-- <div style="cursor: pointer;"><span class="full-image" role="img"><img src="assets/img/guided-learning/glp.png" alt="Images"></span></div> -->
                                    <div class="info">
                                        <p style="font-size: 12px; line-height: normal; max-width: 100%;">Our Goal is to ensure mastery in Mathematical and Science
                                            concepts from Grade VI onwards until Grade XII through our unique Guided
                                            Learning Platform. <br><br>
                                            We will be launching concept learning platform soon for crucial subjects, starting with
                                            Grades VI to VIII, stay tuned via signing up for Newsletters, for updates on launch
                                            and trial packages.</p>
                                    </div>
                                </article>
                            </div>

                    </div>
                </div>
                    <div class="blog-widget-left">
                        <div class="blog-widget" *ngIf="similarBlogs">
                            <h3 class="title">Our Blogs</h3>
                            <div class="widget-popular-post">
                                <article class="item" *ngFor="let similarBlog of similarBlogs">
                                    <div style="cursor: pointer;" (click)="openSimilarBlog(similarBlog)"><span class="full-image" role="img"><img src="images/{{ similarBlog.id }}.jpg" alt="Images"></span></div>
                                    <div class="info">
                                        <span>{{ similarBlog.publishDate | date: 'longDate' }}</span>
                                        <h4 class="title-text"><a style="cursor: pointer;" (click)="openSimilarBlog(similarBlog)">{{ similarBlog.title }}</a></h4>
                                        <p>{{ similarBlog.subTitle }}</p>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<app-footer></app-footer>
