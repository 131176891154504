export const environment = {
  production: true,

  endpoints: {
    schoolService: '',
    adminService: '',
  },
  sherpaAppUrl: 'https://sherpatest.eduspeed.org',
  googleMapsApiKey: 'AIzaSyBp__k_IMC3Zq6q4f6WargnxYpujjn0mzo',
  googleMapsMapId: 'a78559245179d86c'
};
