import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CoachingCenterDetails, CoachingCenterResponse, CoachingCenterResponsePayload } from 'src/app/models/coaching-center';
import { CoachingCenterService } from 'src/app/services/coaching-center/coaching-center.service';
declare let $: any;

@Component({
  selector: 'app-search-coaching-center',
  templateUrl: './search-coaching-center.component.html',
  styleUrl: './search-coaching-center.component.scss'
})
export class SearchCoachingCenterComponent {
  
  constructor(private coachingCenterService: CoachingCenterService) {}
  
  id: string = '';
  @Input()
  usedIn: string;
  coachingCenterByIdResponse: CoachingCenterDetails

  @Output()
  centerDetails = new EventEmitter();

  search() {
    this.start();

    if(this.id !== '') {
      this.coachingCenterService.findCoachingCenterById(this.id).subscribe({
        next: (value) => {
          this.coachingCenterByIdResponse = value.payload.coachingCenter
          
              if(this.usedIn === UsedIn.DUPLICATE) {
                this.coachingCenterByIdResponse.id = null;
                this.coachingCenterByIdResponse.name = null;
              }
              this.centerDetails.emit(this.coachingCenterByIdResponse)
              this.end()
        },
        error:() => {
          alert('Coaching center not found')
          this.end()
        },

      })
    } else {
      alert('Please enter Coaching Center Id')
    }
  }
  start(){
    $('.preloader').fadeIn('slow');
  }

  end(){
    $('.preloader').fadeOut('slow');
  }
}

export enum UsedIn {
  CREATE = 'Create',
  DUPLICATE = 'Duplicate',
  EDIT = 'Edit',
}