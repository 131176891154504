<app-navbar />

<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-title text-center">
            <h1>{{title}}</h1>
            <h2>Find Top Coaching Centers for Competitive Exams</h2>
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="index.html">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">Coaching center</li>
                    </ul>
                </div>

                <!-- <div class="col-lg-6 col-md-5" *ngIf="board !== 'ALL'">
                    <p>Results for: <span style="color: var(--mainColor)">{{board}}</span></p>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div container>
    <div style="background-color: #ededed;">


        <div class="row">
            <div class="col-lg-3 col-md-6" style="padding-left: 3%;padding-bottom:5%">
                <coaching-center-filter class="filter" style="background-color: white" [filterCriteria]="filterCriteria"
                    (filters)="applyFilter($event)" (reset)="resetFilter()" >
                </coaching-center-filter>
            </div>
            <div class="col-lg-6 col-md-6" style="padding-left: -30px;">
                @if (!coachingCenters?.length) {
                    <div class="cards not-found">
                        No Coaching centers found. Please change the filters and try again later
                    </div>
                } @else {
                    <div style="text-align: right;padding-bottom: 10px;">
                        <mat-paginator #paginator class="paginator" (page)="handlePageEvent($event)"
                            [length]="totalRecords" [pageSize]="pageSize" [pageIndex]="currentPage"
                            [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
                        </mat-paginator>
                    </div>

                    <div class="col-lg-12 col-md-12" style="display: flex; justify-content: space-between;">

                    </div>

                    @for (coachingCenter of coachingCenters; track $index) {
                        <div class="cards">
                            <coaching-center-basic-details [coachingCenter]="coachingCenter"></coaching-center-basic-details>
                        </div>
                    }

                    <div class="col-lg-12 col-md-12" style="padding-bottom: 5%;">
                        <mat-paginator #paginator class="paginator" (page)="handlePageEvent($event)"
                            [length]="totalRecords" [pageSize]="pageSize" [pageIndex]="currentPage"
                            [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
                        </mat-paginator>
                    </div>
                }
            </div>
            <div class="col-lg-3 col-md-6" style="padding-right: 3%;padding-bottom:5%">
                <div class="right-container" style="background-color: white;padding:10px">
                    <div class="box">
                        <div class="google-map-placeholder">
                            <app-google-maps [organization]="mapsOraganizationType" [location]="filterCriteria.location"></app-google-maps>
                        </div>
                    </div>
                    <div class="box">
                        <h3 style="font-family: var(--fontFamily);font-weight: bold;">Popular Listings</h3>
                        <app-popular-listings orientation="Vertical" category="Bronze"></app-popular-listings>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<app-footer></app-footer>
