<app-navbar></app-navbar>

<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h1>Insightful blogs on NEET, JEE, SAT, CAT, and educational practices  </h1>
        </div>
    </div>
</div>

<div class="blog-area pb-70">
    <div class="filter-container">
        <div id="header">
            <ul class="categories">
                <li *ngFor="let blogCategory of categories"  [ngClass]="{'activeTab': category === blogCategory}"><a style="cursor: pointer;" (click)="onCategorySelect(blogCategory)">{{ blogCategory }}</a></li>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="row pt-45">
            @if (blogs.length === 0){
                <div class="not-found">No blogs found for {{category}}. Please choose other categories</div>
            } @else {

                <div class="col-lg-4 col-md-6" *ngFor="let blog of blogs; let i = index">
                    <div class="blog-card">
                        <a style="cursor: pointer;" (click)="openBlog(blog)"><img src="images/{{ blog.id }}.jpg" [alt]="blog.title" width="650" height="500"></a>
                        <div class="content">
                            <span> {{ blog.publishDate | date: 'longDate' }} / <a style="cursor: pointer;" (click)="openBlog(blog)"> {{ blog.category }} </a></span>
                            <h3><a style="cursor: pointer;" (click)="openBlog(blog)"> {{ blog.title }} <span *ngIf="blog.subTitle"> - {{ blog.subTitle }} </span></a></h3>
                            <a style="cursor: pointer;" (click)="openBlog(blog)" class="read-more">Read More</a>
                        </div>
                    </div>
                </div>


                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area text-center">
                        <mat-paginator #paginator (page)="handlePageEvent($event)"
                        [length]="totalRecords" [pageSize]="pageSize" [pageIndex]="currentPage"
                        [showFirstLastButtons]="true" [pageSizeOptions]="[6,12,18]" aria-label="Select page">
                    </mat-paginator>
                </div>
        </div>
        }
        </div>
    </div>
</div>

<app-footer></app-footer>
