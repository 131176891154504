
<app-navbar></app-navbar>
<div class="inner-banner inner-admin">
  <div class="container">
      <div class="inner-title text-center">
          <h3>Admin</h3>
      </div>
  </div>
</div>

  <mat-card class="container first-typography background" style="padding-top: 30px;padding-bottom: 50px;padding-left: 50px;">
  <h2 class="title">Create Blog</h2>
  <form>
    <div class="row blog">
      <mat-form-field>
        <mat-label>Blog ID</mat-label>
        <input matInput required [(ngModel)]="blog.id" name="id">
      </mat-form-field>
    </div>
    <div class="row blog">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput required [(ngModel)]="blog.title" name="title">
      </mat-form-field>
    </div>
    <div class="row blog">
      <mat-form-field required>
        <mat-label>Sub Title</mat-label>
        <input matInput required [(ngModel)]="blog.subTitle" name="subTitle">
      </mat-form-field>
    </div>
    <div class="row blog">
      <mat-form-field>
        <mat-label>Category</mat-label>
        <mat-select required [(ngModel)]="blogCategory" name="category" (ngModelChange)="blog.category = blogCategory">
          <mat-option [value]="category" *ngFor="let category of categories">{{ category }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field required>
        <mat-label>Author</mat-label>
        <input matInput [(ngModel)]="blog.author.name" name="author">
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field>
        <mat-label required>Biography</mat-label>
        <input matInput [(ngModel)]="blog.author.bio" name="bio">
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field>
        <mat-label>Facebook</mat-label>
        <input matInput [(ngModel)]="blog.author.facebook" name="facebook">
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field>
        <mat-label>Youtube</mat-label>
        <input matInput  [(ngModel)]="blog.author.youtube" name="youtube">
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field>
        <mat-label>Twitter</mat-label>
        <input matInput  [(ngModel)]="blog.author.twitter" name="twitter">
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field>
        <mat-label>LinkedIn</mat-label>
        <input matInput  [(ngModel)]="blog.author.linkedIn" name="linkedIn">
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-form-field>
        <mat-label>Add tags for this blog</mat-label>
        <mat-chip-grid #chipGrid aria-label="Tag selection" [(ngModel)]="blog.tags" required name="tags">
          <div *ngFor="let tag of blog.tags; track tag">
            <mat-chip-row (removed)="remove(tag)" color="accent">
              {{tag}}
              <button matChipRemove [attr.aria-label]="'remove ' + tag">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </div>
        </mat-chip-grid>
        <input placeholder="Type a tag name and enter..." #tagInput [formControl]="tagCtrl"
          [matChipInputFor]="chipGrid"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"/>
      </mat-form-field>
    </div>
  

    <div class="row blog">
      <mat-slide-toggle
        required 
        (change)="toggleSelection($event)" 
        [checked]="isSchoolSpecificBlog"
        labelPosition="before"
        style="margin-bottom: 20px;">
        <span style="font-family: var(--fontFamily);">Is this blog specific to a school?</span>
      </mat-slide-toggle>

      <mat-form-field required *ngIf="isSchoolSpecificBlog">
        <mat-label>School Registration Code</mat-label>
        <input required matInput  matInput [(ngModel)]="blog.schoolRegistrationCode" name="schoolRegistrationCode">
      </mat-form-field>
    </div>

    <div class="row blog">
        <mat-slide-toggle
        required 
        (change)="togglePremiumSelection($event)" 
        [checked]="blog.isPremiumBlog"
        labelPosition="before"
        style="margin-bottom: 20px;">
        <span style="font-family: var(--fontFamily);">Is this a Premium Blog?</span>
      </mat-slide-toggle>

      <mat-form-field required *ngIf="blog.isPremiumBlog">
        <mat-label>Announcement Category</mat-label>
        <mat-select required [(ngModel)]="announcementCategory" name="announcmentCategory" (ngModelChange)="blog.blogAnnouncementCategory = announcementCategory">
          <mat-option [value]="category" *ngFor="let category of announcementCategories">{{ category }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row blog">
      <mat-slide-toggle
        required 
        (change)="toggleSubcriberContent($event)" 
        [checked]="blog.isSubscriberContent"
        labelPosition="before"
        style="margin-bottom: 20px;">
        <span style="font-family: var(--fontFamily);">Is this a Subscriber content blog?</span>
      </mat-slide-toggle>
    </div>
  </form>

  <text-editor (content)="updateContent($event)" [buttonAction]="buttonAction"></text-editor>

  <div class="action">
    <button mat-raised-button class="submit" (click)="reset()">Cancel</button>
    <button mat-raised-button color="primary" (click)="saveContent()">Submit</button>
  </div>
</mat-card>
