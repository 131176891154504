
<div class="content">
  <div class="display">
  </div>
  <form [formGroup]="form">
    <div class="editor">
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
      </ngx-editor-menu>
      <ngx-editor [editor]="editor" [(ngModel)]="editordoc" formControlName="editorContent">
      </ngx-editor>
    </div>
  </form>
</div>
