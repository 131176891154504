import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class DataEntryGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) {}

  canActivate(): boolean {
    if (this.userService.isDataEntryUser() || this.userService.isAdminUser()) {
      return true;
    } else {
      this.router.navigate(['/error']);
      return false;
    }
  }
}