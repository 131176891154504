<app-navbar></app-navbar>

<div class="inner-banner" style="height: 70%; background-repeat: no-repeat; background-size: contain; background-image: url(images/{{blog?.id}}.jpg)">
    <div class="container">
        <div class="inner-banner-title text-center">
            <!-- <h3> {{ blog?.title }}</h3>
            <p> {{ blog?.subTitle }} </p> -->
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="/">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li><a href="/blog">Blogs</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">{{ blog?.title }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area pt-45 pb-70" style="background-color: darkgrey;">
    <div class="container">
        <div class="row">
            <div class="col-lg-9">
                <div class="blog-article">

                    <div class="article-content" >
                        <h1>{{ blog?.title }}</h1>
                       <div [innerHtml]="blog?.content" class="mainContent"  [ngClass]="restrictContent ? 'restricted' : ''">
                       </div>

                       <div *ngIf="restrictContent" class="overlay">
                        <button class="default-btn" (click)="goToLogin()">
                            <div class="d-flex flex-column align-items-center">
                                <div class="fw-semibold">
                                    <i class='bx bx-lock-alt' ></i>
                                    Login/Register for free
                                </div>
                                <span class="small">to view content</span>
                            </div>
                        </button>
                      </div>
                    </div>

                    <div class="comments-wrap" *ngIf="blog?.comments.length">
                        <h3 class="title">Comments ({{ blog.comments.length | number: '2.0-0' }})</h3>

                        <ul>
                            <li *ngFor="let comment of blog.comments">
                                <img style="width: 75px; height: 75px;" src="assets/img/blog/comment-profile1.png" alt="Image">
                                <h3> {{ comment.name }} </h3>
                                <span>{{ comment.timeStamp }}</span>
                                <p>{{ comment.comment }}</p>
                            </li>
                        </ul>
                    </div>

                    <div class="comments-form">
                        <div class="contact-form">
                            <span>Hope you enjoyed this read, we would love to hear your key takeaways on this blog, pls add it in below comments section</span>
                            <h2></h2>

                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="text" name="name" class="form-control" readonly placeholder="Name*" value="{{user?.firstName}} {{user?.lastName}}">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <i class='bx bx-mail-send'></i>
                                            <input type="email" name="email"  class="form-control" readonly placeholder="Email Id*" value="{{user?.email}}">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <i class='bx bx-envelope'></i>
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" [(ngModel)]="message" required placeholder="Your Message"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" (click)="addComment()" class="default-btn border-radius">Submit  </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3">
                    <div class="blog-widget-left">
                        <div class="blog-widget-profile" *ngIf="blog?.author">
                            <div class="images">
                                <img [src]="authorImage" alt="Images">
                            </div>
                            <div class="content">
                                <h3> {{ blog.author.name }} </h3>
                                <span>Author Of This Blog</span>
                            </div>
                            <p> {{ blog.author.bio }} </p>
                            <ul class="social-link">
                                <li *ngIf="blog.author.facebook"><a [href]="blog.author.facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li *ngIf="blog.author.twitter"><a [href]="blog.author.twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li *ngIf="blog.author.instagram"><a [href]="blog.author.instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li *ngIf="blog.author.pinterest"><a [href]="blog.author.pinterest" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                                <li *ngIf="blog.author.youtube"><a [href]="blog.author.youtube" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                            </ul>
                        </div>
                        <div class="blog-widget">
                            <h3 class="title">Popular Listings</h3>
                            <div class="widget-popular-post" style="padding: 0;">
                                <app-popular-listings orientation="Vertical" category="Silver"></app-popular-listings>
                            </div>
                        </div>
                        <div class="blog-widget" *ngIf="similarBlogs">
                            <h3 class="title">Similar Posts</h3>
                            <div class="widget-popular-post">
                                <article class="item" *ngFor="let similarBlog of similarBlogs">
                                    <div style="cursor: pointer;" (click)="openSimilarBlog(similarBlog)"><span class="full-image" role="img"><img src="images/{{ similarBlog.id }}.jpg" alt="Images"></span></div>
                                    <div class="info">
                                        <span>{{ similarBlog.publishDate | date: 'longDate' }}</span>
                                        <h4 class="title-text"><a style="cursor: pointer;" (click)="openSimilarBlog(similarBlog)">{{ similarBlog.title }}</a></h4>
                                        <p>{{ similarBlog.subTitle }}</p>
                                    </div>
                                </article>
                            </div>
                        </div>

                        <div class="blog-widget" *ngIf="blog?.tags">
                            <h3 class="title">Tags</h3>

                            <ul class="blog-widget-tag">
                                <li><a style="cursor: pointer;" (click)="openBlogsByTag(tag)" *ngFor="let tag of blog.tags">#{{tag}}</a></li>
                            </ul>
                        </div>

                        <div class="blog-widget" *ngIf="blogCategories">
                            <h3 class="title">Categories</h3>

                            <div class="widget_categories">
                                <ul>
                                    <li *ngFor="let category of blogCategories"><a style="cursor: pointer;" (click)="openBlogsByCategory(category.name)">{{category.name}} <span>({{category.count}})</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
